import Chapter from '../Chapter'
import HiverOfTheDay from './HiverOfTheDay'
import DOMManager from '../../managers/DOMManager'
// import CameraManager from '../../managers/CameraManager'
import config from '../../settings/config'
import Step from '../Step'

/**
 * The second Chapter of the story. The trigger of the story happens in it, with the first message sent by Zima.
 * @todo Merge Tutorial and Chapter2 as they both are continuous.
 * @class
 */
export default class Chapter2 extends Chapter {
  constructor(props = {}) {
    super({
      name: config.scenario.chapter2.name,
      steps: props.steps,
      refreshInterfacesOnStart: props.refreshInterfacesOnStart
    })
  }

  createSteps() {
    return [
      new HiverOfTheDay(),
      new Step(config.scenario.chapter2.steps.comment),
      new Step(config.scenario.chapter2.steps.report)
    ]
  }

  // onStart() {
  //   return new Promise((resolve) => {
  //     CameraManager.setStandUpPosition()

  //     resolve()
  //   })
  // }

  /**
   * Ends this Chapter by fading out the screen until its black, by using DOMManager after a given delay.
   *
   * @returns {Promise} A Promise resolved when the DOMManager finished the animation.
   */
  onEnd() {
    return DOMManager.interlude(2)
  }
}
