import TimelineMax from 'gsap/TimelineMax'

import config from '../../settings/config'
import HistoryManager from '../../managers/HistoryManager'
import { degreesToRadians } from '../../utils/rad-deg'
import Interactive from '../interactives/Interactive'
import Video from '../Video'
import HiveObjects from '../HiveObjects'
import Button from '../Button'
import CameraManager from '../../managers/CameraManager';

/**
 * The base class to create an Interface in the Scene. Used for Interface planes in the scene (not physical elements).
 * Extends from Interactive so it has `onApproach` / `onGetAway` and `onStartLooking` / `onStopLooking` behavior.
 * @class
 */
export default class Interface extends Interactive {

  /**
   * Creates an Interface by preparing its active Steps, creating its sounds, buttons, textures and modifying its helpersColor.
   *
   * @param {Object} param An object with a mesh, a name and some settings.
   * @param {Mesh} param.mesh The mesh to interact with.
   * @param {string} param.name The instance / mesh name.
   * @param {boolean} param.isImage If `true`, this Interface will use Texture instead of VideoTexture.
   */
  constructor({ mesh, name, isImage = false, roomName = null }) {
    super({
      mesh,
      name,
      roomName
    })

    this.bindMethods()

    this.canInteract = true
    this.isImage = isImage
    this.isShow = false
    this.textures = []
    this.buttons = []
    this.buttonGroups = []
    this.helpersColor = 0x0000ff
    this.steps = this.setSteps()
    this.isInterface = true
    
    this.textures = this.setTextures()
    this.setTexturesCallbacks()
    this.addSounds()
    this.addButtons()

    // If this Interface doesn't have any Texture, it is useless to continue creating it.
    if (!this.textures.length) {
      config.debug && console.warn('[InterfaceObject] - No textures for ' + this.name + '-interface')

      return
    }

    this.setChildProps()
    this.setMaterial()
    this.setFirstTexture()
    this.rotateMesh()

    this.mesh.matrixAutoUpdate = false
    this.mesh.updateMatrix()
  }

  bindMethods() {
    super.bindMethods()

    this.onSoundEnded = this.onSoundEnded.bind(this)
    this.onVideoEnded = this.onVideoEnded.bind(this)
    this.onVideoPlayed = this.onVideoPlayed.bind(this)
  }

  /**
   * Sets the active Steps for this Interface.
   * Called by constructor.
   * Override it to add specific Steps by returning an array of them.
   *
   * @override
   * @returns {Array} An array of Step instances defining when this Interface will be used.
   */
  setSteps() {
    config.debug && console.warn('[InterfaceObject] - Method setSteps need to e overrided')

    return []
  }

  /**
   * Sets a list of textures available for this Interface.
   * Used to navigate between Videos.
   *
   * @override
   * @returns {Array} An array of Video instances
   */
  setTextures() {
    config.debug && console.warn('[InterfaceObject] - setTextures() method need to be overriden by child Class')
    
    return []
  }

  /**
   * Maps the video events from the VideoTexture to `this.onVideoPlayed` and `this.onVideoEnded` callback methods.
   *
   * @returns {void}
   */
  setTexturesCallbacks() {
    for (let i = 0; i < this.textures.length; i++) {
      if (this.textures[i] instanceof Video) {
        this.textures[i].videoEndedCallBack = this.onVideoEnded
        this.textures[i].videoPlayCallBack = this.onVideoPlayed
      }
    }
  }

  /**
   * Fired when a "ended" event occurs on a video texture.
   *
   * @param {Video} video The video that fired the callback.
   *
   * @override
   * @event
   * @returns {void}
   */
  onVideoEnded(video) {}

  /**
   * Fired when a "play" event occurs on a video texture.
   *
   * @param {Video} video The video that fired the callback.
   *
   * @override
   * @event
   * @returns {void}
   */
  onVideoPlayed(video) {}

  /**
   * Attaches Buttons to this Interface.
   * Called by constructor. You must override it to add buttons.
   *
   * @override
   * @returns {void}
   */
  addButtons() {}

  /**
   * @todo Ask Lucas more information about this method.
   *
   * @override
   * @returns {void}
   */
  setChildProps() {}

  /**
   * Sets the Mesh' Material.
   * Called by constructor.
   * Override it to add custom Material with specific Texture.
   *
   * @override
   * @returns {void}
   */
  setMaterial() {
    config.debug && console.warn('[InterfaceObject] - setMaterial() method needs to be overriden by child Class')
  }

  /**
   * Sets the Mesh' first Texture.
   * Called by constructor right after `this.setMaterial()`.
   * Override it to add custom first Texture.
   *
   * @override
   * @returns {void}
   */
  setFirstTexture() {
    config.debug && console.warn('[InterfaceObject] - setFirstTexture() method needs to be overriden by child Class')
  }

  /**
   * Rotates and flips the Interface so it is aligned with the model's walls.
   *
   * @todo Shouldn't be done at all.
   *
   * @returns {void}
   */
  rotateMesh() {
    if (this.mesh.scale.x !== -1) {
      this.mesh.rotateY(degreesToRadians(180))
      this.mesh.scale.x = -1
    }
  }

  checkActive() {
    const currentStepName = HistoryManager.currentChapter.currentStep.name
    const objectIndex = this.steps.indexOf(currentStepName)

    const isActive = objectIndex !== -1

    // if (!isActive && this.currentVideo && this.currentVideo.loop) this.currentVideo.loop = false

    return isActive
  }

  onApproach() {
    super.onApproach()

    if (this.isShow) return
    this.isShow = true
  }

  /**
   * Shows the buttons.
   *
   * @param {Array} groupButtons An array of buttons.
   * @param {boolean} animation Whether to animate or not.
   *
   * @returns {void}
   */
  showButtons(groupButtons = [], animation = true) {
    if (!groupButtons.length) return

    const { buttonsGeometries, buttonsMaterials } = this.getButtonsProperties(groupButtons)

    if (animation) {
      for (let i = 0; i < groupButtons.length; i++) {
        groupButtons[i].mesh.material.transparent = true
      }

      Button.textMaterial.transparent = true

      const tl = new TimelineMax({
        onStart: () => {
          for (let i = 0; i < groupButtons.length; i++) {
            groupButtons[i].mesh.visible = true
          }
        },
        onComplete: () => {
          for (let i = 0; i < groupButtons.length; i++) {
            groupButtons[i].updateWorldPosition()
            groupButtons[i].mesh.material.transparent = false
          }

          CameraManager.needsUpdate = true

          Button.textMaterial.transparent = false

          if (config.debug) {
            for (let i = 0; i < this.helpers.length; i++) {
              this.helpers[i].update()
            }
          }
        }
      })

      tl.staggerTo(buttonsGeometries, 0.6, {
          x: '+=15',
          ease: Power2.easeInOut
        }, 0.02, 0)
        .staggerTo(buttonsMaterials, 0.6, {
          value: 1.0,
          ease: Power2.easeInOut
        }, 0.02, 0)
        .staggerTo(Button.textMaterial, 0.6, {
          opacity: 1.0,
          ease: Power2.easeInOut
        }, 0.02, 0)
    } else {
      for (let i = 0; i < groupButtons.length; i++) {
        groupButtons[i].mesh.visible = true
      }

      TweenMax.set(buttonsGeometries, { x: '+=15' })
      TweenMax.set(buttonsMaterials, { value: 1.0 })
      TweenMax.set(Button.textMaterial, { opacity: 1.0 })

      for (let i = 0; i < groupButtons.length; i++) {
        groupButtons[i].updateWorldPosition()
        groupButtons[i].mesh.material.transparent = false
      }

      Button.textMaterial.transparent = false

      if (config.debug) {
        for (let i = 0; i < this.helpers.length; i++) {
          this.helpers[i].update()
        }
      }

      CameraManager.needsUpdate = true
    }
  }

  /**
   * Hides the buttons.
   *
   * @param {Array} groupButtons An array of buttons.
   * @param {boolean} animation Whether to animate or not.
   * @param {boolean} destroy Whether to destroy the button on end or not.
   *
   * @returns {void}
   */
  hideButtons(groupButtons = [], animation = true, destroy) {
    if (!groupButtons.length) return

    const { buttonsGeometries, buttonsMaterials } = this.getButtonsProperties(groupButtons)

    if (animation) {
      for (let i = 0; i < groupButtons.length; i++) {
        groupButtons[i].mesh.material.transparent = true
      }

      Button.textMaterial.transparent = true

      const tl = new TimelineMax({
        onComplete: () => {
          // Remove the Group from the scene.
          if (destroy) groupButtons[0].mesh.parent.parent.remove(groupButtons[0].mesh.parent)

          for (let i = 0; i < groupButtons.length; i++) {
            groupButtons[i].mesh.visible = false
            groupButtons[i].mesh.material.transparent = false
            groupButtons[i].updateWorldPosition()

            if (destroy) groupButtons[i].destroy()
          }

          if (config.debug) {
            for (let i = 0; i < this.helpers.length; i++) {
              this.helpers[i].update()
            }
          }

          Button.textMaterial.transparent = false

          CameraManager.needsUpdate = true
        }
      })

      tl.staggerTo(buttonsGeometries, 0.6, {
          x: '-=15',
          ease: Power2.easeInOut
        }, 0.02, 0)
        .staggerTo(buttonsMaterials, 0.6, {
          value: 0,
          ease: Power2.easeInOut
        }, 0.02, 0)
        .staggerTo(Button.textMaterial, 0.6, {
          opacity: 0,
          ease: Power2.easeInOut
        }, 0.02, 0)
    } else {
      TweenMax.set(buttonsGeometries, { x: '-=15' })
      TweenMax.set(buttonsMaterials, { value: 0 })
      TweenMax.set(Button.textMaterial, { opacity: 0 })

      for (let i = 0; i < groupButtons.length; i++) {
        groupButtons[i].mesh.visible = false
        groupButtons[i].mesh.material.transparent = false
        groupButtons[i].updateWorldPosition()

        if (destroy) groupButtons[i].destroy()
      }

      if (config.debug) {
        for (let i = 0; i < this.helpers.length; i++) {
          this.helpers[i].update()
        }
      }

      Button.textMaterial.transparent = true

      CameraManager.needsUpdate = true

      if (destroy) groupButtons[0].mesh.parent.parent.remove(groupButtons[0].mesh.parent)
    }
  }

  /**
   * Gets all materials and geometries from an array of Button instances.
   *
   * @param {Array} groupButtons An array of Buttons.
   *
   * @returns {Object} An object containing arrays of the buttons' geometries named `buttonsGeometries`, an array of their materials named `buttonsMaterials` and an array of their text materials named `textMaterials`.
   */
  getButtonsProperties(groupButtons) {
    const buttonsGeometries = groupButtons.map((btn) => btn.mesh.position)
    const buttonsMaterials = groupButtons.map((btn) => {
      const val = btn.isHidden ? { value: 0 } : btn.mesh.material.uniforms.u_opacity

      return val
    })

    return {
      buttonsGeometries,
      buttonsMaterials
    }
  }

  startDebugMode() {
    super.startDebugMode()
    
    // Adds a folder to the GUI named Interfaces if it doesn't exist and store it to a static value.
    if (config.datGui && !Interface.GUIFolder) Interface.GUIFolder = config.datGui.addFolder('Interfaces')
  }

  /**
   * Destroys this Interface by removing it from `HiveObjects` static array.
   * Also disposes of the Mesh' material and texture.
   * Also destroys its Buttons if it has any.
   * Also removes the GUI folder if it has any and if debug mode.
   *
   * @returns {void}
   */
  destroy() {
    if (this.destroyed) return

    this.destroyed = true

    // for (const key in this.sounds.voices) {
    //   if (this.sounds.voices[key]) this.sounds.voices[key].destroy()
    // }

    this.sounds.voices = {}

    for (let i = 0; i < this.buttonGroups.length; i++) {
      if (this.buttonGroups[i].parent) this.buttonGroups[i].parent.remove(this.buttonGroups[i])
    }

    for (let i = 0; i < this.buttons.length; i++) {
      this.buttons[i].destroy()
    }

    if (Interface.GUIFolder && Interface.GUIFolder.__folders[this.name]) Interface.GUIFolder.removeFolder(Interface.GUIFolder.__folders[this.name])

    this.mesh.material.map = null

    for (let i = 0; i < this.textures.length; i++) {
      this.textures[i].destroy()
    }

    for (let i = 0; i < this.helpers.length; i++) {
      this.helpers[i].visible = false
    }

    this.mesh.material.dispose()

    const i = HiveObjects.interfaces.indexOf(this)

    HiveObjects.interfaces.splice(i, 1)
  }
}
