import VideoInterface from '../VideoInterface'
import Resources from '../../../Resources'
import config from '../../../settings/config'
import HistoryManager from '../../../managers/HistoryManager'

export default class WakeUpDay2 extends VideoInterface {
  setSteps() {
    return [config.scenario.chapter3.steps.wakeUpTomorrow]
  }

  play() {
    super.playVideo()
    
    this.sounds.voices.helloDay2.play()
  }

  setTextures() {
    return [Resources.textures.videos.wakeUp]
  }

  nextDay() {
    this.mesh.visible = false
  }

  onSoundEnded(sound) {
    if (sound === this.sounds.voices.helloDay2) HistoryManager.nextStep()
  }
}
