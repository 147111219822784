import HiveObjects from '../objects/HiveObjects'
import Tutorial from '../scenario/tutorial/Tutorial'
import Chapter2 from '../scenario/chapter2/Chapter2'
import Chapter3 from '../scenario/chapter3/Chapter3'
import Chapter4 from '../scenario/chapter4/Chapter4'
import Chapter5 from '../scenario/chapter5/Chapter5'
import config from '../settings/config'
import World from '../World';

/**
 * This class manages the different states of the story.
 * It creates the structure of our storyline.
 * It allows to navigate from a Step to another, from a Chapter to another.
 * @class
 */
export default class HistoryManager {

  /**
   * Creates the Chapters of the story.
   * Adds custom events for the end of Chapters.
   * Call `.start()` method to start the story.
   * @constructor
   */
  constructor() {
    HistoryManager.chapters = []

    this.bindMethods()
    this.createChapters()

    this.ended = false

    HistoryManager.isLocked = false
    HistoryManager.currentChapterIndex = 0
    HistoryManager.currentChapter = HistoryManager.chapters[HistoryManager.currentChapterIndex]
    
    this.events()
  }

  /**
   * Binds class methods to itself to keep the right scope.
   *
   * @returns {void}
   */
  bindMethods() {
    this.chapterEnded = this.chapterEnded.bind(this)
  }

  /**
   * Creates the Chapters of the story.
   * Adds them to the static `HistoryManager.chapters` array.
   *
   * @returns {void}
   */
  createChapters() {
    const tutorial = new Tutorial({ refreshInterfacesOnStart: false })
    const chapter2 = new Chapter2({ refreshInterfacesOnStart: false })
    const chapter3 = new Chapter3({ refreshInterfacesOnStart: true })
    const chapter4 = new Chapter4({ refreshInterfacesOnStart: true })
    const chapter5 = new Chapter5({ refreshInterfacesOnStart: true })

    HistoryManager.chapters.push(tutorial, chapter2, chapter3, chapter4, chapter5)
  }

  /**
   * Adds custom events that will be fired on Chapters end.
   *
   * @returns {void}
   */
  events() {
    for (let i = 0; i < HistoryManager.chapters.length; i++) {
      HistoryManager.chapters[i].addEventListener('end', this.chapterEnded)
    }
  }

  /**
   * Starts the story at the first Step of the first Chapter.
   * Loads the active Interfaces for the current Step.
   *
   * @returns {void}
   */
  start() {
    config.debug && console.log('Scenario : START')

    if (!HistoryManager.chapters.length) this.end()

    HistoryManager.currentChapter.start()
      .then(() => {
        // Entered new Step
        HiveObjects.reloadInterfacesActives()
      })
  }

  /**
   * Method that allow to go to the next Step in the story.
   * If `stepName` is given, goes directly to the Step named with the value of `stepName`.
   * If the current Step is the last of its Chapter, starts the first Step of the next Chapter.
   *
   * @param {string} stepName - A Step name to go to directly.
   *
   * @static
   * @returns {void}
   */
  static nextStep(stepName) {
    if (HistoryManager.isLocked) return

    HistoryManager.isLocked = true

    HistoryManager.currentChapter.nextStep(stepName)
      .then(() => {
        // Entered new Step
        HistoryManager.isLocked = false
        HiveObjects.reloadInterfacesActives()
      })
  }

  /**
   * Fired when a `end` event occurs at the end of a Chapter.
   * Goes to the next Chapter.
   * Also removes the "end" event listener from this chapter.
   *
   * @param {event} e - The event object.
   *
   * @event
   * @returns {void}
   */
  chapterEnded(e) {
    this.nextChapter()

    e.chapter.removeEventListener('end', this.chapterEnded)
  }

  /**
   * Goes to the next Chapter by starting its first Step.
   * If there is no next Chapter, end the Story.
   *
   * @returns {void}
   */
  nextChapter() {
    if (this.ended) return
    
    if (HistoryManager.currentChapterIndex === HistoryManager.chapters.length - 1) this.end()
    else {
      HistoryManager.currentChapterIndex++
      
      HistoryManager.currentChapter = HistoryManager.chapters[HistoryManager.currentChapterIndex]
      
      HistoryManager.currentChapter.start()
    }
  }

  /**
   * Ends the scenario.
   * Turns off every interactive object in the scene so they are not usable anymore.
   *
   * @returns {void}
   */
  end() {
    config.debug && console.log('Scenario : END')

    World.pause()

    setTimeout(() => {
      window.location.href = 'https://hive-experience.com/landing';
    }, 1500)

    for (let i = 0; i < HiveObjects.interactives.length; i++) {
      HiveObjects.interactives[i].canInteract = false
    }

    this.ended = true
  }
}
