import VideoInterface from '../VideoInterface'
import Resources from '../../../Resources'

/**
 * WakeUp4 is a VideoInterface that is the very first to be seen by the user on Chapter 4.
 * @class
 */
export default class Score4 extends VideoInterface {
    constructor(props) {
        super(props)
        this.play()
    }

    setTextures() {
        this.background = Resources.textures.videos.ch4Background
        this.renegatIn = Resources.textures.videos.ch4RenegatIn
        this.renegatLoop = Resources.textures.videos.ch4RenegatLoop

       return [
            this.background,
            this.renegatIn,
            this.renegatLoop
       ]
    }

    onVideoEnded(video) {
        if (video === this.background) this.updateCurrentVideo(this.renegatIn)
        if (video === this.renegatIn) this.updateCurrentVideo(this.renegatLoop)
    }
}
