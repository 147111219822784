import { Vector2 } from 'three'

/**
 * @todo Ask Lucas how to define this class.
 * @class
 */
export default class ImageTexture {
    constructor({ texture }) {
        this.texture = texture
        this.ratioFactor = null
    }

    getRatioFactor(geometryRatio) {
        if (!this.ratioFactor) this.updateAspectRatioFactor(geometryRatio)

        return this.ratioFactor
    }

    updateAspectRatioFactor(geometryRatio) {
        const imageRatio = this.texture.image.width / this.texture.image.height

        const factorX = geometryRatio < imageRatio ? geometryRatio / imageRatio : 1
        const factorY = geometryRatio > imageRatio ? 1 / geometryRatio * imageRatio : 1
        
        this.ratioFactor = new Vector2(factorX, factorY)
    }

    destroy() {
        this.texture.dispose()
    }
}
