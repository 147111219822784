import {
    ShaderMaterial,
    Box3,
    Vector2,
    Vector3
} from 'three'
import TweenMax from 'gsap/TweenMax'
import { Power1 } from 'gsap/EasePack'

import Interface from './Interface'
import imgTransitionVert from '../../shaders/imgTransition/imgTransition.vert'
import imgTransitionFrag from '../../shaders/imgTransition/imgTransition.frag'

/**
 * An ImageInterface extends from Interface because it used just like an Interface but has no video, just image(s).
 * @class
 */
export default class ImageInterface extends Interface {
    setChildProps() {
        this.factor = new Vector2(1.0, 1.0)
        this.planeSize = this.getPlaneSize()
        this.planeRatio = this.planeSize.x / this.planeSize.y
    }

    setMaterial() {
        this.uniforms = {
            u_texture: { value: null },
            u_texture2: { value: null },
            u_textureFactor: { value: this.factor },
            u_textureFactor2: { value: this.factor },
            u_progress: { value: 0.0 }
        }

        const material = new ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: imgTransitionVert,
            fragmentShader: imgTransitionFrag
        })
        
        this.mesh.material = material
    }

    setFirstTexture() {
        this.setCurrentImage(this.textures[0])
    }

    /**
     * Sets the first texture to appear in the slider.
     *
     * @param {Texture} image The first image to show in the slider.
     *
     * @returns {Promise} A Promise resolved when the first image has been shown.
     */
    setCurrentImage(image) {
        return new Promise((resolve) => {
            if (this.currentImageTexture) {
                this.uniforms.u_texture2.value = image.texture
                this.uniforms.u_textureFactor2.value = image.getRatioFactor(this.planeRatio)

                TweenMax.to(this.uniforms.u_progress, 2.5, {
                    value: 1,
                    ease: Power1.easeInOut,
                    onComplete: () => {
                        this.currentImageTexture = image.texture
                        this.uniforms.u_texture.value = this.currentImageTexture
                        this.uniforms.u_textureFactor.value = image.getRatioFactor(this.planeRatio)
                        
                        this.uniforms.u_progress.value = 0.0

                        resolve()
                    }
                })
            } else {
                this.currentImageTexture = image.texture
                this.uniforms.u_texture.value = this.currentImageTexture
                this.uniforms.u_textureFactor.value = image.getRatioFactor(this.planeRatio)

                resolve()
            }
        })
    }

    /**
     * In order to display image fully inside the Interface plane, we need to calculate its plane dimensions.
     *
     * @returns {Object} An object containing plane's width as `x` property and height as `y` property.
     */
    getPlaneSize() {
        const box3 = new Box3().setFromBufferAttribute(this.mesh.geometry.attributes.position)
        const planeSize = new Vector3()

        box3.getSize(planeSize)
        
        return {
            x: planeSize.x,
            y: planeSize.z
        }
    }
}
