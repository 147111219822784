/* eslint-disable no-param-reassign */
import TimelineMax from 'gsap/TimelineMax'
import TweenMax from 'gsap/TweenMax'
import { Back, Power1, Power2, Power3 } from 'gsap/EasePack'
import { debounce, throttle } from 'throttle-debounce'
import { CountUp } from 'countup.js'

import USER from '../User'
import config from '../settings/config'
import CameraManager from './CameraManager'
import HistoryManager from './HistoryManager'
import World from '../World'
import AudioManager from './AudioManager'
import shuffle from '../utils/shuffle'
import MenuManager from './MenuManager'
import Resources from '../Resources'

/**
 * This class is used to manage every DOM-related modifications (start screen, UI, etc).
 * It will fetch and store all references to DOM elements in a static object.
 * Static functions are also available, in order to launch animations / modify UI from anywhere in the app.
 *
 * @class
 */
export default class DOMManager {

	/**
	 * Saves all control keys.
	 * Fetches and saves all useful DOM elements.
	 *
	 * @constructor
	 */
	constructor() {
		this.controlTypes = Object.entries(config.controls)

		DOMManager.openedBadge = -1
		DOMManager.openedBadgeNotif = -1
		DOMManager.ui = {}
		DOMManager.isVignetteAnimate = false
		DOMManager.lastScaleX = null
		DOMManager.animePoints = false
		DOMManager.isInterluding = false
		DOMManager.waitingPointsAnimations = []
		DOMManager.menu = new MenuManager()

		this.getElems()
	}

	/**
	 * Fetches all useful DOM elements for later.
	 * Stores it in instance properties for the Launch screen (because this screen will not be used later by other files).
	 * Stores others in a `ui` object as a static property of this class. In order to access them easily from any files.
	 *
	 * @returns {void}
	 */
	getElems() {
		// List of DOM elements that can be used by `App` and `this`.
		this.standardBtn = document.querySelector('#standard')
		this.facebookBtn = document.querySelector('#facebook')
		this.launcher = document.querySelector('.start-screen')
		this.canvasWrapper = document.querySelector('.webgl')

		// List of DOM elements that can  used by any other file as they are static.
		DOMManager.ui = {
			webglWrapper: document.querySelector('.webgl'),
			wrapper: document.querySelector('.game-ui'),
			crosshair: document.querySelector('.crosshair'),
			actionsWrapper: document.querySelector('.actions'),
			actionSlot: document.querySelector('.action-slot'),
			infoWrapper: document.querySelector('.info'),
			info: document.querySelector('.info-text'),
			subtitles: document.querySelector('.subtitles'),
			goal: document.querySelector('.goal'),
			goalDisk: document.querySelector('.goal-disk'),
			loadingText: document.querySelector('.loading__text .number'),
			mobile: {
				wrapper: document.querySelector('.mobile-ui'),
				interact: document.querySelector('.interact'),
				moveNipple: document.querySelector('.nipple--move'),
				cameraNipple: document.querySelector('.nipple--camera')
			},
			rendererEl: null,
			vignette: document.querySelector('.vignette'),
			ambience: document.querySelector('.ambience'),
			interlude: {
				el: document.querySelector('.interlude'),
				chapterEl: document.querySelector('.interlude-chapter'),
				chapterNumber: document.querySelector('.interlude-number'),
				names: document.querySelectorAll('.interlude-name'),
				dates: document.querySelectorAll('.interlude-date')
			},
			map: document.querySelector('.map'),
			mapCursor: document.querySelector('.map-cursor'),
			notification: {
				text: document.querySelector('.notification-text'),
				img: document.querySelector('.notification-img')
			},
			mapGoals: {
				bedroom: document.querySelector('.goal-room-bed'),
				kitchen: document.querySelector('.goal-room-kitchen'),
				livingroom: document.querySelector('.goal-room-living'),
				entrance: document.querySelector('.goal-room-entrance')
			},
			badges: {
				el: document.querySelector('.badges'),
				leave: document.querySelector('.badge-leave'),
				back: document.querySelector('.badge-back'),
				notifs: [],
				list: []
			},
			profile: {
				rank: document.querySelector('.profile-rank'),
				type: document.querySelector('.profile-type'),
				next: document.querySelector('.profile-next'),
				hexaWhite: document.querySelector('.profile .hexagon--white'),
				hexaPulse: document.querySelector('.profile .hexagon--pulse'),
				progress: document.querySelector('.profile-progress'),
				name: document.querySelector('.profile-name'),
				img: document.querySelector('.profile-img'),
				points: document.querySelector('.profile-points'),
				messages: document.querySelector('.profile-messages')
			},
			tutorial: {
				el: document.querySelector('.tutorial'),
				back: document.querySelector('.tutorial-back'),
				logo: document.querySelector('.tutorial-logo'),
				titles: document.querySelectorAll('.tutorial-title'),
				keys: document.querySelectorAll('.tutorial-key')
			}
		}

		const badges = document.querySelectorAll('.badge')
		const notifs = document.querySelectorAll('.unlock')

		for (let i = 0; i < badges.length; i++) {
			const badge = {
				el: badges[i],
				head: badges[i].querySelector('.badge-head div'),
				img: badges[i].querySelector('.unlock-img'),
				content: badges[i].querySelector('.badge-content'),
				names: badges[i].querySelector('.badge-names'),
				foot: badges[i].querySelector('.badge-foot')
			}

			DOMManager.ui.badges.list.push(badge)

			const notif = {
				el: notifs[i],
				img: notifs[i].querySelector('.unlock-img'),
				back: notifs[i].querySelector('.unlock-back'),
				content: notifs[i].querySelector('.unlock-content')
			}

			DOMManager.ui.badges.notifs.push(notif)
		}
	}

	/**
	 * Get Appartment Map size and put it in `UI` static object
	 *
	 * @returns {void}
	 */
	getMapSize() {
		const mapRect = DOMManager.ui.map.getBoundingClientRect()

		if (mapRect) DOMManager.mapSize = {
			width: mapRect.width,
			height: mapRect.height
		}
	}

	static setLoadingPercentage(percent) {

		const options = {
			startVal: Number(DOMManager.ui.loadingText.textContent),
			duration: 0.9,
			useEasing: false,
			useGrouping: false
		}
		
		const content = new CountUp(DOMManager.ui.loadingText, percent, options)
		
		content.start()
	}

	/**
	 * Move Map cursor on map representing the user position in appartment
	 *
	 * @param {Number} posX - Normalized posX for Global positions
	 * @param {Number} posY - Normalized posY for Global positions
	 * @param {Number} rotation - Angle in degree (represent user orientation)
	 * @returns {void}
	 */
	static moveMapCursorTo(posX, posY, rotation) {
		if (!DOMManager.mapSize) return

		const x = DOMManager.mapSize.height * (1.0 - posY) - 5
		const y = DOMManager.mapSize.width * posX - 5
		const rule = 'translate3d(' + x.toFixed(0) + 'px, ' + y.toFixed(0) + 'px, 0px) rotate(' + -rotation + 'deg)'

		if (DOMManager.ui.mapCursor.style.transform === rule) return

		DOMManager.ui.mapCursor.style.transform = rule
	}


	/**
	 * Saves all callback functions references for later.
	 * These functions will be called by related events once the game is started.
	 * Only `resize` event is started right away.
	 * Called by App's constructor once World has been instanciated.
	 *
	 * @param {object} props - Object containing all the callbacks that may be used by the app during the game.
	 * @returns {void}
	 */
	addCallbacks(props) {
		// Click events / PointerLock
		this.onDomElementClick = props.onDomElementClick
		this.onPointerlockChange = props.onPointerlockChange
		this.onPointerlockError = props.onPointerlockError

		// Keyboard / mouse events for desktop use
		this.onKeyDown = props.onKeyDown
		this.parseKeyDown = this.parseKeyDown.bind(this)
		this.onKeyUp = props.onKeyUp
		this.parseKeyUp = this.parseKeyUp.bind(this)
		this.onMouseMove = props.onMouseMove
		this.onMouseMoveDebounced = props.onMouseMoveDebounced

		this.onMouseMoveDebounced = debounce(60, this.onMouseMoveDebounced)

		this.toggleBadge = this.toggleBadge.bind(this)

		// Global event
		this.resizeCb = props.resize
		this.resize = this.resize.bind(this)
		this.resize = throttle(150, this.resize)
		
		window.addEventListener('resize', this.resize)
	}

	/**
	 * Fired when a "resize" event occurs on the window.
	 *
	 * @event
	 * @returns {void}
	 */
	resize() {
		this.getMapSize()
		this.resizeCb()
	}

	/**
	 * Used to detect if the pressed key is listed in the configuration.
	 * If this is the correct key, the callback given in `addCallbacks` will be fired.
	 *
	 * @event
	 * @param {event} e - keyDown event.
	 * @returns {void}
	 */
	parseKeyDown(e) {
		const controlName = this.getInteractionByKeyName(e.code)

		if (!controlName) return

		if (controlName === 'info') this.toggleBadge()
		else this.onKeyDown(controlName)
	}

	/**
	 * Fired when a "keydown" event occurs on one of the "info" keys from the config-controls.
	 * Also fired by a click on the leave and back elements on the badge popup.
	 * In mobile mode, fired when a "click" event occurs on one of the notification badge.
	 * If the badge popup is not opened and the user can open a popup, opens his last obtained badge.
	 * If the badge popup is already opened, closes it.
	 *
	 * @returns {void}
	 */
	toggleBadge() {
		if (DOMManager.openedBadge === -1 && USER.canOpenBadge) {
			DOMManager.hideBadgeNotif()
				.then(() => {
					DOMManager.showBadge(USER.badges - 1)
				})

			USER.canOpenBadge = false
		} else if (DOMManager.openedBadge !== -1) DOMManager.hideBadge()
	}

	/**
	 * Used to detect if the pressed key is listed in the configuration.
	 * If this is the correct key, the callback given in `addCallbacks` will be fired.
	 *
	 * @event
	 * @param {event} e - keyUp event.
	 * @returns {void}
	 */
	parseKeyUp(e) {
		const controlName = this.getInteractionByKeyName(e.code)

		if (controlName && controlName !== 'info') this.onKeyUp(controlName)
	}

	/**
	 * Compares the event.code given in parameter to the list of codes from the configuration.
	 *
	 * @param {string} keyName - The code given by the event.
	 * @returns {(string|null)} If the given code is in the list of codes from the config, returns the direction associated to it. Else, returns null.
	 */
	getInteractionByKeyName(keyName) {
		for (let i = 0; i < this.controlTypes.length; i++) {
			const controlName = this.controlTypes[i][0]
			const keyNames = this.controlTypes[i][1]

			for (let j = 0; j < keyNames.length; j++) {
				if (keyName === keyNames[j]) return controlName
			}
		}

		return null
	}

	/**
	 * Appends the canvas to the DOM and store it to a static property.
	 * Enables the start screen buttons and adds listeners to it.
	 * Called by App when the assets has been loaded.
	 *
	 * @param {HTMLElement} el - The WebGL renderer's DOM element : the canvas.
	 * @returns {void}
	 */
	webGLReady(el) {
		this.canvasWrapper.appendChild(el)

		DOMManager.ui.rendererEl = el

		this.facebookBtn.disabled = false
		this.standardBtn.disabled = false
	}

	/**
	 * The game has been started. Removes launch screen's button events.
	 * Hides the start screen.
	 * Populates UI by putting user's name and pictures into the game UI. Shows this UI.
	 * Adds events related to the game (click).
	 * Requests for full screen.
	 *
	 * @returns {void}
	 */
	startMobile() {
		this.hideLauncher()
		this.populateUI()

		DOMManager.ui.wrapper.classList.add('visible')
		DOMManager.ui.mobile.interact.addEventListener('click', this.onDomElementClick, false)
		DOMManager.ui.badges.leave.addEventListener('click', this.toggleBadge, false)
		DOMManager.ui.badges.back.addEventListener('click', this.toggleBadge, false)

		if (config.debug) DOMManager.ui.interlude.el.classList.remove('fadeout')

		for (let i = 0; i < DOMManager.ui.badges.notifs.length; i++) {
			const notif = DOMManager.ui.badges.notifs[i]

			notif.el.addEventListener('click', this.toggleBadge, false)
		}

		document.body.requestFullscreen()
			.then(() => {
				if (screen && screen.orientation && screen.orientation.lock) screen.orientation.lock('landscape-primary')
			})
		
        DOMManager.ui.mobile.wrapper.classList.add('active')
		
		this.getMapSize()
	}

	/**
	 * The game has been started. Removes launch screen's button events.
	 * Hides the start screen.
	 * Populates UI by putting user's name and pictures into the game UI. Shows this UI.
	 * Adds events related to the game (keyboard, mouse, pointerlock, click).
	 *
	 * @returns {void}
	 */
	startStandard() {
		this.hideLauncher()
		this.populateUI()

		DOMManager.ui.rendererEl.addEventListener('click', this.onDomElementClick, false)
		DOMManager.ui.badges.leave.addEventListener('click', this.toggleBadge, false)
		DOMManager.ui.badges.back.addEventListener('click', this.toggleBadge, false)

		if (config.debug) DOMManager.ui.interlude.el.classList.remove('fadeout')

		DOMManager.ui.wrapper.classList.add('visible')
		
		this.getMapSize()

		document.addEventListener('mousemove', this.onMouseMove, false)
		document.addEventListener('mousemove', this.onMouseMoveDebounced, false)
		document.addEventListener('pointerlockchange', this.onPointerlockChange, false)
		document.addEventListener('pointerlockerror', this.onPointerlockError, false)
		document.addEventListener('keydown', this.parseKeyDown)
		document.addEventListener('keyup', this.parseKeyUp)
	}

	/**
	 * Hides start screen.
	 *
	 * @returns {void}
	 */
	hideLauncher() {
		this.launcher.style.display = 'none'
	}

	/**
	 * Puts the user's information into the UI (name, rank, rank type, profile picture).
	 *
	 * @returns {void}
	 */
	populateUI() {
		USER.rank = config.user.defaultRank

		DOMManager.ui.profile.name.textContent = USER.name
		DOMManager.ui.profile.rank.textContent = USER.rank
		DOMManager.ui.profile.next.dataset.nextRank = USER.rank + 1
		DOMManager.ui.profile.type.textContent = config.ranks[USER.rank - 1]

		const img = new Image()

		img.src = USER.profilePicture

		DOMManager.ui.profile.img.appendChild(img)
	}

	/**
	 * Attaches an Action to the action slot in the UI.
	 *
	 * @param {Action} action The action to attach to the UI action slot.
	 *
	 * @static
	 * @returns {void}
	 */
	static setAction(action) {
		DOMManager.ui.actionSlot.textContent = action.name
        DOMManager.ui.actionSlot.classList.add('active')
        DOMManager.ui.actionSlot.action = action

		DOMManager.ui.actionsWrapper.classList.add('active')
	}

	/**
	 * Removes any Action from the action slot in the UI.
	 *
	 * @static
	 * @returns {void}
	 */
	static removeAction() {
		DOMManager.ui.actionSlot.textContent = ''
        DOMManager.ui.actionSlot.classList.remove('active')
        DOMManager.ui.actionSlot.action = null

		DOMManager.ui.actionsWrapper.classList.remove('active')
	}

	/**
	 * Adds a text information to the info element in the UI.
	 *
	 * @param {string} info The text to add in the info UI.
	 *
	 * @static
	 * @returns {void}
	 */
	static setInfo(info) {
		DOMManager.ui.info.textContent = info
        DOMManager.ui.info.classList.add('active')
	}

	/**
	 * Removes any text information from the info element in the UI.
	 *
	 * @static
	 * @returns {void}
	 */
	static removeInfo() {
		DOMManager.ui.info.textContent = ''
        DOMManager.ui.info.classList.remove('active')
	}

	/**
	 * Shows and animates the interlude section to the given chapter index.
	 *
	 * @param {Number} chapterNumber The new chapter's index, from 0 to 4.
	 * @param {Number} delay The delay in second to wait before starting the animation.
	 *
	 * @returns {Promise} A Promise resolved when the animation is over.
	 */
	static interlude(chapterNumber = 0, delay) {
		return new Promise((resolve) => {
			if (DOMManager.isInterluding || config.debug) {
				resolve()

				return
			}

			DOMManager.isInterluding = true

			AudioManager.fadeOut()

			DOMManager.fadeOut(delay, chapterNumber === 0 || chapterNumber === 5)
				.then(() => {
					const tl = new TimelineMax()
					const letters = []
					const chaps = [0, 0, 1, 2, 3, 4]
					const currentChapter = chaps[chapterNumber]
					const startSpacing = 75
					const nameEl = DOMManager.ui.interlude.names[currentChapter]
					const alphaChapter = { alpha: 0 }

					World.pause(true)

					if (currentChapter === 4) {
						const title = nameEl.querySelector('.title')
						const subtitles = nameEl.querySelector('.subtitle')

						tl.set(nameEl, { display: 'flex' })
						.to(subtitles, 1.0, { alpha: 1 })

						.to(subtitles, 1.0, {
							alpha: 0,
							delay: 1.6
						})

						.to(title, 1.3, { alpha: 1 })
						.to(title, 1.3, {
							alpha: 0,
							delay: 2.0,
							onComplete: resolve
						})

						return
					}

					for (let i = 0; i < nameEl.children.length; i++) {
						letters.push(nameEl.children[i])
					}

					// Shuffles the span elements of the chapter's name
					shuffle(letters)

					// Shows the correct chapterName
					tl.set(nameEl, { display: 'flex' })
						.add('start', 0)
						// alphaChapter is just a useless variable to animate.
						// The real alpha is animated from CSS with the "active" class.
						.to(alphaChapter, 2, {
							alpha: 1,
							ease: Power2.easeOut,
							onStart: () => {
								DOMManager.ui.interlude.chapterEl.classList.add('active')

								DOMManager.ui.interlude.chapterNumber.textContent = currentChapter + 1

								// Presets the spaced x property of each "Chapitre X" letter
								for (let i = 0; i < DOMManager.ui.interlude.chapterEl.children.length; i++) {
									const factor = Number(DOMManager.ui.interlude.chapterEl.children[i].dataset.factor)

									TweenMax.set(DOMManager.ui.interlude.chapterEl.children[i], { x: factor * startSpacing })
								}
							}
						})
						// Same as last .to here.
						// Fades out the "Chapitre X" text before the x animation ends (declared just below).
						.to(alphaChapter, 2, {
							alpha: 0,
							onStart: () => {
								DOMManager.ui.interlude.chapterEl.classList.remove('active')
							},
							delay: 3,
							ease: Power2.easeIn
						})

						// Animates x property of each letter according to its "data-factor" attribute.
						for (let i = 0; i < DOMManager.ui.interlude.chapterEl.children.length; i++) {
							const param = { spacing: startSpacing }
							const factor = Number(DOMManager.ui.interlude.chapterEl.children[i].dataset.factor)

							tl.to(param, 14, {
								spacing: 0,
								// eslint-disable-next-line no-loop-func
								onUpdate: () => {
									const value = param.spacing * factor

									TweenMax.set(DOMManager.ui.interlude.chapterEl.children[i], { x: value })
								},
								onComplete: () => {
									const value = param.spacing * factor

									TweenMax.set(DOMManager.ui.interlude.chapterEl.children[i], { x: value })
								},
								ease: Power2.easeOut
							}, 'start')
						}

						let c = 0
						let c2 = 0

						tl.staggerTo(letters, 3, {
								alpha: 1,
								onStart: () => {
									letters[c].classList.add('active')

									c++
								},
								ease: Power2.easeOut
							}, { amount: 2.5 }, 'start+=1', () => { shuffle(letters) })
							.staggerTo(letters, 2, {
								alpha: 0,
								onStart: () => {
									letters[c2].classList.remove('active')

									c2++
								},
								ease: Power2.easeIn
							}, { amount: 1.6 }, 'start+=7', () => {
								nameEl.style.display = 'none'

								resolve()
							})
				})
		})
	}

	static leaveInterlude(chapterNumber = 0) {
		return new Promise((resolve) => {
			if (!DOMManager.isInterluding || config.debug) {
				resolve()

				return
			}

			const chaps = [0, 0, 1, 2, 3]
			const currentChapter = chaps[chapterNumber]
			const date = DOMManager.ui.interlude.dates[currentChapter]

			World.resume(true)
			
			DOMManager.fadeIn()
				.then(() => new Promise((resolve2) => {
					if (currentChapter === 0) {
						resolve2()

						return
					}

					const tl = new TimelineMax()

					tl.set(date, { display: 'block' })
						.to(date, 2, {
							alpha: 1,
							ease: Power2.easeOut
						})
						.to(date, 2, {
							alpha: 0,
							ease: Power2.easeIn,
							onStart: resolve2
						})
						.set(date, { display: 'none' })
				}))
				.then(() => {
					DOMManager.ui.wrapper.classList.remove('hidden')
					DOMManager.isInterluding = false

					resolve()
				})
		})
	}

	static showTutorial(duration = 8) {
		return new Promise((resolve) => {
			if (DOMManager.isTutorialShown || config.debug) {
				resolve()
				
				return
			}

			DOMManager.isTutorialShown = true

			DOMManager.ui.wrapper.classList.add('hidden')

			const tl = new TimelineMax({
				onComplete: () => {
					DOMManager.hideTutorial(duration)
						.then(resolve)
				}
			})

			tl.set(DOMManager.ui.tutorial.el, { display: 'flex' })
				.to(DOMManager.ui.tutorial.back, 1.7, {
					alpha: 1,
					ease: Power2.easeOut
				})
				.add('start', 1.2)
				.to(DOMManager.ui.tutorial.logo, 0.5, {
					alpha: 1,
					ease: Power2.easeOut
				}, 'start')
				.staggerTo(DOMManager.ui.tutorial.titles, 0.7, {
					alpha: 1,
					y: 0,
					ease: Power2.easeOut
				}, 0.05, 'start')
				.staggerTo(DOMManager.ui.tutorial.keys, 0.7, {
					alpha: 1,
					y: 0,
					ease: Power2.easeOut
				}, {
					each: 0.05,
					from: 'center'
				}, 'start')
		})
	}

	static hideTutorial(delay = 0) {
		return new Promise((resolve) => {
			if (!DOMManager.isTutorialShown || config.debug) {
				resolve()

				return
			}

			const tl = new TimelineMax({
				delay,
				onComplete: () => {
					DOMManager.ui.tutorial.el.style.display = 'none'

					DOMManager.isTutorialShown = false

					DOMManager.ui.wrapper.classList.remove('hidden')

					resolve()
				}
			})

			tl.add('start')
				.staggerTo(DOMManager.ui.tutorial.titles, 0.6, {
					alpha: 0,
					y: -20,
					ease: Power2.easeIn
				}, 0.05)
				.staggerTo(DOMManager.ui.tutorial.keys, 0.6, {
					alpha: 0,
					y: -20,
					ease: Power2.easeIn
				}, {
					each: 0.075,
					from: 'center'
				}, 'start')
				.to(DOMManager.ui.tutorial.logo, 0.4, {
					alpha: 0,
					ease: Power2.easeIn
				}, 'start')
				.to(DOMManager.ui.tutorial.back, 0.6, {
					alpha: 0,
					ease: Power2.easeIn
				})
		})
	}

	/**
	 * Animates the DOM to set a new goal for the user.
	 * Lights up a spot in one of the three rooms in the minimap.
	 *
	 * @static
	 * @param {string} goal - Text to be added to the DOM as goal.
	 * @param {string} room - 'bedroom', 'kitchen', 'livingroom' or 'entrance', used to light up the correct spot in minimap.
	 * @returns {void}
	 */
	static setNewGoal(goal = '', room) {
		const tl = new TimelineMax({
			onComplete: () => {
				DOMManager.ui.goalDisk.classList.add('active')
			}
		})

		if (DOMManager.ui.mapGoals[room]) DOMManager.ui.mapGoals[room].classList.add('blink')

		tl.set(DOMManager.ui.goal, {
				alpha: 0,
				y: 30,
				scale: 1,
				onComplete: () => {
					DOMManager.ui.goal.innerHTML = goal
				}
			})
			.fromTo(DOMManager.ui.goalDisk, 0.75, {
				scale: 0,
				alpha: 0
			}, {
				scale: 1,
				alpha: 1,
				ease: Elastic.easeOut
			})
			.to(DOMManager.ui.goal, 0.7, {
				alpha: 1,
				y: 0,
				ease: Power2.easeOut
			})
	}

	/**
	 * Animates the DOM to remove the goal from UI.
	 *
	 * @static
	 * @returns {void}
	 */
	static goalAchieved() {
		const tl = new TimelineMax({
			onComplete: () => { DOMManager.ui.goal.innerHTML = '' }
		})

		DOMManager.ui.goalDisk.classList.remove('active')
		DOMManager.ui.mapGoals.bedroom.classList.remove('blink')
		DOMManager.ui.mapGoals.kitchen.classList.remove('blink')
		DOMManager.ui.mapGoals.livingroom.classList.remove('blink')
		DOMManager.ui.mapGoals.entrance.classList.remove('blink')

		tl.to(DOMManager.ui.goalDisk, 0.8, {
				alpha: 0,
				scale: 2,
				ease: Back.easeIn
			})
			.to(DOMManager.ui.goal, 0.5, {
				alpha: 0,
				ease: Power2.easeOut
			}, '-=0.3')
	}

	/**
	 * Animates the giving of some Hive points in the UI.
	 * If `isUpRank` is `true`, the animation is modified and the rank increments.
	 *
	 * @static
	 * @param {(number|string)} number - The number of points to show in UI.
	 * @param {boolean} isUpRank - Whether the given points are going full and stepping up the rank.
	 * @returns {void}
	 */
	static givePoints(number, isUpRank) {
		DOMManager.movePoints({
			number,
			isUpRank
		})
	}

	/**
	 * Animates the withdrawal of some Hive points in the UI.
	 * If `isDownRank` is `true`, the animation is modified and the rank decrements.
	 *
	 * @static
	 * @param {(number|string)} number - The number of points to show in UI.
	 * @param {boolean} isDownRank - Whether the given points are going empty and stepping down the rank.
	 * @returns {void}
	 */
	static removePoints(number, isDownRank) {
		DOMManager.movePoints({
			number,
			isDown: true,
			isDownRank
		})
	}

	static showAmbience() {
		TweenMax.to(DOMManager.ui.ambience, 0.5, { alpha: 1 })
	}
	

	static showVignette(color) {
		if (DOMManager.isVignetteAnimate) return

		const tl = new TimelineMax({
			onStart: () => {
				DOMManager.ui.vignette.classList.add(color)
				DOMManager.isVignetteAnimate = true
			},
			onComplete: () => {
				DOMManager.ui.vignette.classList.remove(color)
				DOMManager.isVignetteAnimate = false
			}
		})

		tl.to(DOMManager.ui.vignette, 0.5, { alpha: 1 })
			.to(DOMManager.ui.vignette, 0.5, { alpha: 0 })
	}

	/**
	 * Called internaly by DOMManager.givePoints() and DOMManager.removePoints().
	 * Does all the animation going up or down in points.
	 *
	 * @static
	 * @param {Object} points - Points to give / remove and its options.
	 * @param {number} points.number - The number of points to add / remove.
	 * @param {boolean} points.isDown - Whether the points should be removed or added.
	 * @param {boolean} points.isDownRank - Whether the rank should go down after the animation.
	 * @param {boolean} points.isUpRank - Whether the rank should go up after the animation.
	 * @returns {void}
	 */
	// eslint-disable-next-line complexity
	static movePoints({ number, isDown, isDownRank, isUpRank }) {
		if (DOMManager.animePoints) {
			// eslint-disable-next-line object-property-newline
			DOMManager.waitingPointsAnimations.push({ number, isDown, isDownRank, isUpRank })

			return
		}

		// eslint-disable-next-line no-nested-ternary
		const scaleChange = HistoryManager.currentChapter.name === config.scenario.chapter4.name ? 0.04 : HistoryManager.currentChapter.name === config.scenario.chapter5.name ? 0.01 : 0.05
		const timeScale = DOMManager.waitingPointsAnimations.length > 0 ? 3 : 1
		const currentScaleX = DOMManager.ui.profile.progress._gsTransform ? DOMManager.ui.profile.progress._gsTransform.scaleX : null

		if (currentScaleX !== null) {
			if (isDown && currentScaleX < scaleChange && DOMManager.lastScaleX > scaleChange) isDownRank = true
			else if (!isDown && currentScaleX > 1 - scaleChange && DOMManager.lastScaleX < 1 - scaleChange) isUpRank = true
			
			DOMManager.lastScaleX = currentScaleX
		}

		if (USER.rank === 1 && isDownRank) return

		const tl = new TimelineMax({
			onStart: () => {
				// Change points color if going down
				DOMManager.animePoints = true
				if (isDown) DOMManager.ui.profile.points.classList.add('goingDown')
			},
			onComplete: () => {
				// Reset points content to null
				DOMManager.ui.profile.points.textContent = ''
				DOMManager.animePoints = false

				// Reset default point color on anim end
				if (isDown) DOMManager.ui.profile.points.classList.remove('goingDown')

				if (isDownRank || isUpRank) {
					// Makes the hexagon pulse if up / down rank
					DOMManager.ui.profile.hexaPulse.classList.remove('pulse')
					// Resets hexagon to its initial size if user changed rank
					DOMManager.ui.profile.hexaWhite.classList.remove('big')
				}

				if (DOMManager.waitingPointsAnimations.length && USER.rank > 2 && HistoryManager.currentChapter.name === config.scenario.chapter4.name) {
					const animation = DOMManager.waitingPointsAnimations.shift()

					DOMManager.movePoints(animation)
				}
			}
		})

		tl.timeScale(timeScale)
			.set(DOMManager.ui.profile.points, {
				alpha: 0,
				x: isDown ? 50 : 0,
				onComplete: () => {
					DOMManager.ui.profile.points.textContent = isDown ? '- ' + number : '+ ' + number
				}
			})
			.to(DOMManager.ui.profile.points, 1, {
				x: isDown ? 8 : 42,
				ease: Power2.easeInOut,
				onStart: () => {
					// Scale progress bar up to move points
					DOMManager.ui.profile.progress.parentElement.classList.add('big')
				},
				onComplete: () => {
					// Scale hexagon up if user is changing rank
					if (isDownRank || isUpRank) DOMManager.ui.profile.hexaWhite.classList.add('big')
				}
			})
			.to(DOMManager.ui.profile.points, 0.5, {
				alpha: 1,
				ease: Power2.easeIn
			}, '-=0.9')
			.to(DOMManager.ui.profile.progress, 0.6, {
				// eslint-disable-next-line no-nested-ternary
				scaleX: isDownRank ? 0 : isUpRank ? 1 : isDown ? '-=' + scaleChange : '+=' + scaleChange,
				ease: Power2.easeIn
			}, '-=0.5')
			.to(DOMManager.ui.profile.points, 0.6, {
				alpha: 0,
				x: isDown ? 0 : 50,
				delay: 1,
				ease: Power2.easeIn,
				onStart: () => {
					// Resets progress bar's scale when done
					DOMManager.ui.profile.progress.parentElement.classList.remove('big')
				}
			})

		if (USER.rank <= 1 && isDownRank) isDownRank = false

		if (isDownRank || isUpRank) {
			tl.to(DOMManager.ui.profile.rank, 0.5, {
					y: isDownRank ? -40 : 40,
					ease: Power2.easeIn,
					onStart: () => {
						// Make the hexagon bigger if going to new rank
						DOMManager.ui.profile.hexaWhite.classList.add('big')
						DOMManager.ui.profile.type.classList.add('hidden')
					},
					onComplete: () => {
						USER.rank = isDownRank ? USER.rank - 1 : USER.rank + 1

						DOMManager.ui.profile.rank.textContent = USER.rank
						DOMManager.ui.profile.next.dataset.nextRank = USER.rank + 1
						DOMManager.ui.profile.type.textContent = config.ranks[USER.rank - 1]
						DOMManager.ui.profile.type.classList.remove('hidden')
					}
				}, '-=0.5')
				.set(DOMManager.ui.profile.rank, { y: isDownRank ? 40 : -40 })
				.to(DOMManager.ui.profile.rank, 0.5, {
					y: 0,
					ease: Power2.easeOut,
					onStart: () => {
						// Pulse the hexagon if the user is going to a new rank
						DOMManager.ui.profile.hexaPulse.classList.add('pulse')
						
						if (isUpRank) Resources.audios.effects.newRank.play()
						else if (isDownRank) {
							if (USER.rank <= 2)	Resources.audios.effects.lostRankRenegade.play()
							// else Resources.audios.effects.lostRank.play()
						}
					}
				})
				.to(DOMManager.ui.profile.progress, 0.6, {
					scaleX: isDownRank ? 1 - scaleChange : scaleChange,
					ease: Power2.easeInOut
				}, '-=0.3')
		}
	}

	/**
	 * Fades out the page to show a black layer over the game.
	 *
	 * @static
	 * @param {number} delay - If specified, the animation will start with a delay (in seconds).
	 * @param {boolean} skip - Whether to resolve immediately the animation by doing nothing at all.
	 * @returns {void}
	 */
	static fadeOut(delay = 0, skip) {
		return new Promise((resolve) => {
			if (skip) {
				resolve()

				return
			}

			const sensitivity = {
				mouse: config.cameras.standard.mouseSensitivity * 1000,
				speed: config.cameras.speed,
				roll: config.cameras.standard.rollSensitivity * 1000
			}

			TweenMax.to(sensitivity, 3, {
				mouse: 0.5,
				speed: 50,
				roll: 0.1,
				ease: Power1.easeOut,
				delay,
				onStart: () => {
					DOMManager.ui.interlude.el.classList.add('fadeout')
					DOMManager.ui.wrapper.classList.add('hidden')
				},
				onUpdate: () => {
					config.cameras.standard.mouseSensitivity = sensitivity.mouse / 1000
					config.cameras.speed = sensitivity.speed
					config.cameras.standard.rollSensitivity = sensitivity.roll / 1000
				},
				onComplete: () => {
					config.cameras.standard.mouseSensitivity = sensitivity.mouse / 1000
					config.cameras.speed = sensitivity.speed
					config.cameras.standard.rollSensitivity = sensitivity.roll / 1000

					resolve()
				}
			})
		})
	}

	/**
	 * Fades in the page to hide the black layer over the game.
	 *
	 * @static
	 * @param {number} delay - If specified, the animation will start with a delay (in seconds).
	 * @returns {void}
	 */
	static fadeIn(delay = 0) {
		return new Promise((resolve) => {
			const sensitivity = {
				mouse: 0.5,
				speed: 50,
				roll: 0.1
			}

			TweenMax.to(sensitivity, 2, {
				mouse: 2,
				speed: 500,
				roll: 1,
				ease: Power2.easeIn,
				delay,
				onStart: () => {
					DOMManager.ui.interlude.el.classList.remove('fadeout')
				},
				onUpdate: () => {
					config.cameras.standard.mouseSensitivity = sensitivity.mouse / 1000
					config.cameras.speed = sensitivity.speed
					config.cameras.standard.rollSensitivity = sensitivity.roll / 1000
				},
				onComplete: () => {
					config.cameras.standard.mouseSensitivity = sensitivity.mouse / 1000
					config.cameras.speed = sensitivity.speed
					config.cameras.standard.rollSensitivity = sensitivity.roll / 1000

					resolve()
				}
			})
		})
	}

	/**
	 * Sets the given number in messages UI notification.
	 * Hides the UI elem if parameters are not given.
	 * Changes the element's gradient color the higher the number is (> 75, > 150, and > 300).
	 *
	 * @param {Object} param An object with number parameters.
	 * @param {number} param.number The number to set directly in the UI.
	 * @param {boolean} param.increment If `true` the current value in the UI is incremented by 1.
	 * @param {boolean} param.decrement If `true` the current value in the UI is decremented by 1.
	 *
	 * @static
	 * @returns {void}
	 */
	static setUnreadMessages({ number = 0, increment = false, decrement = false } = {}) {
		if (!number && !decrement && !increment) {
			DOMManager.ui.profile.messages.parentElement.classList.remove('active')
			DOMManager.ui.profile.messages.parentElement.classList.remove('enormous')
			DOMManager.ui.profile.messages.parentElement.classList.remove('numerous')
			DOMManager.ui.profile.messages.parentElement.classList.remove('huge')
			DOMManager.ui.profile.messages.textContent = 0
			
			return
		}

		let theNumber = number

		if (increment) theNumber = Number(DOMManager.ui.profile.messages.textContent) + 1
		else if (decrement) theNumber = Number(DOMManager.ui.profile.messages.textContent) - 1

		DOMManager.ui.profile.messages.textContent = theNumber
		DOMManager.ui.profile.messages.parentElement.classList.add('active')

		if (theNumber > 500) {
			DOMManager.ui.profile.messages.parentElement.classList.remove('enormous')
			DOMManager.ui.profile.messages.parentElement.classList.remove('numerous')
			DOMManager.ui.profile.messages.parentElement.classList.add('huge')
		} else if (theNumber > 400) {
			DOMManager.ui.profile.messages.parentElement.classList.remove('huge')
			DOMManager.ui.profile.messages.parentElement.classList.remove('numerous')
			DOMManager.ui.profile.messages.parentElement.classList.add('enormous')
		} else if (theNumber > 150) {
			DOMManager.ui.profile.messages.parentElement.classList.remove('huge')
			DOMManager.ui.profile.messages.parentElement.classList.remove('enormous')
			DOMManager.ui.profile.messages.parentElement.classList.add('numerous')
		}
	}

	/**
	 * Shows a new notification for 5 seconds
	 *
	 * @param {Object} param The object containing parameters for this method.
	 * @param {string} param.text The notification text.
	 * @param {string} param.img The notification image's url.
	 * @param {number} param.duration The duration of the notification in ms. Default is 7000.
	 *
	 * @static
	 * @returns {void}
	 */
	static newNotification({ text = '', img, duration = 7000, noSound } = {}) {
		DOMManager.ui.notification.img.firstElementChild.src = ''
		DOMManager.ui.notification.text.textContent = text

		if (!noSound) Resources.audios.effects.notification.play()

		if (img) {
			DOMManager.ui.notification.img.firstElementChild.src = img
			DOMManager.ui.notification.img.parentElement.classList.remove('noimg')
		} else DOMManager.ui.notification.img.parentElement.classList.add('noimg')

		DOMManager.ui.notification.text.parentElement.classList.add('active')

		setTimeout(() => {
			DOMManager.ui.notification.text.parentElement.classList.remove('active')
		}, duration)
	}

	/**
	 * Helper to show a badge notification.
	 * If any badge notification is already opened, close it, then open the new one.
	 *
	 * @param {number} index The index of the badge to show.
	 *
	 * @returns {Promise} A Promise resolved when the job is done.
	 */
	static newBadgeNotif(index) {
		if (DOMManager.openedBadgeNotif === -1) return DOMManager.showBadgeNotif(index)
		
		return DOMManager.hideBadgeNotif()
			.then(() => DOMManager.showBadgeNotif(index))
	}

	/**
	 * Shows a notification allowing the user to open a newly unlocked information badge.
	 *
	 * @param {number} index The index of the badge to show.
	 *
	 * @returns {Promise} A Promise resolved when the animation is done.
	 */
	static showBadgeNotif(index) {
		return new Promise((resolve) => {
			if (!index && index !== 0) {
				resolve(false)

				return
			}

			const notif = DOMManager.ui.badges.notifs[index]

			if (!notif) {
				resolve(false)

				return
			}

			const tl = new TimelineMax({
				onComplete: () => {
					resolve(true)
				}
			})

			Resources.audios.effects.badge.play()

			DOMManager.openedBadgeNotif = index

			notif.el.classList.add('active')
			notif.content.classList.remove('active')

			tl.fromTo(notif.back, 0.5, {
					x: '100%',
					alpha: 0
				}, {
					x: '0%',
					alpha: 1,
					ease: Power2.easeOut,
					onComplete: () => {
						notif.content.classList.add('active')
					}
				})
				.fromTo(notif.img, 0.6, {
					x: 0,
					scale: 0.85,
					alpha: 0
				}, {
					scale: 1.2,
					alpha: 1,
					onComplete: () => {
						notif.img.classList.add('active')
					},
					ease: Back.easeOut
				})
				.to(notif.img, 1, {
					scale: 1,
					ease: Power3.easeOut
				})
		})
	}

	/**
	 * Hides the currently opened badge notification.
	 *
	 * @returns {Promise} A Promise resolved when the animation is done.
	 */
	static hideBadgeNotif() {
		return new Promise((resolve) => {
			if (DOMManager.openedBadgeNotif === -1) resolve()

			const notif = DOMManager.ui.badges.notifs[DOMManager.openedBadgeNotif]

			DOMManager.openedBadgeNotif = -1

			const tl = new TimelineMax({
				delay: 0.25,
				onComplete: () => {
					setTimeout(() => {
						notif.el.classList.remove('active')
					}, 500)

					resolve()
				}
			})
			const rect = notif.content.getBoundingClientRect()

			notif.content.classList.remove('active')

			tl.to(notif.img, 0.5, {
					x: rect.width * 1.5,
					ease: Power3.easeIn
				})
				.to(notif.back, 0.4, {
					x: rect.width * 1.5,
					ease: Power2.easeIn
				}, '-=0.4')
		})
	}

	/**
	 * Shows a badge according to the given `index`.
	 *
	 * @param {number} index The index of the badge to show.
	 *
	 * @static
	 * @returns {void}
	 */
	static showBadge(index) {
		// eslint-disable-next-line no-extra-parens
		if ((!index && index !== 0) || DOMManager.openedBadge === index) return

		const badge = DOMManager.ui.badges.list[index]

		if (!badge) return

		DOMManager.openedBadge = index

		World.pause()
		AudioManager.enableLowpass()

		DOMManager.ui.wrapper.classList.add('hidden')

		const tl = new TimelineMax()

		tl.to([DOMManager.ui.badges.el, badge.el], 0.1, { display: 'flex' })
			.fromTo(badge.el, 0.5, {
				alpha: 0,
				x: 100
			}, {
				alpha: 1,
				x: 0,
				ease: Power2.easeOut
			})
			.add('start', 0.5)
			.staggerFromTo(badge.names.children, 0.5, {
				alpha: 0,
				x: 25
			}, {
				alpha: 1,
				x: 0,
				ease: Power2.easeOut
			}, 0.05, 'start')
			.staggerFromTo(badge.head.children, 0.5, {
				alpha: 0,
				y: 25
			}, {
				alpha: 1,
				y: 0,
				onStart: () => {
					DOMManager.ui.badges.el.classList.add('active')
					badge.img.classList.add('active')
				},
				ease: Power2.easeOut
			}, 0.05, 'start')
			.staggerFromTo(badge.content.children, 0.5, {
				alpha: 0,
				y: 25
			}, {
				alpha: 1,
				y: 0,
				ease: Power2.easeOut
			}, 0.05, 'start')
			.fromTo(badge.foot, 0.4, { alpha: 0 }, {
				alpha: 1,
				ease: Power2.easeOut
			})
	}

	/**
	 * Hides the currently opened badge.
	 *
	 * @static
	 * @returns {void}
	 */
	static hideBadge() {
		if (DOMManager.openedBadge === -1) return

		const badge = DOMManager.ui.badges.list[DOMManager.openedBadge]
		
		DOMManager.openedBadge = -1
		DOMManager.ui.badges.el.classList.remove('active')

		AudioManager.disableLowpass()

		const tl = new TimelineMax({
			onComplete: () => {
				DOMManager.ui.wrapper.classList.remove('hidden')
				World.resume()
			}
		})

		tl.add('start')
			.staggerTo(badge.head.children, 0.4, {
				alpha: 0,
				y: 25,
				ease: Power2.easeIn
			}, -0.05)
			.staggerTo(badge.content.children, 0.4, {
				alpha: 0,
				y: 25,
				ease: Power2.easeIn
			}, -0.05, 'start')
			.staggerTo(badge.names.children, 0.4, {
				alpha: 0,
				x: 25,
				ease: Power2.easeIn
			}, 0.05, 'start')
			.to(badge.foot, 0.4, {
				alpha: 0,
				ease: Power2.easeIn
			}, 'start')
			.to(badge.el, 0.5, {
				alpha: 0,
				x: 100,
				ease: Power2.easeIn
			})
			.set([DOMManager.ui.badges.el, badge.el], { display: 'none' })
	}

	static showFinalChoice() {

	}
}
