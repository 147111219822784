import { Group } from 'three'

import VideoInterface from '../VideoInterface'
import Resources from '../../../Resources'
import config from '../../../settings/config'
import HistoryManager from '../../../managers/HistoryManager'
import Action from '../../actions/Action'
import Button from '../../Button'
import { degreesToRadians } from '../../../utils/rad-deg'
import DOMManager from '../../../managers/DOMManager'
import Montage from './Montage'
import MessagesManager from '../../../managers/MessagesManager'

export default class Oven extends VideoInterface {
	constructor(props) {
		super(props)

		this.firstApproach = false
	}

	setSteps() {
		return [
			config.scenario.chapter3.steps.goToKitchen,
			config.scenario.chapter3.steps.food,
			config.scenario.chapter3.steps.goToMontage
		]
	}

	setTextures() {
		this.food = Resources.textures.videos.food
		this.foodOut = Resources.textures.videos.foodOut
		this.ch4Oven = Resources.textures.videos.ch4Oven

		return [
			this.food,
			this.foodOut,
			this.ch4Oven
		]
	}

	addButtons() {
		this.createPrepareBtn()

		this.hideButtons(this.prepareBtns, false)
	}

	onApproach() {
		if (!this.firstApproach && HistoryManager.currentChapter.currentStep.name === config.scenario.chapter3.steps.food) {
			this.showButtons(this.prepareBtns, false)

			for (let i = 0; i < Resources.rooms.livingroom.interfaces.length; i++) {
				const montageInterface = Resources.rooms.livingroom.interfaces[i]

				if (montageInterface instanceof Montage) montageInterface.onApproach(true)
			}

			this.firstApproach = true
		}

		// super.onApproach()
	}

	createPrepareBtn() {
		const btn = new Button({
			name: 'ovenBtn',
			actions: [
				new Action({
					name: 'Préparer',
					steps: [
						{
							name: config.scenario.chapter3.steps.food,
							isLast: false
						}
					],
					interaction: this.onPrepareClick.bind(this)
				})
			],
			isHidden: true,
			size: {
				x: 95,
				y: 1,
				z: 65
			}
		})

		this.prepareBtnsGroup = new Group()
		this.prepareBtnsGroup.rotation.z = degreesToRadians(90)
		this.prepareBtnsGroup.position.copy(this.mesh.position)
		this.prepareBtnsGroup.add(btn.mesh)
		Resources.scene.add(this.prepareBtnsGroup)

		Resources.rooms.kitchen.interactives.push(btn)

		this.prepareBtns = [btn]

		btn.updateWorldPosition()

		this.buttonGroups.push(this.prepareBtns)
		this.buttons.push(btn)
	}

	onPrepareClick() {
		return new Promise((resolve) => {
			this.playVideo()
			this.hideButtons(this.prepareBtns, true, true)

			this.sounds.turnOn.play()

			DOMManager.goalAchieved()
			DOMManager.givePoints(300)

			setTimeout(() => {
				this.sounds.voices.tagged.play()

				MessagesManager.popMessages({
					interval: 1000,
					duration: 40,
					onlyGood: false,
					number: 2,
					indexesRoom: Resources.rooms.kitchen,
					indexes: [80, 81, 83],
					specificMessages: [
						Resources.textures.imgs.messages.bad.content.message35,
						Resources.textures.imgs.messages.bad.content.message4
						// Resources.textures.imgs.messages.bad.content.message20
					]
				})
				
				setTimeout(() => {
					DOMManager.newNotification({
						text: 'Vous avez récemment été identifié dans une publication.',
						img: document.querySelector('#zima').src
					})
				}, 800)

				resolve()
			}, 400)
		})
	}

	nextDay() {
		this.updateCurrentVideo(this.ch4Oven)
	}

	onVideoEnded(video) {
		if (video === this.food) this.updateCurrentVideo(this.foodOut)
	}

	onSoundEnded(sound) {
		if (sound === this.sounds.voices.tagged) HistoryManager.nextStep()
	}
}
