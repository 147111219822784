import config from '../settings/config'
import HistoryManager from '../managers/HistoryManager'
import Resources from '../Resources';
import { MeshBasicMaterial, Color } from 'three';

/**
 * This class is very important as it stores as static properties every useful instances related to model's meshes.
 * Including Colliders, Interfaces and InteractiveObjects.
 * This is definitely a static class.
 * @static
 * @class
 */
export default class HiveObjects {

  /**
   * Initiates every static property needed later.
   * @constructor
   */
  constructor() {
    // Every child mesh from the one passed to `setup`
    HiveObjects.meshes = []

    // Lists of HiveObject instances
    HiveObjects.interactives = []
    HiveObjects.colliders = []
    HiveObjects.interfaces = []
    HiveObjects.messages = []

    HiveObjects.baked = []

    // Lists of HiveObject instances that can be interacted with
    // refreshed at every Step start
    HiveObjects.activeInteractives = []
    HiveObjects.activeInteractiveMeshes = []
    HiveObjects.activeInterfaces = []

    // List of meshes
    HiveObjects.interactiveMeshes = []
    HiveObjects.colliderMeshes = []

    // List of near interactives
    HiveObjects.nearInteractives = []

    // List of near messages
    HiveObjects.nearMessages = []

    window.setNightBaking = HiveObjects.setNightBaking

    
  }

  /**
   * Sets all baked object's textures to their night mode if they have one.
   * Disposes all unused textures.
   *
   * @static
   * @returns {void}
   */
  static setNightBaking() {
    Resources.materials.floorLights.color = new Color(1, 0.528, 0.528)
    for (let i = 0; i < HiveObjects.baked.length; i++) {
      const obj = HiveObjects.baked[i]

      if (obj.userData.nightBaking) {
        if (obj.userData.onlyNight) {
          const oldMat = obj.material
          const oldTex = oldMat ? obj.material.map : null

          obj.material = new MeshBasicMaterial({ map: obj.userData.nightBaking })

          oldMat && oldMat.dispose()
          oldTex && oldTex.dispose()
        } else {
          const old = obj.material.map

          obj.material.map = obj.userData.nightBaking

          old.dispose()
        }
      }
    }
  }

  /**
   * Stores every InteractiveObject and Collider mesh into `HiveObjects.interactiveMeshes` and `HiveObjects.colliderMeshes`, respectively.
   * These arrays will be used to access easily the Colliders to check collision, and the Interactives to check interaction.
   *
   * @static
   * @returns {void}
   */
  static mapMeshes() {
    HiveObjects.interactiveMeshes = HiveObjects.interactives.flatMap(HiveObjects.mapInteractive)

    HiveObjects.colliderMeshes = HiveObjects.colliders.map((interactiveObject) => interactiveObject.mesh)
  }

  /**
   * Gets inner meshes of an InteractiveObject.
   *
   * @param {InteractiveObject} interactiveObject - An InteractiveObject instance to get its meshes.
   *
   * @static
   * @returns {Array} An array of Meshes parsed from the given InteractiveObject's children.
   */
  static mapInteractive(interactiveObject) {
    const meshes = []

    if (interactiveObject.mesh.isMesh) meshes.push(interactiveObject.mesh)

    if (interactiveObject.mesh.children.length) {
      interactiveObject.mesh.traverse((child) => {
        if (child.userData.isInteractiveChild && child.parent.name === interactiveObject.mesh.name) meshes.push(child)
      })
    }

    return meshes
  }

  /**
   * Returns a Collider instance from a name.
   *
   * @param {string} name The Collider's name.
   *
   * @static
   * @returns {(Collider|null)} A Collider instance named by given `name`, or null if it doesn't exist.
   */
  static getColliderByName(name) {
    for (let index = 0; index < HiveObjects.colliders.length; index++) {
      const collider = HiveObjects.colliders[index]

      if (name === collider.name) return collider
    }

    return null
  }

  /**
   * Sets new `HiveObjects.activeInteractives` from an array of InteractiveObjects.
   * Also fetches for their Meshes and adds them to `HiveObjects.activeInteractiveMeshes`.
   *
   * @param {Array} objects An array object InteractiveObject instances.
   *
   * @static
   * @returns {void}
   */
  static setActiveObjects(objects = []) {
    if (HiveObjects.activeInteractives.length) HiveObjects.resetActiveObjects()
    HiveObjects.activeInteractives = objects
    HiveObjects.activeInteractiveMeshes = HiveObjects.activeInteractives.flatMap(HiveObjects.mapInteractive)
  }

  /**
   * Tell every active object that they are away from the camera.
   *
   * @todo Maybe the source of the get away / near shader bug.
   *
   * @static
   * @returns {void}
   */
  static resetActiveObjects() {
    for (let i = 0; i < HiveObjects.activeInteractives.length; i++) {
      HiveObjects.activeInteractives[i].onGetAway()
    }
  }

  /**
   * Disposes of a mesh.
   *
   * @param {Mesh} mesh The Mesh to dispose.
   *
   * @returns  {void}
   */
  static disposeMesh(mesh) {
    if (!mesh.isMesh) return

    mesh.geometry.dispose()
    mesh.material.dispose()
    if (mesh.material.map) mesh.material.map.dispose()
  }

  /**
   * Removes an InteractiveObject from the `HiveObjects.activeInteractive` array.
   * Also removes its Mesh from `HiveObjects.activeInteractiveMeshes`.
   *
   * @param {InteractiveObject} object An InteractiveObject instance to remove from active ones.
   *
   * @static
   * @returns {void}
   */
  static removeActiveInteractive(object) {
    const index = HiveObjects.activeInteractives.indexOf(object)
          
    HiveObjects.activeInteractives.splice(index, 1)
    
    HiveObjects.activeInteractiveMeshes = HiveObjects.activeInteractives.flatMap(HiveObjects.mapInteractive)
  }

  /**
   * Reloads active Interfaces for the Step that has the given `name`.
   *
   * @param {string} stepName The Step name to reload active Interfaces for.
   *
   * @static
   * @returns {void}
   */
  static reloadInterfacesActives(stepName) {
    HiveObjects.activeInterfaces = []

    for (let i = 0; i < HiveObjects.interfaces.length; i++) {
      HiveObjects.interfaces[i].checkActive(stepName) && HiveObjects.activeInterfaces.push(HiveObjects.interfaces[i])
    }

    HiveObjects.resetActiveInterfaces()
  }

  static resetActiveInterfaces() {
    for (let i = 0; i < HiveObjects.activeInterfaces.length; i++) {
      HiveObjects.activeInterfaces[i].onGetAway()
    }
  }

  /**
   * Updates every Interactive in the scene.
   * Called each frame by GameManager.
   *
   * @param {Object} timeStamp - Object containing the time and deltaTime properties.
   *
   * @static
   * @returns {void}
   */
  static update(timeStamp) {
    for (let i = 0; i < HiveObjects.interactives.length; i++) {
      HiveObjects.interactives[i].update(timeStamp)
    }
    
    for (let i = 0; i < HiveObjects.interfaces.length; i++) {
      HiveObjects.interfaces[i].update(timeStamp)
    }
  }

  /**
   * Starts debug mode for every interface and interactive instance.
   * Also adds keyboard event to toggle Interactive and Interface helpers.
   *
   * @static
   * @returns {void}
   */
  static startDebugMode() {
    for (let i = 0; i < HiveObjects.interfaces.length; i++) {
      HiveObjects.interfaces[i].startDebugMode()
    }
    
    for (let i = 0; i < HiveObjects.interactives.length; i++) {
      HiveObjects.interactives[i].startDebugMode()
    }

    document.addEventListener('keydown', (e) => {
      // H key toggles mesh helpers
      if (e.code === config.controls.helpers.meshes) {
        for (let i = 0; i < HiveObjects.interactives.length; i++) {
          for (let j = 0; j < HiveObjects.interactives[i].helpers.length; j++) {
            HiveObjects.interactives[i].helpers[j].visible = !HiveObjects.interactives[i].helpers[j].visible
          }
        }

        for (let i = 0; i < HiveObjects.interfaces.length; i++) {
          for (let j = 0; j < HiveObjects.interfaces[i].helpers.length; j++) {
            HiveObjects.interfaces[i].helpers[j].visible = !HiveObjects.interfaces[i].helpers[j].visible
          }
        }
      }
    })
  }

  /**
   * Starts debug mode for every interface and interactive instance.
   * Also adds keyboard event to toggle Interactive and Interface helpers.
   * Called by World if config.debug is true.
   *
   * @returns {void}
   */
  startDebugMode() {
    HiveObjects.startDebugMode()
  }

  /**
   * Destroys all Interfaces instances.
   *
   * @static
   * @returns {void}
   */
  static destroyInterfaces() {
    for (let i = HiveObjects.interfaces.length - 1; i >= 0; i--) {
      const anInterface = HiveObjects.interfaces[i]

      anInterface.destroy()
    }
  }

  /**
   * Refreshes the list with all new active Interfaces.
   * Individually destroys the Interfaces that are not used anymore.
   *
   * @static
   * @returns {void}
   */
  static refreshInterfaces() {
    const rooms = Object.values(Resources.rooms)

    for (let i = 0; i < rooms.length; i++) {
      const room = rooms[i]

      for (let j = 0; j < room.interfaces.length; j++) {
          const anInterface = room.interfaces[j]
          const name = anInterface.name
    
          const InstanceName = typeof config.namings.interfaces[name] === 'object' && config.namings.interfaces[name] !== null ? config.namings.interfaces[name][HistoryManager.currentChapter.name] : config.namings.interfaces[name]
          
          if (!(anInterface instanceof InstanceName)) {
            anInterface.destroy()
            
            const instance = new InstanceName({
              mesh: anInterface.mesh,
              name
            })

            for (let k = 0; k < instance.buttons.length; k++) {
              room.interactives.push(instance.buttons[k])
            }

            room.interfaces[j] = instance
          }
      }
    }
  }

  /**
   * Parses all interactive objects on the scene to send to HiveObjects the one that are activated on this specific Step.
   *
   * @returns {void}
   */
  static reloadActiveObjects() {
    const activeObjects = []
    
    for (let i = 0; i < HiveObjects.interactives.length; i++) {
      const interactive = HiveObjects.interactives[i]
      const isActive = interactive.checkActiveActions()
      
      if (isActive) activeObjects.push(interactive)
    }

    HiveObjects.setActiveObjects(activeObjects)
  }
}
