import VideoInterface from '../VideoInterface'
import Resources from '../../../Resources'

export default class RenegadeCorridor extends VideoInterface {
	constructor(props) {
		super(props)

		this.mesh.visible = false
	}

	setTextures() {
		return [
			Resources.textures.videos.ch4CorridorIn,
			Resources.textures.videos.ch4CorridorLoop
		]
	}

	renegade() {
		this.mesh.visible = true

		this.play()
	}

	onVideoEnded(video) {
		if (video === this.textures[0]) this.updateCurrentVideo(this.textures[1])
	}
}
