import {
  MeshBasicMaterial,
  DoubleSide,
  Color,
  MeshLambertMaterial
} from 'three'

import HiveObjects from '../objects/HiveObjects'
import Collider from '../objects/colliders/Collider'
import Message from '../objects/messages/Message'
import config from '../settings/config'
import Resources from '../Resources'
import HistoryManager from '../managers/HistoryManager'
import InteractiveObject from '../objects/interactives/InteractiveObject'
import Dish from '../objects/interfaces/chapter3/Dish'
import Montage from '../objects/interfaces/chapter3/Montage'
import FrontDoor from '../objects/interactives/FrontDoor'

/**
 * Room is used to store every 3D object from the model and attach them to a specific room instance.
 * Three rooms are created from the model : 'bedroom', 'kitchen' and 'livingroom'.
 * @class
 */
export default class Room {

  /**
   * Creates empty arrays in which the instances of objects will be pushed.
   *
   * @param {string} name - The room's name.
   * @constructor
   */
  constructor(name) {
    this.name = name

    this.colliders = []
    this.interactives = []
    this.interfaces = []
    this.useless = []
    this.others = []
    this.messages = []
    this.doubleSided = []

    this.waitingInterfaces = []
  }

  /**
   * Adds an object to this room by checking its type and creating a instance according to this type.
   * Also searches for loaded textures named identically as the object to map the textures to the mesh' material (for baking).
   *
   * @param {Object} object - Object to add to the scene.
   * @param {Mesh} object.mesh - The mesh.
   * @param {string} object.type - The mesh' type ('interactive', 'interface', 'collider', etc...).
   * @param {string} object.name - The mesh' name.
   *
   * @returns {void}
   */
  add({ mesh, type, name, data }) {
    this.bake(mesh)
    
    switch (type) {
      case config.model.types.collider:
        this.addCollider({
          mesh,
          name
        })
        break
      case config.model.types.point:
        this.addMessage({
          mesh,
          name,
          data
        })
        break
      case config.model.types.interactive:
        this.addInteractive({
          mesh,
          name
        })
        break
      case config.model.types.interface:
        this.addInterface({
          mesh,
          name
        })
        break
      case config.model.types.light:
        this.addUseless(mesh)
        break
      case config.model.types.target:
        this.addUseless(mesh)
        break
      case config.model.types.other:
        this.addOther(mesh)
        break
      case config.model.types.useless:
        this.addUseless(mesh)
        break
      default:
        console.warn('[Room] Unrecognized type : ' + type)
        break
    }
  }

  /**
   * Maps a baking Texture to a given Mesh if the Texture is found in the loaded Resources.
   *
   * @param {Mesh} mesh The mesh to set a baked Texture to.
   *
   * @returns {void}
   */
  bake(mesh) {
    const groundLights = [
      'Plan14',
      'Plan15',
      'Plan14',
      'Plan13',
      'Plan12',
      'Plan11',
      'Plan10',
      'Plan9',
      'Plan8',
      'Plan7',
      'Plan6',
      'Plan5',
      'Plan4',
      'Plan3',
      'Plan2',
      'Plan1',
      'Plan'
    ]
    const doublesided = [
      'kitchen-other-plate1',
      'entrance-other-socle',
      'livingroom-other-trash',
      'bedroom-other-curtain',
      'livingroom-other-curtain'
    ]
    
    mesh.traverse((child) => {
      if (groundLights.indexOf(child.name) !== -1) {
        if (!Resources.materials.floorLights) Resources.materials.floorLights = new MeshBasicMaterial({ color: new Color(1, 1, 0.624) })
        
        child.material = Resources.materials.floorLights

        HiveObjects.baked.push(child)
      } else if (Resources.textures.imgs.baking.day[child.name]) {
        const oldMat = child.material
        const oldTex = oldMat ? child.material.map : null

        child.material = new MeshBasicMaterial({ map: Resources.textures.imgs.baking.day[child.name] })
        child.userData = { nightBaking: Resources.textures.imgs.baking.night[child.name] }

        oldMat && oldMat.dispose()
        oldTex && oldTex.dispose()

        HiveObjects.baked.push(child)
      } else if (Resources.textures.imgs.baking.night[child.name]) {
        child.userData = {
          onlyNight: true,
          nightBaking: Resources.textures.imgs.baking.night[child.name]
        }

        HiveObjects.baked.push(child)
      } else {
        // eslint-disable-next-line no-lonely-if
        if (child.name === 'Oreiller1' || child.name === 'Oreiller' || child.name === 'Oreiller_21' || child.name === 'Oreiller_2') {
          const oldMat = child.material
          const oldTex = oldMat.map

          child.material = new MeshLambertMaterial({ color: 0xa60000 })

          oldMat.dispose()
          if (oldTex) oldTex.dispose()
        } else if (child.isMesh) {
          const oldMat = child.material
          const oldTex = oldMat.map

          child.material = new MeshLambertMaterial({ color: oldMat.color.clone() })

          oldMat.dispose()
          if (oldTex) oldTex.dispose()
        }
      }
      
      if (doublesided.indexOf(child.name) !== -1) this.doubleSided.push(child)
    })
  }

  addMessage({ mesh, name, data }) {
    const instance = new Message({
      mesh,
      name,
      orientation: data,
      roomName: this.name
    })

    this.messages.push(instance)
    this.interactives.push(instance.button)
    HiveObjects.messages.push(instance)
  }

  /**
   * Adds a Collider instance to this Room's `colliders` array.
   * Also adds it to static `HiveObjects.colliders` where all Colliders are stored.
   *
   * @param {Object} collider - The collider to add.
   * @param {Mesh} collider.mesh - The mesh that is a Collider.
   * @param {string} collider.name - The Collider's name.
   *
   * @returns {void}
   */
  addCollider({ mesh, name }) {
    const instance = new Collider({
      mesh,
      name
    })

    this.colliders.push(instance)
    HiveObjects.colliders.push(instance)
  }

  /**
   * Adds an Interactive instance to this Room's `interactives` array.
   * Also adds it to static `HiveObjects.interactives` where all Interactives are stored.
   *
   * @todo Check if `InteractiveObject` should only be created in debug mode. Maybe better to created everytime a name is not found.
   *
   * @param {Object} interactive - The Interactive to add.
   * @param {Mesh} interactive.mesh - The mesh that is an Interactive.
   * @param {string} interactive.name - The Interactive's name.
   *
   * @returns {void}
   */
  addInteractive({ mesh, name }) {
    let instance = null

    if (config.namings.interactives[name]) {
      instance = new config.namings.interactives[name]({
        mesh,
        name,
        roomName: this.name
      })
    } else if (config.debug) {
      console.warn('[Room] Unknown interactive object ' + name + '. Building InteractiveObject instead...')

      instance = new InteractiveObject({
        mesh,
        name,
        roomName: this.name
      })
    }

    if (instance) {
      this.interactives.push(instance)
      HiveObjects.interactives.push(instance)

      if (instance.name === 'door') this.door = instance
    }
  }
  
  /**
   * Adds an Interface instance to this Room's `waitingInterfaces` array.
   * This array is used to stores instance that are waiting to be fully implemented by adding loaded textures to them (usually user's pictures).
   *
   * @param {Object} interface - The Interface to add.
   * @param {Mesh} interface.mesh - The mesh that is an Interface.
   * @param {string} interface.name - The Interface's name.
   *
   * @returns {void}
   */
  addInterface({ mesh, name }) {
    this.waitingInterfaces.push({
      mesh,
      name
    })
  }

  /**
   * Adds all Interface instances contained in this Room's `waitingInterfaces` array to this Room's `interfaces` array.
   * Also adds it to static `HiveObjects.interfaces` where all Interfaces are stored.
   *
   * @returns {void}
   */
  addInterfaces() {
    for (let i = 0; i < this.waitingInterfaces.length; i++) {
      const { mesh, name } = this.waitingInterfaces[i]
      let instance = null

      if (config.namings.interfaces[name]) {
        const InstanceName = typeof config.namings.interfaces[name] === 'object' && config.namings.interfaces[name] !== null ? config.namings.interfaces[name][HistoryManager.currentChapter.name] : config.namings.interfaces[name]

        instance = new InstanceName({
          mesh,
          name,
          roomName: this.name
        })

      } else if (config.debug) console.warn('[Room] Unknown interface object ' + name + '.')
  
      if (instance) {
        for (let j = 0; j < instance.buttons.length; j++) {
          this.interactives.push(instance.buttons[j])
        }
        
        this.interfaces.push(instance)
        HiveObjects.interfaces.push(instance)
      }
    }
  }
  
  /**
   * Adds a mesh to this Room's `others` array.
   *
   * @todo Export the less other objects as possible.
   *
   * @param {Mesh} mesh - The mesh to add to `others` array.
   *
   * @returns {void}
   */
  addOther(mesh) {
    mesh.matrixAutoUpdate = false
    mesh.updateMatrix()

    this.others.push(mesh)
  }
  
  /**
   * Adds a mesh to this Room's `useless` array.
   * This mesh is directly disposed and removed.
   *
   * @todo Export the less useless objects as possible.
   *
   * @param {Mesh} mesh - The mesh to add to `useless` array.
   *
   * @returns {void}
   */
  addUseless(mesh) {
    this.useless.push(mesh)
  }

  /**
   * Once the Room is ready, remove useless objects from the Scene.
   * Called by Loader when Room is entirely parsed.
   *
   * @returns {void}
   */
  prepare() {
    this.removeUseless()
    this.setDoubleSided()
  }

  setDoubleSided() {
    for (let i = 0; i < this.doubleSided.length; i++) {
      this.doubleSided[i].traverse((child) => {
        if (child.isMesh) child.material.side = DoubleSide
      })
    }
  }
 
  /**
   * Remove useless meshes from their parents and disposes them.
   *
   * @returns {void}
   */
  removeUseless() {
    for (let i = 0; i < this.useless.length; i++) {
      this.useless[i].parent.remove(this.useless[i])

      this.useless[i].traverse((child) => {
        HiveObjects.disposeMesh(child)
      })
    }

    for (let i = 0; i < this.messages.length; i++) {
      this.messages[i].oldMesh.parent.remove(this.messages[i].oldMesh)

      this.messages[i].oldMesh.traverse((child) => {
        HiveObjects.disposeMesh(child)
      })
    }
  }

  // eslint-disable-next-line complexity
  onEnter() {
    for (let i = 0; i < this.interactives.length; i++) {
      HiveObjects.interactives.push(this.interactives[i])
    }
    for (let i = 0; i < this.colliders.length; i++) {
      HiveObjects.colliders.push(this.colliders[i])
    }
    for (let i = 0; i < this.interfaces.length; i++) {
      HiveObjects.interfaces.push(this.interfaces[i])
    }
    for (let i = 0; i < this.messages.length; i++) {
      HiveObjects.messages.push(this.messages[i])
    }

    HiveObjects.reloadActiveObjects()
    HiveObjects.reloadInterfacesActives()

    Room.currentRoom = this

    if (this.name === 'kitchen') {
      for (let i = 0; i < this.interfaces.length; i++) {
        if (this.interfaces[i] instanceof Dish) this.interfaces[i].onApproach()
      }

      if (HistoryManager.currentChapter.currentStep.name === config.scenario.chapter3.steps.goToKitchen) {
        HistoryManager.nextStep()

        Resources.audios.voices.ch3Kitchen.play()
      }
    } else if (this.name === 'livingroom') {
      if (HistoryManager.currentChapter.currentStep.name === config.scenario.chapter3.steps.goToKitchen) Resources.audios.voices.ch3Taste.play()
      else if (HistoryManager.currentChapter.currentStep.name === config.scenario.chapter3.steps.goToMontage) {
        for (let i = 0; i < this.interfaces.length; i++) {
          if (this.interfaces[i] instanceof Montage) this.interfaces[i].popFirstComment()
        }
      } else if (HistoryManager.currentChapter.currentStep.name === config.scenario.chapter5.steps.goToDoor) {
        const interactives = Resources.rooms.entrance.interactives

        for (let i = 0; i < interactives.length; i++) {
          if (interactives[i] instanceof FrontDoor) interactives[i].startTocking()
        }
      }
    } else if (this.name === 'entrance') {
      if (HistoryManager.currentChapter.currentStep.name === config.scenario.chapter5.steps.goToDoor) {
        HistoryManager.nextStep()

        for (let i = 0; i < this.interactives.length; i++) {
          if (this.interactives[i] instanceof FrontDoor) this.interactives[i].accelerateTocking()
        }
      }
    }
  }

  onLeave() {
    HiveObjects.interactives = []
    HiveObjects.colliders = []
    HiveObjects.interfaces = []
    HiveObjects.messages = []
    HiveObjects.nearMessages = []
    Room.currentRoom = null
  }
}

