/**
 * Configuration object for the glb model and it's naming conventions.
 * `collider` : meshes that will not be displayed but will be used to prevent user from going through other meshes.
 * `interactive` : meshes that are "physical" in the environment and will be used to interact or display textual information.
 * `interface` : plane meshes that are "inner UI" in the environment and will be used to map Hive interfaces as VideoTextures.
 * `light` : points placed at angles of each room. Not displayed, only position is used to move lights across the rooms.
 * `target` : points placed at the same angles as the `light` points, but higher. Not displayed, only position is used to set correct target to the moving lights.
 * `other` : meshes that are displayed but have no action in the environment.
 * `useless` : temporary meshes created from C4D. Will not be displayed and will be totally removed.
 *
 * @todo (For production / size) Do not export `useless` at all from C4D.
 */
const model = {
	uri: './models/hive-draco.gltf',
	rooms: {
		bedroom: null,
		livingroom: null,
		kitchen: null,
		entrance: null
	},
	types: {
		collider: 'collider',
		interactive: 'interactive',
		interface: 'interface',
		light: 'light',
		target: 'target',
		point: 'point',
		other: 'other',
		useless: 'useless'
	},
	positions: {
		global: {
			xMin: -300,
			xMax: 800,
			zMin: -800,
			zMax: 200
		},
		kitchen: {
			xMin: -300,
			xMax: 150,
			zMin: -800,
			zMax: -200
		},
		livingroom: {
			xMin: 150,
			xMax: 800,
			zMin: -800,
			zMax: -200
		},
		bedroom: {
			xMin: -300,
			xMax: 300,
			zMin: -200,
			zMax: 200
		},
		entrance: {
			xMin: 600,
			xMax: 800,
			zMin: -200,
			zMax: 200
		}
	}
}

export default model
