import { TweenMax, Power2 } from 'gsap'

import config from '../../settings/config'
import Action from '../actions/Action'
import InteractiveObject from './InteractiveObject'
import DOMManager from '../../managers/DOMManager'

export default class ButtonCache extends InteractiveObject {
	constructor(props) {
		super(props)

		this.firstApproach = true
		this.isLifted = false
	}

	bindMethods() {
		super.bindMethods()

		this.slideUp = this.slideUp.bind(this)
	}

	onApproach() {
		super.onApproach()

		if (this.firstApproach) {
			this.firstApproach = false

			DOMManager.goalAchieved()

			setTimeout(() => {
				DOMManager.setNewGoal('Soulevez le cache du bouton à droite de la porte d\'entrée', 'livingroom')
			}, 1500)
		}
	}

	createActions() {
		this.liftAction = new Action({
			name: 'Lever le cache',
			steps: [
				{
					name: config.scenario.chapter5.steps.liftButton,
					isLast: true
				},
				{
					name: config.scenario.chapter5.steps.pushButton,
					isLast: false,
					multiple: true
				}
			],
			interaction: this.slideUp
		})

		this.addAction(this.liftAction)
	}

	slideUp() {
		return new Promise((resolve) => {
			TweenMax.to(this.mesh.position, 1.0, {
				y: this.isLifted ? '-=20' : '+=20',
				ease: Power2.easeOut,
				onComplete: () => {
					this.isLifted = !this.isLifted

					this.actions[0].name = this.isLifted ? 'Refermer le cache' : 'Lever le cache'

					this.updateWorldPosition()

					resolve()
				}
			})
		})
	}
}
