/**
 * Configuration object for textures / baking / interfaces.
 * `videos` object is defining VideoTextures using their key to attach them to an Interface's mesh' material.
 * `imgs` object contains all basic textures (skybox for example).
 * `baking` object contains all textures applied to the baked meshes in the model, using their key to attach them.
 */
const textures = {
	videos: {
		chapter1: {
			weather: { url: './textures/chapter1/chap1-0-weather.mp4' },
			wakeUp: { url: './textures/chapter1/chap1-1-intro.mp4' },
			wakeUp2: {
				url: './textures/chapter1/chap1-1-loop.mp4',
				loop: true
			},
			sleepDataWait: {
				url: './textures/chapter1/chap1-2-sleepdata-wait.mp4',
				loop: true
			},
			sleepDataIn: { url: './textures/chapter1/chap1-3-sleepdata-in.mp4' },
			sleepDataLoop: {
				url: './textures/chapter1/chap1-4-sleepdata-loop.mp4',
				loop: true
			},
			sleepDataOut: { url: './textures/chapter1/chap1-5-sleepdata-out.mp4' },
			rankIn: { url: './textures/chapter1/chap1-6-rank-in.mp4' },
			awards: { url: './textures/chapter1/chap1-7-awards.mp4' },
			rankOut: { url: './textures/chapter1/chap1-8-rank-out.mp4' }
		},
		chapter2: {
			wait: {
				url: './textures/chapter2/chap2-0-wait.mp4',
				loop: true
			},
			hiverOfTheDayIn: { url: './textures/chapter2/chap2-1-hiveroftheday-in.mp4' },
			hiverOfTheDayOut: { url: './textures/chapter2/chap2-2-hiveroftheday-out.mp4' },
			commentIn: { url: './textures/chapter2/chap2-3-comment-in.mp4' },
			commentAnswer: { url: './textures/chapter2/chap2-4-comment-answer.mp4' },
			commentAnswer2: { url: './textures/chapter2/chap2-5-comment-answer-2.mp4' },
			commentAnswer3: { url: './textures/chapter2/chap2-6-comment-answer-3.mp4' },
			commentOut: { url: './textures/chapter2/chap2-7-comment-out.mp4' }
		},
		chapter3: {
			waitCh3: {
				url: './textures/chapter3/chap3-0-wait.mp4',
				loop: true
			},
			montageIn: { url: './textures/chapter3/chap3-1-montage-in.mp4' },
			commentPop: { url: './textures/chapter3/chap3-2-comment-pop.mp4' },
			commentPop2: { url: './textures/chapter3/chap3-3-comment-pop-2.mp4' },
			commentPop3: { url: './textures/chapter3/chap3-4-comment-pop-3.mp4' },
			newMessage: { url: './textures/chapter3/chap3-5-new-message.mp4' },
			messagesIn: { url: './textures/chapter3/chap3-6-messages-in.mp4' },
			messagesIn2: { url: './textures/chapter3/chap3-7-messages-in-2.mp4' },
			answer: { url: './textures/chapter3/chap3-8-answer.mp4' },
			messagesPop: { url: './textures/chapter3/chap3-9-messages-pop.mp4' },
			messagesPop2: { url: './textures/chapter3/chap3-10-messages-pop-2.mp4' },
			readMessages: { url: './textures/chapter3/chap3-11-read-messages.mp4' },
			block: { url: './textures/chapter3/chap3-12-block.mp4' },
			return: { url: './textures/chapter3/chap3-13-return.mp4' },
			kitchen: { url: './textures/chapter3/chap3-kitchen.mp4' },
			food: { url: './textures/chapter3/chap3-food.mp4' },
			foodOut: {
				url: './textures/chapter3/chap3-food-loop.mp4',
				loop: true
			},
			weatherDay2: { url: './textures/chapter3/chap3-weather.mp4' },
			ch3SleepIn: { url: './textures/chapter3/chap3-sleep-in.mp4' },
			ch3SleepLoop: {
				url: './textures/chapter3/chap3-sleep-loop.mp4',
				loop: true
			}
		},
		chapter4: {
			ch4Background: {
				url: './textures/chapter4/chap4-bg.mp4',
				loop: true
			},
			ch4RenegatIn: { url: './textures/chapter4/renegat-salon-in.mp4' },
			ch4RenegatLoop: {
				url: './textures/chapter4/renegat-salon-loop.mp4',
				loop: true
			},
			ch4CorridorIn: { url: './textures/chapter4/chap4-renegade-corridor-in.mp4' },
			ch4CorridorLoop: {
				url: './textures/chapter4/chap4-renegade-corridor-loop.mp4',
				loop: true
			},
			ch4KitchenWait: { url: './textures/chapter4/chap4-kitchen.mp4' },
			ch4KitchenIn: { url: './textures/chapter4/chap4-renegade-kitchen-in.mp4' },
			ch4KitchenLoop: {
				url: './textures/chapter4/chap4-renegade-kitchen-loop.mp4',
				loop: true
			},
			ch4Oven: { url: './textures/chapter4/chap4-oven.mp4' },
			weatherDay3: { url: './textures/chapter4/chap4-weather.mp4' }
		},
		chapter5: {
			sleepDataMidnight: {
				url: './textures/chapter5/chap5-1-night-loop.mp4',
				loop: true
			},
			publicationIn: { url: './textures/chapter5/chap5-2-photo-in.mp4' },
			publicationComment: { url: './textures/chapter5/chap5-3-comment-in.mp4' },
			publicationCommentLoop: {
				url: './textures/chapter5/chap5-4-comment-loop.mp4',
				loop: true
			}
		},
		home: {
			motion: { url: './textures/home/motion.mp4' }
		}
	},
	imgs: {
		sky: './textures/skybox-day.basis',
		skyCh3: './textures/skybox-chap3.basis',
		skyCh4: './textures/skybox-chap4.basis',
		deleteMessage: './textures/messages/deleteMessage.png'
	},
	messages: {
		bad: {
			profilePictures: {
				userMessage1: './textures/messages/bad/profilePictures/photo1.png',
				userMessage2: './textures/messages/bad/profilePictures/photo2.png',
				userMessage3: './textures/messages/bad/profilePictures/photo3.png',
				userMessage4: './textures/messages/bad/profilePictures/photo4.png',
				userMessage5: './textures/messages/bad/profilePictures/photo6.png',
				userMessage6: './textures/messages/bad/profilePictures/photo7.png',
				userMessage7: './textures/messages/bad/profilePictures/photo9.png',
				userMessage8: './textures/messages/bad/profilePictures/user1.png'
			},
			content: {
				'msg-mdp1': './textures/messages/bad/content/msg-mdp1.png',
				'msg-mdp2': './textures/messages/bad/content/msg-mdp2.png',
				'msg-mdp3': './textures/messages/bad/content/msg-mdp3.png',
				'msg-mdp4': './textures/messages/bad/content/msg-mdp4.png',
				'msg-mdp5': './textures/messages/bad/content/msg-mdp5.png',
				'msg-mdp6': './textures/messages/bad/content/msg-mdp6.png',
				message1: './textures/messages/bad/content/message1.png',
				message2: './textures/messages/bad/content/message2.png',
				message3: './textures/messages/bad/content/msg-rouge1.png',
				message4: './textures/messages/bad/content/msg-rouge10.png',
				message5: './textures/messages/bad/content/msg-rouge11.png',
				message6: './textures/messages/bad/content/msg-rouge12.png',
				message7: './textures/messages/bad/content/msg-rouge13.png',
				message8: './textures/messages/bad/content/msg-rouge14.png',
				message9: './textures/messages/bad/content/msg-rouge15.png',
				message10: './textures/messages/bad/content/msg-rouge16.png',
				// message11: './textures/messages/bad/content/msg-rouge17.png',
				// message12: './textures/messages/bad/content/msg-rouge18.png',
				message13: './textures/messages/bad/content/msg-rouge2.png',
				// message14: './textures/messages/bad/content/msg-rouge20.png',
				// message15: './textures/messages/bad/content/msg-rouge21.png',
				// message16: './textures/messages/bad/content/msg-rouge22.png',
				// message17: './textures/messages/bad/content/msg-rouge23.png',
				// message18: './textures/messages/bad/content/msg-rouge24.png',
				// message19: './textures/messages/bad/content/msg-rouge25.png',
				// message20: './textures/messages/bad/content/msg-rouge26.png',
				// message21: './textures/messages/bad/content/msg-rouge27.png',
				// message22: './textures/messages/bad/content/msg-rouge28.png',
				message23: './textures/messages/bad/content/msg-rouge29.png',
				message24: './textures/messages/bad/content/msg-rouge3.png',
				message25: './textures/messages/bad/content/msg-rouge30.png',
				message26: './textures/messages/bad/content/msg-rouge31.png',
				message27: './textures/messages/bad/content/msg-rouge32.png',
				message28: './textures/messages/bad/content/msg-rouge33.png',
				message29: './textures/messages/bad/content/msg-rouge34.png',
				message30: './textures/messages/bad/content/msg-rouge35.png',
				message31: './textures/messages/bad/content/msg-rouge36.png',
				message32: './textures/messages/bad/content/msg-rouge37.png',
				message33: './textures/messages/bad/content/msg-rouge38.png',
				message34: './textures/messages/bad/content/msg-rouge39.png',
				message35: './textures/messages/bad/content/msg-rouge4.png',
				message36: './textures/messages/bad/content/msg-rouge40.png',
				message37: './textures/messages/bad/content/msg-rouge41.png',
				message38: './textures/messages/bad/content/msg-rouge42.png',
				message39: './textures/messages/bad/content/msg-rouge43.png',
				message40: './textures/messages/bad/content/msg-rouge44.png',
				message41: './textures/messages/bad/content/msg-rouge45.png',
				message42: './textures/messages/bad/content/msg-rouge46.png',
				message43: './textures/messages/bad/content/msg-rouge5.png',
				message44: './textures/messages/bad/content/msg-rouge6.png',
				message45: './textures/messages/bad/content/msg-rouge7.png',
				message46: './textures/messages/bad/content/msg-rouge8.png',
				message47: './textures/messages/bad/content/msg-rouge9.png'
			}
		},
		good: {
			profilePictures: {
				userMessage1: './textures/messages/good/profilePictures/photo10.png',
				userMessage2: './textures/messages/good/profilePictures/photo11.png',
				userMessage3: './textures/messages/good/profilePictures/photo5.png',
				userMessage4: './textures/messages/good/profilePictures/photo8.png'
			},
			content: {
				always1: './textures/messages/good/content/always-message2.png',
				always2: './textures/messages/good/content/always-messages1.png',
				'ch1-1': './textures/messages/good/content/ch1-message1.png',
				'ch1-2': './textures/messages/good/content/ch1-message2.png',
				'ch1-3': './textures/messages/good/content/ch1-message3.png',
				'ch1-4': './textures/messages/good/content/ch1-message-4.png',
				'ch4-1': './textures/messages/good/content/ch4-message1.png',
				'ch4-2': './textures/messages/good/content/ch4-message2.png',
				'ch4-3': './textures/messages/good/content/ch4-message3.png'
			}
		}
	},
	baking: {
		day: {
			'bedroom-interactive-door': './textures/baking/basis/day/bedroom-interactive-door.basis',
			'bedroom-other-bed': './textures/baking/basis/day/bedroom-other-bed.basis',
			'bedroom-other-board2': './textures/baking/basis/day/bedroom-other-board2.basis',
			'bedroom-other-chair': './textures/baking/basis/day/bedroom-other-chair.basis',
			'bedroom-other-sol': './textures/baking/basis/day/bedroom-other-sol.basis',
			'bedroom-other-tapis': './textures/baking/basis/day/bedroom-other-tapis.basis',
			'bedroom-other-wallbedroom': './textures/baking/basis/day/bedroom-other-wallbedroom.basis',
			'bedroom-other-wallbedroom2': './textures/baking/basis/day/bedroom-other-wallbedroom2.basis',
			'bedroom-other-wallbedroom3': './textures/baking/basis/day/bedroom-other-wallbedroom3.basis',
			'entrance-interactive-cache': './textures/baking/basis/day/entrance-interactive-cache.basis',
			'entrance-interactive-frontdoor': './textures/baking/basis/day/entrance-interactive-frontdoor.basis',
			'entrance-other-cadre': './textures/baking/basis/day/entrance-other-cadre.basis',
			'entrance-other-door-1': './textures/baking/basis/day/entrance-other-door-1.basis',
			'entrance-other-door-2': './textures/baking/basis/day/entrance-other-door-2.basis',
			'entrance-other-socle': './textures/baking/basis/day/entrance-other-socle.basis',
			'entrance-other-wallenter': './textures/baking/basis/day/entrance-other-wallenter.basis',
			'kitchen-other-bloc': './textures/baking/basis/day/kitchen-other-bloc.basis',
			'kitchen-other-bloc2': './textures/baking/basis/day/kitchen-other-bloc2.basis',
			'kitchen-other-bloc3': './textures/baking/basis/day/kitchen-other-bloc3.basis',
			'kitchen-other-chair-1': './textures/baking/basis/day/kitchen-other-chair-1.basis',
			'kitchen-other-chair-2': './textures/baking/basis/day/kitchen-other-chair-2.basis',
			'kitchen-other-chair-3': './textures/baking/basis/day/kitchen-other-chair-3.basis',
			'kitchen-other-kitchen1': './textures/baking/basis/day/kitchen-other-kitchen1.basis',
			'kitchen-other-kitchen2': './textures/baking/basis/day/kitchen-other-kitchen2.basis',
			'kitchen-other-kitchen3': './textures/baking/basis/day/kitchen-other-kitchen3.basis',
			'kitchen-other-lamp': './textures/baking/basis/day/kitchen-other-lamp.basis',
			'kitchen-other-plate': './textures/baking/basis/day/kitchen-other-plate.basis',
			'kitchen-other-shelf': './textures/baking/basis/day/kitchen-other-shelf.basis',
			'kitchen-other-table': './textures/baking/basis/day/kitchen-other-table.basis',
			'livingroom-other-armchair': './textures/baking/basis/day/livingroom-other-armchair.basis',
			'livingroom-other-board': './textures/baking/basis/day/livingroom-other-board.basis',
			'livingroom-other-carpet': './textures/baking/basis/day/livingroom-other-carpet.basis',
			'livingroom-other-coathanger': './textures/baking/basis/day/livingroom-other-coathanger.basis',
			'livingroom-other-roof': './textures/baking/basis/day/livingroom-other-roof.basis',
			'livingroom-other-sofa': './textures/baking/basis/day/livingroom-other-sofa.basis',
			'livingroom-other-table': './textures/baking/basis/day/livingroom-other-table.basis',
			'livingroom-other-tv': './textures/baking/basis/day/livingroom-other-tv.basis',
			'meuble1': './textures/baking/basis/day/meuble1.basis',
			'meuble2': './textures/baking/basis/day/meuble2.basis',
			'meuble3': './textures/baking/basis/day/meuble3.basis',
			'meuble4': './textures/baking/basis/day/meuble4.basis',
			'meuble42': './textures/baking/basis/day/meuble42.basis',
			'meuble5': './textures/baking/basis/day/meuble5.basis',
			'meuble6': './textures/baking/basis/day/meuble6.basis',
			'meuble62': './textures/baking/basis/day/meuble62.basis',
			'tablette': './textures/baking/basis/day/tablette.basis',
			'tiroir1': './textures/baking/basis/day/tiroir1.basis',
			'tiroir2': './textures/baking/basis/day/tiroir2.basis',
			'tiroir3': './textures/baking/basis/day/tiroir3.basis',
			'tiroir4': './textures/baking/basis/day/tiroir4.basis',
			'wall-kitchen': './textures/baking/basis/day/wall-kitchen.basis',
			'wall-livingroom1': './textures/baking/basis/day/wall-livingroom1.basis',
			'wall-livingroom2': './textures/baking/basis/day/wall-livingroom2.basis'
		},
		night: {
			'bedroom-interactive-door': './textures/baking/basis/night/bedroom-interactive-door.basis',
			'bedroom-other-bed': './textures/baking/basis/night/bedroom-other-bed.basis',
			'bedroom-other-board2': './textures/baking/basis/night/bedroom-other-board2.basis',
			'bedroom-other-chair': './textures/baking/basis/night/bedroom-other-chair.basis',
			'bedroom-other-sol': './textures/baking/basis/night/bedroom-other-sol.basis',
			'bedroom-other-tapis': './textures/baking/basis/night/bedroom-other-tapis.basis',
			'bedroom-other-wallbedroom': './textures/baking/basis/night/bedroom-other-wallbedroom.basis',
			'bedroom-other-wallbedroom2': './textures/baking/basis/night/bedroom-other-wallbedroom2.basis',
			'bedroom-other-wallbedroom3': './textures/baking/basis/night/bedroom-other-wallbedroom3.basis',
			'entrance-interactive-cache': './textures/baking/basis/night/entrance-interactive-cache.basis',
			'entrance-interactive-frontdoor': './textures/baking/basis/night/entrance-interactive-frontdoor.basis',
			'entrance-other-cadre': './textures/baking/basis/night/entrance-other-cadre.basis',
			'entrance-other-door-1': './textures/baking/basis/night/entrance-other-door-1.basis',
			'entrance-other-door-2': './textures/baking/basis/night/entrance-other-door-2.basis',
			'entrance-other-socle': './textures/baking/basis/night/entrance-other-socle.basis',
			'entrance-other-wallenter': './textures/baking/basis/night/entrance-other-wallenter.basis',
			'kitchen-other-bloc': './textures/baking/basis/night/kitchen-other-bloc.basis',
			'kitchen-other-bloc2': './textures/baking/basis/night/kitchen-other-bloc2.basis',
			'kitchen-other-bloc3': './textures/baking/basis/night/kitchen-other-bloc3.basis',
			'kitchen-other-chair-1': './textures/baking/basis/night/kitchen-other-chair-1.basis',
			'kitchen-other-chair-2': './textures/baking/basis/night/kitchen-other-chair-2.basis',
			'kitchen-other-chair-3': './textures/baking/basis/night/kitchen-other-chair-3.basis',
			'kitchen-other-kitchen1': './textures/baking/basis/night/kitchen-other-kitchen1.basis',
			'kitchen-other-kitchen2': './textures/baking/basis/night/kitchen-other-kitchen2.basis',
			'kitchen-other-kitchen3': './textures/baking/basis/night/kitchen-other-kitchen3.basis',
			'kitchen-other-lamp': './textures/baking/basis/night/kitchen-other-lamp.basis',
			'kitchen-other-plate': './textures/baking/basis/night/kitchen-other-plate.basis',
			'kitchen-other-shelf': './textures/baking/basis/night/kitchen-other-shelf.basis',
			'kitchen-other-table': './textures/baking/basis/night/kitchen-other-table.basis',
			'livingroom-other-armchair': './textures/baking/basis/night/livingroom-other-armchair.basis',
			'livingroom-other-board': './textures/baking/basis/night/livingroom-other-board.basis',
			'livingroom-other-carpet': './textures/baking/basis/night/livingroom-other-carpet.basis',
			'livingroom-other-coathanger': './textures/baking/basis/night/livingroom-other-coathanger.basis',
			'livingroom-other-roof': './textures/baking/basis/night/livingroom-other-roof.basis',
			'livingroom-other-sofa': './textures/baking/basis/night/livingroom-other-sofa.basis',
			'livingroom-other-table': './textures/baking/basis/night/livingroom-other-table.basis',
			'livingroom-other-tv': './textures/baking/basis/night/livingroom-other-tv.basis',
			'meuble1': './textures/baking/basis/night/meuble1.basis',
			'meuble2': './textures/baking/basis/night/meuble2.basis',
			'meuble3': './textures/baking/basis/night/meuble3.basis',
			'meuble4': './textures/baking/basis/night/meuble4.basis',
			'meuble42': './textures/baking/basis/night/meuble42.basis',
			'meuble5': './textures/baking/basis/night/meuble5.basis',
			'meuble6': './textures/baking/basis/night/meuble6.basis',
			'meuble62': './textures/baking/basis/night/meuble62.basis',
			'tablette': './textures/baking/basis/night/tablette.basis',
			'tiroir1': './textures/baking/basis/night/tiroir1.basis',
			'tiroir2': './textures/baking/basis/night/tiroir2.basis',
			'tiroir3': './textures/baking/basis/night/tiroir3.basis',
			'tiroir4': './textures/baking/basis/night/tiroir4.basis',
			'wall-kitchen': './textures/baking/basis/night/wall-kitchen.basis',
			'wall-livingroom1': './textures/baking/basis/night/wall-livingroom1.basis',
			'wall-livingroom2': './textures/baking/basis/night/wall-livingroom2.basis'
		}
	}
}

export default textures
