import { Color } from 'three'
import TweenMax from 'gsap/TweenMax'
import { Power2 } from 'gsap/EasePack'

import HiveObjects from '../HiveObjects'
import config from '../../settings/config'
import Resources from '../../Resources'
import Interactive from './Interactive'
import DOMManager from '../../managers/DOMManager'
import App from '../../App'

/**
 * The base class to create an Interactive object in the Scene. Useful for physical elements in the scene (not interfaces).
 * Extends from Interactive so it has `onApproach` / `onGetAway` and `onStartLooking` / `onStopLooking` behavior.
 * @class
 */
export default class InteractiveObject extends Interactive {

  /**
   * Creates an InteractiveObject by preparing its shader for overlay, creating its sounds and modifying its helpersColor.
   *
   * @param {Object} param An object with a mesh, a name and some settings.
   * @param {Mesh} param.mesh The mesh to interact with.
   * @param {string} param.name The instance / mesh name.
   * @param {boolean} param.noOverlay Whether this InteractiveObject doesn't have overlay shader.
   */
  constructor({ mesh, name, noOverlay = false }) {
    super({
      mesh,
      name
    })

    this.bindMethods()

    this.actionsShown = false
    this.fragmentShaders = []
    this.actions = []
    this.nearColor = new Color(config.shaders.nearColor)
    this.noOverlay = noOverlay
    this.hasActiveActions = false
    this.helpersColor = 0x00ff00
    this.isInteractiveObject = true

    this.description = config.descriptions[this.name]

    this.checkGroup()
    this.addShaders()
    this.addSounds()
    this.createActions()
  }

  bindMethods() {
    super.bindMethods()

    this.onBeforeCompile = this.onBeforeCompile.bind(this)
    this.onSoundEnded = this.onSoundEnded.bind(this)
  }

  /**
   * Checks if the given `mesh` contains children that are not Mesh instances.
   *
   * @returns {void}
   */
  checkGroup() {
    this.mesh.traverse((child) => {
      if (child.name !== this.mesh.name) {
        if (child.isMesh) child.userData.isInteractiveChild = true
        else if (config.debug) console.warn('InteractiveObject ' + this.mesh.name + '\'s child ' + child.name + ' is not a Mesh', child)
      }
    })
  }

  /**
   * Traverse the mesh and modify its shader to add overlay effect when near / looking.
   * No effect if `this.noOverlay = true`.
   *
   * @returns {void}
   */
  addShaders() {
    if (this.noOverlay) return

    this.mesh.traverse((child) => {
      if (child.isMesh) child.material.onBeforeCompile = this.onBeforeCompile
    })
  }

  /**
   * When looking, plays the "hover" sound effect specific to every InteractiveObject.
   * Changes the crosshair to "hover" state.
   * Adds actions to the UI slot.
   * Animates the overlay shader.
   *
   * @override
   * @event
   * @returns {void}
   */
  onStartLooking() {
    super.onStartLooking()

    Resources.audios.effects.hover.play()

    this.description && DOMManager.setInfo(this.description)

    DOMManager.ui.crosshair.classList.add('hovering')
    
    App.isMobile && DOMManager.ui.mobile.interact.classList.add('hovering')
    
    this.setActionsInSlots()

    if (this.fragmentShaders.length && !this.noOverlay && this.hasActiveActions) {
      this.showShaderColor(0.6)
      this.showShadersColor(0.05)
    }
  }

  /**
   * When not looking anymore, resets crosshair basic state.
   * Removes actions from UI slot.
   * Reset overlay shader.
   *
   * @override
   * @event
   * @returns {void}
   */
  onStopLooking() {
    super.onStopLooking()

    DOMManager.ui.crosshair.classList.remove('hovering')
    DOMManager.ui.mobile.interact.classList.remove('hovering')
    DOMManager.removeAction()
    
    this.description && DOMManager.removeInfo()
    
    this.hasActiveActions && !this.noOverlay && this.fragmentShaders.length && this.hideShadersColor()
  }

  /**
   * When getting close from this object, shows overlay color.
   *
   * @override
   * @event
   * @returns {void}
   */
  onApproach() {
    super.onApproach()
    this.hasActiveActions && !this.noOverlay && this.fragmentShaders.length && this.showShaderColor()
  }

  /**
   * When not close enough from this object anymore, resets overlay color.
   *
   * @override
   * @event
   * @returns {void}
   */
  onGetAway() {
    super.onGetAway()
    !this.noOverlay && this.fragmentShaders.length && this.hideShaderColor()
  }

  /**
   * An interaction is launched by user "click".
   * Plays a specific sound effect on click.
   * Resets the available action in UI slot if needed.
   *
   * @event
   * @returns {void}
   */
  onInteractionStart() {
    this.canInteract = this.setActionsInSlots()

    Resources.audios.effects.action.play()
  }

  /**
   * Fired when an interaction is completed.
   *
   * @param {Action} action The action linked to the completed interaction.
   *
   * @override
   * @event
   * @returns {void}
   */
  onInteractionComplete(action) {
    !this.hasActiveActions && !this.noOverlay && this.fragmentShaders.length && this.hideShaderColor()
  }

  /**
   * Creates the Actions related to this InteractiveObject.
   * Called by InteractiveObject's constructor.
   *
   * @override
   * @returns {void}
   */
  createActions() {}

  /**
   * Stores Actions with specific behavior and active Steps.
   *
   * @param  {...Action} actions Action instances to be added to `this.actions`.
   *
   * @returns {void}
   */
  addAction(...actions) {
    for (let i = 0; i < actions.length; i++) {
      actions[i].hiveObject = this

      this.actions.push(actions[i])
    }
  }

  /**
   * Checks if inner actions are active at the current Step, inject it in UI slot to show its availability to user.
   *
   * @returns {boolean} Whether this InteractiveObject has at least one active Action for the current Step.
   */
  setActionsInSlots() {
    let hasActive = Boolean(this.description)

    for (let i = 0; i < this.actions.length; i++) {
      if (this.actions[i].canInteract) {
        DOMManager.setAction(this.actions[i])
        hasActive = true
      } else DOMManager.removeAction()
    }

    return hasActive
  }

  /**
   * Checks whether this InteractiveObject has at least one active action for this Step
   * Called by a Step when it starts (`Step.start()`)
   *
   * @returns {boolean} Whether this InteractiveObject has at least one active action for this Step.
   */
  checkActiveActions() {
    this.hasActiveActions = false

    for (let i = 0; i < this.actions.length; i++) {
      const isActive = this.actions[i].checkCanInteract()

      if (isActive) this.hasActiveActions = true
    }

    this.canInteract = this.hasActiveActions ? true : Boolean(this.description)

    return this.canInteract
  }

  /**
   * Modifies the fragment shader of `this.mesh` and its children to add an overlay effect to it.
   *
   * @param {Object} shader A shader object.
   *
   * @returns {void}
   */
  onBeforeCompile(shader) {
    if (this.noOverlay) return

    shader.uniforms.whiteRate = { value: 0.0 }

    shader.fragmentShader = 'uniform float whiteRate;\n' + shader.fragmentShader
    shader.fragmentShader = shader.fragmentShader.replace(
      'gl_FragColor = vec4( outgoingLight, diffuseColor.a );',
      'gl_FragColor = vec4(mix(outgoingLight, vec3(' + this.nearColor.r + ', ' + this.nearColor.g + ', ' + this.nearColor.b + '), whiteRate), diffuseColor.a);'
    )

    this.fragmentShaders.push(shader)
  }

  /**
   * Shows an overlay over the Mesh intensified by the given value.
   *
   * @param {number} value The intensity of the overlay to show.
   *
   * @returns {void}
   */
  showShaderColor(value = 0.4) {
    if (this.noOverlay) return

    const shadersToColor = this.fragmentShaders.map((elt) => elt.uniforms.whiteRate)

    this.updateShadersColor(shadersToColor, value)
  }

  /**
   * Hides the overlay over the Mesh.
   *
   * @returns  {void}
   */
  hideShaderColor() {
    if (this.noOverlay) return
    
    const shadersToColor = this.fragmentShaders.map((elt) => elt.uniforms.whiteRate)

    this.updateShadersColor(shadersToColor, 0.0)
  }

  /**
   * Shows an overlay over the near Meshes except `this.mesh`.
   *
   * @param {number} value The intensity of the overlay to show.
   *
   * @returns {void}
   */
  showShadersColor(value = 0.05) {
    if (this.noOverlay) return

    const shadersArray = HiveObjects.nearInteractives.map((object) => object.fragmentShaders)

    // REMOVE CURRENT INSTANCE'S SHADERS
    shadersArray.splice(shadersArray.indexOf(this.fragmentShaders), 1)

    const shadersArrayFlat = shadersArray.flatMap((object) => object)

    if (shadersArrayFlat.length) {
      const shaders = shadersArrayFlat.map((shader) => shader.uniforms.whiteRate)

      this.updateShadersColor(shaders, value)
    }
  }

  /**
   * Nearly hides the overlay over the near Meshes except `this.mesh`.
   *
   * @param {number} value The intensity of the overlay to decrease.
   *
   * @returns {void}
   */
  hideShadersColor(value = 0.3) {
    if (this.noOverlay) return

    const shadersArray = HiveObjects.nearInteractives.flatMap((object) => object.fragmentShaders)

    if (shadersArray.length) {
      const shaders = shadersArray.map((shader) => shader.uniforms.whiteRate)

      this.updateShadersColor(shaders, value)
    }
  }

  /**
   * Animates the overlay's intensity.
   *
   * @param {Array} elts An array of uniform references to animate their values.
   * @param {number} value The value to update the uniform to.
   *
   * @returns {void}
   */
  updateShadersColor(elts, value) {
    if (this.noOverlay) return

    TweenMax.to(elts, 0.4, {
      value,
      ease: Power2.easeOut
    })
  }

  /**
   * Destroys this InteractiveObject by removing it and its Mesh from `HiveObjects` static arrays.
   *
   * @returns {void}
   */
  destroy() {
    if (!super.destroy()) return

    const i = HiveObjects.interactives.indexOf(this)
    const j = HiveObjects.interactiveMeshes.indexOf(this.mesh)
    
    HiveObjects.interactives.splice(i, 1)
    HiveObjects.interactiveMeshes.splice(j, 1)
  }
}
