export default [
	{
		start: 0.05,
		end: 1.8,
		text: 'Zima vient de vous envoyer un message privé.'
	},
	{
		start: 2.1,
		end: 6,
		text: 'Ne le faites pas patienter, il pourrait vous enlever des points Hive.'
	}
]
