import Resources from '../../../Resources'
import config from '../../../settings/config'
import VideoInterface from '../VideoInterface'

/**
 * Wheather is the Interface positioned behind the bed and showing the date, hour and wheather.
 * @todo Do not use video for this Interface, an image with no transparency would be fine.
 * @class
 */
export default class Weather extends VideoInterface {
  constructor(props) {
    super(props)

    this.currentTexture = 0
  }

  setTextures() {
    return [
      Resources.textures.videos.weather,
      Resources.textures.videos.weatherDay2,
      Resources.textures.videos.weatherDay3
    ]
  }

  nextDay() {
    if (!this.textures[this.currentTexture + 1]) return

    this.currentTexture++

    this.setCurrentVideo({
      video: this.textures[this.currentTexture],
      isFirst: true,
      replaceOld: true
    })
  }
}
