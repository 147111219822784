export default [
	{
		start: 0.05,
		end: 2.75,
		text: 'Hive : Ne faites pas ça, Hiver ! Je peux vous aider mieux que quiconque.'
	},
	{
		start: 3,
		end: 5.3,
		text: 'Je vous connais parfaitement.'
	},
	{
		start: 5.5,
		end: 7,
		text: 'C\'est peut-être un piège !'
	}
]
