import { MeshBasicMaterial } from 'three'

/**
 * Object containing loaded and instanciated resources.
 * Contains references to some WebGL elements : scene, model, fonts, textures (images and videos) and audios.
 * As well as users pictures loaded as Textures.
 * `room` is object containing a reference to each Room instance.
 * Also contains helpers for every PositionalAudio in the game.
 */
const Resources = {
  scene: null,
  canvas: null,
  models: {},
  fonts: {},
  textures: {
    videos: {},
    imgs: {
      messages: {
        good: {
          profilePictures: {},
          content: {}
        },
        bad: {
          profilePictures: {},
          content: {}
        }
      },
      baking: {
        day: {},
        night: {}
      },
      other: {}
    }
  },
  user: {
    pictures: {},
    profilePicture: {}
  },
  rooms: {},
  audios: {
    ambiences: {},
    effects: {},
    voices: {},
    interactives: {},
    defaultInteractives: {},
    interfaces: {},
    messages: {},
    defaultInterfaces: {},
    positionalHelpers: []
  },
  materials: {
    hidden: new MeshBasicMaterial({ visible: false })
  }
}

export default Resources
