import CameraControls from './CameraControls'
import CameraNipple from '../nipple/CameraNipple'
import MoveNipple from '../nipple/MoveNipple'
import DOMManager from '../managers/DOMManager'

/**
 * MobileCameraControls allows user to control the camera in mobile mode.
 * It creates nipples as position / rotation controllers for the camera.
 * @class
 */
export default class MobileCameraControls extends CameraControls {

    /**
	 * Creates nipples in addition to the properties inherited from CameraControls.
	 *
     * @param {Camera} camera - The camera that needs to have controls.
	 * @constructor
	 */
    constructor(camera) {
        super(camera)

        this.createNipples()

        this.isOnFocus = true

        this.inertia.head.engine.disabled = true
        this.inertia.position.engine.disabled = true
        this.inertia.roll.engine.disabled = true
    }

    bindMethods() {
        super.bindMethods()

        this.onMoveNippleDir = this.onMoveNippleDir.bind(this)
        this.onMoveNippleEnd = this.onMoveNippleEnd.bind(this)

        this.onCameraNippleMove = this.onCameraNippleMove.bind(this)
        this.onCameraNippleEnd = this.onCameraNippleEnd.bind(this)
    }

    /**
	 * Fired when a "click" event occurs on the mobile interaction zone.
     * If the focus is already on the game, starts interaction on the current Action, if there is any.
	 *
	 * @event
	 * @returns {void}
	 */
    onDomElementClick() {
        if (DOMManager.ui.actionSlot.action) DOMManager.ui.actionSlot.action.startInteraction()
	}

    /**
     * Creates two nipples using specific classes that are controlling either the position or the rotation of the camera.
     *
     * @returns {void}
     */
    createNipples() {
        this.moveNipple = new MoveNipple({
            domElt: DOMManager.ui.mobile.moveNipple,
            onDir: this.onMoveNippleDir,
            onEnd: this.onMoveNippleEnd
        })

        this.cameraNipple = new CameraNipple({
            domElt: DOMManager.ui.mobile.cameraNipple,
            onMove: this.onCameraNippleMove,
            onEnd: this.onCameraNippleEnd
        })
    }

    /**
     * Fired when a "move" event occurs on the "move" nipple (the left one).
     * Moves the camera in function of the nipple's direction.
     *
     * @event
     * @returns {void}
     */
    onMoveNippleDir() {
        if (this.moveNipple.currentDirection !== this.moveNipple.oldDirection) this.setDirection(this.moveNipple.oldDirection, false)

        this.setDirection(this.moveNipple.currentDirection, true)
    }

    /**
     * Fired when a "end" event occurs on the "move" nipple (the left one).
     * Stops the camera movement.
     *
     * @event
     * @returns {void}
     */
    onMoveNippleEnd() {
        this.setDirection(this.moveNipple.oldDirection, false)
    }

    /**
     * Fired when a "move" event occurs on the "camera" nipple (the right one).
     * Rotates the camera in function of the nipple's direction.
     *
     * @param {Object} movement The movement object from the event.
     * @param {number} movement.x The horizontal strength of the nipple movement.
     * @param {number} movement.y The vertical strength of the nipple movement.
     *
     * @event
     * @returns {void}
     */
    onCameraNippleMove(movement) {
        this.inertia.head.value.set(movement.x, movement.y)
        this.inertia.roll.value = movement.x
        this.moving.head = true
    }

    /**
     * Fired when a "end" event occurs on the "camera" nipple (the right one).
     * Stops the camera rotation.
     *
     * @event
     * @returns {void}
     */
    onCameraNippleEnd() {
        this.inertia.head.value.set(0, 0)
        this.inertia.roll.value = 0
        this.moving.head = false
    }
}
