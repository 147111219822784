import { AudioListener } from 'three'
import TweenMax from 'gsap/TweenMax'
import { Linear, Power2 } from 'gsap/EasePack'

import config from '../settings/config'
import Resources from '../Resources'

/**
 * Used only to create a Listener and add it to the camera.
 * Also creates a key event to toggle PositionalAudio helpers.
 * @class
 */
export default class AudioManager {

  /**
   * Creates an AudioListener and add it to the given camera.
   *
   * @param {Camera} camera - The camera where the listener will be added.
   * @constructor
   */
  constructor(camera) {
    AudioManager.sounds = []
    AudioManager.currentMusic = null
    AudioManager.listener = new AudioListener()
    AudioManager.disable()
    AudioManager.biquadFilter = AudioManager.listener.context.createBiquadFilter()
    AudioManager.playingAudios = []
    AudioManager.muted = false
    AudioManager.isLowPassed = false
    AudioManager.volumes = {
      master: 1,
      lowPassVolume: 2,
      ambiences: {
        default: 0.14,
        chapter4: 0.16,
        chapter4Loop: 0.16,
        chapter4End: 0.16,
        chapter5Intro: 0.17,
        chapter5Loop: 0.17,
        chapter5End: 0.17
      },
      effects: {
        default: 0.4,
        newRank: 0.3,
        notification: 0.2,
        goodMessage: 0.3,
        badMessage: 0.3,
        heart: 0.3
      },
      voices: {
        default: 1.8,
        hello: 2,
        baseline: 2,
        hiversOfTheDayComing: 2.7,
        ch5Savior1: 0.8,
        ch5Savior2: 0.8,
        ch5SaviorDoGoodChoice: 0.8,
        ch5SaviorFinal: 0.8
      },
      interfaces: 1,
      interactives: 1,
      messages: 1,
      defaultInteractives: 1,
      defaultInterfaces: 1,
      positional: {
        default: 1,
        turnOn: 0.3,
        tock1: 1.2,
        tock2: 1.2,
        tock3: 1.2,
        tock4: 1.2,
        tock5: 1.2,
        tock6: 1.2,
        tock7: 1.2,
        message: 0.5,
        message2: 0.55,
        unlock: 0.7
      }
    }

    AudioManager.biquadFilter.frequency.value = AudioManager.listener.context.sampleRate / 2
    
    camera.add(AudioManager.listener)
  }

  /**
   * Starts the audio context on user interaction.
   * Called by GameManager when the game starts.
   *
   * @static
   * @returns {void}
   */
  static enable() {
    AudioManager.listener.context.resume()
  }

  static disable() {
    AudioManager.listener.context.suspend()
  }

  static enableLowpass(value = 250) {
    AudioManager.listener.setFilter(AudioManager.biquadFilter)

    TweenMax.to(AudioManager.biquadFilter.frequency, 1, {
      value,
      ease: Power2.easeOut
    })

    AudioManager.isLowPassed = true
    
    if (AudioManager.muted) return
    
    const param = { volume: AudioManager.listener.getMasterVolume() }
    
    TweenMax.to(param, 1, {
      volume: AudioManager.volumes.lowPassVolume,
      ease: Power2.easeIn,
      onUpdate: () => {
        AudioManager.listener.setMasterVolume(param.volume)
      },
      onComplete: () => {
        AudioManager.listener.setMasterVolume(param.volume)
      }
    })
  }

  static disableLowpass() {
    TweenMax.to(AudioManager.biquadFilter.frequency, 1.3, {
      value: AudioManager.listener.context.sampleRate / 2,
      ease: Power2.easeIn,
      onComplete: () => {
        AudioManager.listener.removeFilter()
      }
    })

    AudioManager.isLowPassed = false

    if (AudioManager.muted) return

    const param = { volume: AudioManager.volumes.lowPassVolume }
    
    TweenMax.to(param, 1.3, {
      volume: AudioManager.volumes.master,
      ease: Power2.easeOut,
      onUpdate: () => {
        AudioManager.listener.setMasterVolume(param.volume)
      },
      onComplete: () => {
        AudioManager.listener.setMasterVolume(param.volume)
      }
    })
  }

  static resume() {
    for (let i = 0; i < AudioManager.playingAudios.length; i++) {
      AudioManager.playingAudios[i].resume()
    }
  }

  static toggle() {
    if (AudioManager.listener.getMasterVolume() === AudioManager.volumes.master || AudioManager.listener.getMasterVolume() === AudioManager.volumes.lowPassVolume) AudioManager.mute()
    else AudioManager.unmute()
  }

  static mute() {
    AudioManager.listener.setMasterVolume(0)
    AudioManager.muted = true
  }

  static unmute() {
    AudioManager.listener.setMasterVolume(AudioManager.isLowPassed ? AudioManager.volumes.lowPassVolume : AudioManager.volumes.master)
    AudioManager.muted = false
  }

  static pause() {
    for (let i = 0; i < AudioManager.playingAudios.length; i++) {
      AudioManager.playingAudios[i].pause()
    }
  }

  static addPlayingAudio(audio) {
    AudioManager.playingAudios.push(audio)
  }

  static removePlayingAudio(audio) {
    const index = AudioManager.playingAudios.indexOf(audio)

    if (index !== -1) AudioManager.playingAudios.splice(index, 1)
  }

  update() {
    for (let i = 0; i < AudioManager.sounds.length; i++) {
      AudioManager.sounds[i].update()
    }
  }

  static playMusic({ intro, loop, fadeIn = true }) {
    if (!intro || !loop) return

    if (AudioManager.currentMusic) AudioManager.fadeOut(0)

    if (fadeIn) intro.sound.setVolume(0)
    else {
      const vol = AudioManager.volumes.ambiences[intro.name] ? AudioManager.volumes.ambiences[intro.name] : AudioManager.volumes.ambiences.default

      intro.sound.setVolume(vol)
    }
    
    loop.sound.setVolume(AudioManager.volumes.ambiences[loop.name] ? AudioManager.volumes.ambiences[loop.name] : AudioManager.volumes.ambiences.default)
    loop.sound.setLoop(true)

    intro.onEnd = () => {
      loop.play()
      
      AudioManager.currentMusic = loop
    }

    intro.play()

    if (fadeIn) {
      const param = { volume: 0 }

      TweenMax.to(param, 2, {
        volume: AudioManager.volumes.ambiences[intro.name] ? AudioManager.volumes.ambiences[intro.name] : AudioManager.volumes.ambiences.default,
        ease: Linear.easeNone,
        onUpdate: () => {
          intro.sound.setVolume(param.volume)
        },
        onComplete: () => {
          intro.sound.setVolume(param.volume)
        }
      })
    }
  
    AudioManager.currentMusic = intro
  }

  static queueMusic(music) {
    return new Promise((resolve) => {
      AudioManager.currentMusic.sound.setLoop(false)

      AudioManager.currentMusic.onEnd = () => {
        music.play()

        AudioManager.currentMusic = music
      }

      resolve()
    })
  }

  static fadeOut(duration = 4) {
    return new Promise((resolve) => {
      if (!AudioManager.currentMusic) {
        resolve()
        
        return
      }

      const param = { volume: AudioManager.volumes.ambiences[AudioManager.currentMusic.name] ? AudioManager.volumes.ambiences[AudioManager.currentMusic.name] : AudioManager.volumes.ambiences.default }

      TweenMax.to(param, duration, {
        volume: 0,
        ease: Linear.easeNone,
        onUpdate: () => {
          AudioManager.currentMusic.sound.setVolume(param.volume)
        },
        onComplete: () => {
          AudioManager.currentMusic.sound.setVolume(param.volume)
          AudioManager.currentMusic.sound.setLoop(false)
          AudioManager.currentMusic.destroy()
          AudioManager.currentMusic = null

          resolve()
        }
      })
    })
  }

  /**
   * Starts debug mode and adds keyboard event to toggle Audio Helpers.
   * Called by GameManager if config.debug is true.
   *
   * @returns {void}
   */
  startDebugMode() {
    document.addEventListener('keydown', (e) => {
      // A key toggles audio helpers
      if (e.code === config.controls.helpers.sounds) {
        for (let i = 0; i < Resources.audios.positionalHelpers.length; i++) {
          Resources.audios.positionalHelpers[i].visible = !Resources.audios.positionalHelpers[i].visible
        }
      }
    })
  }
}
