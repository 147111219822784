import CameraControls from './CameraControls'
import config from '../settings/config'
import World from '../World'
import Resources from '../Resources'
import DOMManager from '../managers/DOMManager'

/**
 * StandardCameraControls is used on desktop to move and rotate the camera using keyboard and mouse.
 * Heritates from CameraControls, which checks collisions, distance and interactions with objects in the scene.
 * @class
 */
export default class StandardCameraControls extends CameraControls {

	/**
	 * Creates specific properties in addition to those inherited from CameraControls.
	 *
     * @param {Camera} camera - The camera that needs to have controls.
	 * @constructor
	 */
	constructor(camera) {
		super(camera)

		this.isOnFocus = false
		this.isDebugCamera = false

		this.PI_2 = Math.PI / 2
	}

	/**
	 * Fired when a "click" event occurs on the canvas.
	 * If the focus is not on the game, turns on the PointerLock (first person control).
     * If the focus is already on the game, starts interaction on the current Action, if there is any.
	 *
	 * @event
	 * @returns {void}
	 */
	onDomElementClick() {
		if (!this.isOnFocus && !this.isDebugCamera) this.lock()
		if (this.isOnFocus && DOMManager.ui.actionSlot.action) DOMManager.ui.actionSlot.action.startInteraction()
	}

	/**
	 * Fired when a `onKeyDown` event occurs on the correct key.
	 * The direction associated is given as parameter.
	 * Initiates the movement.
	 * Called by CameraManager.
	 *
	 * @param {string} controlName - 'forward', 'backward', 'right' or 'left'.
	 * @event
	 * @returns {void}
	 */
	onKeyDown(controlName) {
		if (this.active) this.setDirection(controlName, true)
	}

	/**
	 * Fired when a `onKeyUp` event occurs on the correct key.
	 * The direction associated is given as parameter.
	 * Stops the movement.
	 * Called by CameraManager.
	 *
	 * @param {string} controlName - 'forward', 'backward', 'right' or 'left'.
	 * @event
	 * @returns {void}
	 */
	onKeyUp(controlName) {
		if (this.active) this.setDirection(controlName, false)
	}

	/**
	 * Locks the pointer to give focus to the game.
	 *
	 * @returns {void}
	 */
	lock() {
		Resources.canvas.requestPointerLock()

		this.isDebugCamera = false
	}

	/**
	 * Unlocks the pointer to lose focus from the game.
	 *
	 * @param {boolean} goingToDebugCamera - If `true`, the state of the controls will be in "debug" mode (showing the OrbitControls camera).
	 *
	 * @returns {void}
	 */
	unlock(goingToDebugCamera = false) {
		document.exitPointerLock()

		this.isDebugCamera = goingToDebugCamera
	}

	/**
	 * Fired when a `mousemove` event occurs.
	 * Sets the inertia values according to mouse speed for yaw / pitch, and roll.
	 * Called by CameraManager.
	 *
	 * @param {event} e - Mousemove event.
	 * @event
	 * @returns {void}
	 */
	onMouseMove(e) {
		if (!this.isOnFocus || !this.active) return

		const movementX = e.movementX || e.mozMovementX || e.webkitMovementX || 0
		const movementY = e.movementY || e.mozMovementY || e.webkitMovementY || 0

		this.inertia.head.value.set(movementX, movementY)
        this.inertia.roll.value = movementX

        this.moving.head = true
	}

	/**
	 * Fired when a `mousemove` event finishes (is debounced).
	 * Sets the inertia values to zero to reset movement.
	 * Called by CameraManager.
	 *
	 * @event
	 * @returns {void}
	 */
	onMouseMoveDebounced() {
		if (!this.isOnFocus || !this.active) return

		this.inertia.head.value.set(0, 0)
        this.inertia.roll.value = 0
        
        this.moving.head = false
	}

	/**
	 * Fired when a `pointerlockchange` event occurs.
	 * Sets the focus to the game or lose the focus.
	 * Called by CameraManager.
	 *
	 * @event
	 * @returns {void}
	 */
	onPointerlockChange() {
		if (document.pointerLockElement === Resources.canvas) {
			this.isOnFocus = true
			World.isPointerLocked = true
		} else {
			this.inertia.head.value.set(0, 0)
			this.inertia.roll.value = 0
			this.isOnFocus = false
            this.moving.head = false
			World.isPointerLocked = false

			if (!this.isDebugCamera) DOMManager.menu.open()
		}
	}

	/**
	 * Fired when a `pointerlockerror` event occurs.
	 * Usually called when an attempt to lock the focus is made but the user doesn't have his mouse on the screen.
	 * Called by CameraManager.
	 *
	 * @param {event} e - The original event for debugging.
	 * @event
	 * @returns {void}
	 */
	onPointerlockError(e) {
		console.warn('StandardCameraControls: Unable to use Pointer Lock API', e)
	}

	/**
     * Starts debug mode by adding GUI settings to control inertias.
     * Called by CameraManager if config.debug is true when the game starts.
     * This method override its parent method.
	 *
	 * @param {Object} parentGUIFolder - The GUI folder to add inner folders to.
     *
     * @returns {void}
     */
	startDebugMode(parentGUIFolder) {
		if (!parentGUIFolder) return

		const options = {
			head: {
                sensitivity: config.cameras.standard.mouseSensitivity * 1000,
				acceleration: this.inertia.head.engine.ac,
				drag: this.inertia.head.engine.dr,
				reflect: this.inertia.head.engine.ref,
				threshold: this.inertia.head.engine.threshold * 1000
			},
			roll: {
                sensitivity: config.cameras.standard.rollSensitivity * 1000,
				acceleration: this.inertia.roll.engine.ac,
				drag: this.inertia.roll.engine.dr,
				reflect: this.inertia.roll.engine.ref,
				threshold: this.inertia.roll.engine.threshold * 1000
			},
			position: {
				acceleration: this.inertia.position.engine.ac,
				drag: this.inertia.position.engine.dr,
				reflect: this.inertia.position.engine.ref,
				threshold: this.inertia.position.engine.threshold * 1000
			}
		}
		const guiFolder = parentGUIFolder.addFolder('Standard Controls')
		const f = guiFolder.addFolder('Head inertia')

		f.add(options.head, 'acceleration', 0, 1)
			.onChange((value) => {
				this.inertia.head.engine.ac = value
			})

		f.add(options.head, 'drag', 0, 1)
			.onChange((value) => {
				this.inertia.head.engine.dr = value
			})

		f.add(options.head, 'reflect', -1, 1)
			.onChange((value) => {
				this.inertia.head.engine.ref = value
			})

		f.add(options.head, 'threshold', 1, 500)
			.onChange((value) => {
				this.inertia.head.engine.threshold = value / 1000
			})

        f.add(options.roll, 'sensitivity', 0, 7)
            .onChange((value) => {
                config.cameras.standard.mouseSensitivity = value / 1000
            })

		const f1 = guiFolder.addFolder('Roll inertia')

		f1.add(options.roll, 'acceleration', 0, 1)
			.onChange((value) => {
				this.inertia.roll.engine.ac = value
			})

		f1.add(options.roll, 'drag', 0, 1)
			.onChange((value) => {
				this.inertia.roll.engine.dr = value
			})

		f1.add(options.roll, 'reflect', -1, 1)
			.onChange((value) => {
				this.inertia.roll.engine.ref = value
			})

        f1.add(options.roll, 'threshold', 1, 500)
            .onChange((value) => {
                this.inertia.roll.engine.threshold = value / 1000
            })
		
        f1.add(options.roll, 'sensitivity', 0, 7)
			.onChange((value) => {
				config.cameras.standard.rollSensitivity = value / 1000
			})

		const f2 = guiFolder.addFolder('Position inertia')

		f2.add(options.position, 'acceleration', 0, 1)
			.onChange((value) => {
				this.inertia.position.engine.ac = value
			})

		f2.add(options.position, 'drag', 0, 1)
			.onChange((value) => {
				this.inertia.position.engine.dr = value
			})

		f2.add(options.position, 'reflect', -1, 1)
			.onChange((value) => {
				this.inertia.position.engine.ref = value
			})

        f2.add(options.position, 'threshold', 1, 500)
            .onChange((value) => {
                this.inertia.position.engine.threshold = value / 1000
            })
	}
}
