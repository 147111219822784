import TimelineMax from 'gsap/TimelineMax'
import { Power1 } from 'gsap/EasePack'
import 'gsap/BezierPlugin'

import Chapter from '../Chapter'
import SleepData from './SleepData'
import LeaveRoom from './LeaveRoom'
import config from '../../settings/config'
import Step from '../Step'
import CameraManager from '../../managers/CameraManager'
import AudioManager from '../../managers/AudioManager'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager'

/**
 * Creates the first Chapter of the story : Tutorial.
 * @class
 */
export default class Tutorial extends Chapter {
  constructor(props = {}) {
    super({
      name: config.scenario.tutorial.name,
      steps: props.steps,
      refreshInterfacesOnStart: props.refreshInterfacesOnStart
    })
  }

  /**
   * Animates the camera like a wake up movement.
   *
   * @todo Make things more compact (other file for position data ?).
   *
   * @returns {void}
   */
  onStart() {
    return new Promise((resolve) => {
      if (config.debug) CameraManager.setStandUpPosition(false, true)
      else CameraManager.setDefaultPosition()

      DOMManager.interlude(0)
        .then(() => DOMManager.leaveInterlude(0))
        .then(this.wakeUp.bind(this))
        .then(resolve)
    })
  }

  wakeUp() {
    return new Promise((resolve) => {
      AudioManager.playMusic({
        intro: Resources.audios.ambiences.chapter1,
        loop: Resources.audios.ambiences.chapter1Loop,
        fadeIn: false
      })

      if (config.debug) {
        DOMManager.showTutorial()
          .then(() => {
            CameraManager.activate()

            resolve()
          })

        return
      }

      const tl = new TimelineMax({
        delay: 1,
        onComplete: () => {
          DOMManager.showTutorial()
            .then(() => {
              CameraManager.activate()

              resolve()
            })
        }
      })
      const state1 = {
        position: {
          from: {
            posX: -155,
            posY: 60
          },
          to: {
            posX: -85,
            posY: 120
          },
          i: {
            posX: -156,
            posY: 117
          },
          i2: {
            posX: -122,
            posY: 124
          }
        },
        rotation: {
          from: {
            x: 50,
            y: -90
          },
          to: {
            x: -10,
            y: -90
          }
        }
      }
      const state2 = {
        rotation: {
          from: {
            x: -10,
            y: -90
          },
          to: {
            x: -7.5,
            y: 0
          }
        },
        position: {
          from: {
            posX: -85,
            posY: 120,
            posZ: 0
          },
          to: {
            posX: -76,
            posY: config.user.height,
            posZ: -65
          }
        }
      }

      tl.to(state1.rotation.from, 2.5, {
          x: state1.rotation.to.x,
          y: state1.rotation.to.y,
          ease: Power1.easeInOut,
          onUpdate: () => {
            CameraManager.rotateOn('y', Number(state1.rotation.from.y))
            CameraManager.rotateOn('x', Number(state1.rotation.from.x))
          }
        })
        .to(state1.position.from, 2.5, {
          bezier: [{
            posX: state1.position.from.posX,
            posY: state1.position.from.posY
          },
          {
            posX: state1.position.i.posX,
            posY: state1.position.i.posY
          },
          {
            posX: state1.position.i2.posX,
            posY: state1.position.i2.posY
          },
          {
            posX: state1.position.to.posX,
            posY: state1.position.to.posY
          }],
          ease: Power1.easeInOut,
          onUpdate: () => {
            CameraManager.defaultCamera.position.x = Number(state1.position.from.posX)
            CameraManager.defaultCamera.position.y = Number(state1.position.from.posY)
          }
        }, '-=2.5')
        .to(state2.rotation.from, 1, {
          x: state2.rotation.to.x,
          y: state2.rotation.to.y,
          ease: Power1.easeInOut,
          onUpdate: () => {
            CameraManager.rotateOn('y', Number(state2.rotation.from.y))
            CameraManager.rotateOn('x', Number(state2.rotation.from.x))
          }
        })
        .to(state2.position.from, 1, {
          posX: state2.position.to.posX,
          posY: state2.position.to.posY,
          posZ: state2.position.to.posZ,
          ease: Power1.easeInOut,
          onUpdate: () => {
            CameraManager.defaultCamera.position.x = Number(state2.position.from.posX)
            CameraManager.defaultCamera.position.y = Number(state2.position.from.posY)
            CameraManager.defaultCamera.position.z = Number(state2.position.from.posZ)
          }
        }, '-=0.3')
    })
  }

  createSteps() {
    return [
      new Step(config.scenario.tutorial.steps.wakeUp),
      new SleepData(),
      new Step(config.scenario.tutorial.steps.noShare),
      new Step(config.scenario.tutorial.steps.share),
      new LeaveRoom()
    ]
  }
}
