export default [
	{
		start: 0.05,
		end: 1.8,
		text: 'Ça sent déjà bon !'
	},
	{
		start: 2.5,
		end: 5.25,
		text: 'Tiens. Vous avez été identifié dans une publication.'
	},
	{
		start: 5.75,
		end: 8,
		text: 'Elle provient de Zima, qui vous a parlé hier.'
	},
	{
		start: 8.35,
		end: 12,
		text: 'Retournez dans votre salon pour en savoir plus.'
	}
]
