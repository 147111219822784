export default [
	{
		start: 0.05,
		end: 2.25,
		text: 'Inconnu : Il faut que tu parles de tout ça. Près de ta porte...'
	},
	{
		start: 2.3,
		end: 7,
		text: '...tu pourras désactiver Hive et me laisser entrer, ne reste pas seul...'
	}
]
