export default [
	{
		start: 0.05,
		end: 2,
		text: 'Vous venez de recevoir deux nouveaux messages.'
	},
	{
		start: 2.3,
		end: 6,
		text: 'Ils m\'ont l\'air... aggressifs. Que voulez-vous faire ?'
	}
]
