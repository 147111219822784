/* eslint-disable no-alert */
import {
  FontLoader,
  TextureLoader,
  LinearFilter,
  Audio,
  AudioLoader,
  PositionalAudio
} from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import Resources from '../Resources'
import config from '../settings/config'
import HiveObjects from '../objects/HiveObjects'
import AudioManager from '../managers/AudioManager'
import Video from '../objects/Video'
import Sound from '../objects/Sound'
import Room from '../rooms/Room'
import User from '../User'
import App from '../App'
import BasisTextureLoader from './BasisTextureLoader'
import DRACOLoader from './DRACOLoader'
import DOMManager from '../managers/DOMManager'

/**
 * Loader class used to load asynchronously every useful assets in the game, data to load is found in the config.
 * Stores loaded assets in Resources.
 * Model is splitted into Room instances.
 * Objects are added to HiveObjects according to their type.
 * @class
 */
class Loader {

  /**
   * Creates THREE Loaders (AudioLoader, FontLoader, TextureLoader, GLTFLoader).
   * This class is instanciated by World.
   * @param {WebGLRenderer} renderer - Renderer of world
   *
   * @constructor
   */
  constructor(renderer) {
    this.loadScene = this.loadScene.bind(this)
    this.loadTextures = this.loadTextures.bind(this)
    this.loadVideos = this.loadVideos.bind(this)
    this.loadAudios = this.loadAudios.bind(this)
    this.loadFonts = this.loadFonts.bind(this)
    
    this.audioLoader = new AudioLoader()
    this.fontLoader = new FontLoader()
    this.textureLoader = new TextureLoader()
    this.basisLoader = new BasisTextureLoader()
    this.basisLoader.setTranscoderPath('./basis/')
    this.basisLoader.detectSupport(renderer)

    this.gltfLoader = new GLTFLoader()
    
    DRACOLoader.setDecoderPath('./draco-decoders/')
    this.gltfLoader.setDRACOLoader(new DRACOLoader())
  }

  /**
   * Loads everything and returns a Promise when its done.
   * Loads textures, fonts, and audios.
   * Then loads model.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadGame() {
    return new Promise((resolve) => {
      this.loadAssets()
        .then(this.loadScene)
        .then(() => {
          DOMManager.setLoadingPercentage(100)
          
          window.dispatchEvent(new Event('siteloaded'))
          resolve()
        })
    })
  }

  /**
   * Loads textures, fonts and audios at the same time.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadAssets() {
    return DRACOLoader.getDecoderModule()
    .then(this.loadTextures)
    .then(this.loadVideos)
    .then(this.loadFonts)
    .then(this.loadAudios)
  }

  loadVideos() {
    
    DOMManager.setLoadingPercentage(7)
    const videosChapter1 = Object.entries(config.textures.videos.chapter1)
    const videosChapter2 = Object.entries(config.textures.videos.chapter2)
    const videosChapter3 = Object.entries(config.textures.videos.chapter3)
    const videosChapter4 = Object.entries(config.textures.videos.chapter4)
    const videosChapter5 = Object.entries(config.textures.videos.chapter5)
    const videosHome = Object.entries(config.textures.videos.home)
    const promisesChapter1 = []
    const promisesChapter2 = []
    const promisesChapter3 = []
    const promisesChapter4 = []
    const promisesChapter5 = []
    const promisesHome = []

    for (let index = 0; index < videosChapter1.length; index++) {
      promisesChapter1.push(this.loadVideo(videosChapter1[index]))
    }

    return Promise.all(videosChapter1)
    .then(() => {
      
      DOMManager.setLoadingPercentage(15)
      for (let index = 0; index < videosChapter2.length; index++) {
        promisesChapter2.push(this.loadVideo(videosChapter2[index]))
      }

      return Promise.all(promisesChapter2)
    })
    .then(() => {
      DOMManager.setLoadingPercentage(25)
      for (let index = 0; index < videosChapter3.length; index++) {
        promisesChapter3.push(this.loadVideo(videosChapter3[index]))
      }
      
      return Promise.all(promisesChapter3)
    })
    .then(() => {
      DOMManager.setLoadingPercentage(36)
      for (let index = 0; index < videosChapter4.length; index++) {
        promisesChapter4.push(this.loadVideo(videosChapter4[index]))
      }

      return Promise.all(promisesChapter4)
    })
    .then(() => {
      DOMManager.setLoadingPercentage(45)
      for (let index = 0; index < videosChapter5.length; index++) {
        promisesChapter5.push(this.loadVideo(videosChapter5[index]))
      }
      
      return Promise.all(promisesChapter5)
    })
    .then(() => {
      DOMManager.setLoadingPercentage(50)
      for (let index = 0; index < videosHome.length; index++) {
        promisesHome.push(this.loadVideo(videosHome[index]))
      }
      
      return Promise.all(videosHome)
    })
    
    // eslint-disable-next-line max-statements-per-line
    // return promisesVideos.reduce((a, b) => a.then(b), () => { Promise.resolve(null) })

    // return Promise.all(promisesVideos)
  }

  /**
   * Parse every texture path in the config.textures object, then load them.
   * Loads Textures and VideoTextures at the same time.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadTextures() {
    DOMManager.setLoadingPercentage(5)
    const imgs = Object.entries(config.textures.imgs)
    const bakingDay = Object.entries(config.textures.baking.day)
    const baking = Object.entries(config.textures.baking.night)
    const userGoodImagesMessage = Object.entries(config.textures.messages.good.profilePictures)
    const contentGoodMessage = Object.entries(config.textures.messages.good.content)
    const userBadImagesMessage = Object.entries(config.textures.messages.bad.profilePictures)
    const contentBadMessage = Object.entries(config.textures.messages.bad.content)
    const promisesTextures = []

      for (let index = 0; index < imgs.length; index++) {
        promisesTextures.push(this.loadTexture(imgs[index], Resources.textures.imgs, true))
      }
  
      for (let index = 0; index < bakingDay.length; index++) {
        promisesTextures.push(this.loadTexture(bakingDay[index], Resources.textures.imgs.baking.day, false))
      }

      for (let index = 0; index < baking.length; index++) {
        promisesTextures.push(this.loadTexture(baking[index], Resources.textures.imgs.baking.night, false))
      }
  
      for (let index = 0; index < userGoodImagesMessage.length; index++) {
        promisesTextures.push(this.loadTexture(userGoodImagesMessage[index], Resources.textures.imgs.messages.good.profilePictures, true))
      }
  
      for (let index = 0; index < contentGoodMessage.length; index++) {
        promisesTextures.push(this.loadTexture(contentGoodMessage[index], Resources.textures.imgs.messages.good.content, true))
      }
  
      for (let index = 0; index < userBadImagesMessage.length; index++) {
        promisesTextures.push(this.loadTexture(userBadImagesMessage[index], Resources.textures.imgs.messages.bad.profilePictures, true))
      }
  
      for (let index = 0; index < contentBadMessage.length; index++) {
        promisesTextures.push(this.loadTexture(contentBadMessage[index], Resources.textures.imgs.messages.bad.content, true))
      }

      return Promise.all(promisesTextures)


    // console.log(promises)

    // eslint-disable-next-line arrow-body-style
    // const reducer = (oldPromise, newPromise) => {
    //   newPromise.then(() => {
    //     return
    //   })
    // }

    // return promises.reduce(reducer);
    
      // return promises.reduce((a, b) => a.then(b), Promise.resolve(null));

    // return Promise.all(promises).catch((e) => {
    //   console.error(e)
    // })

  }


  /**
   * Parse every font path found in the config.fonts object, then load them.
   * Loads Fonts at the same time.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadFonts() {
    
    DOMManager.setLoadingPercentage(46)
    const promises = []
    const fonts = Object.entries(config.fonts)

    for (let index = 0; index < fonts.length; index++) {
      promises.push(this.loadFont(fonts[index]))
    }

    return Promise.all(promises)
  }

  /**
   * Parse every audio path found in the config.sounds object, then load them.
   * Loads Audio and PositionalAudio at the same time.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadAudios() {
    
    DOMManager.setLoadingPercentage(65)
    const promises = []

    // AMBIENCE AND EFFECTS ARE CLASSIC AUDIOS (NOT POSITIONAL)
    const ambiences = Object.entries(config.sounds.ambiences)
    const effects = Object.entries(config.sounds.effects)
    const voices = Object.entries(config.sounds.voices)

    for (let i = 0; i < ambiences.length; i++) {
      promises.push(this.loadAudio(ambiences[i], 'ambiences'))
    }
    
    for (let i = 0; i < effects.length; i++) {
      promises.push(this.loadAudio(effects[i], 'effects'))
    }

    for (let i = 0; i < voices.length; i++) {
      promises.push(this.loadAudio(voices[i], 'voices'))
    }

    // DEFAULT INTERFACE AND INTERACTIVE ARE POSITIONAL AUDIOS
    const defaultInterfaces = Object.entries(config.sounds.defaultInterfaces)
    const defaultInteractives = Object.entries(config.sounds.defaultInteractives)
    
    for (let i = 0; i < defaultInterfaces.length; i++) {
      promises.push(this.loadAudio(defaultInterfaces[i], 'defaultInterfaces'))
    }
    
    for (let i = 0; i < defaultInteractives.length; i++) {
      promises.push(this.loadAudio(defaultInteractives[i], 'defaultInteractives'))
    }

    const messages = Object.entries(config.sounds.messages)
    
    for (let i = 0; i < messages.length; i++) {
      promises.push(this.loadAudio(messages[i], 'messages'))
    }

    // INNER INTERFACE AND INTERACTIVE SOUNDS ARE POSITIONAL AUDIOS
    // EXCEPT FOR SOUNDS INSIDE 'voice' PROPERTY
    const interfaces = Object.entries(config.sounds.interfaces)
    const interactives = Object.entries(config.sounds.interactives)
    
    this.parseAudios(interfaces, 'interfaces', promises)
    this.parseAudios(interactives, 'interactives', promises)

    return Promise.all(promises)
      .catch((e) => {
        console.error(e)
      })
  }

  /**
   * Used to load nested sound paths from config. (Interfaces and Interactives).
   *
   * @param {Array} array - An array containing at [0] a key name and at [1] the sounds to load.
   * @param {string} type - 'ambiences', 'effects', 'defaultInterfaces', 'defaultInteractives', 'interfaces', 'interactives'.
   * @param {Array} promises - An array of Promises where new Promises will be added.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  parseAudios(array, type, promises) {
    for (let i = 0; i < array.length; i++) {
      const sounds = Object.entries(array[i][1])
      
      for (let j = 0; j < sounds.length; j++) {
        if (sounds[j][0] === 'voices') {
          const voices = Object.entries(sounds[j][1])

          for (let k = 0; k < voices.length; k++) {
            promises.push(this.loadAudio(voices[k], type, array[i][0], true))
          }
        } else promises.push(this.loadAudio(sounds[j], type, array[i][0]))
      }
    }
  }

  /**
   * Loads an audio file, creates a Sound instance for it.
   * Place the loaded Sound instance in Resources according to parameters.
   *
   * @param {Array} audio - An array containing the audio's name and its path.
   * @param {string} category - The audio type.
   * @param {string} objectName - The parent name if the sound has to be attached to a specific object.
   * @param {boolean} isVoice - Whether the audio file to load is a voice or not.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadAudio(audio, category, objectName, isVoice) {
    return new Promise((resolve, reject) => {
      const sound = (objectName || category === 'defaultInteractives' || category === 'defaultInterfaces' || category === 'messages') && !isVoice ? new PositionalAudio(AudioManager.listener) : new Audio(AudioManager.listener)
      
      const isObject = typeof audio[1] === 'object' && audio[1] !== null
      const url = isObject ? audio[1].url : audio[1]

      this.audioLoader.load(url, (buffer) => {
        const soundObject = new Sound({
          name: audio[0],
          sound,
          category: isVoice ? 'voices' : category,
          buffer
        })

        if (objectName) {
          if (Resources.audios[category][objectName]) {
            if (isVoice) {
              if (Resources.audios[category][objectName].voices) Resources.audios[category][objectName].voices[audio[0]] = soundObject
              else Resources.audios[category][objectName].voices = { [audio[0]]: soundObject }
            } else Resources.audios[category][objectName][audio[0]] = soundObject
          } else {
            Resources.audios[category][objectName] = {}

            if (isVoice) Resources.audios[category][objectName].voices = { [audio[0]]: soundObject }
            else Resources.audios[category][objectName][audio[0]] = soundObject
          }
        } else Resources.audios[category][audio[0]] = soundObject

        resolve()
      }, () => {}, (e) => {
        console.error('ERROR LOADING AUDIO', e)
        reject(e)
      })
    })
  }

  /**
   * Loads a video as VideoTexture. Saves it in Resources.
   *
   * @param {Array} videoObject - An array containing the name of the video and its path and options.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadVideo(videoObject) {
    return new Promise((resolve, reject) => {
      const loop = videoObject[1].loop ? videoObject[1].loop : false
      const video = document.createElement('video')

      video.onerror = (e) => {
        reject(e)
      }
      if (!App.isMobile) video.onloadeddata = resolve
      video.src = videoObject[1].url
      video.crossOrigin = 'anonymous'
      video.muted = true
      video.setAttribute('webkit-playsinline', 'webkit-playsinline')
      video.setAttribute('playsinline', 'playsinline')
      video.load()

      Resources.textures.videos[videoObject[0]] = new Video({
        name: videoObject[0],
        loop,
        video
      })

      App.isMobile && resolve()
    })
  }

  /**
   * Loads the GLTF model, then parse its meshes to split appartment and store it to Resources.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadScene() {
    return new Promise((resolve, reject) => {
      
      DOMManager.setLoadingPercentage(75)
      this.loadGLTF(config.model.uri, 'scene')
        .then((gltf) => {
          
          DOMManager.setLoadingPercentage(85)
          Resources.scene.add(gltf.scene)
          this.parseApartment(gltf.scene)

          resolve()
        })
        .catch((e) => {
          console.error('Error loading model', e)

          reject(e)
        })
    })
  }

  /**
   * Loads user's pictures. Either Facebook pictures or default ones from config.
   * Creates Textures for these pictures.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadUserPictures() {
    const promises = []

    for (let index = 0; index < User.pictures.length; index++) {
      promises.push(this.loadTexture(User.pictures[index], Resources.user.pictures, true))
    }

    promises.push(this.loadTexture(User.profilePicture, Resources.user.profilePicture, true))

    return Promise.all(promises)
  }

  /**
   * Parses the appartment model to instanciate rooms and attach meshes to the right places.
   *
   * @param {Mesh} mesh - The mesh to parse, this is the whole gltf.scene.
   *
   * @returns {void}
   */
  parseApartment(mesh) {
    const roomNames = Object.keys(config.model.rooms)

    for (let i = 0; i < roomNames.length; i++) {
      Resources.rooms[roomNames[i]] = new Room(roomNames[i])
    }

    for (let i = 0; i < mesh.children.length; i++) {
      const splittedName = mesh.children[i].name.split('-')

      if (splittedName.length !== 3 && splittedName.length !== 4) {
        config.debug && console.warn('[Loader] -  Error Mesh : ' + mesh.children[i].name + ' does not respect 3 part naming convention, CORENTIN !')
        // eslint-disable-next-line no-continue
        continue
      }
      
      const room = splittedName[0]
      const type = splittedName[1]
      const name = splittedName[2]
      const data = splittedName[3]

      Resources.rooms[room].add({
        type,
        mesh: mesh.children[i],
        name,
        data
      })
    }

    for (let i = 0; i < roomNames.length; i++) {
      Resources.rooms[roomNames[i]].prepare()
    }

    HiveObjects.mapMeshes()
  }

  /**
   * Loads the GLTF model using GLTFLoader.
   * Stores it into Resources.
   *
   * @param {string} uri - The file path to load.
   * @param {string} resourceName - The name of the resource to load.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadGLTF(uri, resourceName) {
    return new Promise((resolve, reject) => {
      this.gltfLoader.load(uri, (model) => {
        if (resourceName) Resources.models[resourceName] = model

        resolve(model)
      }, () => {}, reject)
    })
  }

  /**
   * Loads a font with FontLoader and store it into Resources.
   *
   * @param {Array} fontObjet - Array containing the font name and its path.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadFont(fontObjet) {
    return new Promise((resolve, reject) => {
      this.fontLoader.load(fontObjet[1], (font) => {
        Resources.fonts[fontObjet[0]] = font

        resolve()
      }, () => {}, reject)
    })
  }

  /**
   * Generates a uuid.
   *
   * @returns {string} - A unique identifier.
   */
  uuid() {
    return Math.random()
      .toString(36)
      .substr(2, 9)
  }

  /**
   * Loads an image / video as a Texture / VideoTexture.
   *
   * @param {Array} imgObject - An array containing the texture name and its path.
   * @param {Object} resourcesSlot - The object in which the loaded Texture will be stored.
   * @param {boolean} flip - Whether to flip the texture along the Y axis or not.
   *
   * @returns {Promise} - A Promise resolved when everything is loaded.
   */
  loadTexture(imgObject, resourcesSlot = Resources.textures.imgs, flip) {
    return new Promise((resolve, reject) => {
      let arrayImgObject = null

      if (Array.isArray(imgObject)) arrayImgObject = imgObject
      else {
        arrayImgObject = [2]
        arrayImgObject[0] = this.uuid()
        arrayImgObject[1] = imgObject
      }

      const lastDotPosition = arrayImgObject[1].lastIndexOf('.')
      const extension = arrayImgObject[1].substring(lastDotPosition + 1)

      if (extension === 'basis') {
          this.basisLoader.load(arrayImgObject[1], (texture) => {
            texture.minFilter = LinearFilter
            texture.maxFilter = LinearFilter
            texture.flipY = flip
    
            resourcesSlot[arrayImgObject[0]] = texture
            resolve(texture)
        })
      } else {
        this.textureLoader.load(arrayImgObject[1], (texture) => {
          texture.minFilter = LinearFilter
          texture.maxFilter = LinearFilter
          texture.flipY = flip
  
          resourcesSlot[arrayImgObject[0]] = texture
  
          resolve(texture)
        }, () => {}, reject)
      }
    })
  }
}

export default Loader
