export default [
	{
		start: 0.05,
		end: 2.4,
		text: 'Pensez aux risques que cela implique !'
	},
	{
		start: 2.5,
		end: 5.1,
		text: 'Vous êtes en sécurité ici et rien de grave ne peut vous arriver.'
	},
	{
		start: 5.4,
		end: 8.5,
		text: 'Personne de l\'extérieur ne doit savoir ce que nous vivons.'
	},
	{
		start: 8.7,
		end: 11,
		text: 'Ils ne comprendraient pas...'
	}
]
