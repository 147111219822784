import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import FrontDoor from '../../objects/interactives/FrontDoor'
import DOMManager from '../../managers/DOMManager'

export default class EndScene extends Step {
  constructor() {
    super(config.scenario.chapter5.steps.endScene)
  }

  onStart() {
    return new Promise((resolve) => {
      const interactives = Resources.rooms.entrance.interactives

      for (let i = 0; i < interactives.length; i++) {
        if (interactives[i] instanceof FrontDoor) {
          interactives[i].description = ' '
          interactives[i].stopTocking()
        }
      }

      resolve()
    })
  }
}
