/**
 * Converts an angle in degrees to an angle in radians.
 *
 * @param {number} angle - Angle in degrees to convert.
 *
 * @returns {number} - The converted angle, now in radians.
 */
export const degreesToRadians = (angle) => angle * Math.PI / 180

/**
 * Converts an angle in radians to an angle in degrees.
 *
 * @param {number} angle - Angle in radians to convert.
 *
 * @returns {number} - The converted angle, now in degrees.
 */
export const radiansToDegrees = (angle) => angle * 180 / Math.PI
