const ranks = [
	'Renégat',
	'Renégat',
	'',
	'',
	'',
	'',
	'',
	'',
	'',
	''
]

export default ranks
