import config from '../../settings/config'
import Step from '../Step'
import DOMManager from '../../managers/DOMManager';

export default class Food extends Step {
	constructor() {
		super(config.scenario.chapter3.steps.food)
	}

	onStart() {
		return new Promise((resolve) => {
			setTimeout(() => {
				DOMManager.setNewGoal('Préparez votre plat en interagissant avec le four', 'kitchen')

				resolve()
			}, 1000)
		})
	}
}
