import config from './settings/config'
import World from './World'
import Facebook from './utils/Facebook'
import USER from './User'
import DOMManager from './managers/DOMManager'

/**
 * This class is the app's starting point.
 * It will immediately launch the loading of several assets,
 * Instanciate the DOMManager (which is used for DOM manipulation),
 * And wait for the user to click on either "Facebook" or "Standard" button once everything has loaded.
 * @class
 */
export default class App {
    
    /**
     * Starting point of everything.
     * Initializes DOMManager and World, giving it a DOM wrapper.
     * Sets event callbacks.
     * Starts loading.
     * Set debug mode if `config` says so.
     *
     * @constructor
     */
    constructor() {
        App.isMobile = false
        this.onStateMotionEnd = this.onStateMotionEnd.bind(this)
        
        if (window.innerWidth <= 1024) {
            App.isMobile = true
            config.debug = false
        }

        this.domManager = new DOMManager()
        this.world = new World(this.domManager.canvasWrapper)

        this.addDOMCallbacks()
        this.setupWorld()
        this.addEvents()

        !App.isMobile && config.debug && this.startDebugMode()
    }

    addEvents() {
        window.addEventListener('startmotionend', this.onStateMotionEnd)
    }

    onStateMotionEnd() {
        window.removeEventListener('startmotionend', this.onStateMotionEnd)

        if (DOMManager.buttonType === 'facebook') this.onFacebookBtnClick()
        else if (DOMManager.buttonType === 'standard') this.onStandardBtnClick()
    }

    /**
     * This method is called once the World instance has been initialized.
     * It is used to bind DOM events to World instance's callbacks.
     * These are the events that will be used in game, not related to the starting screen.
     *
     * @returns {void}
     */
    addDOMCallbacks() {
        this.domManager.addCallbacks({
            // Global events
            resize: this.world.resize.bind(this.world),
            // Action / focus events
            onDomElementClick: this.world.onDomElementClick.bind(this.world),
            onPointerlockChange: this.world.onPointerlockChange.bind(this.world),
            onPointerlockError: this.world.onPointerlockError.bind(this.world),
            // Controls events
            onKeyDown: this.world.onKeyDown.bind(this.world),
            onKeyUp: this.world.onKeyUp.bind(this.world),
            onMouseMove: this.world.onMouseMove.bind(this.world),
            onMouseMoveDebounced: this.world.onMouseMoveDebounced.bind(this.world)
        })
    }

    /**
     * `World.setup()` Loads all game-related assets.
     * Once these are loaded, `DOMManager.webGLReady()` is called.
     * The renderer is added to the DOM, and the launch buttons are enabled by the DOMManager.
     *
     * @returns {void}
     */
    setupWorld() {
        this.world.setup()
            .then(() => {
                this.domManager.webGLReady(this.world.renderer.domElement)
            })
            .catch((e) => {
                console.error('Error creating the world', e)
            })
    }

    /**
     * Fired when a `click` event occurs on the "Facebook" button.
     * Starts the `Facebook` class by giving it the button element,
     * As well as a callback for when the connection to FB is done, which will start the game.
     * FB will fetch and save user's information.
     *
     * @returns {void}
     */
    onFacebookBtnClick() {
        this.start()
    }

    static createFacebook(callback) {
        this.facebook = new Facebook(callback)
    }

    /**
     * Fired when a `click` event occurs on the "Standard" button.
     * Save default pictures as user's pictures.
     * Starts the game.
     *
     * @returns {void}
     */
    onStandardBtnClick() {
        USER.pictures = config.user.defaultPictures
        USER.profilePicture = config.user.defaultProfilPicture

        this.start()
    }

    /**
     * Loads user's pictures (FB or default ones).
     * Then starts the game by telling `World` and `DOMManager` to start whether in "Standard" mode or "Mobile" mode.
     *
     * @returns {void}
     */
    start() {
        this.world.loadUser()
            .then(() => {
                this.world.createInterfaces()
                this.world.preloadTextures()

                if (App.isMobile) {
                    this.world.startMobile()
                    this.domManager.startMobile()
                } else {
                    this.world.startStandard()
                    this.domManager.startStandard()
                }
            })
            .catch((e) => {
                console.error(e)
            })
    }

    /**
     * Called when `config.debug` is `true`.
     * Adds dat.GUI to DOM and place it manually.
     * Starts `World`'s debug mode too.
     *
     * @returns {void}
     */
    startDebugMode() {
        if (config.datGui && !config.datGui.autoPlace) {
            document.body.appendChild(config.datGui.domElement)
            config.datGui.domElement.style.position = 'absolute'
            config.datGui.domElement.style.top = '0'
            config.datGui.domElement.style.right = '0'
        }

        this.world.startDebugMode()
    }
}
