import ImageInterface from '../ImageInterface'
import Resources from '../../../Resources'
import config from '../../../settings/config'
import ImageTexture from '../../ImageTexture'

/**
 * Social is the Interface showing the frame with the user's profile picture.
 * @todo Change this Interface's name, not explicit enough.
 * @class
 */
export default class Social extends ImageInterface {
  setTextures() {
    this.imgs = Object.values(Resources.user.profilePicture)

    return [new ImageTexture({ texture: this.imgs[0] })]
  }

  setSteps() {
    return [config.scenario.tutorial.steps.wakeUp]
  }
}
