import {
  Scene,
  WebGLRenderer,
  Color,
  DirectionalLight,
  BoxBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  BackSide,
  Object3D
} from 'three'

import Loader from './loaders/Loader'
import config from './settings/config'
import GameManager from './managers/GameManager'
import HiveObjects from './objects/HiveObjects'
import Resources from './Resources'
import CameraManager from './managers/CameraManager'
import DOMManager from './managers/DOMManager'
import AudioManager from './managers/AudioManager'
import VideoManager from './managers/VideoManager'

/**
 * World's purpose is to manage everything that is 3D-related.
 * It creates a renderer, a scene, a GameManager and a Loader.
 * @class
 */
export default class World {

  /**
   * Creates a World instance. Manages the 3D in the app.
   * Prepares the HiveObjects, creates a Renderer, a Scene, and instanciates the GameManager.
   * Creates a Loader instance that will be used to load every useful assets.
   * Also contains a static property to check easily whether the desktop user is in focus.
   *
   * @param {HTMLElement} wrapper - The DOM element in which the canvas will be added.
   * @constructor
   */
  constructor(wrapper) {
    World.isPointerLocked = false
    World.started = false
    World.paused = false

    this.bindMethods()

    this.wrapper = wrapper
    this.hiveObjects = new HiveObjects()
    
    // Used in the raf (time, deltaTime).
    this.t = 0
    this.tDelta = 0

    this.createRenderer()
    this.createScene()

    this.gameManager = new GameManager()
    this.loader = new Loader(this.renderer)

    World.directional = new DirectionalLight(0xffffff, 0.9)

    World.directionalTarget = new Object3D()

    World.directionalTarget.position.z = -0.1

    World.directional.target = World.directionalTarget

    this.scene.add(World.directionalTarget)
    this.scene.add(World.directional)

    this.finalScene()
    this.addFocusEvents()
  }

  static nightMode() {
    World.directional.intensity = 0.3
    World.directionalTarget.position.set(-0.5, 0, 0.4)
  }

  finalScene() {
    const geom = new BoxBufferGeometry(200, 250, 100)
    const mat = new MeshBasicMaterial({
      color: 0xffffff,
      side: BackSide
    })
    const mesh = new Mesh(geom, mat)

    mesh.position.set(688, 130, 253)

    window.cube = mesh

    this.scene.add(mesh)

    mesh.matrixAutoUpdate = false
    mesh.updateMatrix()
  }

  /**
   * Binds class methods to itself to keep the right scope.
   *
   * @returns {void}
   */
  bindMethods() {
    this.render = this.render.bind(this)
  }

  /**
   * Creates a WebGLRenderer and saves the canvas element to the Resources.
   *
   * @returns {void}
   */
  createRenderer() {
    this.renderer = new WebGLRenderer({
      antialias: true,
      powerPreference: 'high-performance',
      stencil: false
    })
    
    this.renderer.setPixelRatio(Math.floor(window.devicePixelRatio))
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.renderer.sortObjects = false
    this.renderer.debug.checkShaderErrors = config.debug

    Resources.canvas = this.renderer.domElement
  }

  /**
   * Creates a Scene and saves it to the Resources.
   *
   * @returns {void}
   */
  createScene() {
    this.scene = new Scene()
    this.scene.background = new Color(0x131B23)
    this.scene.name = 'hive-scene'

    Resources.scene = this.scene
  }

  /**
   * Loads everything and returns a Promise when done.
   *
   * @returns {Promise} - Promise resolved when VR availability has been checked (result given as parameter) and the loader finished loading everything.
   */
  setup() {
    return new Promise((resolve) => {
      const promises = []

      promises.push(this.loader.loadGame())

      Promise.all(promises).then((results) => {
        this.gameManager.onLoaded()

        resolve(results[0])
      })
    })
  }

  /**
   * For each room, create its interfaces.
   *
   * @todo Use for..in instead of Object.entries.
   *
   * @returns {void}
   */
  createInterfaces() {
    const rooms = Object.entries(Resources.rooms)
    
    for (let i = 0; i < rooms.length; i++) {
      const room = rooms[i]

      room[1].addInterfaces()
    }
  }

  /**
   * Loads users pictures.
   *
   * @returns {Promise} - A promise resolved when the Loader finished loading users pictures.
   */
  loadUser() {
    return this.loader.loadUserPictures()
  }

  /**
   * Used to compile every material on the scene so they do not cause the game to freeze at first rendering.
   * Called by App.
   *
   * @returns {void}
   */
  preloadTextures() {
    this.scene.traverse((child) => {
      if (child.isObject3D) child.frustumCulled = false
    })
    
    this.renderer.render(this.scene, CameraManager.defaultCamera)

    this.scene.traverse((child) => {
      if (child.isObject3D) child.frustumCulled = true
    })
  }

  /**
   * Starts the GameManager in Mobile mode.
   * Launches the raf.
   *
   * @returns {void}
   */
  startMobile() {
    this.gameManager.startMobile()
    DOMManager.ui.interlude.el.classList.add('active')

    World.started = true
    
    requestAnimationFrame(this.render)
  }

  /**
   * Starts the GameManager in Standard mode.
   * Launches the raf.
   * Tells HiveObjects to go in debug mode if needed.
   *
   * @returns {void}
   */
  startStandard() {
    this.gameManager.startStandard()
    config.debug && this.hiveObjects.startDebugMode()
    DOMManager.ui.interlude.el.classList.add('active')

    World.started = true

    requestAnimationFrame(this.render)
  }

  addFocusEvents() {
    window.addEventListener('focus', () => {
      AudioManager.unmute()
    })
    window.addEventListener('blur', () => {
      AudioManager.mute()
      World.pause()
    })
  }

  static pause(fromInterlude) {
    World.paused = true

    World.started && CameraManager.pause(fromInterlude)
    
    if (!fromInterlude) {
      AudioManager.pause()
      VideoManager.pause()
    }
  }

  static resume(fromInterlude) {
    World.paused = false

    World.started && CameraManager.resume(fromInterlude)
    
    if (!fromInterlude) {
      AudioManager.resume()
      VideoManager.resume()
    }
  }

  /**
   * The render loop, called each frame by a requestAnimationFrame.
   * Fires the update method of the GameManager.
   * Also calculates time and deltaTime.
   *
   * @returns {void}
   */
  render() {
    if (World.paused) {
      requestAnimationFrame(this.render)
      
      return
    }

    const newT = performance.now()

    this.tDelta = newT - this.t
    this.t = newT

    this.gameManager.update({
      time: this.t,
      deltaTime: this.tDelta
    })

    this.renderer.render(this.scene, CameraManager.currentCamera)

    requestAnimationFrame(this.render)
  }

  /**
   * Resize event triggered by DOMManager on window resize.
   *
   * @event
   * @returns {void}
   */
  resize() {
    this.gameManager.resize()
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  // Make the events go down in our structure.
  // These events only happens in Standard mode.
  onMouseMove(e) { this.gameManager.onMouseMove(e) }
  onMouseMoveDebounced() { this.gameManager.onMouseMoveDebounced() }
  onPointerlockChange() { this.gameManager.onPointerlockChange() }
  onPointerlockError(e) { this.gameManager.onPointerlockError(e) }
  onKeyDown(controlName) { this.gameManager.onKeyDown(controlName) }
  onKeyUp(controlName) { this.gameManager.onKeyUp(controlName) }

  // This event happens in both Standard and Mobile mode.
  onDomElementClick() { this.gameManager.onDomElementClick() }

  /**
   * Starts debug mode and adds a folder to the GUI.
   * Called by App if config.debug is true.
   *
   * @returns {void}
   */
  startDebugMode() {
    this.gameManager.startDebugMode()

    if (!config.datGui) return
    
    const options = {
      drawCalls: 0,
      triangles: 0,
      programs: 0
    }

    const f = config.datGui.addFolder('World')
    
    const drawCalls = f.add(options, 'drawCalls')
    const triangles = f.add(options, 'triangles')
    const programs = f.add(options, 'programs')

    options.calculate = () => {
      triangles.setValue(this.renderer.info.render.triangles)
      drawCalls.setValue(this.renderer.info.render.calls)
      programs.setValue(this.renderer.info.programs.length)
    }

    f.add(options, 'calculate')
  }
}
