import VideoInterface from '../VideoInterface'
import Resources from '../../../Resources'

export default class Dish extends VideoInterface {
  setTextures() {
    this.firstVideo = Resources.textures.videos.kitchen
    this.ch4KitchenWait = Resources.textures.videos.ch4KitchenWait

    return [
      this.firstVideo,
      this.ch4KitchenWait
    ]
  }

  nextDay() {
    this.setCurrentVideo({
      video: this.ch4KitchenWait,
      isFirst: true,
      replaceOld: true
    })
  }
}
