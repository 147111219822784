export default [
	{
		start: 0.1,
		end: 3.5,
		text: 'Oh ! La liste des Hivers du jour vient d\'arriver. Vous en faites partie !'
	},
	{
		start: 4.25,
		end: 8,
		text: 'Allez jeter un coup d\'oeil à côté de votre chambre.'
	}
]
