import { Vector3 } from 'three'

/**
 * Configuration object for camera-related settings. Including all inertia parameters.
 * `debug` object contains the debug camera options (OrbiControls).
 */
const cameras = {
	fov: 50,
	near: 1,
	far: 3000,
	collisionDistance: 10,
	interactionDistance: 260,
	speed: 500,
	defaultPosition: new Vector3(-155, 60, 0),
	defaultRotation: new Vector3(50, -90, 0),
	standard: {
		mouseSensitivity: 0.002,
		rollSensitivity: 0.001,
		head: {
			acceleration: 0.35,
			drag: 0.39,
			threshold: 0.005
		},
		roll: {
			acceleration: 0.57,
			drag: 0.2,
			threshold: 0.005
		},
		position: {
			acceleration: 0.20,
			drag: 0.48
		}
	},
	debug: {
		position: [0, 160, 150]
	}
}

export default cameras
