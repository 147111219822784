import {
  SphereBufferGeometry,
  MeshBasicMaterial,
  Mesh,
  BackSide,
  // Fog,
  Color
} from 'three'

import CameraManager from './CameraManager'
import config from '../settings/config'
import HiveObjects from '../objects/HiveObjects'
import HistoryManager from './HistoryManager'
import AudioManager from './AudioManager'
import Resources from '../Resources'
import VideoManager from './VideoManager'

/**
 * GameManager creates everything related to the game itself.
 * This means the cameras, the environment and the audio managers.
 * @class
 */
export default class GameManager {

  /**
   * Creates the game-related Managers.
   * @constructor
   */
  constructor() {
    this.cameraManager = new CameraManager()
    this.audioManager = new AudioManager(this.cameraManager.defaultCamera)
    this.videoManager = new VideoManager()
    this.historyManager = new HistoryManager()
    
    GameManager.createSky()
  }

  /**
   * Creates a sky : a big sphere around the model.
   *
   * @returns {void}
   */
  static createSky() {
    const geometry = new SphereBufferGeometry(1500, 32, 32)
    const mat = new MeshBasicMaterial({ side: BackSide })

    GameManager.sky = new Mesh(geometry, mat)
    GameManager.sky.position.set(250, 275, -250)
    GameManager.sky.rotation.y = -2.4

    Resources.scene.add(GameManager.sky)

    // this.fog = new Fog(0x000000, 250, 3000)
    // Resources.scene.fog = this.fog
  }

  /**
   * Fired when World finished loading the assets.
   * Sets the sky texture.
   *
   * @returns {void}
   */
  onLoaded() {
    GameManager.sky.material.map = Resources.textures.imgs.sky
  }

  static updateSkyBox(chapter) {
    if (chapter === 3) {
      GameManager.sky.material.map = Resources.textures.imgs.skyCh3
      GameManager.sky.position.y = 100

    }
    else if (chapter === 4) {
      GameManager.sky.material.map = Resources.textures.imgs.skyCh4
      GameManager.sky.position.y = 200
    }
  }

  /**
   * Starts the game in Standard mode by setting the Camera Controls to Standard.
   *
   * @returns {void}
   */
  startStandard() {
    this.cameraManager.setStandardControls()

    this.startGame()
  }

  /**
   * Starts the game in Mobile mode by setting the Camera Controls to Mobile.
   *
   * @returns {void}
   */
  startMobile() {
    this.cameraManager.setMobileControls()

    this.startGame()
  }

  /**
   * Starts the game by creating the HistoryManager and telling the AudioManager to start.
   * Play the wake up animation unless config.debug is true.
   *
   * @returns {void}
   */
  startGame() {
    this.historyManager.start()
    AudioManager.enable()
  }

  // Make the events go down in our structure
  // These events only happens in Standard mode
  onMouseMove(e) { this.cameraManager.onMouseMove(e) }
  onMouseMoveDebounced() { this.cameraManager.onMouseMoveDebounced() }
  onPointerlockChange() { this.cameraManager.onPointerlockChange() }
  onPointerlockError(e) { this.cameraManager.onPointerlockError(e) }
  onDomElementClick() { this.cameraManager.onDomElementClick() }
  onKeyDown(controlName) { this.cameraManager.onKeyDown(controlName) }
  onKeyUp(controlName) { this.cameraManager.onKeyUp(controlName) }

  // Make the events go down in our structure
  // These events only happens in VR mode
  onTouchStart() { this.cameraManager.onTouchStart() }
  onTouchEnd() { this.cameraManager.onTouchEnd() }

  /**
   * Updates the Camera Manager and the objects in the scene.
   * Called each frame by World.
   *
   * @param {Object} timeStamp - Object containing the time and deltaTime properties.
   *
   * @returns {void}
   */
  update(timeStamp) {
    this.cameraManager.update(timeStamp)
    HiveObjects.update(timeStamp)
    this.audioManager.update()
  }

  /**
   * Resize event called by World on window resize.
   *
   * @event
   * @returns {void}
   */
  resize() {
    this.cameraManager.resize()
  }

  /**
   * Starts debug mode and adds folder to the GUI.
   * Called by World if config.debug is true.
   *
   * @returns {void}
   */
  startDebugMode() {
    this.cameraManager.startDebugMode()
    this.audioManager.startDebugMode()
    
    if (!config.datGui) return

    const options = {}

    if (this.fog) {
      options.fogColor = '#' + this.fog.color.getHexString()
      options.fogNear = this.fog.near
      options.fogFar = this.fog.far
    }

    const guiFolder = config.datGui.addFolder('Game Manager')

    const skyF = guiFolder.addFolder('Sky box')

    skyF.add(GameManager.sky.position, 'x', -1000, 1000)
      .onChange((value) => {
        GameManager.sky.position.x = value
      })
    skyF.add(GameManager.sky.position, 'y', -1000, 1000)
      .onChange((value) => {
        GameManager.sky.position.y = value
      })
    skyF.add(GameManager.sky.position, 'z', -1000, 1000)
      .onChange((value) => {
        GameManager.sky.position.z = value
      })

    if (this.fog) {
      const fogF = guiFolder.addFolder('Fog')

      fogF.addColor(options, 'fogColor')
        .onChange((value) => {
          this.fog.color = new Color(value)
        })
      fogF.add(options, 'fogNear', 1, 10000)
        .onChange((value) => {
          this.fog.near = value
        })
      fogF.add(options, 'fogFar', 1, 10000)
        .onChange((value) => {
          this.fog.far = value
        })
    }
  }
}
