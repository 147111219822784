import HiveObjects from '../objects/HiveObjects'
import config from '../settings/config'
import CameraManager from '../managers/CameraManager'

/**
 * This class manages the progress a single Step of the story.
 * You can create a class extending this one if flexibility is wanted.
 * @class
 */
export default class Step {

  /**
   * Creates a Step instance and gives it a name.
   *
   * @param {string} name The Step's name.
   * @constructor
   */
  constructor(name) {
    this.name = name
  }

  /**
   * Starts the Step then analyze the active objects for this specific Step.
   *
   * @returns {Promise} A Promise resolved when the Step has been started.
   */
  start() {
    return new Promise((resolve) => {
      if (config.debug) console.log('Step ' + this.name + ' : START')

      this.onStart()
        .then(() => {
          HiveObjects.reloadActiveObjects()

          this.onStarted()
          
          CameraManager.needsUpdate = true

          resolve()
        })
    })
  }

  /**
   * Start callback returning a Promise. Useful for animations.
   * Override it to adapt to every Step.
   *
   * @override
   * @returns {Promise} A Promise to resolve when you finished starting your Step.
   */
  onStart() { return Promise.resolve() }

  onStarted() {}

  /**
   * Ends the Step.
   *
   * @returns {Promise} A Promise resolved when the Step has been ended.
   */
  end() {
    return new Promise((resolve) => {
      if (config.debug) console.log('Step ' + this.name + ' : END')

      this.onEnd()
        .then(resolve)
    })
  }
  
  /**
   * End callback returning a Promise. Useful for animations.
   * Override it to adapt to every Step.
   *
   * @override
   * @returns {Promise} A Promise to resolve when you finished ending your Step.
   */
  onEnd() { return Promise.resolve() }
}
