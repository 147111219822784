export default [
	{
		start: 0.05,
		end: 3.9,
		text: 'Déjà réveillé ? Êtes-vous décidé à remonter vos rangs ?'
	},
	{
		start: 4,
		end: 6.75,
		text: 'Vous avez plus de 1000 messages non lus.'
	},
	{
		start: 6.9,
		end: 10,
		text: 'J\'ai honte de vous voir comme ça, Hiver.'
	}
]
