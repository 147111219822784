/* eslint-disable guard-for-in */
import {
    BoxHelper,
    PlaneBufferGeometry,
    ShaderMaterial,
    Mesh,
    BoxBufferGeometry,
    PositionalAudio
} from 'three'
import TweenMax from 'gsap/TweenMax'
import TimelineMax from 'gsap/TimelineMax'

import config from '../../settings/config'
import Resources from '../../Resources'
import messageVert from '../../shaders/message/message.vert'
import messageFrag from '../../shaders/message/message.frag'
import Action from '../actions/Action'
import Button from '../Button'
import HiveObjects from '../HiveObjects'
import { degreesToRadians, radiansToDegrees } from '../../utils/rad-deg'
import DOMManager from '../../managers/DOMManager'
import AudioManager from '../../managers/AudioManager'
import Sound from '../Sound'


/**
 * Message is a class that create, show & hide a Message in 3d World
 * @class
 */
export default class Message {

    /**
   * Creates the Message instance by saving its positions from mesh, name and datas
   *
   * @param {Object} param An object defining the parameters of this Message.
   * @param {(Object3D)} param.mesh An Mesh present in model
   * @param {string} param.name The name of the object
   * @param {string} param.orientation The orientation of the object
   * @constructor
   */
    constructor({ mesh, name, orientation }) {
        if (!Message.GUIFolfer && config.debug && config.datGui) Message.GUIFolfer = config.datGui.addFolder('Messages')
        if (!Message.geometry) Message.geometry = new PlaneBufferGeometry(100, 28.21)
        if (Message.playingSounds === undefined) {
            Message.playingSounds = 0
            Message.maxPlayingSounds = 3
        }
        if (Message.count === undefined) Message.count = 0

        // get position of the model mesh before destroy it
        this.position = mesh.position.clone()

        // init uniforms values
        this.progressValue = 0
        this.showValue = 0
        this.depthValue = -5
        this.scaleValue = 0.85
        this.progressBarValue = 0
        this.isGoodMessage = null
        this.visibleDuration = 8

        // init params class
        this.orientation = orientation
        this.oldMesh = mesh
        this.isAnimating = false
        this.isDeleted = false
        this.animations = {}
        this.size = {
            x: 100,
            y: 28.21,
            z: 1
        }

        this.bindMethods()
        this.mesh = this.createMesh()
        this.name = name

        this.addButton()
        this.setOrientation()
        this.addSounds()

        Resources.scene.add(this.mesh)
        Resources.scene.add(this.button.mesh)

        this.mesh.matrixAutoUpdate = false
        this.mesh.updateMatrix()
        this.button.updateWorldPosition()

        config.debug && this.startDebugMode()
    }

    onSoundEnded() {
        Message.playingSounds--
    }

    /**
   * Rotate message in 3D world according to this orientation.
   * this.orientation can be North(`N`), South(`S`), Ease(`E`), West(`W`)
   *
   * @returns {void}
   */
    setOrientation() {
        // eslint-disable-next-line default-case
        switch (this.orientation) {
            case 'N':
                this.mesh.rotation.y = degreesToRadians(-90)
            break;
            case 'S':
                this.mesh.rotation.y = degreesToRadians(90)
            break;
            case 'E':
                this.mesh.rotation.y = degreesToRadians(180)
            break;
            // case 'O':
            // break;
        }

        this.button.mesh.rotation.copy(this.mesh.rotation)
    }

    /**
   * Add Button to delete Message
   *
   * @returns {void}
   */
    addButton() {
        const actionSteps = []
        const chapters = Object.keys(config.scenario)

        for (let i = 0; i < chapters.length; i++) {
            const steps = Object.keys(config.scenario[chapters[i]].steps)

            for (let j = 0; j < steps.length; j++) {
                const step = config.scenario[chapters[i]].steps[steps[j]]

                actionSteps.push({
                    name: step,
                    isLast: false,
                    multiple: true
                })
            }
        }

        this.action = new Action({
            name: 'Supprimer',
            steps: actionSteps,
            interaction: this.deleteMessage.bind(this)
        })

        this.button = new Button({
            name: 'messagebtn' + Message.count,
            actions: [this.action],
            isHidden: true,
            size: this.size,
            onApproach: this.onApproach,
            onGetAway: this.onGetAway
        })

        this.button.mesh.position.copy(this.mesh.position)
        
        this.button.mesh.visible = false

        Message.count++
    }

    addSounds() {
        // Add positional sounds for this message
        const defaultGoodSound = Resources.audios.messages.goodMessage
        const defaultBadSound = Resources.audios.messages.badMessage

        const goodPositional = new PositionalAudio(AudioManager.listener)
        const badPositional = new PositionalAudio(AudioManager.listener)

        this.goodSound = new Sound({
            sound: goodPositional,
            name: 'message',
            category: 'positional',
            buffer: defaultGoodSound.buffer
        })
        this.badSound = new Sound({
            sound: badPositional,
            name: 'message2',
            category: 'positional',
            buffer: defaultBadSound.buffer
        })
        this.goodSound.onEnd = this.onSoundEnded
        this.badSound.onEnd = this.onSoundEnded

        this.mesh.add(this.goodSound.sound, this.badSound.sound)
    }

    bindMethods() {
        this.playProgressBar = this.playProgressBar.bind(this)
        this.hide = this.hide.bind(this)
        this.onApproach = this.onApproach.bind(this)
        this.onGetAway = this.onGetAway.bind(this)
        this.incrementeUI = this.incrementeUI.bind(this)
    }

    /**
   * Create Mesh instance with Shader Material & set initial uniforms
   *
   * @returns {Mesh} the created mesh
   */
    createMesh() {
        this.uniforms = {
            u_userTexture: { value: null },
            u_mainTexture: { value: null },
            u_deleteTexture: { value: Resources.textures.imgs.deleteMessage},
            u_progress: { value: this.progressValue },
            u_show: { value: this.showValue },
            u_depth: { value: this.depthValue },
            u_scale: { value: this.scaleValue },
            u_progressbar: { value: this.progressBarValue }
        }

        const material = new ShaderMaterial({
            uniforms: this.uniforms,
            vertexShader: messageVert,
            fragmentShader: messageFrag,
            transparent: true
        })
        const mesh = new Mesh(Message.geometry, material)

        mesh.position.copy(this.position)

        mesh.visible = false

        return mesh
    }

    /**
   * Update uniforms & sound message with new values.
   *
   * @param {Texture} userProfile Texture of user profile.
   * @param {Texture} message Texture of message.
   * @param {Boolean} isGoodMessage Define if message is good or bad
   * @param {Int} visibleDuration The visible duration.
   *
   * @returns {void}
   */
    setMessage(userProfile, message, isGoodMessage = false, visibleDuration = this.visibleDuration) {
        this.isGoodMessage = isGoodMessage
        this.uniforms.u_userTexture.value = userProfile
        this.uniforms.u_mainTexture.value = message
        this.visibleDuration = visibleDuration
    }

     /**
   * Play a sequence of animations to show a message, hide it and remove points from DOMManager
   * Guard : check if current animation is already playing before start
   *
   * @param {boolean} delayedSound If `true`, the sound will be delayed randomly between 0ms and 750ms.
   *
   * @returns {void}
   */
    playAnimation(delayedSound = false) {
        if (this.isAnimating) return

        this.resetValues()
        this.playVignette()
        this.show(delayedSound)
            .then(this.playProgressBar)
            .then(this.hide)
            .then(this.incrementeUI)
    }

/**
   * Show UI vignette with color dependant of goodMessage instance value
   *
   * @returns {void}
   */
    playVignette() {
        if (this.isGoodMessage) DOMManager.showVignette('blue')
        else DOMManager.showVignette('red')
    }

    /**
   * Remove UI Hive points & add a missing unread message
   *
   * @returns {void}
   */
    incrementeUI() {
        if (this.isGoodMessage) return

        DOMManager.removePoints(10, false)
        DOMManager.setUnreadMessages({
            number: null,
            increment: true,
            decrement: false
        })
    }

    /**
   * Reset uniform & class values
   *
   * @returns {void}
   */
    resetValues() {
        this.uniforms.u_progressbar.value = 0
        this.uniforms.u_progress.value = 0
        this.isDeleted = false
        this.isAnimating = false
    }

    /**
   * Show a message with it shader uniforms and play it's song attached
   * Guard : reject if this message is already animating
   *
   * @param {boolean} delayedSound If `true`, the sound will be delayed randomly between 0ms and 750ms.
   *
   * @returns {void}
   */
    show(delayedSound = false) {
        return new Promise((resolve, reject) => {
            if (this.isAnimating) {
                reject()

                return
            }

            if (Message.playingSounds < Message.maxPlayingSounds) {
                if (delayedSound) {
                    const delay = Math.floor(Math.random() * 500)
                    
                    setTimeout(() => {
                        if (this.isGoodMessage) this.goodSound.play()
                        else this.badSound.play()
                    }, delay)
                } else if (this.isGoodMessage) this.goodSound.play()
                else if (!this.isGoodMessage) this.badSound.play()

                Message.playingSounds++
            }

            this.animations.show = new TimelineMax({
                onStart: () => {
                    this.mesh.visible = true
                    // if (this.helper) this.helper.visible = true
                    this.isAnimating = true
                },
                onComplete: () => {
                    this.button.mesh.visible = true
                    resolve()
                }
            })
            .to([this.uniforms.u_show, this.uniforms.u_depth], 0.35, {
                value: 1,
                ease: Power2.easeOut
            })
            .to(this.uniforms.u_scale, 0.55, {
                value: 1,
                ease: Back.easeOut
            }, '-=0.35')
        })
    }

    /**
   * Show a progressBar & animate its width with on updating uniforms shader values
   * The animation duration depend of visibleDuration class value
   *
   * @returns {void}
   */
    playProgressBar() {
        return new Promise((resolve) => {
            this.animations.progressbar = TweenMax.to(this.uniforms.u_progressbar, this.visibleDuration, {
                value: 1,
                ease: Power0.easeNone,
                onComplete: resolve
            })
        })
    }

    /**
   * Hide showed message on updating it's uniforms shader values
   *
   * @returns {void}
   */
    hide() {
        return new Promise((resolve) => {
            this.animations.hide = new TimelineMax({
                    onComplete: () => {
                        this.isAnimating = false
                        this.mesh.visible = false
                        // if (this.helper) this.helper.visible = false
                        this.button.mesh.visible = false
                        resolve()
                    }
                })
                .to(this.uniforms.u_show, 0.45, {
                    value: 0,
                    ease: Power2.easeIn
                })
                .to(this.uniforms.u_depth, 0.45, {
                    value: -5,
                    ease: Power2.easeIn
                }, '-=0.45')
                .to(this.uniforms.u_scale, 0.45, {
                    value: 0.85,
                    ease: Power2.easeIn
                }, '-=0.45')
        })
    }

    /**
   * This method is called from an interaction on Button attached to the message
   * Hide showed message on updating it's uniforms shader values & stop progressBar animation
   * Reject if `show` of `hide` animation is playing
   *
   * @returns {void}
   */
    deleteMessage() {
        return new Promise((resolve, reject) => {
            if (this.isDeleted) {
                reject()

                return
            }
            if (this.animations.show && this.animations.show.isActive()) {
                reject()

                return
            }
            if (this.animations.hide && this.animations.hide.isActive()) {
                reject()

                return
            }

            this.animations.progressbar.isActive() && this.animations.progressbar.pause()
            this.button.mesh.visible = false

            TweenMax.to(this.uniforms.u_progress, 0.5, {
                value: 1,
                onComplete: () => {
                    this.isDeleted = true
                    this.hide()
                    resolve()
                }
            })
        })
    }

    startDebugMode() {
        this.helper = new BoxHelper(this.mesh, 0xff0000)

        this.helper.visible = false
        
        Resources.scene.add(this.helper)

        if (!config.datGui) return

        const options = {
            progress: this.progressValue,
            show: this.showValue,
            depth: this.depthValue,
            progressbar: this.progressBarValue,
            showMessage: this.show.bind(this),
            hideMessage: this.hide.bind(this),
            deleteMessage: this.deleteMessage.bind(this),
            playProgress: this.playProgressBar.bind(this),
            playFullAnimation: this.playAnimation.bind(this),
            button: {
                rotationX: radiansToDegrees(this.button.mesh.rotation.x),
                rotationY: radiansToDegrees(this.button.mesh.rotation.y),
                rotationZ: radiansToDegrees(this.button.mesh.rotation.z),
                positionX: this.button.mesh.position.x,
                positionY: this.button.mesh.position.y,
                positionZ: this.button.mesh.position.z,
                width: this.size.x,
                height: this.size.z
            }
        }

        const data = Message.GUIFolfer.addFolder(this.name)
    
        data.add(options, 'progress', 0, 1, 0.1).onChange((value) => {
            this.progressValue = value
            this.uniforms.u_progress.value = this.progressValue
        })

        data.add(options, 'show', 0, 1, 0.1).onChange((value) => {
            this.showValue = value
            this.uniforms.u_show.value = this.showValue
        })

        data.add(options, 'depth', -10, 10, 1).onChange((value) => {
            this.depthValue = value
            this.uniforms.u_depth.value = this.depthValue
        })

        data.add(options, 'progressbar', 0.0, 1.0, 0.1).onChange((value) => {
            this.progressBarValue = value
            this.uniforms.u_progressbar.value = this.progressBarValue
        })

        data.add(options.button, 'rotationX', -180, 180, 1).onChange((value) => {
            this.button.mesh.rotation.x = degreesToRadians(value)
        })

        data.add(options.button, 'rotationY', -180, 180, 1).onChange((value) => {
            this.button.mesh.rotation.y = degreesToRadians(value)
        })

        data.add(options.button, 'rotationZ', -180, 180, 1).onChange((value) => {
            this.button.mesh.rotation.z = degreesToRadians(value)
        })

        data.add(options.button, 'positionX', -350, 350, 1).onChange((value) => {
            console.log(this.position.x - value)
            this.button.mesh.position.x = value
        })

        data.add(options.button, 'positionY', -350, 350, 1).onChange((value) => {
            console.log(this.position.y - value)
            this.button.mesh.position.y = value
        })

        data.add(options.button, 'positionZ', -350, 350, 1).onChange((value) => {
            console.log(this.position.z - value)
            this.button.mesh.position.z = value
        })

        data.add(options.button, 'width', 0, 50, 1).onChange((value) => {
            this.size.x = value
            this.button.mesh.geometry = new BoxBufferGeometry(this.size.x, this.size.y, this.size.z)
        })

        data.add(options.button, 'height', 0, 50, 1).onChange((value) => {
            this.size.z = value
            this.button.mesh.geometry = new BoxBufferGeometry(this.size.x, this.size.y, this.size.z)
        })

        data.add(options, 'showMessage')
        data.add(options, 'hideMessage')
        data.add(options, 'deleteMessage')
        data.add(options, 'playProgress')
        data.add(options, 'playFullAnimation')
    }

    /**
   * Update HiveObjects nearMessages on Message approach
   *
   * @returns {void}
   */
    onApproach() {
        HiveObjects.nearMessages.push(this)
    }

    /**
   * Remive this message from HiveObjects nearMessages on Message getAway
   *
   * @returns {void}
   */
    onGetAway() {
        const index = HiveObjects.nearMessages.indexOf(this)

        index !== -1 && HiveObjects.nearMessages.splice(index, 1)
    }
}
