import { PositionalAudioHelper } from 'three'

import config from '../settings/config'
import Subtitles from '../subtitles/Subtitles'
import Resources from '../Resources'
import AudioManager from '../managers/AudioManager'

/**
 * Sound is a class that manages everything related to Audio and PositionalAudio.
 * It can have Subtitles if it is specified in the config.
 * @class
 */
export default class Sound {

  /**
   * Creates the Sound instance by saving its name, checking if it has Subtitles and starting events related to the given sound.
   *
   * @param {Object} param An object defining the parameters of this Sound.
   * @param {(Audio|PositionalAudio)} param.sound An Audio or PositionalAudio instance for the sound to manage.
   * @param {string} param.name The sound's name.
   * @param {string} param.category The sound's category (its parent object key in `Resources.audios`).
   * @param {AudioBuffer} param.buffer The AudioBuffer (used for Messages' positionals to duplicate them).
   * @constructor
   */
  constructor({ sound, name, category, buffer }) {
    this.name = name
    this.sound = sound
    this.sound.name = name
    this.sound.isPlaying = false
    this.onEnd = () => {}
    this.isPaused = false
    this.soundCurrentTime = 0
    this.soundPrevTime = 0
    this.subtitles = this.getSubtitles()
    this.destroyed = false
    this.helper = null
    this.category = category
    this.buffer = buffer

    this.sound.setBuffer(this.buffer)

    this.presetVolume()

    // If sound is PositionalAudio
    if (this.sound.panner) {
      // Below 2m distance, the volume is not reduced.
      this.sound.setRefDistance(200)
      // How quickly the volume is reduced as the source moves away from the listener.
      this.sound.setRolloffFactor(0.3)

      if (this.name === 'turnOn') this.sound.setRolloffFactor(2.5)
      else if (this.name === 'message' || this.name === 'message2') this.sound.setRolloffFactor(0.9)
      else if (this.name === 'unlock') this.sound.setRolloffFactor(2.8)
    }

    this.onSoundEnded = this.onSoundEnded.bind(this)
    this.sound.onEnded = this.onSoundEnded
    
    config.debug && this.createHelper()

    AudioManager.sounds.push(this)
  }

  presetVolume() {
    let volume = 1

    if (this.sound.panner) {
      if (AudioManager.volumes.positional[this.name]) volume = AudioManager.volumes.positional[this.name]
      else volume = AudioManager.volumes.positional.default
    
      this.sound.setVolume(volume)

      return
    }

    if (isNaN(AudioManager.volumes[this.category])) {
      if (AudioManager.volumes[this.category][this.name]) volume = AudioManager.volumes[this.category][this.name]
      else volume = AudioManager.volumes[this.category].default
    } else volume = AudioManager.volumes[this.category]
    
    this.sound.setVolume(volume)
  }

  /**
   * If this sound has subtitles from the config, returns it.
   *
   * @returns {(Subtitles|null)} Either a Subtitles instance or null.
   */
  getSubtitles() {
    if (!config.namings.subtitles[this.name]) return null

    return new Subtitles(config.namings.subtitles[this.name])
  }

  /**
   * Toggles the sound's state.
   * Plays if it was paused.
   * Pause if it was playing.
   *
   * @returns {void}
   */
  toggle() {
    if (this.destroyed) return

    if (this.isPaused) this.sound.play()
    else this.sound.pause()
    
    this.isPaused = !this.isPaused
  }

  /**
   * Plays the sound.
   *
   * @returns {void}
   */
  play() {
    if (!this.destroyed) {
      if (this.sound.isPlaying) this.stop()
      
      this.sound.play()

      if (this.category !== 'ambiences') AudioManager.addPlayingAudio(this)

      if (this.helper) this.helper.update()
    }
  }

  /**
   * Fired when a "ended" event occurs on `this.sound`.
   *
   * @event
   * @returns {void}
   */
  onSoundEnded() {
    this.sound.isPlaying = false

    if (this.category !== 'ambiences') AudioManager.removePlayingAudio(this)

    this.onEnd(this)

    if (this.subtitles) this.subtitles.setText()
  }

  pause() {
    this.isPaused = true
    this.soundPrevTime = this.soundCurrentTime
    this.sound.pause()
  }

  stop() {
    this.sound.stop()
    this.soundPrevTime = 0
    this.soundCurrentTime = 0
    this.isPaused = false
  }

  resume() {
    this.isPaused = false
    this.sound.play()
  }

  /**
   * Gets the elapsed duration since the sound file started playing.
   * Called each frame when sound is playing, by `this.update`.
   *
   * @returns {void}
   */
  getElapsedTime() {
    this.soundCurrentTime = this.sound.context.currentTime - this.sound.startTime + this.soundPrevTime
  }

  /**
   * Updates the Subtitles with the elapsedTime if this instance has Subtitles.
   * Called each frame when `this.sound` is playing.
   *
   * @returns {void}
   */
  update() {
    // eslint-disable-next-line no-extra-parens
    if (!this.subtitles || (!this.sound.isPlaying && !this.isPaused) || this.destroyed) return

    this.getElapsedTime()
    this.subtitles.update(this.soundCurrentTime)
  }

  /**
   * Creates a PositionalAudioHelper for Sound instances that have a PositionalAudio instance.
   * Happens only in debug mode.
   *
   * @returns {void}
   */
  createHelper() {
    if (config.debug && this.sound.panner) {
      const helper = new PositionalAudioHelper(this.sound, this.name === 'message' || this.name === 'message2' ? 200 : 750)

      helper.visible = false

      this.sound.add(helper)

      Resources.audios.positionalHelpers.push(helper)

      this.helper = helper
    }
  }

  /**
   * Destroys this Sound by stopping audio and removing helpers if debug mode.
   *
   * @returns {void}
   */
  destroy() {
    this.destroyed = true

    this.sound.onEnded = null
    if (this.category !== 'ambiences') AudioManager.removePlayingAudio(this.sound)
    this.stop()

    if (this.helper) {
      const i = Resources.audios.positionalHelpers.indexOf(this.helper)

      Resources.audios.positionalHelpers.splice(i, 1)
      
      this.helper.dispose()
      this.helper.parent.remove(this.helper)
    }
  }
}
