import config from '../../settings/config'
import Step from '../Step'
import DOMManager from '../../managers/DOMManager'

export default class GoToKitchen extends Step {
	constructor() {
		super(config.scenario.chapter3.steps.goToKitchen)
	}

	onStart() {
		return new Promise((resolve) => {
			DOMManager.setNewGoal('Rendez-vous dans la cuisine', 'kitchen')

			resolve()
		})
	}

	onEnd() {
		return new Promise((resolve) => {
			DOMManager.goalAchieved()

			resolve()
		})
	}
}
