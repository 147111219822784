import { TimelineMax, Power2 } from 'gsap'

import config from '../../settings/config'
import Action from '../actions/Action'
import InteractiveObject from './InteractiveObject'
import DOMManager from '../../managers/DOMManager'

export default class ButtonDoor extends InteractiveObject {
	bindMethods() {
		super.bindMethods()

		this.press = this.press.bind(this)
	}

	createActions() {
		const action = new Action({
			name: 'Appuyer sur le bouton d\'arrêt',
			steps: [
				{
					name: config.scenario.chapter5.steps.pushButton,
					isLast: true
				}
			],
			interaction: this.press
		})

		this.addAction(action)
	}

	press() {
		return new Promise((resolve) => {
			const tl = new TimelineMax({
				onComplete: () => {
					DOMManager.goalAchieved()
					resolve()
				}
			})

			tl.to(this.mesh.position, 0.5, {
					x: '-=2',
					ease: Power2.easeOut
				})
				.to(this.mesh.position, 0.25, {
					x: '+=2',
					delay: 0.25,
					ease: Power2.easeIn
				})
		})
	}
}
