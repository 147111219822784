import loadFBSDK from 'facebook-sdk-promise'

import User from '../User'
import config from '../settings/config'

/**
 * Loads Facebook SDK, connects user and fetches his data.
 * @class
 */
export default class Facebook {

  /**
   * Saves the given callback.
   * Binds methods and loads the SDK.
   * When loaded, start it and ask user to log in.
   *
   * @param {function} callback - Callback function fired when user information has been fetched.
   * @constructor
   */
  constructor(callback = () => {}) {
    this.callback = callback
    this.sdk = null

    this.bindMethods()

    loadFBSDK()
      .then(this.onFacebookReady)
  }

  /**
   * Binds class methods to itself to keep the right scope.
   *
   * @returns {void}
   */
  bindMethods() {
    this.onFacebookReady = this.onFacebookReady.bind(this)
    this.onUserLogged = this.onUserLogged.bind(this)
  }

  /**
   * Callback fired when Facebook SDK is loaded and ready to use.
   * Initializes the SDK and asks the user to log in.
   *
   * @param {Object} sdk - Facebook SDK instance that has been loaded and returned from a Promise.
   *
   * @returns {void}
   */
  onFacebookReady(sdk) {
    this.sdk = sdk

    this.initFacebook()
    this.sdk.login(this.onUserLogged, { scope: 'email,user_birthday,user_gender' })
  }
  
  /**
   * Initializes Facebook SDK.
   *
   * @returns {void}
   */
  initFacebook() {
    this.sdk.init({
      appId: '178258633088475',
      status: true,
      xfbml: true,
      version: 'v3.3'
    })
  }

  /**
   * Callback fired when user is supposed to be logged in.
   * If user is correctly logged in, fetches his information.
   *
   * @param {Object} response - Facebook response to the login action.
   * @param {boolean} response.authResponse - Whether the user is correctly authentified or not.
   *
   * @returns {void}
   */
  onUserLogged({ authResponse }) {
    if (authResponse) this.getUserInformation()
    else console.warn('User cancelled login or did not fully authorize.')
  }

  /**
   * Fetches user data and pictures.
   * When done, fire the callback given earlier in the class constructor
   *
   * @returns {void}
   */
  getUserInformation() {
    const promises = []
    
    promises.push(this.fetchUserData())
    promises.push(this.fetchUserPhotos())

    Promise.all(promises)
      .then(() => {
        this.callback()
      })
  }

  /**
   * Gets user data from Facebook.
   * Retrieves name, first name, last name, email, gender and id.
   * Then stores it to the USER global variable.
   *
   * @returns {Promise} - A Promise resolved when the request to Facebook has a response.
   */
  fetchUserData() {
    return new Promise((resolve) => {
      this.sdk.api('/me', { fields: 'name,first_name,birthday,email,gender,last_name' }, (response) => {
        User.name = response.name
        User.firstName = response.first_name
        User.lastName = response.last_name
        User.email = response.email
        User.gender = response.gender
        User.id = response.id

        resolve()
      })
    })
  }

  /**
   * Gets user pictures from Facebook.
   * Retrieves users pictures and select only the one that has been created less than 3 years ago.
   * Then stores it to the USER global variable.
   *
   * @returns {Promise} - A Promise resolved when the request to Facebook has a response.
   */
  fetchUserPhotos() {
    return new Promise((resolve) => {
      this.sdk.api('/me', { fields: 'albums{name,photos{created_time,source}}' }, (response) => {
        const albums = response.albums.data
        const currentDate = new Date()
        let profilePictureTaken = false

        for (let i = 0; i < albums.length; i++) {
          const name = albums[i].name
          const photos = albums[i].photos.data

          for (let j = 0; j < photos.length; j++) {
            if (name === 'Profile Pictures' && !profilePictureTaken) {
              profilePictureTaken = true
              User.profilePicture = photos[j].source
            } else {
              const photoDateTime = new Date(photos[j].created_time)
              const diff = this.dateDiff(photoDateTime, currentDate)

              if (diff.year < 3) User.pictures.push(photos[j].source)
            }
          }
        }

        if (!User.pictures.length) {
          for (let i = 0; i < config.user.defaultPictures.length; i++) {
            User.pictures.push(config.user.defaultPictures[i])
          }
        }

        resolve()
      })
    })
  }

  /**
   * Compares two dates.
   *
   * @param {Date} date1 - First date to compare.
   * @param {Date} date2 - Second date to compare.
   *
   * @returns {Object} - The difference in years, days, hours, mins and secs between two dates.
   */
  dateDiff(date1, date2) {
    const diff = {}
    let tmp = date2 - date1
 
    tmp = Math.floor(tmp / 1000)
    diff.sec = tmp % 60

    tmp = Math.floor((tmp - diff.sec) / 60)
    diff.min = tmp % 60
 
    tmp = Math.floor((tmp - diff.min) / 60)
    diff.hour = tmp % 24
     
    tmp = Math.floor((tmp - diff.hour) / 24)
    diff.day = tmp % 365

    tmp = Math.floor((tmp - diff.day) / 365)
    diff.year = tmp
     
    return diff
  }
}
