import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager'

/**
 * The first Chapter's last Step. Asks the user to leave the room to discover the rest of the flat.
 * @class
 */
export default class LeaveRoom extends Step {
  constructor() {
    super(config.scenario.tutorial.steps.leaveRoom)
  }

  onStart() {
    return new Promise((resolve) => {
      Resources.rooms.bedroom.door.description = 'La porte est déverrouillée !'
      DOMManager.setNewGoal('Ouvrez la porte de la chambre et visitez votre appartement', 'bedroom')

      resolve()
    })
  }

  onEnd() {
    return new Promise((resolve) => {
      DOMManager.goalAchieved()
      Resources.rooms.bedroom.door.description = null

      resolve()
    })
  }
}
