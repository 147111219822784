import {
  BoxHelper,
  Vector3
} from 'three'

import Resources from '../../Resources'

/**
 * The base class for every useful Mesh contained in the appartment model.
 * Basic behavior is a callback when the Interactive is near or isn't anymore.
 * And a callback when object is being looked (or not being looked at anymore) at when it is near enough.
 * @class
 */
export default class Interactive {

  /**
   * Creates basic properties such as `mesh`, `canInteract`, `isLooking`, `isNear`, `sounds`, and `helpersColor` for debug helpers.
   *
   * @param {Object} param An object providing a mesh and a name.
   * @param {Mesh} param.mesh The mesh to interact with.
   * @param {string} param.name The instance / mesh name.
   * @constructor
   */
  constructor({ mesh, name, roomName }) {
    this.roomName = roomName
    this.mesh = mesh
    this.name = name
    this.canInteract = false
    this.isLooking = false
    this.isNear = false
    this.helpersColor = 0xffffff
    this.sounds = {}

    if (this.mesh) {
      const worldPosition = new Vector3()

      this.mesh.getWorldPosition(worldPosition)

      this.mesh.userData = {
        instance: this,
        worldPosition
      }
    }

    this.helpers = []
    this.destroyed = false
  }

  /**
   * Binds class methods to itself to keep the right scope.
   *
   * @returns {void}
   */
  bindMethods() {
    this.onStartLooking = this.onStartLooking.bind(this)
    this.onStopLooking = this.onStopLooking.bind(this)
    this.onApproach = this.onApproach.bind(this)
    this.onGetAway = this.onGetAway.bind(this)
  }

  /**
   * Fired when the camera is looking directly to this object.
   * Object has to been near enough for this callback to be fired (`this.isNear = true`).
   * Override it to add custom behavior when looking at the object.
   *
   * @override
   * @event
   * @returns {void}
   */
  onStartLooking() {
    if (this.isLooking) return

    this.isLooking = true
  }

  /**
   * Fired when the camera is not looking to this object anymore.
   * Override it to add custom behavior when looking at the object.
   *
   * @override
   * @event
   * @returns {void}
   */
  onStopLooking() {
    if (!this.isLooking) return

    this.isLooking = false
  }

  /**
   * Fired when the camera is near enough from this object to interact with it.
   * Override it to add custom behavior when the object is getting close.
   *
   * @override
   * @event
   * @returns {void}
   */
  onApproach() {
    this.isNear = true
  }

  /**
   * Fired when the camera is not near enough from this object anymore.
   * Override it to add custom behavior when getting the object is not close enough anymore.
   *
   * @override
   * @event
   * @returns {void}
   */
  onGetAway() {
    this.isNear = false
  }

  /**
   * Gets Sound instance from Resources and attaches the ones that are linked to this objects `name`.
   * Includes default sounds for this type of Interactive, specific sounds for this instance, and voices.
   *
   * @returns {void}
   */
  addSounds() {
    let defaultAudiosList = null
    let specificAudiosList = null
    
    if (!this.isInteractiveObject && !this.isInterface) return
    
    if (this.isInteractiveObject) {
      defaultAudiosList = Resources.audios.defaultInteractives
      specificAudiosList = Resources.audios.interactives
    } else if (this.isInterface) {
      defaultAudiosList = Resources.audios.defaultInterfaces
      specificAudiosList = Resources.audios.interfaces
    }

    const sounds = Object.entries(defaultAudiosList)

    for (let i = 0; i < sounds.length; i++) {
      this.addSound(sounds[i])
    }

    if (!specificAudiosList[this.name]) return

    const specificSounds = Object.entries(specificAudiosList[this.name])

    for (let i = 0; i < specificSounds.length; i++) {
      if (specificSounds[i][0] === 'voices') {
        const voices = Object.entries(specificSounds[i][1])

        for (let j = 0; j < voices.length; j++) {
          this.addSound(voices[j], true)
        }
      } else this.addSound(specificSounds[i])
    }
  }

  /**
   * Adds a Sound instance to `this.sounds`.
   *
   * @param {Sound} sound A Sound instance to attach to this Interactive.
   * @param {boolean} isVoice Whether the given Sound is a voice or not.
   *
   * @returns {void}
   */
  addSound(sound, isVoice) {
    if (isVoice) {
      if (!this.sounds.voices) this.sounds.voices = {}

      this.sounds.voices[sound[0]] = sound[1]
    } else this.sounds[sound[0]] = sound[1]

    if (sound[1].sound.panner) this.mesh.add(sound[1].sound)

    sound[1].onEnd = this.onSoundEnded
  }

  /**
   * Fired when a "ended" event occurs on a Sound instance.
   * The concerned Sound is given in parameter.
   * Override it to add custom behavior when sounds end (such as voices).
   *
   * @param {Sound} sound A Sound instance that just finished playing its sound.
   *
   * @override
   * @event
   * @returns {void}
   */
  onSoundEnded(sound) {}

  /**
   * Called each frame by HiveObjects.
   *
   * @param {Object} timeStamp - Object containing the time and deltaTime properties.
   *
   * @returns {void}
   */
  update(timeStamp) {}

  /**
   * Changes the stored world position vector.
   * Use it if you move the mesh in the scene.
   *
   * @returns {void}
   */
  updateWorldPosition() {
    this.mesh.getWorldPosition(this.mesh.userData.worldPosition)
  }

  /**
   * Starts debug mode by adding BoxHelper colored according to `this.helpersColor` and adds it to the Scene.
   * Called by HiveObjects if config.debug is true.
   *
   * @returns {void}
   */
  startDebugMode() {
    const helper = new BoxHelper(this.mesh, this.helpersColor)

    helper.visible = false

    this.helpers.push(helper)

    Resources.scene.add(...this.helpers)
  }
  
  /**
   * Destroys this Interactive by disposing and removing its Meshes geometry and material.
   * Also removes its Mesh from Scene.
   * Also removes helpers if debug mode.
   *
   * @override
   * @returns {boolean} `false` if the instance has already been destroyed earlier.
   */
  destroy() {
    if (this.destroyed) return false

    this.destroyed = true

    if (this.helpers.length) {
      for (let i = 0; i < this.helpers.length; i++) {
        const helper = this.helpers[i]

        Resources.scene.remove(helper)

        helper.material.dispose()
        helper.geometry.dispose()
        if (helper.material.map) helper.material.map.dispose()
      }
      
      this.helpers = []
    }

    if (this.mesh) {
      const toRemove = []

      this.mesh.traverse((child) => {
        if (child !== this.mesh) toRemove.push(child)
      })

      for (let i = 0; i < toRemove.length; i++) {
        const mesh = toRemove[i]

        mesh.parent.remove(mesh)

        if (mesh.isMesh) {
          if (mesh.material.map) {
            const map = mesh.material.map
            
            mesh.material.map = null
            
            map.dispose()
          }

          mesh.material.dispose()
          mesh.geometry.dispose()
        }
      }
    }

    return true
  }
}
