import ImageInterface from '../ImageInterface'
import Resources from '../../../Resources'
import config from '../../../settings/config'
import SlideManager from 'slidemanager'
import ImageTexture from '../../ImageTexture'

/**
 * Landscape is showing a slider of images.
 * @todo Rename it. Not explicit enough.
 * @class
 */
export default class Landscape extends ImageInterface {
  constructor(props) {
    super(props)

    this.createSlider()
  }

  /**
   * Creates a slider transitioning between textures using a shader.
   *
   * @returns {void}
   */
  createSlider() {
    if (!this.imgs.length) return

    this.slider = new SlideManager({
      el: null,
      length: this.imgs.length,
      auto: true,
      loop: true,
      swipe: false,
      callback: (event) => {
        if (this.currentTexture) this.oldTexture = this.currentTexture
        
        this.currentTexture = this.textures[event.new]

        this.setCurrentImage(this.currentTexture)
          .then(this.slider.done())
      }
    })
  }

  setTextures() {
    this.imgs = Object.values(Resources.user.pictures)

    const textures = []

    for (let index = 0; index < this.imgs.length; index++) {
      textures.push(new ImageTexture({ texture: this.imgs[index] }))
    }

    return textures
  }

  setSteps() {
    return [config.scenario.tutorial.steps.wakeUp]
  }
}
