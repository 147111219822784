export default [
	{
		start: 0.05,
		end: 5,
		text: 'Ça alors, vous venez apparemment de diffuser publiquement une photo sur le réseau.'
	},
	{
		start: 5.5,
		end: 8,
		text: 'C\'est étrange...'
	}
]
