import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import ScoreMidnight from '../../objects/interfaces/chapter5/ScoreMidnight'
import MessagesManager from '../../managers/MessagesManager'
import HistoryManager from '../../managers/HistoryManager'
import DOMManager from '../../managers/DOMManager'

export default class PresentSituation extends Step {
  constructor() {
    super(config.scenario.chapter5.steps.presentSituation)
  }

  onStart() {
    return new Promise((resolve) => {
      this.getSounds()
      this.sounds[0].play()
      this.playMessages()

      resolve()
    })
  }
  
  playMessages() {
    MessagesManager.popMessages({
      interval: 1500,
      number: 3,
      duration: 5,
      onlyGood: false,
      indexes: [6, 7, 8, 9],
      indexesRoom: Resources.rooms.bedroom
    })
  }

  getSounds() {
		this.sounds = [
      Resources.audios.voices.ch5WakeUp,
      Resources.audios.voices.ch5Detect
		]

		for (let i = 0; i < this.sounds.length; i++) {
			this.sounds[i].onEnd = this.onSoundEnded.bind(this, this.sounds[i])
		}
  }

  
  onSoundEnded(sound) {
    if (sound === this.sounds[0]) {
      DOMManager.newNotification({ text: 'Une activité suspecte a été détectée sur votre compte Hive.' })

      setTimeout(() => {
        this.sounds[1].play()

        const interfaces = Resources.rooms.bedroom.interfaces

        for (let i = 0; i < interfaces.length; i++) {
          if (interfaces[i] instanceof ScoreMidnight) interfaces[i].onApproach(true)
        }
      }, 1000)
    } else if (sound === this.sounds[1]) {
      DOMManager.setNewGoal('Approchez-vous de la publication dont parle Hive', 'bedroom')

      HistoryManager.nextStep()
    }
  }
}
