import VideoInterface from '../VideoInterface'
import Resources from '../../../Resources'
import config from '../../../settings/config'
import HistoryManager from '../../../managers/HistoryManager'
import DOMManager from '../../../managers/DOMManager'

export default class Messenger4 extends VideoInterface {

    constructor(props) {
        super(props)
        this.play()
    }

    setSteps() {
        return [config.scenario.chapter4.steps.goToNewsInterface]
    }

    setTextures() {
        this.background = Resources.textures.videos.ch4Background
        this.renegatIn = Resources.textures.videos.ch4RenegatIn
        this.renegatLoop = Resources.textures.videos.ch4RenegatLoop

       return [
            this.background,
            this.renegatIn,
            this.renegatLoop
       ]
    }

    onVideoEnded(video) {
        if (video === this.background) this.updateCurrentVideo(this.renegatIn)
        if (video === this.renegatIn) this.updateCurrentVideo(this.renegatLoop)
    }

    onApproach() {
        super.onApproach()
        DOMManager.goalAchieved()
        HistoryManager.nextStep()
    }
}
