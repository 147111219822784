import config from '../../settings/config'
import Step from '../Step'
import DOMManager from '../../managers/DOMManager'
import Resources from '../../Resources'

export default class LiftButton extends Step {
  constructor() {
    super(config.scenario.chapter5.steps.liftButton)
  }

  onStart() {
    return new Promise((resolve) => {
      Resources.audios.voices.ch5HiveRisk.play()

      resolve()
    })
  }

  onEnd() {
    return new Promise((resolve) => {
      DOMManager.goalAchieved()

      resolve()
    })
  }
}
