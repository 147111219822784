export default [
	{
		start: 0.05,
		end: 3,
		text: 'Écoutez, Hiver. Mon réseau a des solutions pour vous aider.'
	},
	{
		start: 3.3,
		end: 6.75,
		text: 'Et... et si Zima se cachait derrière cette porte ?'
	},
	{
		start: 7,
		end: 11,
		text: 'Si vous me désactivez, vous perdez tout ce que nous avons construit ensemble.'
	},
	{
		start: 11.25,
		end: 17,
		text: 'Vous me connaissez, nous pouvons surmonter ça ensemble ! Je serai toujours là pour vous.'
	}
]
