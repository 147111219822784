/**
 * Configuration object defining all the sounds in the experience.
 * They are grouped by categories and will be loaded then instanciated either to a Audio instance, or a PositionalAudio instance.
 * `interactives` and `interfaces` objects contains sub-objects that will attach their loaded sounds to the corresponding Interface or Interactive.
 */
const sounds = {
	ambiences: {
		chapter1: './sounds/ambiences/chapter1/ch1-1-intro.mp3',
		chapter1Loop: './sounds/ambiences/chapter1/ch1-2-loop.mp3',
		chapter3: './sounds/ambiences/chapter3/ch3-1-intro.mp3',
		chapter3Loop: './sounds/ambiences/chapter3/ch3-2-loop.mp3',
		chapter4: './sounds/ambiences/chapter4/ch4-1-intro.mp3',
		chapter4Loop: './sounds/ambiences/chapter4/ch4-2-loop.mp3',
		chapter4End: './sounds/ambiences/chapter4/ch4-3-end.mp3',
		chapter5Intro: './sounds/ambiences/chapter5/ch5-1-intro.mp3',
		chapter5Loop: './sounds/ambiences/chapter5/ch5-2-loop.mp3',
		chapter5End: './sounds/ambiences/chapter5/ch5-3-end.mp3'
	},
	effects: {
		hover: './sounds/effects/hover.mp3',
		action: './sounds/effects/action.mp3',
		newRank: './sounds/effects/new-rank.mp3',
		notification: './sounds/effects/notification.mp3',
		lostRankRenegade: './sounds/effects/lost-rank-renegade.mp3',
		badge: './sounds/effects/badge.mp3',
		goodMessage: './sounds/effects/message-good.mp3',
		badMessage: './sounds/effects/message-bad.mp3',
		heart: './sounds/effects/heart.mp3'
	},
	voices: {
		baseline: './sounds/voices/baseline-down.mp3',
		ch3Taste: './sounds/voices/chapter3/chap3-2-taste.mp3',
		ch3Kitchen: './sounds/voices/chapter3/chap3-3-kitchen.mp3',
		ch4Explain: './sounds/voices/chapter4/chap4-1-explain.mp3',
		ch4ComeOn: './sounds/voices/chapter4/chap4-2-come-on.mp3',
		ch4Continue: './sounds/voices/chapter4/chap4-3-continue.mp3',
		ch4Who: './sounds/voices/chapter4/chap4-4-who-v2.mp3',
		ch4TooMuch: './sounds/voices/chapter4/chap4-5-too-much.mp3',
		ch4NearRenegade: './sounds/voices/chapter4/chap4-6-near-renegade.mp3',
		ch4Useless: './sounds/voices/chapter4/chap4-7-useless.mp3',
		ch4NeverStop: './sounds/voices/chapter4/chap4-8-never-stop.mp3',
		ch4HurryUp: './sounds/voices/chapter4/chap4-9-hurry-up.mp3',
		ch4YourFault: './sounds/voices/chapter4/chap4-10-your-fault.mp3',
		ch4Renegade: './sounds/voices/chapter4/chap4-17-renegade.mp3',
		ch4DontTalk: './sounds/voices/chapter4/chap4-18-dont-talk.mp3',
		ch5WakeUp: './sounds/voices/chapter5/chap5-1-wake-up.mp3',
		ch5Detect: './sounds/voices/chapter5/chap5-2-detect.mp3',
		ch5Savior1: './sounds/voices/chapter5/chap5-5-savior-1.mp3',
		ch5HiveToSavior1: './sounds/voices/chapter5/chap5-6-what.mp3',
		ch5Savior2: './sounds/voices/chapter5/chap5-7-savior-2.mp3',
		ch5HiveToSavior2: './sounds/voices/chapter5/chap5-8-trap.mp3',
		ch5HiveRisk: './sounds/voices/chapter5/chap5-9-risks.mp3',
		ch5HiveListen: './sounds/voices/chapter5/chap5-10-listen.mp3',
		ch5SaviorDoGoodChoice: './sounds/voices/chapter5/chap5-11-savior-3.mp3',
		ch5SaviorFinal: './sounds/voices/chapter5/chap5-12-savior-4-final.mp3'
	},
	interactives: {
		door: {
			slide: './sounds/interactives/door/sliding-door.mp3'
		},
		cache: {},
		button: {},
		frontdoor: {
			unlock: './sounds/interactives/frontdoor/frontdoor-unlock.mp3',
			tock1: './sounds/interactives/frontdoor/tock-1.mp3',
			tock2: './sounds/interactives/frontdoor/tock-2.mp3',
			tock3: './sounds/interactives/frontdoor/tock-3.mp3',
			tock4: './sounds/interactives/frontdoor/tock-4.mp3',
			tock5: './sounds/interactives/frontdoor/tock-5.mp3',
			tock6: './sounds/interactives/frontdoor/tock-6.mp3',
			tock7: './sounds/interactives/frontdoor/tock-7.mp3'
		}
	},
	defaultInteractives: {},
	messages: {
		goodMessage: './sounds/effects/message-good.mp3',
		badMessage: './sounds/effects/message-bad.mp3'
	},
	interfaces: {
		wakeup: {
			voices: {
				hello: './sounds/voices/tutorial/chap1-1-hello.mp3',
				helloDay2: './sounds/voices/chapter3/chap3-1-new-day.mp3'
			}
		},
		score: {
			voices: {
				sleepDataVoiceIn: './sounds/voices/tutorial/chap1-2-sleepdata.mp3',
				newRank: './sounds/voices/tutorial/chap1-3-rank.mp3',
				trendChoice: './sounds/voices/tutorial/chap1-4-trend.mp3',
				whatHaveYouDone: './sounds/voices/chapter5/chap5-3-never-stop.mp3',
				deservedIt: './sounds/voices/chapter5/chap5-4-you-deserved-it.mp3'
			}
		},
		messenger: {
			voices: {
				hiversOfTheDayComing: './sounds/voices/chapter2/chap2-1-hiversoftheday.mp3',
				hiversOfTheDayExplain: './sounds/voices/chapter2/chap2-2-explain.mp3',
				hiversOfTheDayComment: './sounds/voices/chapter2/chap2-3-comment-v2.mp3',
				reportUser: './sounds/voices/chapter2/chap2-4-report.mp3',
				surprise: './sounds/voices/chapter3/chap3-5-surprise.mp3',
				zimaMsg: './sounds/voices/chapter3/chap3-6-zima.mp3',
				newMessages: './sounds/voices/chapter3/chap3-7-new-messages.mp3',
				block: './sounds/voices/chapter3/chap3-8-block.mp3',
				newMessage: './sounds/voices/chapter3/chap3-9-new-message-v2.mp3',
				again: './sounds/voices/chapter3/chap3-10-again-v2.mp3',
				stress: './sounds/voices/chapter3/chap3-11-stress.mp3'
			}
		},
		oven: {
			turnOn: './sounds/interfaces/oven/oven-turn-on.mp3',
			voices: {
				tagged: './sounds/voices/chapter3/chap3-4-tagged.mp3'
			}
		}
	},
	defaultInterfaces: {}
}

export default sounds
