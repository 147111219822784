import config from '../../settings/config'
import Step from '../Step'
import MessagesManager from '../../managers/MessagesManager'
import HistoryManager from '../../managers/HistoryManager'
import DOMManager from '../../managers/DOMManager'

export default class GoToDoor extends Step {
  constructor() {
    super(config.scenario.chapter5.steps.goToDoor)
  }

  onStart() {
    return new Promise((resolve) => {
      // @todo: Create "entrance" in minimap svg
      this.playMessages()

      DOMManager.goalAchieved()

      setTimeout(() => {
        DOMManager.setNewGoal('Approchez-vous de la porte d\'entrée', 'entrance')
      }, 1250)

      resolve()
    })
  }

  // Play all messages in the appartment 👹
  playMessages() {
    MessagesManager.popMessages({
      interval: 50,
      number: 20000,
      duration: 5,
      onlyGood: false,
      fullApartment: true,
      canHaveDelayedSound: true,
      intervalChecking: () => HistoryManager.currentChapter.currentStep.name === config.scenario.chapter5.steps.endScene
    })
  }
}
