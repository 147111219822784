import { GUI } from 'dat.gui'

import user from './config-user'
import shaders from './config-shaders'
import model from './config-model'
import scenario from './config-scenario'
import namings from './config-namings'
import textures from './config-textures'
import fonts from './config-fonts'
import sounds from './config-sounds'
import cameras from './config-cameras'
import controls from './config-controls'
import ranks from './config-ranks'
import descriptions from './config-descriptions'

/**
 * Configuration object for the whole app.
 * Each key of this object is a sub-configuration object targetting specific parts of the environment.
 *
 * @todo Add starting position / rotation of camera system (after animation).
 */
const config = {
    debug: false,
    // datGui: new GUI({
    //     hideable: false,
    //     autoPlace: false
    // }),
    user,
    shaders,
    model,
    scenario,
    namings,
    textures,
    fonts,
    sounds,
    cameras,
    controls,
    ranks,
    descriptions
}

export default config
