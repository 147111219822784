export default [
	{
		start: 0.05,
		end: 2.9,
		text: 'Sans surprise, votre amour pour les pâtes a guidé votre choix.'
	},
	{
		start: 3.15,
		end: 7.8,
		text: 'Au fait, ma mise à jour a apporté de nombreuses améliorations à votre appartement.'
	},
	{
		start: 8,
		end: 11,
		text: 'Partez à leur découverte en sortant de votre chambre !'
	}
]
