import config from '../../settings/config'
import Step from '../Step'
import HistoryManager from '../../managers/HistoryManager'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager'
import MessagesManager from '../../managers/MessagesManager'

export default class Renegade extends Step {
	constructor() {
		super(config.scenario.chapter4.steps.renegade)
		this.stopInterval = false
	}

	onStart() {
		return new Promise((resolve) => {
			this.currentSoundIndex = 0

			MessagesManager.popMessages({
				interval: 2000,
				number: 100,
				duration: 59,
				onlyGood: false,
				intervalChecking: this.checkClearInterval.bind(this)
			})
		
			this.getSounds()
			this.playNextSound()
			this.updateInterfaces()
			
			resolve()
		})
	}

	checkClearInterval() {
		return this.stopInterval
	}

	updateInterfaces() {
		const interfaces = this.getAllInterfaces()

		for (let i = 0; i < interfaces.length; i++) {
			if (interfaces[i].currentVideo) interfaces[i].currentVideo.loop = false
			if (interfaces[i].renegade) interfaces[i].renegade()
		}
	}

	getAllInterfaces() {
		const interfaces = []
		const rooms = Object.values(Resources.rooms)
	
		for (let i = 0; i < rooms.length; i++) {
			const room = rooms[i]
	
			for (let j = 0; j < room.interfaces.length; j++) {
				interfaces.push(room.interfaces[j])
			}
		}
	
		return interfaces
	}

	playNextSound() {
		this.sounds[this.currentSoundIndex].play()
		this.currentSoundIndex ++
	}

	onSoundEnded(sound) {
		if (sound === this.sounds[0]) {
			setTimeout(() => {
				this.playNextSound()
				DOMManager.waitingPointsAnimations = []
				this.stopInterval = true
			}, 1000)
		} else if (sound === this.sounds[1]) {
			HistoryManager.nextStep()
		}
	}

	getSounds() {
		this.sounds = [
			Resources.audios.voices.ch4Renegade,
			Resources.audios.voices.ch4DontTalk
		]

		for (let i = 0; i < this.sounds.length; i++) {
			this.sounds[i].onEnd = this.onSoundEnded.bind(this)
		}
	}
}
