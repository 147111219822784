export default [
	{
		start: 0.05,
		end: 2,
		text: 'D\'accord, je bloque tous ces Hivers.'
	},
	{
		start: 3,
		end: 6,
		text: 'Ah... Un peu de répis. On dirait bien qu\'ils en ont après vous.'
	}
]
