export default [
	{
		start: 0.05,
		end: 2.75,
		text: 'Hive : Quoi ?! Ne lui faites pas confiance, Hiver.'
	},
	{
		start: 2.85,
		end: 5,
		text: 'Je serai toujours là pour vous.'
	}
]
