export default [
	{
		start: 0.05,
		end: 3.5,
		text: 'Vous n\'auriez jamais dû soutenir ce Renégat, ni même répondre à Zima.'
	},
	{
		start: 3.75,
		end: 9,
		text: 'Finalement, vous méritez peut-être tout ça...'
	}
]
