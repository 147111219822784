export default class VideoManager {
	constructor() {
		VideoManager.playingVideos = []
	}

	static addPlayingVideo(video) {
		VideoManager.playingVideos.push(video)
	}

	static removePlayingVideo(video) {
		const index = VideoManager.playingVideos.indexOf(video)

		if (index !== -1) VideoManager.playingVideos.splice(index, 1)
	}

	static pause() {
		for (let i = 0; i < VideoManager.playingVideos.length; i++) {
			VideoManager.playingVideos[i].pause()
		}
	}

	static resume() {
		for (let i = 0; i < VideoManager.playingVideos.length; i++) {
			VideoManager.playingVideos[i].resume()
		}
	}
}
