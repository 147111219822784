import { TimelineMax } from 'gsap/TweenMax'

import Chapter from '../Chapter'
import DOMManager from '../../managers/DOMManager'
import CameraManager from '../../managers/CameraManager'
import AudioManager from '../../managers/AudioManager'
import config from '../../settings/config'
import CyberAttack from './CyberAttack'
import Renegade from './Renegade'
import StartAttack from './StartAttack'
import Resources from '../../Resources'
import Weather from '../../objects/interfaces/global/Weather'
import Oven from '../../objects/interfaces/chapter3/Oven'
import Dish from '../../objects/interfaces/chapter3/Dish'
import HiveObjects from '../../objects/HiveObjects'
import WakeUpDay2 from '../../objects/interfaces/chapter3/WakeUpDay2'
import Step from '../Step'
import World from '../../World'
import GameManager from '../../managers/GameManager';

/**
 * This Chapter is the climax of the story.
 * @class
 */
export default class Chapter4 extends Chapter {
	constructor(props) {
		super({
			name: config.scenario.chapter4.name,
			steps: props.steps,
			refreshInterfacesOnStart: props.refreshInterfacesOnStart
		})
	}

	createSteps() {
		return [
			new StartAttack(),
			new Step(config.scenario.chapter4.steps.goToNewsInterface),
			new CyberAttack(),
			new Renegade()
		]
	}

	onStart() {
		return new Promise((resolve) => {
			GameManager.updateSkyBox(4)
			CameraManager.rotateOn('x', -20)
			CameraManager.rotateOn('y', 180)
			CameraManager.defaultCamera.position.x = 500
			CameraManager.defaultCamera.position.y = 100
			CameraManager.defaultCamera.position.z = -700

			DOMManager.showAmbience()
			
			HiveObjects.setNightBaking()
			World.nightMode()
			Resources.rooms.livingroom.onEnter()

			const bedroomInterfaces = Resources.rooms.bedroom.interfaces

			for (let i = 0; i < bedroomInterfaces.length; i++) {
				if (bedroomInterfaces[i] instanceof Weather) bedroomInterfaces[i].nextDay()
				else if (bedroomInterfaces[i] instanceof WakeUpDay2) bedroomInterfaces[i].nextDay()
			}

			const kitchenInterfaces = Resources.rooms.kitchen.interfaces
			
			for (let i = 0; i < kitchenInterfaces.length; i++) {
				if (kitchenInterfaces[i] instanceof Oven) kitchenInterfaces[i].nextDay()
				else if (kitchenInterfaces[i] instanceof Dish) kitchenInterfaces[i].nextDay()
			}

			AudioManager.playMusic({
				intro: Resources.audios.ambiences.chapter4,
				loop: Resources.audios.ambiences.chapter4Loop
			})
			
			DOMManager.leaveInterlude(3)
				.then(this.startSofaAnimation.bind(this))
				.then(resolve)
		})
	}

	startSofaAnimation() {
		return new Promise((resolve) => {
			const tl = new TimelineMax({
				delay: 1,
				onComplete: () => {
					CameraManager.activate()
					resolve()
				}
			})
			const state1 = {
				position: {
					from: {
						posX: 500,
						posY: 100,
						posZ: -700
					},
					to: {
						posX: 500,
						posY: 155,
						posZ: -670
					},
					i: {
						posX: 500,
						posY: 105,
						posZ: -671
					},
					i2: {
						posX: 500,
						posY: 123,
						posZ: -665
					}
				},
				rotation: {
					from: {
						x: -20,
						y: 180
					},
					to: {
						x: 0,
						y: 180
					}
				}
			}

			tl.to(state1.rotation.from, 2.5, {
					x: state1.rotation.to.x,
					y: state1.rotation.to.y,
					ease: Power1.easeInOut,
					onUpdate: () => {
						CameraManager.rotateOn('y', Number(state1.rotation.from.y))
						CameraManager.rotateOn('x', Number(state1.rotation.from.x))
					}
				})
				.to(state1.position.from, 2.5, {
					bezier: [{
						posX: state1.position.from.posX,
						posY: state1.position.from.posY,
						posZ: state1.position.from.posZ
					},
					{
						posX: state1.position.i.posX,
						posY: state1.position.i.posY,
						posZ: state1.position.i.posZ
					},
					{
						posX: state1.position.i2.posX,
						posY: state1.position.i2.posY,
						posZ: state1.position.i2.posZ
					},
					{
						posX: state1.position.to.posX,
						posY: state1.position.to.posY,
						posZ: state1.position.to.posZ
					}],
					ease: Power1.easeInOut,
					onUpdate: () => {
						CameraManager.defaultCamera.position.x = Number(state1.position.from.posX)
						CameraManager.defaultCamera.position.y = Number(state1.position.from.posY)
						CameraManager.defaultCamera.position.z = Number(state1.position.from.posZ)
					}
				}, '-=2.5')
		})
	}

	/**
	 * Ends this Chapter by fading out the screen until its black, by using DOMManager after a given delay.
	 *
	 * @returns {Promise} A Promise resolved when the DOMManager finished the animation.
	 */
	onEnd() {
		return DOMManager.interlude(4, 1)
	}
}
