import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager'
import MessagesManager from '../../managers/MessagesManager'

/**
 * Second Step of the first Chapter (Tutorial).
 * Asks the user to go from a point to another.
 * @class
 */
export default class SleepData extends Step {
  constructor() {
    super(config.scenario.tutorial.steps.sleepData)
  }

  /**
   * Starts the Step by moving lights towards the sleepdata interface.
   * Also sets a new goal in the UI.
   *
   * @returns {Promise} A Promise resolved when Step has started.
   */
  onStart() {
    return new Promise((resolve) => {
      DOMManager.setNewGoal('Approchez-vous du mur au pied du lit', 'bedroom')

      MessagesManager.popMessages({
        interval: 1100,
        number: 3,
        duration: 60,
        onlyGood: true,
        specificMessages: [
          Resources.textures.imgs.messages.good.content['ch1-1'],
          Resources.textures.imgs.messages.good.content['ch1-2'],
          Resources.textures.imgs.messages.good.content['ch1-4']
        ],
        indexes: [30, 31, 43],
        indexesRoom: Resources.rooms.bedroom
      })

      resolve()
    })
  }
}
