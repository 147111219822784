import {
    Vector2,
    Vector3
} from 'three'

/**
 * Creates an Inertia to smooth value modifications.
 * Supports numbers, but also Vector2 and Vector3.
 * @class
 */
export default class Inertia {

    /**
     * Creates an Inertia instance according to given parameters.
     *
     * @param {Object} params - Parameters object to tweak inertia.
     * @param {(number|Vector2|Vector3)} params.min - The bounds minimum value.
     * @param {(number|Vector2|Vector3)} params.max - The bounds maximum value.
     * @param {number} params.acceleration - A range greater than 0 and <= 1. This is how responsive to change the chase value is. Low values will make the control seem sluggish or heavy. Higher values make the chase quick to respond and seem light to the touch.
     * @param {number} params.drag - A value greater than 0 and <= 1. It's more like a damping spring in function. Values less than 0.5 provide a smooth stop to the counting value. Values >= 0.5 cause the chase value to bounce around the required value, as the drag value moves towards 1 the bounce become more and more pronounced.
     * @param {number} params.reflect - Describes the behaviour if the value crosses the bounds by defining the reflection or bounce. 0 makes it stop dead at the bounds, < 0 and >= -1 will give a small bounce from the ends. Other values create interesting FX.
     * @param {number} params.threshold  - If the value goes lower than `threshold` value, sets it directly to 0.
     * @constructor
     */
    constructor({ min, max, acceleration = 0.4, drag = 0.35, reflect = 0, threshold = 0.05 }) {
        this.ac = acceleration
        this.dr = drag
        
        this.minV = min
        this.maxV = max

        this.threshold = threshold
        this.disabled = false

        if (min instanceof Vector3 && max instanceof Vector3) this.isVector = 3
        else if (min instanceof Vector2 && max instanceof Vector2) this.isVector = 2
        else this.isVector = 0

        this.ref = -Math.abs(reflect)
        this.value = this.isVector === 0 ? min : min.clone()

        if (this.isVector) this.emptyVector = this.isVector === 3 ? new Vector3() : new Vector2()

        /* eslint-disable-next-line */
        this.delta = this.isVector === 3 ? new Vector3() : this.isVector === 2 ? new Vector2() : 0
    }

    /**
     * Call this function in a requestAnimationFrame with the value to update.
     * Used if `input` parameter is a number. If not, `updateVector` is automatically called and returned.
     * Returns the given value modified by inertia.
     *
     * @param {(number|Vector2|Vector3)} input - The value to update.
     *
     * @returns {number} - The given value modified by inertia.
     */
    update(input) {
        if (this.disabled) return this.setValue(input)
        if (this.isVector !== 0) return this.updateVector(input)

        // Once per frame, accelerate towards the input value by adding to deltaV
        this.delta += (input - this.value) * this.ac

        // Add drag to the delta by reducing its magnitude
        this.delta *= this.dr

        // Then add the deltaV to the chasing value
        this.value += this.delta

        // Bounds control
        if (this.value < this.minV) {
            this.value = this.minV

            if (this.delta < 0) this.delta *= this.ref
        } else if (this.value > this.maxV) {
            this.value = this.maxV

            if (this.delta > 0) this.delta *= this.ref
        }

        if (this.value <= this.threshold && this.value > 0) this.value = 0
        else if (this.value >= -this.threshold && this.value < 0) this.value = 0

        return this.value
    }

    /**
     * Call this function in a requestAnimationFrame with the value to update.
     * Used if `input` parameter is a Vector2 or a Vector3. If not, `update` is automatically called and returned.
     * Returns the given value modified by inertia.
     *
     * @param {(number|Vector2|Vector3)} input - The value to update.
     *
     * @returns {(Vector2|Vector3)} - The given value modified by inertia.
     */
    updateVector(input) {
        if (this.disabled) return this.setValue(input)
        if (this.isVector === 0) return this.update(input)

        // Once per frame, accelerate towards the input value by adding to deltaV
        this.calculateAcceleration(input, 'x')
        this.calculateAcceleration(input, 'y')
        if (this.isVector === 3) this.calculateAcceleration(input, 'z')

        // Add drag to the delta by reducing its magnitude
        this.delta.multiplyScalar(this.dr)

        // Then add the deltaV to the chasing value
        this.value.add(this.delta)

        // Bounds control
        this.calculateBounds('x')
        this.calculateBounds('y')
        if (this.isVector === 3) this.calculateBounds('z')

        this.calculateThreshold('x')
        this.calculateThreshold('y')
        if (this.isVector === 3) this.calculateThreshold('z')
        
        return this.value
    }

    /**
     * Set delta of a Vector on a particular axis.
     *
     * @param {(Vector2|Vector3)} input - A vector used to calculate delta.
     * @param {string} axis - 'x', 'y' or 'z'. Axis on which to calculate the delta on `input`.
     *
     * @returns {void}
     */
    calculateAcceleration(input, axis) {
        this.delta[axis] += (input[axis] - this.value[axis]) * this.ac
    }

    /**
     * Checks if the value of specific axis is inside or outside the bounds.
     *
     * @param {string} axis - 'x', 'y' or 'z'. Axis on which to check the bounds on internal value.
     *
     * @returns {void}
     */
    calculateBounds(axis) {
        if (this.value[axis] < this.minV[axis]) {
            this.value[axis] = this.minV[axis]

            if (this.delta[axis] < 0) this.delta[axis] *= this.ref
        } else if (this.value[axis] > this.maxV[axis]) {
            this.value[axis] = this.maxV[axis]

            if (this.delta[axis] > 0) this.delta[axis] *= this.ref
        }
    }

    /**
     * Checks if the value of specific axis is smaller than the threshold.
     *
     * @param {string} axis - 'x', 'y' or 'z'. Axis on which to check the threshold on internal value.
     *
     * @returns {void}
     */
    calculateThreshold(axis) {
        if (this.value[axis] <= this.threshold && this.value[axis] > 0) this.value[axis] = 0
        else if (this.value[axis] >= -this.threshold && this.value[axis] < 0) this.value[axis] = 0
    }

    /**
     * This moves the value to the required value without any inertial or drag.
     * Is bound checked.
     * If `input` is not a number, will call `setVectorValue` and return it automatically.
     *
     * @param {(number|Vector2|Vector3)} input - Input that will be set to a specific value without any inertia.
     * @param {number} index - Used if `input` is a Vector2 / Vector3 to set a specific axis to the value.
     *
     * @returns {(number)} - The modified value.
     */
    setValue(input, index) {
        if (this.isVector !== 0) return this.setVectorValue(input, index)

        this.delta = 0
        this.value = Math.min(this.maxV, Math.max(this.minV, input))

        return this.value
    }

    /**
     * This moves the value to the required value without any inertial or drag.
     * Is bound checked.
     * If `input` is not a Vector2 or Vector3, will call `setValue` and return it automatically.
     *
     * @param {(number|Vector2|Vector3)} input - Input that will be set to a specific value without any inertia.
     * @param {number} index - Used if `input` is a Vector2 / Vector3 to set a specific axis to the value.
     *
     * @returns {(Vector2|Vector3)} - The modified value.
     */
    setVectorValue(input, index) {
        if (this.isVector === 0) return this.setValue(input)

        if (index === 0) this.delta.x = 0
        else if (index === 1) this.delta.y = 0
        else if (index === 2) this.delta.z = 0
        else this.delta.set(0, 0, 0)

        if (index === 0) this.value.x = Math.min(this.maxV.x, Math.max(this.minV.x, input.x))
        else if (index === 1) this.value.y = Math.min(this.maxV.y, Math.max(this.minV.y, input.y))
        else if (index === 2 && this.isVector === 3) this.value.z = Math.min(this.maxV.z, Math.max(this.minV.z, input.z))
        else {
            this.value.x = Math.min(this.maxV.x, Math.max(this.minV.x, input.x))
            this.value.y = Math.min(this.maxV.y, Math.max(this.minV.y, input.y))
            if (this.isVector === 3) this.value.z = Math.min(this.maxV.z, Math.max(this.minV.z, input.z))
        }

        return this.value
    }
}
