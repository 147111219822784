import TweenMax from 'gsap/TweenMax'
import { Power2 } from 'gsap/EasePack'

import config from '../../settings/config'
import HiveObjects from '../HiveObjects'
import Action from '../actions/Action'
import InteractiveObject from './InteractiveObject'

/**
 * Door is an InteractiveObject containing the bedroom's door's Mesh.
 * It can be opened / close with Actions.
 * @class
 */
export default class Door extends InteractiveObject {

  /**
   * Creates a Door by assigning it an Action.
   * Also attaches the right Collider to this instance.
   *
   * @param {Object} props InteractiveObject options.
   * @constructor
   */
  constructor(props) {
    super(props)

    this.isOpen = false

    this.collider = HiveObjects.getColliderByName('door')
  }

  bindMethods() {
    super.bindMethods()

    this.toggleDoor = this.toggleDoor.bind(this)
  }

  createActions() {
    const openAction = new Action({
      name: 'Ouvrir',
      steps: [
        {
          name: config.scenario.tutorial.steps.leaveRoom,
          isLast: true
        },
        {
          name: config.scenario.chapter2.steps.hiverOfTheDay,
          isLast: false,
          multiple: true
        },
        {
          name: config.scenario.chapter3.steps.goToKitchen,
          isLast: false,
          multiple: true
        },
        {
          name: config.scenario.chapter5.steps.voiceHack,
          isLast: true
        }
      ],
      interaction: this.toggleDoor
    })

    this.addAction(openAction)
  }

  /**
   * One of the Action's interaction callback.
   * Opens or closes the Door.
   * Also move the attached Collider to be able to go behind the Door when opened.
   *
   * @returns {Promise} A Promise resolved when the animation is over.
   */
  toggleDoor() {
    return new Promise((resolve) => {
      const objects = []

      objects.push(this.mesh.position)
      this.collider && objects.push(this.collider.mesh.position)

      TweenMax.to(objects, 1, {
        x: this.isOpen ? '+=150' : '-=150',
        ease: Power2.easeInOut,
        onStart: () => {
          this.sounds.slide.play()
        },
        onUpdate: () => {
          this.updateWorldPosition()
          this.collider.mesh.updateMatrix()
        },
        onComplete: () => {
          this.updateWorldPosition()
          this.collider.mesh.updateMatrix()
          
          if (config.debug) {
            for (let i = 0; i < this.helpers.length; i++) {
              this.helpers[i].update()
            }
          }

          if (this.description === 'La porte est déverrouillée !') this.description = null

          this.isOpen = !this.isOpen
          this.actions[0].name = this.isOpen ? 'Fermer' : 'Ouvrir'

          resolve()
        }
      })
    })
  }
}
