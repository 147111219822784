import Resources from '../../Resources'

/**
 * A ColliderObject defines a Mesh used to check collisions between it and the camera.
 * @class
 */
export default class ColliderObject {

  /**
   * Creates a Collider by disposing its original material and applying a unique invisible Material to it.
   *
   * @param {Object} param An object with a `mesh` and a `name` property.
   * @constructor
   */
  constructor({ mesh, name }) {
    this.mesh = mesh
    this.name = name

    const oldMat = this.mesh.material

    this.mesh.material = Resources.materials.hidden
    
    oldMat.dispose()

    mesh.matrixAutoUpdate = false
    mesh.updateMatrix()
  }
}
