export default [
	{
		start: 0.05,
		end: 3.3,
		text: 'Tu as fait le bon choix. Tout ça est derrière toi.'
	},
	{
		start: 3.4,
		end: 6,
		text: 'Nous allons t\'aider à t\'en sortir.'
	}
]
