export default [
	{
		start: 0.05,
		end: 3,
		text: 'J\'ai fait tout mon possible pour vous aider à vous en sortir.'
	},
	{
		start: 3.15,
		end: 7,
		text: 'Mais les messages sont trop nombreux, ils vous minent le moral, je peux le voir.'
	},
	{
		start: 7.2,
		end: 13,
		text: 'Ne dites à personne ce qu\'il nous arrive. Le temps calmera les choses.'
	}
]
