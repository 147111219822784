export default [
	{
		start: 0.2,
		end: 4.2,
		text: 'Bonjour ! Je suis Hive, votre réseau social personnel.'
	},
	{
		start: 4.4,
		end: 6,
		text: 'Je viens de me mettre à jour.'
	},
	{
		start: 6.25,
		end: 10.25,
		text: 'J\'ai maintenant une voix pour mieux vous accompagner. J\'espère qu\'elle vous plaira !'
	},
	{
		start: 10.6,
		end: 12,
		text: 'Car Hive est toujours là pour vous.'
	},
	{
		start: 13.25,
		end: 15.8,
		text: 'Laissez-moi vous présenter mes nouvelles fonctionnalités.'
	},
	{
		start: 15.9,
		end: 19,
		text: 'Dirigez-vous vers l\'interface au pied du lit...'
	}
]
