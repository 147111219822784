export default [
	{
		start: 0.05,
		end: 4.3,
		text: 'Je vous sens stressé, vous avez presque perdu un rang aujourd\'hui...'
	},
	{
		start: 4.5,
		end: 8,
		text: 'Il faut vous reprendre ! Ça ira mieux demain.'
	}
]
