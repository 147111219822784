export default [
	{
		start: 0.05,
		end: 3.55,
		text: 'Lancez la cuisson de votre plat sur le four, vous pourrez le récupérer plus tard.'
	},
	{
		start: 3.85,
		end: 7,
		text: 'Je sens que vous allez adorer !'
	}
]
