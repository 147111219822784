import { TweenMax } from 'gsap/TweenMax'
import { Linear } from 'gsap/EasePack'

import InteractiveObject from './InteractiveObject'
import config from '../../settings/config'
import { degreesToRadians } from '../../utils/rad-deg'
import HistoryManager from '../../managers/HistoryManager'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager';
import AudioManager from '../../managers/AudioManager';

export default class FrontDoor extends InteractiveObject {
  constructor(props) {
    super(props)

    this.firstLook = false

    this.tockInterval = 4000
    this.tockOffsetMin = 50
    this.tockOffsetMax = 750
    this.lastTock = 0
    this.alreadyStarted = false
    this.stopped = false
    this.firstApproach = false

    Resources.audios.voices.ch5SaviorFinal.onEnd = this.onSoundEnded.bind(this)

    this.tocks = [
      this.sounds.tock1,
      this.sounds.tock2,
      this.sounds.tock3,
      this.sounds.tock4,
      this.sounds.tock5,
      this.sounds.tock6,
      this.sounds.tock7
    ]
  }

  open() {
    return new Promise((resolve) => {
      TweenMax.to(this.mesh.rotation, 4, {
        y: '-=' + degreesToRadians(90),
        ease: Linear.easeNone,
        onStart: () => {
          this.sounds.unlock.play()
        },
        onComplete: resolve
      })
    })
  }

  startTocking() {
    if (this.alreadyStarted) return

    this.tocks[0].play()
    
    this.alreadyStarted = true
  }

  accelerateTocking() {
    this.tockInterval *= 0.7
    this.tockOffsetMax *= 1.2
  }

  stopTocking() {
    this.stopped = true
  }

  onSoundEnded(sound) {
    if (sound === Resources.audios.voices.ch5SaviorFinal) HistoryManager.nextStep()
    if (sound === this.sounds.unlock || this.stopped) return

    let newIndex = null
    
    do {
      newIndex = Math.floor(Math.random() * this.tocks.length)
    } while (newIndex === this.lastTock)

    let offset = Math.floor(Math.random() * this.tockOffsetMax) + this.tockOffsetMin

    offset *= Math.floor(Math.random() * 2) === 1 ? 1 : -1
        
    setTimeout(() => {
      if (this.stopped) return

      const nextTock = this.tocks[newIndex]
      
      nextTock.play()

      this.lastTock = newIndex
    }, this.tockInterval + offset)
  }

  onStartLooking() {
    super.onStartLooking()

    if (!this.firstLook && HistoryManager.currentChapter.currentStep.name === config.scenario.chapter5.steps.endScene) {
      this.firstLook = true

      this.description = ''
      
      this.open()

      DOMManager.ui.wrapper.classList.add('hidden')
      DOMManager.ui.interlude.el.classList.add('white')
      DOMManager.fadeOut(0.5, false)
      AudioManager.fadeOut()
      setTimeout(() => {
        Resources.audios.voices.ch5SaviorFinal.play()
      }, 500);
    }
  }

  onApproach() {
    super.onApproach()

    if (!this.firstApproach) {
      this.firstApproach = true
      
      this.accelerateTocking()
    }
  }
}
