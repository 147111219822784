export default [
	{
		start: 0.05,
		end: 3,
		text: 'Mmh... Ce Hiver semble vous en vouloir publiquement.'
	},
	{
		start: 3.3,
		end: 6.7,
		text: 'Je lui envoie tout de suite un avertissement pour comportement inapproprié.'
	},
	{
		start: 6.75,
		end: 10,
		text: 'Il perdra des points Hive si cela se reproduit.'
	}
]
