import TweenMax, { TimelineMax, Power2, Power4 } from 'gsap/TweenMax'

import AudioManager from './AudioManager'
import World from '../World'
import DOMManager from './DOMManager'
import USER from '../User'

export default class MenuManager {
	constructor() {
		this.opened = false
		this.locked = false

		this.targetMargin = 25

		this.bindMethods()
		this.getElems()
		this.addEvents()
	}

	bindMethods() {
		this.mouseEnter = this.mouseEnter.bind(this)
		this.mouseLeave = this.mouseLeave.bind(this)
		this.close = this.close.bind(this)
		this.keyDown = this.keyDown.bind(this)
		this.toggleSound = this.toggleSound.bind(this)
		this.toggleFullscreen = this.toggleFullscreen.bind(this)
	}

	getElems() {
		this.el = document.querySelector('.menu')
		this.back = this.el.querySelector('.menu-back')
		this.head = this.el.querySelector('.menu-head')
		this.chapters = this.el.querySelectorAll('.menu-chapter')
		this.names = this.el.querySelectorAll('.menu-chapter-name')
		this.tuto = this.el.querySelector('.menu-tuto')
		this.foot = this.el.querySelector('.menu-foot')
		this.leave = this.el.querySelector('.menu-leave')
		this.soundBtn = this.el.querySelector('.menu-sound')
		this.badgeNumber = this.el.querySelector('.menu-badge-number')
		this.fullscreenBtn = this.el.querySelector('.menu-fullscreen')
	}

	addEvents() {
		for (let i = 0; i < this.names.length; i++) {
			this.names[i].addEventListener('mouseenter', this.mouseEnter)
			this.names[i].addEventListener('mouseleave', this.mouseLeave)
		}

		this.leave.addEventListener('click', this.close)

		document.addEventListener('keydown', this.keyDown)

		this.soundBtn.addEventListener('click', this.toggleSound)
		this.fullscreenBtn.addEventListener('click', this.toggleFullscreen)
	}

	removeEvents() {
		for (let i = 0; i < this.names.length; i++) {
			this.names[i].removeEventListener('mouseenter', this.mouseEnter)
			this.names[i].removeEventListener('mouseleave', this.mouseLeave)
		}

		this.leave.removeEventListener('click', this.close)

		document.removeEventListener('keydown', this.keyDown)

		this.soundBtn.removeEventListener('click', this.toggleSound)
		this.fullscreenBtn.removeEventListener('click', this.toggleFullscreen)
	}

	keyDown(e) {
		if (e.code === 'Escape') this.close()
	}

	mouseEnter(e) {
		const chLetters = e.currentTarget.nextElementSibling
		const tl = new TimelineMax()

		tl.set(chLetters.children, { x: 0 })
			.add('start')

		for (let i = 0; i < chLetters.children.length; i++) {
			const param = { spacing: 0 }
			const factor = Number(chLetters.children[i].dataset.factor)

			tl.to(param, 1.2, {
				spacing: this.targetMargin,
				onStart: () => {
					if (i === 0) chLetters.classList.add('active')
				},
				onUpdate: () => {
					const x = param.spacing * factor

					TweenMax.set(chLetters.children[i], { x })
				},
				onComplete: () => {
					const x = param.spacing * factor

					TweenMax.set(chLetters.children[i], { x })
				},
				ease: Power4.easeOut
			}, 'start')
		}
	}

	mouseLeave(e) {
		const chLetters = e.currentTarget.nextElementSibling

		chLetters.classList.remove('active')
	}

	toggleSound() {
		AudioManager.toggle()
	}

	toggleFullscreen() {
		if (document.fullscreenElement) document.exitFullscreen()
		else document.body.requestFullscreen()
	}

	open() {
		if (this.opened || this.locked || DOMManager.isInterluding || DOMManager.openedBadge !== -1 || DOMManager.isTutorialShown) return

		this.addEvents()

		this.locked = true

		World.pause()
		AudioManager.enableLowpass()
		DOMManager.ui.wrapper.classList.add('hidden')

		this.badgeNumber.textContent = USER.badges

		const tl = new TimelineMax({
			onComplete: () => {
				this.locked = false
				this.opened = true
			}
		})

		tl.to(this.el, 0.1, { display: 'flex' })
			.to(this.back, 0.5, {
				alpha: 1,
				ease: Power2.easeOut
			})
			.to([this.head, this.foot, this.tuto], 0.6, {
				alpha: 1,
				ease: Power2.easeOut
			})
			.staggerFromTo(this.chapters, 1, {
				alpha: 0,
				y: 20
			}, {
				alpha: 1,
				y: 0,
				ease: Power2.easeOut
			}, 0.05, '-=0.6')
	}

	close() {
		if (!this.opened || this.locked || DOMManager.openedBadge !== -1 || DOMManager.isTutorialShown) return

		this.removeEvents()

		this.locked = true

		AudioManager.disableLowpass()

		const tl = new TimelineMax({
			onComplete: () => {
				this.locked = false
				this.opened = false

				for (let i = 0; i < this.names.length; i++) {
					this.names[i].nextElementSibling.classList.remove('active')
				}

				this.el.style.display = 'none'

				DOMManager.ui.wrapper.classList.remove('hidden')

				World.resume()
			}
		})

		tl.add('start')
			.to([this.head, this.foot, this.tuto], 0.5, {
				alpha: 0,
				ease: Power2.easeIn
			})
			.staggerTo(this.chapters, 0.8, {
				alpha: 0,
				y: 20,
				ease: Power2.easeIn
			}, -0.05, 'start')
			.to(this.back, 0.4, {
				alpha: 0,
				ease: Power2.easeIn
			}, 'start+=0.4')
	}
}
