import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager'

export default class VoiceHack extends Step {
  constructor() {
    super(config.scenario.chapter5.steps.voiceHack)
  }

  onStart() {
    return new Promise((resolve) => {
      this.getSounds()
      this.sounds[0].play()

      resolve()
    })
  }

  getSounds() {
    this.sounds = [
      Resources.audios.voices.ch5Savior1,
      Resources.audios.voices.ch5HiveToSavior1,
      Resources.audios.voices.ch5Savior2,
      Resources.audios.voices.ch5HiveToSavior2
    ]

    for (let i = 0; i < this.sounds.length; i++) {
      this.sounds[i].onEnd = this.onSoundEnded.bind(this, this.sounds[i])
    }
  }

  onSoundEnded(sound) {
    if (sound === this.sounds[0]) this.sounds[1].play()
    else if (sound === this.sounds[1]) this.sounds[2].play()
    else if (sound === this.sounds[2]) this.sounds[3].play()
    else if (sound === this.sounds[3]) DOMManager.setNewGoal('Sortez de la chambre', 'livingroom')
  }
}
