import Nipple from './Nipple'

export default class CameraNipple extends Nipple {

    constructor(props) {
        props.isRightSide = true

        super(props)
        
        this.currentPosition = null
        this.startPosition = null
        this.speedReducer = 0.3
        this.movement = {
            x: 0,
            y: 0
        }

        this.addEvents()
    }

    bindMethods() {
        this.onStart = this.onStart.bind(this)
        this.onMove = this.onMove.bind(this)
        this.onEnd = this.onEnd.bind(this)
    }

    addEvents() {
        this.manager.on('start', this.onStart)
        this.manager.on('end', this.onEnd)
        this.manager.on('move', this.onMove)
    }

    onStart(evt, data) {
        this.startPosition = data.position
        this.onStartCallback()
    }

    onEnd() {
        this.onEndCallback()
    }

    onMove(evt, data) {
        this.currentPosition = data.position

        this.movement.x = (this.currentPosition.x - this.startPosition.x) * this.speedReducer
        this.movement.y = (this.currentPosition.y - this.startPosition.y) * this.speedReducer

        this.onMoveCallback(this.movement)
    }
    
}
