export default [
	{
		start: 0.05,
		end: 3,
		text: 'Mais... Ça ne s\'arrêtera donc jamais ?!'
	},
	{
		start: 3.25,
		end: 6.75,
		text: 'Tout le réseau est en train de donner des avis négatifs sur cette publication.'
	},
	{
		start: 6.9,
		end: 9,
		text: 'Qu\'avez-vous fait ?'
	}
]
