/**
 * Configuration object for our shaders.
 * Contains uniforms that will be set as the starting value of the shaders.
 *
 * @todo Add button's uniforms here.
 * @todo Add picture gallery's uniforms here.
 * @todo Split the object by sub-object with the key corresponding to the shader's name.
 */
const shaders = {
	nearColor: 0xffffff
}

export default shaders
