import config from '../../settings/config'
import Step from '../Step'
import DOMManager from '../../managers/DOMManager'

export default class GoToMontage extends Step {
	constructor() {
		super(config.scenario.chapter3.steps.goToMontage)
	}

	onStart() {
		return new Promise((resolve) => {
			DOMManager.setNewGoal('Approchez-vous de la publication qui vous concerne', 'livingroom')
			
			resolve()
		})
	}

	onEnd() {
		return new Promise((resolve) => {
			DOMManager.goalAchieved()

			resolve()
		})
	}
}
