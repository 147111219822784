import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager'
import CameraManager from '../../managers/CameraManager'
import AudioManager from '../../managers/AudioManager'

export default class PushButton extends Step {
  constructor() {
    super(config.scenario.chapter5.steps.pushButton)
  }

  onStart() {
    return new Promise((resolve) => {
      // CameraManager.setFinalPosition()

      AudioManager.queueMusic(Resources.audios.ambiences.chapter5End)

      this.getSounds()

      this.sounds[0].play()

      resolve()
    })
  }

  getSounds() {
    this.sounds = [
      Resources.audios.voices.ch5HiveListen,
      Resources.audios.voices.ch5SaviorDoGoodChoice
    ]

    for (let i = 0; i < this.sounds.length; i++) {
      this.sounds[i].onEnd = this.onSoundEnded.bind(this, this.sounds[i])
    }
  }

  onSoundEnded(sound) {
    if (sound === this.sounds[0]) this.sounds[1].play()
    else if (sound === this.sounds[1]) {
      DOMManager.setNewGoal('Faites confiance à l\'inconnu et désactivez Hive en pressant le bouton<br>OU<br>Faites confiance à Hive et éjectez l\'intrus en refermant le cache.', 'entrance')
      DOMManager.showFinalChoice()
    }
  }
}
