/**
 * Randomizes in-place the given Array.
 *
 * @param {Array} array The Array to randomize.
 *
 * @returns {Array} The randomized Array.
 */
const shuffle = (array) => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));

		[array[i], array[j]] = [array[j], array[i]]
	}
}

export default shuffle
