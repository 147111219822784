/**
 * Configuration object for our story.
 * This object defines Chapters, their name, their Steps and their Steps names.
 *
 * @todo Use chapter's key as string instead of duplicating it and setting it to `name` property.
 */
const scenario = {
	tutorial: {
		name: 'tutorial',
		steps: {
			wakeUp: 'wakeUp',
			sleepData: 'sleepData',
			share: 'share',
			noShare: 'noShare',
			leaveRoom: 'leaveRoom'
		}
	},
	chapter2: {
		name: 'chapter2',
		steps: {
			updateAppartment: 'updateAppartment',
			hiverOfTheDay: 'hiverOfTheDay',
			comment: 'comment',
			report: 'report'
		}
	},
	chapter3: {
		name: 'chapter3',
		steps: {
			wakeUpTomorrow: 'wakeUpTomorrow',
			goToKitchen: 'goToKitchen',
			food: 'food',
			goToMontage: 'goToMontage',
			theMontage: 'theMontage',
			privateMessage: 'privateMessage',
			aLotOfMessages: 'aLotOfMessages'
		}
	},
	chapter4: {
		name: 'chapter4',
		steps: {
			startAttack: 'startAttack',
			goToNewsInterface: 'goToNewsInterface',
			cyberAttack: 'cyberAttack',
			renegade: 'renegade'
		}
	},
	chapter5: {
		name: 'chapter5',
		steps: {
			presentSituation: 'presentSituation',
			identityTheft: 'identityTheft',
			voiceHack: 'voiceHack',
			goToDoor: 'goToDoor',
			liftButton: 'liftButton',
			pushButton: 'pushButton',
			endScene: 'endScene'
		}
	}
}

export default scenario
