export default [
	{
		start: 0.05,
		end: 4.8,
		text: 'Ça y est... Vous êtes descendu au rang 2 : Renégat.'
	},
	{
		start: 5,
		end: 7.75,
		text: 'Vous entrez en quarantaine, plus personne ne pourra vous contacter...'
	},
	{
		start: 7.85,
		end: 10,
		text: '...pendant les trois prochains jours.'
	}
]
