import nipplejs from 'nipplejs'

export default class Nipple {
    constructor({ domElt, name, isRightSide, onDir = () => {}, onMove = () => {}, onEnd = () => {}, onStart = () => {} } = {}) {
        if (!domElt) return

        this.domElt = domElt
        this.name = name
        this.isRightSide = isRightSide

        this.onDirCallback = onDir
        this.onEndCallback = onEnd
        this.onStartCallback = onStart
        this.onMoveCallback = onMove

        this.bindMethods()
        this.manager = this.createNipple()
    }

    bindMethods() {}

    createNipple() {
        return nipplejs.create({
            zone: this.domElt,
            mode: 'static',
            multitouch: false,
            threshold: 0.2,
            size: 80,
            position: {
                top: '80%',
                left: this.isRightSide ? '70%' : '30%'
            }
        })
    }
}
