/* eslint-disable object-property-newline */
import HiveObjects from '../objects/HiveObjects'
import Resources from '../Resources'
import Room from '../rooms/Room'

/**
 * MessagesManager is a class managing `Message` instances in 3D world.
 * @class
 */
 export default class MessagesManager {

	/**
   * Starts a Message appearance sequence.
   *
   * @param {Object} param An object defining the parameters of this method.
   * @param {Number} param.interval The duration between two messages appearances, in milliseconds.
   * @param {Number} param.number The number of messages appearances.
   * @param {Number} param.duration The duration of the message visibility, in seconds.
   * @param {Boolean} param.onlyGood If `true`, shows only good Messages. Default is `true`.
   * @param {Function} param.intervalChecking Callback called at each interval start. Return `true` to kill the sequence.
   * @param {Array<Number>} param.indexes An Array of indexes to pop Messages into. Must be used with `indexesRoom` parameter.
   * @param {Room} param.indexesRoom If an `indexes` Array is given, the sequence will search for Message positions in this specific Room.
   * @param {Boolean} param.fullApartment If `indexes` & `indexesRoom` are not given, allows the sequence to pop messages from the entire apartment.
   * @param {Array<Texture>} param.specificMessages Specific message Textures.
   * @param {boolean} param.canHaveDelayedSound If `true`, the sound of the popped messages can be delayed (50% chance).
   *
   * @static
   * @returns {void}
   */
     static popMessages({ interval = 3000, number = 0, duration = 5, onlyGood = true, intervalChecking = () => {}, indexes = [], indexesRoom = null, fullApartment = false, specificMessages = [], canHaveDelayedSound = false }) {
		return new Promise((resolve) => {
			let cpt = 0

			const intervalID = setInterval(() => {
				if (cpt === number || intervalChecking()) {
					clearInterval(intervalID)
					resolve()

					return
				}

				const messageElt = indexes.length ? MessagesManager.getMessageByIndex({ index: indexes.shift(), room: indexesRoom }) : MessagesManager.getRandomMessage(fullApartment)

				if (messageElt) {
					const userMessage = MessagesManager.generateRandomMessage(onlyGood)
					const message = specificMessages.length ? specificMessages.shift() : userMessage.message

					messageElt.setMessage(userMessage.userProfile, message, onlyGood, duration)

					const delayedSound = canHaveDelayedSound ? Math.random() < 0.5 : false

					messageElt.playAnimation(delayedSound)
					cpt ++
				}

			}, interval)
		})
	}


	/**
   * Gets a `Message` from a specific `Room` with a given `index`.
   *
   * @param {Room} room The `Room` to search `Message` index into.
   * @param {Number} index The index of the `Message` in `room.messages` Array.
   *
   * @static
   * @returns {(Message|null)} The found `Message`, or `null` if none are found.
   */
	static getMessageByIndex({ room = null, index = null }) {
		const message = room.messages.filter((elt) => elt.name === 'message' + index)

		if (message.length) return message[0]

		return null
	}

	/**
   * Generates a random message with a combination of random pictures & random texts.
   *
   * @param {Boolean} good Whether the message to generate is good or bad.
   *
   * @static
   * @returns {Object} The object containing `profilePictures` and `textMessages` properties.
   */
	static generateRandomMessage(good = false) {
		const profilePictures = good ? Object.entries(Resources.textures.imgs.messages.good.profilePictures) : Object.entries(Resources.textures.imgs.messages.bad.profilePictures)
		const textMessages = good ? Object.entries(Resources.textures.imgs.messages.good.content) : Object.entries(Resources.textures.imgs.messages.bad.content)

		return {
			userProfile: profilePictures[Math.floor(Math.random() * profilePictures.length)][1],
			message: textMessages[Math.floor(Math.random() * textMessages.length)][1]
		}
	}

	/**
   * Gets a random `Message`.
   *
   * @param {Boolean} fullApartment If `true`, gets a random Message in the entire apartment. If `false` (default), gets a random Message that is near from the user.
   *
   * @returns {Message} The random message.
   */
	static getRandomMessage(fullApartment) {
		let messages = null

		if (fullApartment) {
			if (!MessagesManager.allMessages) {
				MessagesManager.allMessages = []

				const rooms = Object.values(Resources.rooms)

				for (let i = 0; i < rooms.length; i++) {
					const room = rooms[i]

					for (let j = 0; j < room.messages.length; j++) {
						!room.messages[j].isAnimating && MessagesManager.allMessages.push(room.messages[j])
					}
				}
			}

			messages = MessagesManager.allMessages.filter((elt) => !elt.isAnimating)
		} else messages = HiveObjects.nearMessages.filter((elt) => !elt.isAnimating)
		
		if (!messages.length) messages = Room.currentRoom.messages.filter((elt) => !elt.isAnimating)

		const random = Math.floor(Math.random() * messages.length)
		const message = messages[random]

		return message
	}
}
