import {
  Group,
  BoxHelper
} from 'three'

import VideoInterface from '../VideoInterface'
import Interface from '../Interface'
import config from '../../../settings/config'
import Resources from '../../../Resources'
import { degreesToRadians } from '../../../utils/rad-deg'
import Button from '../../Button'
import HistoryManager from '../../../managers/HistoryManager'
import Action from '../../actions/Action'
import USER from '../../../User'
import DOMManager from '../../../managers/DOMManager'

/**
 * Score is a VideoInterface showing all the night data, the night ranking and the new rank functionalities.
 * It is a BIG sequence of actions and videos.
 * @class
 */
export default class Score extends VideoInterface {
  constructor(props) {
    super(props)

    this.firstApproach = false
    this.distanceCheckLoop = false

    this.playVideo()
  }

  bindMethods() {
    super.bindMethods()

    this.onShareButtonClick = this.onShareButtonClick.bind(this)
    this.onNoshareBtnClick = this.onNoshareBtnClick.bind(this)
  }

  setSteps() {
    return [
      config.scenario.tutorial.steps.sleepData,
      config.scenario.chapter3.steps.wakeUpTomorrow,
      config.scenario.chapter3.steps.goToKitchen
    ]
  }

  setTextures() {
    this.sleepDataWait = Resources.textures.videos.sleepDataWait
    this.sleepDataIn = Resources.textures.videos.sleepDataIn
    this.sleepDataLoop = Resources.textures.videos.sleepDataLoop
    this.sleepDataOut = Resources.textures.videos.sleepDataOut
    this.rankIn = Resources.textures.videos.rankIn
    this.awards = Resources.textures.videos.awards
    this.rankOut = Resources.textures.videos.rankOut
    this.ch3SleepIn = Resources.textures.videos.ch3SleepIn
    this.ch3SleepLoop = Resources.textures.videos.ch3SleepLoop

    return [
      this.sleepDataWait,
      this.sleepDataIn,
      this.sleepDataLoop,
      this.sleepDataOut,
      this.rankIn,
      this.awards,
      this.rankOut,
      this.ch3SleepIn,
      this.ch3SleepLoop
    ]
  }

  onVideoEnded(video) {
    if (video === this.sleepDataIn) this.updateCurrentVideo(this.sleepDataLoop)
    else if (video === this.sleepDataLoop) this.updateCurrentVideo(this.sleepDataOut)
    else if (video === this.sleepDataOut) {
      if (USER.hasSharedSleepData) {
        this.updateCurrentVideo(this.rankIn)
        this.sounds.voices.newRank.play()

        setTimeout(() => {
          DOMManager.givePoints(300, true)
        }, 6250)
      } else {
        this.updateCurrentVideo(this.rankOut)

        HistoryManager.nextStep(config.scenario.tutorial.steps.leaveRoom)
      }
    } else if (video === this.ch3SleepIn) this.updateCurrentVideo(this.ch3SleepLoop)
  }

  nextDay() {
    this.day2 = true

    this.setCurrentVideo({
      video: this.ch3SleepIn,
      isFirst: true,
      replaceOld: true
    })
  }

  addButtons() {
    this.createShareButtons()
    this.createTrendChoiceButtons()

    this.hideButtons(this.shareButtons, false)
    this.hideButtons(this.trendButtons, false)
  }

  play() {
    super.playVideo()

    if (!this.day2) this.sounds.voices.sleepDataVoiceIn.play()
  }

  onApproach() {
    if (!this.firstApproach && !this.day2) {
      this.setCurrentVideo({
        video: this.sleepDataIn,
        isFirst: true,
        replaceOld: true
      })

      this.distanceCheckLoop = true

      DOMManager.goalAchieved()
      
      this.firstApproach = true
    }

    if (this.day2) this.distanceCheckLoop = true

    super.onApproach()
  }

  onSoundEnded(sound) {
    if (sound === this.sounds.voices.sleepDataVoiceIn) {
      this.showButtons(this.shareButtons)

      DOMManager.setNewGoal('Interagissez avec un bouton au mur, selon votre choix', 'bedroom')
    } else if (sound === this.sounds.voices.newRank) {
      this.showButtons(this.trendButtons, false)

      DOMManager.setNewGoal('Faites votre choix parmi les trois récompenses disponibles', 'bedroom')
    } else if (sound === this.sounds.voices.trendChoice) {
      this.updateCurrentVideo(this.rankOut)

      HistoryManager.nextStep(config.scenario.tutorial.steps.leaveRoom)
    }
  }

  /**
   * The first choice of this Interface.
   * User has to choose between 2 options using 2 Buttons.
   *
   * @returns {void}
   */
  createShareButtons() {
    const ouiAction = new Action({
      name: 'Partager',
      steps: [
        {
          name: config.scenario.tutorial.steps.sleepData,
          isLast: true,
          direction: config.scenario.tutorial.steps.share
        }
      ],
      interaction: this.onShareButtonClick
    })
    const nonAction = new Action({
      name: 'Ne pas partager',
      steps: [
        {
          name: config.scenario.tutorial.steps.sleepData,
          isLast: true,
          direction: config.scenario.tutorial.steps.noShare
        }
      ],
      interaction: this.onNoshareBtnClick
    })
    const ouiBtn = new Button({
      name: 'OUI',
      actions: [ouiAction]
    })
    const nonBtn = new Button({
      name: 'NON',
      actions: [nonAction],
      isSecond: true
    })

    nonBtn.mesh.position.z += 50

    // Create group for position
    this.shareButtonsGroup = new Group()
    this.shareButtonsGroup.rotation.z = degreesToRadians(90)
    this.shareButtonsGroup.position.set(290, 70, -27)
    this.shareButtonsGroup.add(ouiBtn.mesh, nonBtn.mesh)
    Resources.scene.add(this.shareButtonsGroup)

    // Add to interactive Objects
    Resources.rooms.bedroom.interactives.push(ouiBtn, nonBtn)

    // Add to instance buttons
    this.shareButtons = [ouiBtn, nonBtn]

    ouiBtn.updateWorldPosition()
    nonBtn.updateWorldPosition()

    this.buttonGroups.push(this.shareButtonsGroup)
    this.buttons.push(ouiBtn, nonBtn)
  }

  /**
   * The second choice of this Interface.
   * User has to choose between 3 options using 3 Buttons.
   *
   * @todo Use only one Action instance as the 3 Buttons have no implication on the next events.
   *
   * @returns {void}
   */
  createTrendChoiceButtons() {
    const choice1 = new Action({
      name: 'Spécialités japonaises',
      steps: [
        {
          name: config.scenario.tutorial.steps.share,
          isLast: false
        }
      ],
      interaction: this.onChoiceBtn.bind(this, 'spécialités japonaises')
    })
    const choice2 = new Action({
      name: 'Spécialités italiennes',
      steps: [
        {
          name: config.scenario.tutorial.steps.share,
          isLast: false
        }
      ],
      interaction: this.onChoiceBtn.bind(this, 'spécialités italiennes')
    })
    const choice3 = new Action({
      name: 'Spécialités françaises',
      steps: [
        {
          name: config.scenario.tutorial.steps.share,
          isLast: false
        }
      ],
      interaction: this.onChoiceBtn.bind(this, 'spécialités françaises')
    })

    const size = {
      x: 55,
      y: 1,
      z: 55
    }
    const choice1Button = new Button({
      name: 'Japon',
      isHidden: true,
      actions: [choice1],
      size
    })
    const choice2Button = new Button({
      name: 'Italie',
      isHidden: true,
      actions: [choice2],
      size
    })
    const choice3Button = new Button({
      name: 'France',
      isHidden: true,
      actions: [choice3],
      size
    })

    // Separe buttons
    this.trendMargin = 70

    choice1Button.mesh.position.z -= this.trendMargin
    choice3Button.mesh.position.z += this.trendMargin

    // Create group for position
    this.trendButtonsGroup = new Group()
    this.trendButtonsGroup.position.set(296, 85, -2.5)
    this.trendButtonsGroup.rotation.z = degreesToRadians(90)
    this.trendButtonsGroup.add(choice1Button.mesh, choice2Button.mesh, choice3Button.mesh)
    Resources.scene.add(this.trendButtonsGroup)

    // Add to interactive Objects
    Resources.rooms.bedroom.interactives.push(choice1Button, choice2Button, choice3Button)

    // Add to instance buttons
    this.trendButtons = [choice1Button, choice2Button, choice3Button]
    this.buttons.push(choice1Button, choice2Button, choice3Button)

    choice1Button.updateWorldPosition()
    choice2Button.updateWorldPosition()
    choice3Button.updateWorldPosition()

    this.buttonGroups.push(this.trendButtonsGroup)
  }

  /**
   * User just made its choice to share sleep data using one of the 2 Buttons.
   * Removes a goal from the UI.
   *
   * @todo Dispose and remove Button instances once a choice has been made.
   * @todo This is very similar to `this.onNoShareBtnClick`, maybe only one Action with a parameter should be prefered here.
   *
   * @returns {Promise} A Promise resolved when choice is saved and Buttons are hidden.
   */
  onShareButtonClick() {
    return new Promise((resolve) => {
      USER.hasSharedSleepData = true
      this.sleepDataLoop.loop = false
      this.hideButtons(this.shareButtons, true, true)

      DOMManager.newNotification({
        text: 'Vous venez de partager votre première nuit via Hive : 300 points bonus !',
        noSound: true
      })
      DOMManager.goalAchieved()

      resolve()
    })
  }

  /**
   * User just made its choice not to share sleep data using one of the 2 Buttons.
   * Removes a goal from the UI.
   *
   * @todo Dispose and remove Button instances once a choice has been made.
   * @todo This is very similar to `this.onShareButtonClick`, maybe only one Action with a parameter should be prefered here.
   *
   * @returns {Promise} A Promise resolved when choice is saved and Buttons are hidden.
   */
  onNoshareBtnClick() {
    return new Promise((resolve) => {
      USER.hasSharedSleepData = false
      this.sleepDataLoop.loop = false
      this.hideButtons(this.shareButtons, true, true)

      DOMManager.goalAchieved()

      resolve()
    })
  }

  /**
   * User just selected a trend using one of the 3 Buttons.
   * Changes current video and starts a new voice.
   * Removes a goal from the UI.
   *
   * @todo Dispose and remove Button instances once a choice has been made.
   *
   * @param {string} value The trend chosen by the player.
   *
   * @returns {Promise} A Promise resolved when choice is saved and Buttons are hidden.
   */
  onChoiceBtn(value) {
    return new Promise((resolve) => {
      USER.trend = value
      this.updateCurrentVideo(this.awards)
      this.hideButtons(this.trendButtons, true, true)

      setTimeout(() => {
        this.sounds.voices.trendChoice.play()
      }, 500)

      DOMManager.newNotification({
        text: 'Vous avez débloqué 3 ' + value + ' !',
        noSound: true
      })
      DOMManager.goalAchieved()

      resolve()
    })
  }
  
  startDebugMode() {
    super.startDebugMode()

    const h = new BoxHelper(this.shareButtonsGroup, 0xff0000)
    const h2 = new BoxHelper(this.trendButtonsGroup, 0xff0000)
    
    h.visible = false
    h2.visible = false

    this.helpers.push(h, h2)

    Resources.scene.add(h, h2)

    if (!Interface.GUIFolder) return

    const options = {
      interface: {
        position: {
          x: this.mesh.position.x,
          y: this.mesh.position.y,
          z: this.mesh.position.z
        }
      },
      buttons: {
        share: {
          x: this.shareButtonsGroup.position.x,
          y: this.shareButtonsGroup.position.y,
          z: this.shareButtonsGroup.position.z
        },
        trends: {
          x: this.trendButtonsGroup.position.x,
          y: this.trendButtonsGroup.position.y,
          z: this.trendButtonsGroup.position.z,
          margin: this.trendMargin
        }
      }
    }

    const sleepData = Interface.GUIFolder.addFolder(this.name)
    const shareButtonGroupPosition = sleepData.addFolder('Share buttons position')

    shareButtonGroupPosition.add(options.buttons.share, 'x', -500, 500, 1).onChange((value) => {
      this.shareButtonsGroup.position.x = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })
    shareButtonGroupPosition.add(options.buttons.share, 'y', -500, 500, 1).onChange((value) => {
      this.shareButtonsGroup.position.y = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })
    shareButtonGroupPosition.add(options.buttons.share, 'z', -500, 500, 1).onChange((value) => {
      this.shareButtonsGroup.position.z = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })

    const trendButtonGroupPosition = sleepData.addFolder('Trends buttons position')

    trendButtonGroupPosition.add(options.buttons.trends, 'x', -500, 500, 1).onChange((value) => {
      this.trendButtonsGroup.position.x = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })
    trendButtonGroupPosition.add(options.buttons.trends, 'y', -500, 500, 1).onChange((value) => {
      this.trendButtonsGroup.position.y = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })
    trendButtonGroupPosition.add(options.buttons.trends, 'z', -500, 500, 1).onChange((value) => {
      this.trendButtonsGroup.position.z = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })
    trendButtonGroupPosition.add(options.buttons.trends, 'margin', -500, 500, 1).onChange((value) => {
      this.trendButtons[0].mesh.position.z = value
      this.trendButtons[2].mesh.position.z = -value
      
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })

    const interfacePosition = sleepData.addFolder('Interface position')

    interfacePosition.add(options.interface.position, 'x', -500, 500, 1).onChange((value) => {
      this.mesh.position.x = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })
    interfacePosition.add(options.interface.position, 'y', -500, 500, 1).onChange((value) => {
      this.mesh.position.y = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })
    interfacePosition.add(options.interface.position, 'z', -500, 500, 1).onChange((value) => {
      this.mesh.position.z = value
      
      for (let i = 0; i < this.buttons.length; i++) {
        this.buttons[i].updateWorldPosition()
      }
    })
  }
}
