export default [
	{
		start: 0.05,
		end: 3.75,
		text: 'Très bon choix, je suis sûre que ce Hiver vous en sera reconnaissant.'
	},
	{
		start: 3.9,
		end: 7,
		text: 'Oh ! Votre action a reçu un commentaire.'
	}
]
