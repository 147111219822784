import HistoryManager from '../../managers/HistoryManager'
import CameraManager from '../../managers/CameraManager'

/**
 * An Action is used by Buttons and InteractiveObjects to enable user to do something by clicking on a Mesh.
 * @class
 */
export default class Action {

  /**
   * Creates an Action by checking the active steps among the given ones.
   *
   * @param {Object} param An object containing the information of this Action.
   * @param {string} param.name The Action's name, will be shown in the UI on hover.
   * @param {Array} param.steps An array of Steps used to check when this Action will be activated.
   * @param {function} param.interaction A callback used to make various things when a "click" event occurs on this actions `hiveObject`. MUST return a Promise.
   * @param {function} param.onInteractionComplete A callback fired when the interaction is completely over. MUST return a Promise.
   * @constructor
   */
  constructor({ name, steps = [], interaction = () => Promise.resolve(), onInteractionComplete = () => Promise.resolve() }) {
    this.bindMethods()

    this.name = name
    this.canInteract = false
    this.interaction = interaction
    this.onInteractionComplete = onInteractionComplete
    this.isAnimating = false
    this.steps = this.parseSteps(steps)
    this.hiveObject = null
  }

  /**
   * Binds class methods to itself to keep the right scope.
   *
   * @returns {void}
   */
  bindMethods() {
    this.startInteraction = this.startInteraction.bind(this)
    this.onInteractionEnd = this.onInteractionEnd.bind(this)
  }

  /**
   * Check among the given Steps the ones that are active.
   *
   * @param {Array} steps An array of Steps to check.
   *
   * @returns {boolean} Whether there is at least one active Step in the given array of Steps.
   */
  parseSteps(steps) {
    const defaultStep = {
      // Format like : 'chapterName_stepName'
      name: '',
      // Whether this Action will end the given Step
      isLast: false,
      // If isLast is true, direction can be set
      // to the targetted new Step to go to
      direction: null,
      // Whether the Action can be done multiple
      // times in the same Step.
      // Works only if `isLast` is `false`
      multiple: false
    }
    const activeSteps = []

    for (let i = 0; i < steps.length; i++) {
      const parsedStep = Object.assign({}, defaultStep, steps[i])

      if (parsedStep.isLast && parsedStep.multiple) parsedStep.multiple = false

      activeSteps.push(parsedStep)
    }

    return activeSteps
  }

  /**
   * Fired when a "click" event occurs on an active InteractiveObject.
   * Checks whether the next step has to be launched or not.
   * Fires the `interaction` callback given in the constructor.
   * Also fires the `onInteractionStart` callback in the attached InteractiveObject.
   * Then fires `this.onInteractionEnd`.
   *
   * @event
   * @returns {void}
   */
  startInteraction() {
    if (this.isAnimating || !this.canInteract || !this.checkActive()) return

    this.isAnimating = true

    const nextStepName = this.checkNextStep()

    this.hiveObject.onInteractionStart(this)

    this.interaction()
      .then(() => this.onInteractionEnd(nextStepName))
  }

  /**
   * Fired after the work related to the interaction is over.
   * Fires the `onInteractionComplete` callback given in the constructor.
   * Also fires the `onInteractionComplete` callback in the attached InteractiveObject.
   * Then goes to the next / specified step if `nextStep` is given.
   *
   * @param {(string|null)} nextStepName The name of the next step to go to. If not given, the current Step stays the same.
   *
   * @event
   * @returns {void}
   */
  onInteractionEnd(nextStepName) {
    this.isAnimating = false
    
    this.onInteractionComplete()
      .then(() => {
        this.hiveObject.onInteractionComplete(this)
        
        if (nextStepName) HistoryManager.nextStep(nextStepName)

        CameraManager.needsUpdate = true
      })

  }

  /**
   * Checks if this Action must trigger the next Step (or a specified one) on interaction.
   * Or just if this Action is optional and can be used multiple times.
   *
   * @returns {boolean} Whether the Step should change or not.
   */
  checkNextStep() {
    const currentStep = HistoryManager.currentChapter.currentStep
    let goToStep = false

    for (let i = 0; i < this.steps.length; i++) {
      const step = this.steps[i]

      if (step.name === currentStep.name) {
        if (step.isLast) {
          this.canInteract = false

          goToStep = step.direction || true
        } else if (!step.multiple) this.canInteract = false

        return goToStep
      }
    }

    return goToStep
  }

  /**
   * Checks if this Action is active during the current Step.
   *
   * @returns {boolean} Whether this Action is active or not.
   */
  checkActive() {
    const currentStep = HistoryManager.currentChapter.currentStep

    for (let i = 0; i < this.steps.length; i++) {
      if (this.steps[i].name === currentStep.name) return true
    }
    
    return false
  }

  /**
   * Same as `this.checkActive` but switches `this.canInteract` accordingly.
   * May be good to merge the two methods.
   *
   * @returns {boolean} `this.canInteract`.
   */
  checkCanInteract() {
    this.canInteract = this.checkActive()

    return this.canInteract
  }
}
