export default [
	{
		start: 0.05,
		end: 4.75,
		text: 'Inconnu : Fais le bon choix. Si tu ne m\'ouvres pas, Hive nous bloquera, et tout ceci pourrait recommencer...'
	},
	{
		start: 4.9,
		end: 8,
		text: 'Si tu m\'ouvres, nous serons toujours là pour t\'aider.'
	}
]
