import {
  Mesh,
  ShaderMaterial,
  BoxBufferGeometry,
  Box3,
  BoxHelper,
  MeshBasicMaterial,
  TextGeometry,
  Vector3
} from 'three'
import TweenMax from 'gsap/TweenMax'

import Resources from '../Resources'
import vertexShader from '../shaders/button/button.vert'
import fragmentShader from '../shaders/button/button.frag'
import { degreesToRadians } from '../utils/rad-deg'
import InteractiveObject from './interactives/InteractiveObject'
import config from '../settings/config'

/**
 * Buttons are a kind of InteractiveObject used to ask user to make a choice during the story.
 * They are usually attached to an Interface instance and placed along it.
 * @class
 */
export default class Button extends InteractiveObject {
  // eslint-disable-next-line object-property-newline
  constructor({ name, actions = [], isHidden = false, size = { x: 45, y: 1, z: 20 }, isSecond, fontSize = 5.5, onApproach = () => {}, onGetAway = () => {} } = {}) {
    if (!Button.textMaterial) Button.textMaterial = new MeshBasicMaterial({ color: 0xffffff })
    if (!Button.mat1) {
      Button.mat1 = new ShaderMaterial({
        uniforms: {
          u_opacity: { value: 1 },
          u_progress: { value: 0.5 }
        },
        vertexShader,
        fragmentShader
      })

      Button.mat2 = Button.mat1.clone()
    }

    // eslint-disable-next-line no-nested-ternary
    const material = isHidden ? Resources.materials.hidden : isSecond ? Button.mat2 : Button.mat1
    const mesh = new Mesh(Button.getGeometry(size), material)

    mesh.name = name

    super({
      mesh,
      name,
      noOverlay: true
    })

    this.isHidden = isHidden
    this.fontSize = fontSize

    this.mesh.visible = false

    this.onApproachCallback = onApproach
    this.onGetAwayCallback = onGetAway

    this.setup(actions)
  }


  /**
   * Finds a Geometry according to the given dimensions.
   *
   * @param {Object} size An object containing x, y, and z properties, used to determine the dimensions of the geometry.
   *
   * @returns {BoxBufferGeometry} The geometry.
   */
  static getGeometry(size) {
    if (!Button.geometries) Button.geometries = []

    for (let i = 0; i < Button.geometries.length; i++) {
       const geom = Button.geometries[i]

       if (geom.x === size.x && geom.y === size.y && geom.z === size.z) return geom.geometry
    }

    const geom = {
      x: size.x,
      y: size.y,
      z: size.z,
      geometry: new BoxBufferGeometry(size.x, size.y, size.z)
    }

    Button.geometries.push(geom)

    return geom.geometry
  }

  setup(actions) {
    this.name && this.createText()
    this.mesh.rotation.y = degreesToRadians(-90)
    this.addAction(...actions)
  }

  onApproach() {
    super.onApproach()

    this.onApproachCallback()
    !this.isHidden && this.updateButtonColor(1.8)
  }

  onGetAway() {
    super.onGetAway()

    this.onGetAwayCallback()
    !this.isHidden && this.updateButtonColor(0.5)
  }

  onStartLooking() {
    !this.isHidden && this.updateButtonColor(3)
    super.onStartLooking()
  }

  onStopLooking() {
    !this.isHidden && this.updateButtonColor(1.8)
    super.onStopLooking()
  }

  createText() {
    if (this.isHidden) return

    const textGeometry = new TextGeometry(this.name, {
      font: Resources.fonts.avantGardeBold,
      size: this.fontSize,
      height: 0.5,
      curveSegments: 12
    })

    this.textMesh = new Mesh(textGeometry, Button.textMaterial)

    this.updateTextPosition()

    this.mesh.add(this.textMesh)
  }

  updateTextPosition() {
    const textBox = new Box3().setFromObject(this.textMesh)
    const currentTextSize = new Vector3()

    textBox.getSize(currentTextSize)

    this.textMesh.position.set(-currentTextSize.x / 2, currentTextSize.z + 0.01, currentTextSize.y / 2)
    this.textMesh.rotation.x = degreesToRadians(-90)
  }

  updateButtonColor(value) {
    TweenMax.to(this.mesh.material.uniforms.u_progress, 0.5, {
      value,
      ease: Power2.easeInOut
    })
  }

  startDebugMode() {
    super.startDebugMode()

    if (this.isHidden) return

    const textBoxHelper = new BoxHelper(this.textMesh, 0x00ffff)

    textBoxHelper.visible = false

    this.helpers.push(textBoxHelper)

    Resources.scene.add(textBoxHelper)
  }

  updateWorldPosition() {
    super.updateWorldPosition()

    this.updateHelpers()
  }

  updateHelpers() {
    if (!config.debug) return

    for (let i = 0; i < this.helpers.length; i++) {
      this.helpers[i].update()
    }
  }

  /**
   * Destroys this Button by removing it and its Mesh from the Scene.
   *
   * @returns {void}
   */
  destroy() {
    if (!super.destroy()) return

    this.mesh.parent.remove(this.mesh)

    if (this.mesh.material.map) {
      const map = this.mesh.material.map

      this.mesh.material.map = null

      map.dispose()
    }

    this.mesh.material.dispose()
  }
}
