export default [
	{
		start: 0.1,
		end: 3,
		text: 'Voici quelques points Hive, pour avoir partagé une nuit pour la première fois.'
	},
	{
		start: 5.8,
		end: 8,
		text: 'Bravo ! Vous montez au rang Hive 6 !'
	},
	{
		start: 8.4,
		end: 10.35,
		text: 'Vous débloquez de nouveaux styles culinaires.'
	},
	{
		start: 10.45,
		end: 16,
		text: 'Choisissez votre préféré, et les recettes associées seront directement disponibles dans votre cuisine !'
	}
]
