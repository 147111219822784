/* eslint-disable object-property-newline */
import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager'
import USER from '../../User'
import HistoryManager from '../../managers/HistoryManager'
import AudioManager from '../../managers/AudioManager'
import MessagesManager from '../../managers/MessagesManager'

export default class CyberAttack extends Step {
	constructor() {
		super(config.scenario.chapter4.steps.cyberAttack)
	}

	/**
   * Starts the Step by playing background sound.
   *
   * @returns {Promise} A Promise resolved when Step has started.
   */
	onStart() {
		return new Promise((resolve) => {
			this.currentSoundIndex = 0
			this.getSounds()
			this.sounds[0].play()
			this.currentSoundIndex++

			resolve()
		})
	}


	getSounds() {
		this.sounds = [
			Resources.audios.voices.ch4ComeOn,
			Resources.audios.voices.ch4Continue,
			Resources.audios.voices.ch4Who,
			Resources.audios.voices.ch4TooMuch,
			Resources.audios.voices.ch4NearRenegade,
			Resources.audios.voices.ch4Useless,
			Resources.audios.voices.ch4NeverStop,
			Resources.audios.voices.ch4HurryUp,
			Resources.audios.voices.ch4YourFault
		]

		for (let i = 0; i < this.sounds.length; i++) {
			this.sounds[i].onEnd = this.onSoundEnded.bind(this)
		}
	}

	onSoundEnded(sound) {
		if (sound === this.sounds[0]) {
			DOMManager.setNewGoal('Lisez et supprimez les messages', 'livingroom')
			this.playMessages()
		} else if (sound === this.sounds[5]) this.playNextSound()
	}

	checkUserRank() {
		return USER.rank === 2
	}

	playMessages() {
		MessagesManager.popMessages({
			interval: 1400,
			number: 7,
			duration: 60,
			onlyGood: false,
			intervalChecking: this.checkUserRank.bind(this)
		})
		.then(this.playNextSound.bind(this))
		.then(MessagesManager.popMessages.bind(this, {
			interval: 1000,
			number: 5,
			duration: 40,
			onlyGood: false,
			intervalChecking: this.checkUserRank.bind(this),
			fullApartment: false
		}))
		.then(this.playNextSound.bind(this))
		.then(MessagesManager.popMessages.bind(this, {
			interval: 500,
			number: 40,
			duration: 40,
			onlyGood: false,
			intervalChecking: this.checkUserRank.bind(this),
			fullApartment: true,
			canHaveDelayedSound: true
		}))
		.then(this.playNextSound.bind(this))
		.then(() => { Resources.audios.effects.heart.play() })
		.then(MessagesManager.popMessages.bind(this, {
			interval: 400,
			number: 30,
			duration: 35,
			onlyGood: false,
			intervalChecking: this.checkUserRank.bind(this),
			fullApartment: false,
			canHaveDelayedSound: true
		}))
		.then(this.playNextSound.bind(this))
		.then(() => AudioManager.queueMusic(Resources.audios.ambiences.chapter4End))
		.then(MessagesManager.popMessages.bind(this, {
			interval: 250,
			number: 38,
			duration: 30,
			onlyGood: false,
			intervalChecking: this.checkUserRank.bind(this),
			fullApartment: false,
			canHaveDelayedSound: true
		}))
		.then(this.onMessagesEnd.bind(this))
	}

	playNextSound() {
		return new Promise((resolve) => {
			this.sounds[this.currentSoundIndex].play()
			this.currentSoundIndex++

			resolve()
		})
	}

	onMessagesEnd() {
		DOMManager.waitingPointsAnimations = []

		setTimeout(() => {
			if (USER.rank === 3) DOMManager.removePoints(10, true)

			DOMManager.goalAchieved()
			USER.unlockedNewBadge()
			HistoryManager.nextStep()
		}, 2000)
	}
}
