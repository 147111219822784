import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import WakeUpDay2 from '../../objects/interfaces/chapter3/WakeUpDay2'

export default class WakeUpTomorrow extends Step {
  constructor() {
    super(config.scenario.chapter3.steps.wakeUpTomorrow)
  }

  onStarted() {
    const interfaces = Resources.rooms.bedroom.interfaces

    for (let i = 0; i < interfaces.length; i++) {
      if (interfaces[i] instanceof WakeUpDay2) interfaces[i].onApproach()
    }
  }
}
