/* Interactives */
import Door from '../objects/interactives/Door'
import FrontDoor from '../objects/interactives/FrontDoor'
import ButtonDoor from '../objects/interactives/ButtonDoor'
import ButtonCache from '../objects/interactives/ButtonCache'

/* Interfaces */
import Weather from '../objects/interfaces/global/Weather'
import Score from '../objects/interfaces/chapter1/Score'
import Landscape from '../objects/interfaces/global/Landscape'
import Social from '../objects/interfaces/global/Social'
import WakeUp from '../objects/interfaces/chapter1/WakeUp'
import WakeUpDay2 from '../objects/interfaces/chapter3/WakeUpDay2'
import Messenger4 from '../objects/interfaces/chapter4/Messenger4'
import HiverOfTheDay from '../objects/interfaces/chapter2/HiverOfTheDay'
import Montage from '../objects/interfaces/chapter3/Montage'
import Dish from '../objects/interfaces/chapter3/Dish'
import Oven from '../objects/interfaces/chapter3/Oven'
import WakeUpMidnight from '../objects/interfaces/chapter5/WakeUpMidnight'
import ScoreMidnight from '../objects/interfaces/chapter5/ScoreMidnight'
import RenegadeKitchen from '../objects/interfaces/chapter4/RenegadeKitchen'
import RenegadeCorridor from '../objects/interfaces/chapter4/RenegadeCorridor'
import Score4 from '../objects/interfaces/chapter4/Score4'

/* Subtitles */
import wakeUpSub from '../subtitles/chapter1/wakeUp'
import scoreSub from '../subtitles/chapter1/score'
import newRankSub from '../subtitles/chapter1/newRank'
import trendChoiceSub from '../subtitles/chapter1/trendChoice'
import hiversOfTheDaySub1 from '../subtitles/chapter2/hiversOfTheDay1'
import hiversOfTheDaySub2 from '../subtitles/chapter2/hiversOfTheDay2'
import commentSub from '../subtitles/chapter2/comment'
import reportSub from '../subtitles/chapter2/report'
import helloDay2 from '../subtitles/chapter3/helloDay2'
import ch3Taste from '../subtitles/chapter3/taste'
import ch3Kitchen from '../subtitles/chapter3/kitchen'
import tagged from '../subtitles/chapter3/tagged'
import surprise from '../subtitles/chapter3/surprise'
import zimaMsg from '../subtitles/chapter3/zimaMsg'
import newMessages from '../subtitles/chapter3/newMessages'
import block from '../subtitles/chapter3/block'
import newMessage from '../subtitles/chapter3/newMessage'
import again from '../subtitles/chapter3/again'
import stress from '../subtitles/chapter3/stress'
import ch4Explain from '../subtitles/chapter4/explain'
import ch4ComeOn from '../subtitles/chapter4/comeon'
import ch4Continue from '../subtitles/chapter4/continue'
import ch4Who from '../subtitles/chapter4/who'
import ch4TooMuch from '../subtitles/chapter4/toomuch'
import ch4NearRenegade from '../subtitles/chapter4/nearrenegade'
import ch4Useless from '../subtitles/chapter4/useless'
import ch4NeverStop from '../subtitles/chapter4/neverstop'
import ch4HurryUp from '../subtitles/chapter4/hurryup'
import ch4YourFault from '../subtitles/chapter4/yourfault'
import ch4Renegade from '../subtitles/chapter4/renegade'
import ch4DontTalk from '../subtitles/chapter4/donttalk'
import ch5WakeUp from '../subtitles/chapter5/wakeup'
import ch5Detect from '../subtitles/chapter5/detect'
import whatHaveYouDone from '../subtitles/chapter5/whathaveyoudone'
import deservedIt from '../subtitles/chapter5/deservedit'
import ch5Savior1 from '../subtitles/chapter5/savior1'
import ch5HiveToSavior1 from '../subtitles/chapter5/hivetosavior1'
import ch5Savior2 from '../subtitles/chapter5/savior2'
import ch5HiveToSavior2 from '../subtitles/chapter5/hivetosavior2'
import ch5HiveRisk from '../subtitles/chapter5/hiverisk'
import ch5HiveListen from '../subtitles/chapter5/hivelisten'
import ch5SaviorDoGoodChoice from '../subtitles/chapter5/savior3'
import ch5SaviorFinal from '../subtitles/chapter5/savior4'
import baseline from '../subtitles/baseline'

/**
 * Configuration object linking various classes to key names.
 * The keys are the name of the mesh found into the glb model.
 * The values are the class to instanciate and attach to the mesh named by its key.
 *
 * For `subtitles`, the key is name of the Sound instance that needs to instanciate and attach a Subtitle instance to itself.
 */
const namings = {
	interactives: {
		door: Door,
		buttondoor: ButtonDoor,
		cache: ButtonCache,
		frontdoor: FrontDoor
	},
	interfaces: {
		landscape: {
			tutorial: Landscape,
			chapter2: Landscape,
			chapter3: Landscape,
			chapter4: Landscape,
			chapter5: Landscape
		},
		score: {
			tutorial: Score,
			chapter2: Score,
			chapter3: Score,
			chapter4: Score4,
			chapter5: ScoreMidnight
		},
		sharetest: {
			tutorial: Landscape,
			chapter2: Landscape,
			chapter3: Landscape,
			chapter4: Landscape,
			chapter5: Landscape
		},
		wakeup: {
			tutorial: WakeUp,
			chapter2: WakeUp,
			chapter3: WakeUpDay2,
			chapter4: WakeUpDay2,
			chapter5: WakeUpMidnight
		},
		weather: Weather,
		social: Social,
		messenger: {
			tutorial: HiverOfTheDay,
			chapter2: HiverOfTheDay,
			chapter3: Montage,
			chapter4: Messenger4,
			chapter5: Messenger4
		},
		dish: Dish,
		oven: Oven,
		renegat: RenegadeKitchen,
		renegat2: RenegadeCorridor
	},
	subtitles: {
		hello: wakeUpSub,
		sleepDataVoiceIn: scoreSub,
		newRank: newRankSub,
		trendChoice: trendChoiceSub,
		hiversOfTheDayComing: hiversOfTheDaySub1,
		hiversOfTheDayExplain: hiversOfTheDaySub2,
		hiversOfTheDayComment: commentSub,
		reportUser: reportSub,
		baseline,
		helloDay2,
		ch3Taste,
		ch3Kitchen,
		tagged,
		surprise,
		zimaMsg,
		newMessages,
		block,
		newMessage,
		again,
		stress,
		ch4Explain,
		ch4ComeOn,
		ch4Continue,
		ch4Who,
		ch4TooMuch,
		ch4NearRenegade,
		ch4Useless,
		ch4NeverStop,
		ch4HurryUp,
		ch4YourFault,
		ch4Renegade,
		ch4DontTalk,
		ch5WakeUp,
		ch5Detect,
		whatHaveYouDone,
		deservedIt,
		ch5Savior1,
		ch5HiveToSavior1,
		ch5Savior2,
		ch5HiveToSavior2,
		ch5HiveRisk,
		ch5HiveListen,
		ch5SaviorDoGoodChoice,
		ch5SaviorFinal
	}
}

export default namings
