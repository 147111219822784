import config from '../../settings/config'
import Resources from '../../Resources'
import Step from '../Step'
import DOMManager from '../../managers/DOMManager'
import MessagesManager from '../../managers/MessagesManager';

/**
 * This Step shows the user that something happened on a specific Interface.
 * @class
 */
export default class HiverOfTheDay extends Step {
  constructor() {
    super(config.scenario.chapter2.steps.hiverOfTheDay)
  }

  /**
   * Play a voice to interpellate the user.
   * Also moves lights towards the Interface near the bedroom's door.
   *
   * @returns {Promise} A Promise resolved when Step has started.
   */
  onStart() {
    return new Promise((resolve) => {
      DOMManager.newNotification({ text: 'La liste des Hivers du jour est arrivée !' })

      MessagesManager.popMessages({
        interval: 800,
        onlyGood: true,
        number: 2,
        duration: 40,
        indexes: [114, 116],
        indexesRoom: Resources.rooms.livingroom,
        specificMessages: [
          Resources.textures.imgs.messages.good.content['ch1-3'],
          Resources.textures.imgs.messages.good.content.always1
        ]
      })

      setTimeout(() => {
        Resources.audios.interfaces.messenger.voices.hiversOfTheDayComing.play()
      }, 1000)

      const interfaces = Resources.rooms.livingroom.interfaces

      for (let i = 0; i < interfaces.length; i++) {
        if (interfaces[i].ch2PlayLoop) interfaces[i].ch2PlayLoop()
      }

      setTimeout(() => {
        DOMManager.setNewGoal('Approchez-vous de l\'interface du salon, à côté de la porte de votre chambre', 'livingroom')
      }, 2000)

      resolve()
    })
  }
}
