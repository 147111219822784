import { Group } from 'three'

import VideoInterface from '../VideoInterface'
import Interface from '../Interface'
import Resources from '../../../Resources'
import config from '../../../settings/config'
import HiveObjects from '../../HiveObjects'
import Action from '../../actions/Action'
import { degreesToRadians, radiansToDegrees } from '../../../utils/rad-deg'
import Button from '../../Button'
import HistoryManager from '../../../managers/HistoryManager'
import DOMManager from '../../../managers/DOMManager'
import USER from '../../../User'

/**
 * HiverOfTheDay is a VideoInterface used in Chapter 2 to trigger the events of the story.
 * Zima appears and insults the user.
 * It is the only Interface useful to the story in Chapter 2.
 * @class
 */
export default class HiverOfTheDay extends VideoInterface {
  constructor(props) {
    super(props)

    this.defendChoice = null
    this.firstApproach = false
    this.distanceCheckLoop = false
    this.blockApproach = true
  }

  ch2PlayLoop() {
    this.playVideo()
  }

  setSteps() {
    return [config.scenario.chapter2.steps.hiverOfTheDay]
  }

  setTextures() {
    this.wait = Resources.textures.videos.wait
    this.hiverOfTheDayIn = Resources.textures.videos.hiverOfTheDayIn
    this.hiverOfTheDayOut = Resources.textures.videos.hiverOfTheDayOut
    this.commentIn = Resources.textures.videos.commentIn
    this.commentAnswer = Resources.textures.videos.commentAnswer
    this.commentAnswer2 = Resources.textures.videos.commentAnswer2
    this.commentAnswer3 = Resources.textures.videos.commentAnswer3
    this.commentOut = Resources.textures.videos.commentOut

    return [
      this.wait,
      this.hiverOfTheDayIn,
      this.hiverOfTheDayOut,
      this.commentIn,
      this.commentAnswer,
      this.commentAnswer2,
      this.commentAnswer3,
      this.commentOut
    ]
  }

  onApproach() {

    if (this.blockApproach) return

    if (!this.firstApproach) {
      this.wait.loop = false

      this.distanceCheckLoop = true

      DOMManager.goalAchieved()

      this.firstApproach = true
    }

    super.onApproach()
  }

  addButtons() {
    this.addHelpButtons()
    this.addCommentButtons()
    this.addReportButtons()

    this.hideButtons(this.helpButtons, false)
    this.hideButtons(this.commentButtons, false)
    this.hideButtons(this.reportButtons, false)
  }

  onVideoPlayed(video) {
    if (video === this.hiverOfTheDayIn) this.sounds.voices.hiversOfTheDayExplain.play()
    else if (video === this.hiverOfTheDayOut) {
      setTimeout(() => {
        this.sounds.voices.hiversOfTheDayComment.play()
      }, 2300)
    }
  }

  onVideoEnded(video) {
    if (video === this.wait) this.updateCurrentVideo(this.hiverOfTheDayIn)
    else if (video === this.hiverOfTheDayIn) this.showButtons(this.helpButtons)
    else if (video === this.hiverOfTheDayOut) {
      setTimeout(() => {
        this.updateCurrentVideo(this.commentIn)

        this.zimaSrc = document.querySelector('#zima').src

        DOMManager.newNotification({
          text: 'Nouveau commentaire sur votre activité récente !',
          img: this.zimaSrc
        })
      }, 1000)

      HistoryManager.nextStep()
    } else if (video === this.commentIn) this.showButtons(this.commentButtons)
    else if (video === this.commentAnswer) {
      setTimeout(() => {
        this.updateCurrentVideo(this.commentAnswer2)

        DOMManager.newNotification({
          text: 'Nouveau commentaire de Zima !',
          img: this.zimaSrc
        })
        
        HistoryManager.nextStep()
      }, 1000)
    } else if (video === this.commentAnswer2) {
      this.showButtons(this.reportButtons)
      USER.unlockedNewBadge()
    } else if (video === this.commentAnswer3) {
      setTimeout(() => {
        this.sounds.voices.reportUser.play()
      }, 1500)
    } else if (video === this.commentOut) {
      Resources.audios.voices.baseline.play()
      HistoryManager.nextStep()
    }
  }

  onSoundEnded(sound) {
    // If the "report" voice has just ended, finish the Step (and also the Chapter 2).
    if (sound === this.sounds.voices.hiversOfTheDayComing) this.blockApproach = false
    else if (sound === this.sounds.voices.reportUser) this.updateCurrentVideo(this.commentOut)
  }

  /**
   * Adds Buttons to choose whether to congratulate a high ranked Hiver or to help a Renegade Hiver.
   *
   * @returns  {void}
   */
  addHelpButtons() {
    const size = {
      x: 40,
      y: 1,
      z: 15
    }
    const support = new Action({
      name: 'Soutenir \n un renégat',
      steps: [
        {
          name: config.scenario.chapter2.steps.hiverOfTheDay,
          isLast: false
        }
      ],
      interaction: this.onChoiceBtn.bind(this, 'support')
    })
    const supportButton = new Button({
      name: 'Soutenir',
      actions: [support],
      fontSize: 4.5,
      size
    })

    const congratulate = new Action({
      name: 'Féliciter \nun Hiver',
      steps: [
        {
          name: config.scenario.chapter2.steps.hiverOfTheDay,
          isLast: false
        }
      ],
      interaction: this.onChoiceBtn.bind(this, 'congratulate')
    })
    const congratulateButton = new Button({
      name: 'Féliciter',
      actions: [congratulate],
      fontSize: 4.5,
      size,
      isSecond: true
    })

    // Separe buttons
    this.trendMargin = 25

    congratulateButton.mesh.position.z -= this.trendMargin
    supportButton.mesh.position.z += this.trendMargin

    // Create group for position
    this.helpButtonsGroup = new Group()
    this.helpButtonsGroup.rotation.reorder('YXZ')
    this.helpButtonsGroup.rotation.y = degreesToRadians(90)
    this.helpButtonsGroup.rotation.z = degreesToRadians(-90)
    this.helpButtonsGroup.rotation.x = degreesToRadians(180)
    this.helpButtonsGroup.add(supportButton.mesh, congratulateButton.mesh)
    this.helpButtonsGroup.position.set(450, 145, -215)
    this.helpButtonsGroup.name = 'envourageButtonsGroup'
    Resources.scene.add(this.helpButtonsGroup)

    // Add to interactive Objects
    Resources.rooms.livingroom.interactives.push(supportButton, congratulateButton)

    // Add to instance buttons
    this.helpButtons = [supportButton, congratulateButton]
    
    supportButton.updateWorldPosition()
    congratulateButton.updateWorldPosition()

    this.buttonGroups.push(this.helpButtonsGroup)
    this.buttons.push(supportButton, congratulateButton)
  }

  /**
   * Adds 2 Buttons used to allow the user to respond to Zima's comment.
   *
   * @returns {void}
   */
  addCommentButtons() {
    const defendMyself = new Action({
      name: '“Je fais ce que je veux, pour qui tu te prends ?”',
      steps: [
        {
          name: config.scenario.chapter2.steps.comment,
          isLast: false
        }
      ],
      interaction: this.onChoiceBtn.bind(this, 'defendMy')
    })
    const defendMyselfButton = new Button({
      name: 'Me défendre',
      actions: [defendMyself],
      fontSize: 4.5
    })

    const defendHimself = new Action({
      name: '“Il t\'a rien fait, il a juste pas de chance”',
      steps: [
        {
          name: config.scenario.chapter2.steps.comment,
          isLast: false
        }
      ],
      interaction: this.onChoiceBtn.bind(this, 'defendHim')
    })
    const defendHimselfButton = new Button({
      name: 'Le défendre',
      actions: [defendHimself],
      fontSize: 4.5,
      isSecond: true
    })

    // Separe buttons
    this.trendMargin = 30

    defendHimselfButton.mesh.position.z -= this.trendMargin
    defendMyselfButton.mesh.position.z += this.trendMargin

    // Create group for position
    this.commentButtonsGroup = new Group()
    this.commentButtonsGroup.rotation.reorder('YXZ')
    this.commentButtonsGroup.rotation.y = degreesToRadians(90)
    this.commentButtonsGroup.rotation.z = degreesToRadians(-90)
    this.commentButtonsGroup.rotation.x = degreesToRadians(180)
    this.commentButtonsGroup.add(defendMyselfButton.mesh, defendHimselfButton.mesh)
    this.commentButtonsGroup.name = 'commentButtonGroup'
    this.commentButtonsGroup.position.set(450, 130, -215)
    Resources.scene.add(this.commentButtonsGroup)

    // Add to interactive Objects
    Resources.rooms.livingroom.interactives.push(defendMyselfButton, defendHimselfButton)

    // Add to instance buttons
    this.commentButtons = [defendMyselfButton, defendHimselfButton]
    this.buttonGroups.push(this.commentButtonsGroup)

    defendMyselfButton.updateWorldPosition()
    defendHimselfButton.updateWorldPosition()

    this.buttons.push(defendMyselfButton, defendHimselfButton)
  }

  /**
   * Adds the last button used to answer to Zima and report him to Hive.
   *
   * @returns  {void}
   */
  addReportButtons() {
    const action = new Action({
      name: '“Pour qui tu te prends ? Je vais te signaler à Hive tout de suite”',
      steps: [
        {
          name: config.scenario.chapter2.steps.report,
          isLast: false
        }
      ],
      interaction: this.onChoiceBtn.bind(this, 'continue')
    })
    const button = new Button({
      name: 'Répondre',
      actions: [action],
      fontSize: 4.5
    })

    // Create group for position
    this.reportButtonsGroup = new Group()
    this.reportButtonsGroup.rotation.reorder('YXZ')
    this.reportButtonsGroup.rotation.y = degreesToRadians(90)
    this.reportButtonsGroup.rotation.z = degreesToRadians(-90)
    this.reportButtonsGroup.rotation.x = degreesToRadians(180)
    this.reportButtonsGroup.add(button.mesh)
    this.reportButtonsGroup.position.set(450, 90, -215)
    this.reportButtonsGroup.name = 'reportButtonsGroup'
    Resources.scene.add(this.reportButtonsGroup)

    // Add to interactive Objects
    HiveObjects.interactives.push(button)

    // Add to instance buttons
    this.reportButtons = [button]

    button.updateWorldPosition()

    this.buttonGroups.push(this.reportButtonsGroup)
    this.buttons.push(button)
  }

  /**
   * Fired when a "click" event occurs on one of this Interface's Buttons.
   * The corresponding choice is given as `choice` parameter.
   *
   * @param {string} choice The choice that has been made by the user clicking one of the Buttons.
   *
   * @returns {Promise} A Promise resolved when the animation related to the user's choice has ended.
   */
  onChoiceBtn(choice) {
    return new Promise((resolve) => {
      if (choice === 'congratulate' || choice === 'support') {
        this.hideButtons(this.helpButtons, true, true)
        this.updateCurrentVideo(this.hiverOfTheDayOut)

        DOMManager.newNotification({
          text: 'Vous avez encouragé Nicolas, descendu hier au rang de Renégat',
          img: document.querySelector('#nicolas').src,
          duration: 2600,
          noSound: true
        })
        
        setTimeout(() => {
          DOMManager.givePoints(300)
        }, 1500)
      } else if (choice === 'defendMy' || choice === 'defendHim') {
        this.defendChoice = choice
        this.hideButtons(this.commentButtons, true, true)
        this.updateCurrentVideo(this.commentAnswer)
      } else if (choice === 'continue') {
        this.hideButtons(this.reportButtons, true, true)
        this.updateCurrentVideo(this.commentAnswer3)
      }

      resolve()
    })
  }

  startDebugMode() {
    if (!Interface.GUIFolder) return

    const options = {
      buttons: {
        helpButtons: {
          x: this.helpButtonsGroup.position.x,
          y: this.helpButtonsGroup.position.y,
          z: this.helpButtonsGroup.position.z,
          rx: radiansToDegrees(this.helpButtonsGroup.rotation.x),
          ry: radiansToDegrees(this.helpButtonsGroup.rotation.y),
          rz: radiansToDegrees(this.helpButtonsGroup.rotation.z),
          margin: this.trendMargin
        }
      }
    }

    const messengerData = Interface.GUIFolder.addFolder(this.name)
    const helpButtonsGroupPosition = messengerData.addFolder('Encourage buttons position')

    helpButtonsGroupPosition.add(options.buttons.helpButtons, 'x', -500, 500, 1).onChange((value) => {
      this.helpButtonsGroup.position.x = value
    })
    helpButtonsGroupPosition.add(options.buttons.helpButtons, 'y', -500, 500, 1).onChange((value) => {
      this.helpButtonsGroup.position.y = value
    })
    helpButtonsGroupPosition.add(options.buttons.helpButtons, 'z', -500, 500, 1).onChange((value) => {
      this.helpButtonsGroup.position.z = value
    })

    helpButtonsGroupPosition.add(options.buttons.helpButtons, 'rx', -500, 500, 1).onChange((value) => {
      this.helpButtonsGroup.rotation.x = degreesToRadians(value)
    })
    helpButtonsGroupPosition.add(options.buttons.helpButtons, 'ry', -500, 500, 1).onChange((value) => {
      this.helpButtonsGroup.rotation.y = degreesToRadians(value)
    })
    helpButtonsGroupPosition.add(options.buttons.helpButtons, 'rz', -500, 500, 1).onChange((value) => {
      this.helpButtonsGroup.rotation.z = degreesToRadians(value)
    })
  }
}
