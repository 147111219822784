import { MeshBasicMaterial } from 'three'

import Interface from './Interface'

/**
 * A VideoInterface extends from Interface because it used just like an Interface but has a video.
 * @class
 */
export default class VideoInterface extends Interface {
    constructor(props) {
        super(props)

        this.isAwayAndPaused = false
        this.distanceCheckLoop = true
    }

    setMaterial() {
        this.mesh.material = new MeshBasicMaterial()
    }

    setFirstTexture() {
        this.setCurrentVideo({
            video: this.textures[0],
            isFirst: true
        })
    }

    /**
     * Sets a new current video.
     *
     * @param {Video} video The new video to set as current.
     *
     * @returns {void}
     */
    updateCurrentVideo(video) {
        this.setCurrentVideo({
            video,
            replaceOld: Boolean(this.currentVideo)
        })
    }

    /**
     * Sets the given video as the current one and play it.
     * If `isFirst` is `true`, the video will not play automatically.
     *
     * @param {Object} param The parameters for the new video.
     * @param {Video} param.video The Video to set as current.
     * @param {boolean} param.isFirst Whether this video is the first one to show in this Interface.
     * @param {boolean} param.replaceOld Whether to destroy the last video or not.
     *
     * @returns {void}
     */
    setCurrentVideo({ video, isFirst = false, replaceOld = false } = {}) {
        this.mesh.material.map = video.texture

        const oldVideo = this.currentVideo

        this.currentVideo = video

        if (!isFirst) this.playVideo()

        if (replaceOld) oldVideo.destroy()
    }

    onApproach() {
        super.onApproach()

        // Plays a positional sound on the Interface when camera is going near it the first time.
        // if (!this.sounds.approach.isPlaying && !this.isShow) this.sounds.approach.play()
        this.currentVideo && !this.currentVideo.hasPlayed && !this.currentVideo.isPlaying && this.play()

        if (this.currentVideo && this.currentVideo.loop && this.isAwayAndPaused && this.distanceCheckLoop) {
            this.isAwayAndPaused = false
            this.currentVideo.play()
        }
    }

    onGetAway() {
        super.onGetAway()

        if (this.currentVideo && this.currentVideo.isPlaying && this.currentVideo.loop && this.distanceCheckLoop) {
            this.isAwayAndPaused = true
            this.currentVideo.pause()
        }
    }

    play() {
        this.playVideo()
    }
    
    /**
     * Starts playing the current video.
     *
     * @returns {void}
     */
    playVideo() {
        this.currentVideo.play()
    }
}
