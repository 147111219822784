import {
  PerspectiveCamera,
  CameraHelper
} from 'three'

import MobileCameraControls from '../camera/MobileCameraControls'
import StandardCameraControls from '../camera/StandardCameraControls'
import {
  degreesToRadians,
  radiansToDegrees
} from '../utils/rad-deg'
import OrbitControls from '../utils/OrbitControls'
import config from '../settings/config'
import Resources from '../Resources'
import DOMManager from './DOMManager'

/**
 * CameraManager is used to select which type of controls will be used (Standard, VR, Nipples).
 * This class also can animate the camera (wake up animation for example).
 * @class
 */
class CameraManager {

  /**
   * Creates a camera and sets it to its default position.
   * This class has a static property which is a reference to the active camera in the game.
   * @constructor
   */
  constructor() {
    CameraManager.currentCamera = null
    CameraManager.defaultCamera = null
    CameraManager.controls = null
    CameraManager.needsUpdate = false
    this.controls = null
    this.debug = {}

    this.createCamera()
    CameraManager.setDefaultPosition()
  }

  /**
   * Creates the default camera that will be used in the game.
   * Properties are set from the config object.
   * The created camera is added to the scene.
   *
   * @returns {void}
   */
  createCamera() {
    this.defaultCamera = new PerspectiveCamera(
      config.cameras.fov,
      window.innerWidth / window.innerHeight,
      config.cameras.near,
      config.cameras.far
    )

    CameraManager.defaultCamera = this.defaultCamera
    CameraManager.currentCamera = this.defaultCamera

    Resources.scene.add(this.defaultCamera)
  }
  
  /**
   * Places the camera to its default position (from config object).
   *
   * @static
   * @returns {void}
   */
  static setDefaultPosition() {
    CameraManager.defaultCamera.position.copy(config.cameras.defaultPosition)
    CameraManager.rotateOn('y', config.cameras.defaultRotation.y)
    CameraManager.rotateOn('x', config.cameras.defaultRotation.x)
  }

  /**
   * Rotates the camera by a given angle around a given axis.
   *
   * @param {string} axis - 'x' or 'y', the axis to rotate the camera around.
   * @param {number} degrees - The angle in degrees the camera will be rotated around the given axis.
   *
   * @static
   * @returns {void}
   */
  static rotateOn(axis, degrees) {
    const radians = degreesToRadians(degrees)
    
    if (axis === 'y') CameraManager.defaultCamera.rotation.y = radians
    else if (axis === 'x') CameraManager.defaultCamera.rotation.x = radians
  }

  /**
   * Sets the camera immediately to its standing position (the position at the end of the wake up animation).
   * Used if the app is in debug mode.
   *
   * @param {boolean} otherSide - Whether the default position should be on the other side of the bed.
   * @param {boolean} noActivation - If `true`, the controls are not activated afterwards.
   *
   * @returns {void}
   */
  static setStandUpPosition(otherSide, noActivation) {
    CameraManager.defaultCamera.position.set(-85, config.user.height, otherSide ? 85 : -90)
    CameraManager.defaultCamera.rotation.x = degreesToRadians(-10)
    
    if (!noActivation) CameraManager.controls.activate()
  }

  /**
   * Sets the controls as MobileCameraControls.
   * Saves the control reference to a static property.
   *
   * @returns {void}
   */
  setMobileControls() {
    this.controls = new MobileCameraControls(this.defaultCamera)

    CameraManager.controls = this.controls
  }

  /**
   * Sets the controls as StandardCameraControls.
   * Saves the control reference to a static property.
   *
   * @returns {void}
   */
  setStandardControls() {
    this.controls = new StandardCameraControls(this.defaultCamera)

    this.controls.lock()

    CameraManager.controls = this.controls
    
    if (config.debug) {
      Resources.scene.add(this.debug.cameraHelper)
      this.controls.startDebugMode(this.debug.guiFolder)
    }
  }

  // Make the events go down in our structure.
  // These events only happens in Standard mode.
  onMouseMove(e) { this.controls.onMouseMove(e) }
  onMouseMoveDebounced() { this.controls.onMouseMoveDebounced() }
  onPointerlockChange() { this.controls.onPointerlockChange() }
  onPointerlockError(e) { this.controls.onPointerlockError(e) }
  onKeyDown(controlName) { this.controls.onKeyDown(controlName) }
  onKeyUp(controlName) { this.controls.onKeyUp(controlName) }

  // This event happens in both Standard and Mobile mode.
  onDomElementClick() { this.controls.onDomElementClick() }

  /**
   * Updates the CameraControls and the OrbitControls.
   * Called each frame by GameManager.
   *
   * @param {Object} timeStamp - Object containing the time and deltaTime properties.
   *
   * @returns {void}
   */
  update(timeStamp) {
    this.controls && this.controls.update(timeStamp)

    if (config.debug && this.debug.isDebugCamera) this.debug.controls.update()
  }

  /**
   * Resize event called by GameManager on window resize.
   *
   * @event
   * @returns {void}
   */
  resize() {
    this.defaultCamera.aspect = window.innerWidth / window.innerHeight
    this.defaultCamera.updateProjectionMatrix()

    config.debug && this.debug.cameraHelper.update()
  }

  /**
   * Reset the game camera to its default position (from config).
   * Static method so it can be used easily anywhere.
   *
   * @param {boolean} otherSide - Whether the default position should be on the other side of the bed.
   *
   * @static
   * @returns {void}
   */
  static resetDefaultCam(otherSide) {
    CameraManager.defaultCamera.position.set(-85, config.user.height, otherSide ? 65 : -90)
    CameraManager.defaultCamera.rotation.x = degreesToRadians(-10)
    CameraManager.defaultCamera.rotation.y = degreesToRadians(config.cameras.defaultRotation.y)
    CameraManager.defaultCamera.rotation.z = degreesToRadians(config.cameras.defaultRotation.z)
  }

  /**
   * Activates the camera controls.
   * Static method so it can be used easily anywhere.
   *
   * @static
   * @returns {void}
   */
  static activate() {
    CameraManager.controls.activate()
  }

  /**
   * Disactivates the camera controls.
   * Static method so it can be used easily anywhere.
   *
   * @static
   * @returns {void}
   */
  static disactivate() {
    CameraManager.controls.disactivate()
  }

  static pause(fromInterlude) {
    CameraManager.controls.disactivate()
    
    if (!fromInterlude && CameraManager.controls.unlock) CameraManager.controls.unlock()
  }

  static resume(fromInterlude) {
    if (fromInterlude) return

    CameraManager.controls.activate()

    if (CameraManager.controls.lock) CameraManager.controls.lock()
  }

  static setFinalPosition() {
    CameraManager.defaultCamera.position.set(700, config.user.height, 68)
  }

  /**
   * Starts debug mode by adding OrbitControls and starting debug keyboard events to toggle helpers.
   * Called by GameManager if config.debug is true.
   *
   * @returns {void}
   */
  startDebugMode() {
    const camera = new PerspectiveCamera(75, window.innerWidth / window.innerHeight, 10, 10000)

    camera.position.fromArray(config.cameras.debug.position)

    this.debug = {
      isDebugCamera: false,
      camera,
      guiFolder: null,
      controls: new OrbitControls(camera, Resources.canvas),
      cameraHelper: new CameraHelper(this.defaultCamera),
      onKeyDown: (e) => {
        // O key toggles Orbit camera
        if (e.code === config.controls.helpers.orbit) {
          if (this.debug.isDebugCamera) {
            // Set to game camera
            CameraManager.currentCamera = this.defaultCamera
            this.controls && this.controls.lock()
            DOMManager.ui.wrapper.classList.add('visible')
          } else {
            // Set to debug camera
            CameraManager.currentCamera = this.debug.camera
            this.controls && this.controls.unlock(true)
            DOMManager.ui.wrapper.classList.remove('visible')

            if (this.controls.inertia.head) this.controls.inertia.head.value.set(0, 0)
          }

          this.debug.isDebugCamera = !this.debug.isDebugCamera
          if (this.controls) this.controls.active = !this.controls.active
          this.debug.controls.enabled = !this.debug.controls.enabled
        }

        // C key toggles Camera Helpers
        if (e.code === config.controls.helpers.camera) this.debug.cameraHelper.visible = !this.debug.cameraHelper.visible
      }
    }

    this.debug.cameraHelper.visible = false
    this.debug.controls.enabled = false
    
    document.addEventListener('keydown', this.debug.onKeyDown)
    
    config.datGui && this.setDatGuiParams()
  }

  /**
   * Adds folder to the GUI.
   *
   * @returns {void}
   */
  setDatGuiParams() {
    this.debug.options = {
      fov: config.cameras.fov,
      near: config.cameras.near,
      far: config.cameras.far,
      positionX: this.defaultCamera.position.x,
      positionY: this.defaultCamera.position.y,
      positionZ: this.defaultCamera.position.z,
      rotationX: radiansToDegrees(this.defaultCamera.rotation.x),
      rotationY: radiansToDegrees(this.defaultCamera.rotation.y)
    }

    this.debug.guiFolder = config.datGui.addFolder('Camera Manager')

    this.debug.guiFolder.add(this.debug.options, 'fov', 15, 140)
      .onChange((value) => {
        this.defaultCamera.fov = value
        this.defaultCamera.updateProjectionMatrix()
        this.debug.cameraHelper.update()
      })
    this.debug.guiFolder.add(this.debug.options, 'near', 1, 100)
      .onChange((value) => {
        this.defaultCamera.near = value
        this.defaultCamera.updateProjectionMatrix()
        this.debug.cameraHelper.update()
      })
    this.debug.guiFolder.add(this.debug.options, 'far', 1000, 3000)
      .onChange((value) => {
        this.defaultCamera.far = value
        this.defaultCamera.updateProjectionMatrix()
        this.debug.cameraHelper.update()
      })
    this.debug.guiFolder.add(this.debug.options, 'positionX', -200, 200)
      .onChange((value) => {
        this.defaultCamera.position.x = value
      })
    this.debug.guiFolder.add(this.debug.options, 'positionY', -200, 200)
      .onChange((value) => {
        this.defaultCamera.position.y = value
      })
    this.debug.guiFolder.add(this.debug.options, 'positionZ', -200, 200)
      .onChange((value) => {
        this.defaultCamera.position.z = value
      })
    this.debug.guiFolder.add(this.debug.options, 'rotationX', -180, 180)
      .onChange((value) => {
        CameraManager.rotateOn('x', value)
      })
    this.debug.guiFolder.add(this.debug.options, 'rotationY', -180, 180)
      .onChange((value) => {
        CameraManager.rotateOn('y', value)
      })
  }
}

export default CameraManager
