export default [
	{
		start: 0.1,
		end: 2,
		text: 'Voici une sélection de Hivers remarquables...'
	},
	{
		start: 2.2,
		end: 5.55,
		text: '...ainsi que les derniers Hivers à être descendus au rang de Renégat.'
	},
	{
		start: 5.75,
		end: 10.3,
		text: 'A vous de faire une bonne action en choisissant de féliciter ou de soutenir l\'un d\'eux.'
	},
	{
		start: 10.35,
		end: 13,
		text: 'Vous gagnerez tous les deux des points !'
	}
]
