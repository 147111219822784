export default [
	{
		start: 0.05,
		end: 4.5,
		text: 'Inconnu : Nous pouvons t\'aider, ce n\'est pas de ta faute, tu n\'es pas seul.'
	},
	{
		start: 4.75,
		end: 8,
		text: 'Tu dois juste désactiver Hive pour m\'ouvrir la porte.'
	}
]
