/**
 * Configuration object defining all keyboard control keys by code.
 * Code property is used to be easily flexible between QWERTY and AZERTY keyboards.
 * `helpers` object contains the keys that are toggling various helpers into the scene.
 */
const controls = {
	forward: ['ArrowUp', 'KeyW'],
	right: ['ArrowRight', 'KeyD'],
	backward: ['ArrowDown', 'KeyS'],
	left: ['ArrowLeft', 'KeyA'],
	info: ['Space'],
	helpers: {
		camera: 'KeyC',
		orbit: 'KeyO',
		sounds: 'KeyQ',
		meshes: 'KeyH'
	}
}

export default controls
