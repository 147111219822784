import { EventDispatcher } from 'three'

import Step from './Step'
import config from '../settings/config'
import HiveObjects from '../objects/HiveObjects'

/**
 * This class manages the differents Steps of the story.
 * Heritates from EventDispatcher so it can fire an "end" event when its last Step has ended.
 * You can create a class extending this one if flexibility is wanted.
 * @class
 */
export default class Chapter extends EventDispatcher {

  /**
   * Creates a named Chapter containing Steps.
   * If `steps` is not given, `this.createSteps` will be called and the returned value will be saved as its Steps.
   * This is useful if you want to create Steps inside a sub-class inheriting from Chapter.
   *
   * @param {Object} param - Object containing Chapter's parameters.
   * @param {string} param.name - The Chapter's name.
   * @param {Array} param.steps - The Chapter's Step instances array, ordered chronologically.
   * @param {boolean} param.refreshInterfacesOnStart - By default, `HiveObjects.refreshInterfaces()` is called to refresh Interfaces when this Chapters starts.
   * @constructor
   */
  constructor({ name, steps, refreshInterfacesOnStart = true }) {
    super()

    this.name = name
    this.steps = []
    this.refreshOnStart = refreshInterfacesOnStart

    if (steps) this.steps = steps
    else this.steps = this.createSteps()

    this.currentStepIndex = 0
    this.currentStep = this.steps.length ? this.steps[this.currentStepIndex] : new Step({ name: 'empty' })
  }

  /**
   * Called by constructor if Steps array was not given.
   * Override this function when you create a sub-class inheriting from Chapter in order to create Steps from inside the class.
   *
   * @override
   * @returns {Array} An array of Steps.
   */
  createSteps() {
    return []
  }

  /**
   * Starts the Chapter then starts its first Step.
   *
   * @returns {Promise} A Promise resolved when the Chapter has been started and its first Step has also been started.
   */
  start() {
    return new Promise((resolve) => {
      config.debug && console.log('Chapter ' + this.name + ' : START')

      if (this.refreshOnStart) HiveObjects.refreshInterfaces()

      this.onStart()
        .then(() => this.currentStep.start())
        .then(resolve)
    })
  }

  /**
   * Start callback returning a Promise. Useful for animations.
   * Override it to adapt to every Chapter.
   *
   * @override
   * @returns {Promise} A Promise to resolve when you finished starting your Chapter.
   */
  onStart() { return Promise.resolve() }

  /**
   * Ends the Chapter then dispatch its "end" event.
   *
   * @returns {Promise} A Promise resolved when the Chapter has been ended and the "end" event has been fired.
   */
  end() {
    return new Promise((resolve) => {
      config.debug && console.log('Chapter ' + this.name + ' : END')

      this.onEnd()
        .then(() => {
          this.dispatchEvent({
            type: 'end',
            chapter: this
          })

          resolve()
        })
    })
  }

  /**
   * End callback returning a Promise. Useful for animations.
   * Override it to adapt to every Chapter.
   *
   * @override
   * @returns {Promise} A Promise to resolve when you finished ending your Chapter.
   */
  onEnd() { return Promise.resolve() }

  /**
   * Ends the current Step. Starts a new one by either going to the next or searching for a specific one if `stepName` is given.
   * If no more Steps are available, ends the Chapter.
   *
   * @param {string} stepName - The Step name to go directly to if not going to the next Step.
   *
   * @returns {Promise} A Promise resolved when the current Step has ended, and the next one has started. Or if the Chapter has ended.
   */
  nextStep(stepName) {
    return new Promise((resolve) => {
      // Ends current Step.
      this.currentStep.end()
        .then(() => {
          // If no more Steps, end the Chapter.
          // else, start the next Step.
          // OR go to the specified Step (stepName parameter) if it exists.
          if (this.currentStepIndex === this.steps.length - 1) {
            this.end()
              .then(resolve)
          } else {
            const directionSteps = this.steps.filter((step) => step.name === stepName)

            if (directionSteps.length) this.currentStepIndex = this.steps.indexOf(directionSteps[0])
            else this.currentStepIndex++
            
            this.currentStep = this.steps[this.currentStepIndex]
            
            this.currentStep.start()
              .then(resolve)
          }
        })
    })
  }
}
