import {
  // VideoTexture,
  Texture,
  LinearFilter,
  RGBFormat
} from 'three'

import VideoManager from '../managers/VideoManager'

/**
 * This class is used to manage a video element as an HTMLElement but also as a VideoTexture.
 * @class
 */
export default class Video {

  /**
   * Creates a VideoTexture from the given `video` and adds callbacks on this video.
   *
   * @param {Object} param - An object definig the parameters for the video.
   * @param {string} param.name - The video's name.
   * @param {HTMLElement} param.video - The video itself.
   * @constructor
   */
  constructor({ name, loop, video }) {
    this.name = name
    this.video = video
    this.loop = loop
    this.playedOnce = false
    this.texture = this.setTexture()
    this.hasPlayed = false
    this.destroyed = false
    this.interval = false
    
    this.videoPlayCallBack = () => {}
    this.videoEndedCallBack = () => {}

    this.bindMethods()
    this.addEvents()
  }

  /**
   * Binds class methods to itself to keep the right scope.
   *
   * @returns {void}
   */
  bindMethods() {
    this.onVideoPlay = this.onVideoPlay.bind(this)
    this.onVideoEnded = this.onVideoEnded.bind(this)
    this.updateTexture = this.updateTexture.bind(this)
  }

  /**
   * Adds the "play" and "ended" events on the video.
   *
   * @returns {void}
   */
  addEvents() {
    this.video.addEventListener('play', this.onVideoPlay)
    this.video.addEventListener('ended', this.onVideoEnded)
  }

  /**
   * Creates a VideoTexture for the video.
   *
   * @returns {VideoTexture} A VideoTexture instance created from `this.video`.
   */
  setTexture() {
    // VideoTexture.prototype.update = () => {}
    
    // const videoTexture = new VideoTexture(this.video)
    const videoTexture = new Texture(this.video)

    videoTexture.minFilter = LinearFilter
    videoTexture.maxFilter = LinearFilter
    videoTexture.format = RGBFormat
    videoTexture.generateMipmaps = false
    videoTexture.needsUpdate = true
    
    return videoTexture
  }

  /**
   * Removes "play" and "ended" event listeners from the video.
   * Disposes the texture.
   *
   * @returns {void}
   */
  destroy() {
    if (this.destroyed) return
    
    this.destroyed = true
    this.isPlaying = false
    this.hasPlayed = false
    this.playedOnce = false

    VideoManager.removePlayingVideo(this)
    this.video.pause()
    clearInterval(this.interval)

    this.interval = null
    
    this.video.removeEventListener('play', this.onVideoPlay)
    this.video.removeEventListener('ended', this.onVideoEnded)

    this.texture.dispose()
  }

  /**
   * Fired when a "play" event occurs on `this.video`.
   * Calls the `this.videoPlayCallBack` method with itself as parameter.
   *
   * @event
   * @returns {void}
   */
  onVideoPlay() {
    this.hasPlayed = true
    this.playedOnce = true
    this.videoPlayCallBack(this)
  }

  /**
   * Fired when a "ended" event occurs on `this.video`.
   * Calls the `this.videoEndedCallBack` method with itself as parameter.
   *
   * @todo Check if this is really useful to tell the video to play itself if it is already in loop mode. It should do it automatically.
   *
   * @event
   * @returns {void}
   */
  onVideoEnded() {
    if (this.loop) {
      this.video.play()
      
      return
    }

    VideoManager.removePlayingVideo(this)
    this.isPlaying = false
    clearInterval(this.interval)
    this.interval = null
    this.videoEndedCallBack(this)
  }

  pause() {
    this.video.pause()
    this.isPlaying = false

    clearInterval(this.interval)

    this.interval = null
  }

  resume() {
    this.video.play()
    this.isPlaying = true
    this.interval = setInterval(this.updateTexture, 40)
  }

  play() {
    if (this.destroyed) {
      this.destroyed = false
      this.addEvents()
    }

    VideoManager.addPlayingVideo(this)
    
    this.video.play()
    this.isPlaying = true
    this.interval = setInterval(this.updateTexture, 40)
  }

  updateTexture() {
    if (this.texture.image.readyState >= this.texture.image.HAVE_CURRENT_DATA) this.texture.needsUpdate = true
  }
}
