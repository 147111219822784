import VideoInterface from '../VideoInterface'
import DOMManager from '../../../managers/DOMManager'
import HistoryManager from '../../../managers/HistoryManager'
import config from '../../../settings/config'
import Resources from '../../../Resources'
import USER from '../../../User'

export default class ScoreMidnight extends VideoInterface {
    constructor(props) {
        super(props)

        this.distanceCheckLoop = false
        this.firstApproach = false

        this.playVideo()
    }

	setTextures() {
        this.sleepData = Resources.textures.videos.sleepDataMidnight
        this.publicationIn = Resources.textures.videos.publicationIn
        this.publicationComment = Resources.textures.videos.publicationComment
        this.publicationCommentLoop = Resources.textures.videos.publicationCommentLoop

		return [
            this.sleepData,
            this.publicationIn,
            this.publicationComment,
            this.publicationCommentLoop
        ]
    }

    setSteps() {
        return [config.scenario.chapter5.steps.identityTheft]
    }

	onApproach(remote) {
        if (remote) {
            if (!this.isShow) {
                this.sleepData.loop = false

                this.distanceCheckLoop = false

                DOMManager.goalAchieved()
            }

            super.onApproach()
        }

        // Le onApproach a déjà été appelé une fois par le Step
        if (!remote && !this.firstApproach) {
            this.sounds.voices.whatHaveYouDone.play()
            
            this.setCurrentVideo({
                video: this.publicationComment,
                isFirst: true,
                replaceOld: true
            })
            
            DOMManager.goalAchieved()

            this.firstApproach = true
            
            super.onApproach()
        }
	}

    onVideoEnded(video) {
        if (video === this.sleepData) this.updateCurrentVideo(this.publicationIn)
        else if (video === this.publicationComment) {
            this.setCurrentVideo({
                video: this.publicationCommentLoop,
                isFirst: true,
                replaceOld: true
            })
        }
    }

    onSoundEnded(sound) {
        if (sound === this.sounds.voices.whatHaveYouDone) {
            USER.unlockedNewBadge()
            DOMManager.removePoints(150)

            this.sounds.voices.deservedIt.play()
        } else if (sound === this.sounds.voices.deservedIt) {
            HistoryManager.nextStep()

            this.play()
        }
    }
}
