import { getGPUTier } from 'detect-gpu'
import { TimelineMax, Power2, TweenMax } from 'gsap'

import Resources from '../Resources'
import DOMManager from './DOMManager'
import App from '../App'
import AudioManager from './AudioManager'

export default class HomeManager {
    constructor() {
        this.qualityItems = []
        this.launchItems = []
        this.loadingItems = []
        this.bindMethods()
        this.getElems()
        this.detectGpu()
        this.addEvents()
    }

    bindMethods() {
        this.onSoundToolClick = this.onSoundToolClick.bind(this)
        this.onFullScreenToolClick = this.onFullScreenToolClick.bind(this)
        this.siteLoaded = this.siteLoaded.bind(this)
        this.onVideoEnd = this.onVideoEnd.bind(this)
        this.hideLauncher = this.hideLauncher.bind(this)
    }

    siteLoaded() {
        window.removeEventListener('siteloaded', this.siteLoaded)

        this.createVideoElement()
        
        const tl = new TimelineMax({
            onComplete: () => {
                this.loadingWrapper.classList.add('hidden')
                this.qualityWrapper.classList.remove('hidden')
                this.showQualitySection()
            }
        })

        tl.delay(1)
        tl.staggerTo(this.loadingItems, 0.6, {
            alpha: 0,
            y: 20,
            ease: Power2.easeIn
        }, 0.02)
    }

    createVideoElement() {
        this.motion = document.createElement('video')
        this.motion.src = Resources.textures.videos.motion.video.currentSrc
        this.motion.addEventListener('ended', this.onVideoEnd)
        this.motionWrapper.appendChild(this.motion)
        TweenMax.set(this.motion, { alpha: 0 })
    }

    onVideoEnd() {
        this.motion.pause()
        TweenMax.to([this.motionSkip, this.motion, this.wrapper], 0.8, {
            alpha: 0,
            ease: Power2.easeInOut,
            onComplete: () => {
                this.motionWrapper.classList.add('hidden')
                this.wrapper.classList.add('hidden')
                window.dispatchEvent(new Event('startmotionend'))
            }
        })
        
    }

    showQualitySection() {
        TweenMax.staggerTo(this.qualityItems, 0.6, {
            y: 0,
            alpha: 1,
            ease: Power2.easeOut
        })
    }

    getElems() {
        this.wrapper = document.querySelector('.start-screen')
        // VIDEO
        this.motionWrapper = document.querySelector('.startvideo')
        this.motionSkip = document.querySelector('.skip')

        // LOADING SECTION
        this.loadingWrapper = document.querySelector('.loading')
        this.loadingLogo = document.querySelector('.loading__logo')
        this.loadingText = document.querySelector('.loading__text')

        this.loadingItems.push(
            this.loadingLogo,
            this.loadingText
        )

        // QUALITY SECTION
        this.qualityWrapper = document.querySelector('.qualitySelector')
        this.qualityValues = document.querySelectorAll('.qualitySelector__items .item')
        this.qualityLogo = document.querySelector('.qualitySelector__logo')
        this.qualityTitle = document.querySelector('.qualitySelector__title')
        this.headphonesIcon = document.querySelector('.headphones__icon')
        this.headphonesText = document.querySelector('.headphones__text')

        this.qualityItems.push(this.qualityLogo, this.qualityTitle)
        for (let index = 0; index < this.qualityValues.length; index++) {
            this.qualityItems.push(this.qualityValues[index])
        }
        this.qualityItems.push(this.headphonesIcon, this.headphonesText)

        // LAUNCH SECTION
        this.launchWrapper = document.querySelector('.launchExperience')
        this.launchNetEcoute = document.querySelector('.launchExperience__button')
        this.launchLogo = document.querySelector('.content__logo')
        this.launchSubtitle = document.querySelector('.content__subtitle')
        this.launchFacebookBtn = document.querySelector('#facebook')
        this.launchStandardBtn = document.querySelector('#standard')
        this.soundTool = document.querySelector('#toolSound svg')
        this.fullScreenTool = document.querySelector('#toolFullScreen svg')
        this.shareTool = document.querySelector('#toolShare svg')
        this.goLandingButton = document.querySelector('.goLanding')
        this.launchAssoText = document.querySelector('.asso__text')
        this.lainchAssoLogos = document.querySelector('.asso__assos-logo')
        this.standardBtn = document.querySelector('#standard')
		this.facebookBtn = document.querySelector('#facebook')

        this.launchItems.push(
            this.launchNetEcoute,
            this.launchLogo,
            this.launchSubtitle,
            this.launchFacebookBtn,
            this.launchStandardBtn,
            this.soundTool,
            this.fullScreenTool,
            this.shareTool,
            this.goLandingButton,
            this.launchAssoText,
            this.lainchAssoLogos
        )

        TweenMax.set([this.launchItems, this.qualityItems], {
            alpha: 0,
            y: -20
        })
    }

    onButtonClick(type) {
        this.standardBtn.removeEventListener('click', this.standardClickCb)
        this.facebookBtn.removeEventListener('click', this.fbClickCb)

        DOMManager.buttonType = type
        if (type === 'facebook') {
            App.createFacebook(() => {
                this.hideLauncher()
            })
        } else {
            this.hideLauncher()
        }
    }

    hideLauncher() {
        this.soundTool.removeEventListener('click', this.onSoundToolClick)
        this.fullScreenTool.removeEventListener('click', this.onFullScreenToolClick)

        const tl = new TimelineMax({
            onComplete: () => {
                this.launchWrapper.classList.add('hidden')
                this.showMotion()
            }
        })

        tl.staggerTo(this.launchItems, 0.6, {
            y: 20,
            alpha: 0,
            ease: Power2.easeOut
        })
    }

    showMotion() {
        const tl = new TimelineMax()

        tl.to([this.motion, this.motionWrapper], 0.8, {
            alpha: 1,
            ease: Power2.easeInOut,
            onComplete: () => {
                this.motionWrapper.classList.remove('hidden')
                this.motion.play()
            }
        })
       tl.to(this.motionSkip, 0.8, {
            delay: 1,
            alpha: 2
        })
    }

    addEvents() {
        this.soundTool.addEventListener('click', this.onSoundToolClick)
        this.fullScreenTool.addEventListener('click', this.onFullScreenToolClick)

        this.qualityCbs = []
        
        for (let i = 0; i < this.qualityValues.length; i++) {
            this.qualityCbs.push(this.onQualityValueClick.bind(this, this.qualityValues[i].dataset.value))

            this.qualityValues[i].addEventListener('click', this.qualityCbs[i])
        }

        this.standardClickCb = this.onButtonClick.bind(this, 'standard')
        this.fbClickCb = this.onButtonClick.bind(this, 'facebook')

        this.standardBtn.addEventListener('click', this.standardClickCb)
        this.facebookBtn.addEventListener('click', this.fbClickCb)

        window.addEventListener('siteloaded', this.siteLoaded)

        this.motionSkip.addEventListener('click', this.onVideoEnd)
    }

    detectGpu() {
        this.GPUTier = getGPUTier({
            mobileBenchmarkPercentages: [10, 10, 80],
            desktopBenchmarkPercentages: [10, 10, 80]
        })

        if (this.GPUTier.tier === 'GPU_DESKTOP_TIER_0' || this.GPUTier.tier === 'GPU_MOBILE_TIER_0') this.qualityValues[0].classList.add('active')
        else if (this.GPUTier.tier === 'GPU_DESKTOP_TIER_1' || this.GPUTier.tier === 'GPU_MOBILE_TIER_1') this.qualityValues[1].classList.add('active')
        else if (this.GPUTier.tier === 'GPU_DESKTOP_TIER_2' || this.GPUTier.tier === 'GPU_MOBILE_TIER_2') this.qualityValues[2].classList.add('active')
    }

    onQualityValueClick() {
        for (let i = 0; i < this.qualityValues.length; i++) {
            this.qualityValues[i].removeEventListener('click', this.qualityCbs[i])
        }

        const tl = new TimelineMax({
            onComplete: () => {
                this.qualityWrapper.classList.add('hidden')
                this.launchWrapper.classList.remove('hidden')
                this.showLaunchExperience()
            }
        })

        tl.staggerTo(this.qualityItems, 0.6, {
            alpha: 0,
            y: 20,
            ease: Power2.easeIn
        }, 0.02)
    }

    showLaunchExperience() {
        const tl = new TimelineMax()

        tl.staggerTo(this.launchItems, 0.6, {
            alpha: 1,
            y: 0,
            ease: Power2.easeOut
        }, 0.02)
    }

    onSoundToolClick() {
        AudioManager.toggle()
    }

    onFullScreenToolClick() {
        if (document.fullscreenElement) document.exitFullscreen()
        else document.body.requestFullscreen()
    }
}
