import Nipple from './Nipple'

export default class MoveNipple extends Nipple {

    constructor(props) {
        super(props)

        this.oldDirection = null
        this.currentDirection = null
        this.directions = {
            forward: 'forward',
            backward: 'backward',
            left: 'left',
            right: 'right'
        }
        
        this.addEvents()
    }

    bindMethods() {
        this.onStart = this.onStart.bind(this)
        this.onDir = this.onDir.bind(this)
        this.onEnd = this.onEnd.bind(this)
    }

    addEvents() {
        this.manager.on('start', this.onStart)
        this.manager.on('end', this.onEnd)
        this.manager.on('dir:up', this.onDir.bind(this, this.directions.forward))
        this.manager.on('dir:down', this.onDir.bind(this, this.directions.backward))
        this.manager.on('dir:right', this.onDir.bind(this, this.directions.right))
        this.manager.on('dir:left', this.onDir.bind(this, this.directions.left))
    }

    onStart() {
        this.currentDirection = null
        this.onStartCallback()
    }

    onEnd() {
        this.oldDirection = this.currentDirection
        this.currentDirection = null
        this.onEndCallback()
    }

    onDir(direction) {
        this.oldDirection = this.currentDirection
        this.currentDirection = direction
        this.onDirCallback()
    }
}
