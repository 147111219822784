import DOMManager from './managers/DOMManager'

/**
 * Object containing user properties.
 * For example his name, his pictures, his Hive rank, and some choices he made over the game.
 * Pictures are either injected from his Facebook pictures or from our default pictures.
 */
const USER = {
  name: 'Vous',
  firstName: null,
  lastName: null,
  gender: null,
  birthday: null,
  id: null,
  pictures: [],
  profilePicture: null,
  hasSharedSleepData: null,
  trend: null,
  rank: 0,
  badges: 0,
  canOpenBadge: false,
  unlockedNewBadge: () => {
    USER.badges++

    const newBadge = USER.badges

    DOMManager.newBadgeNotif(USER.badges - 1)
      .then((hasOpened = false) => {
        USER.canOpenBadge = hasOpened

        setTimeout(() => {
          if (USER.canOpenBadge && USER.badges === newBadge) {
            USER.canOpenBadge = false

            DOMManager.hideBadgeNotif()
          }
        }, 12000)
      })
  }
}

export default USER
