import DOMManager from '../managers/DOMManager'

/**
 * Subtitles to be used in sync with an audio file.
 * Used for every Hive voice record.
 * @class
 */
export default class Subtitles {

    /**
     * This class is instanciated for every audio file that is a voice.
     * A reference to an instance is then attached to the corresponding Sound instance.
     *
     * @param {Array} subtitles - Array containing the sentences. Comes from the configuration object : `config.subtitles.myObject`.
     * @constructor
     */
    constructor(subtitles) {
        this.setCurrentSubtitle(subtitles)
    }

    /**
     * Called internally by the constructor.
     * Used to save sentences and prepare everything else.
     *
     * @param {Array} subtitles - Array containing the sentences.
     *
     * @returns {void}
     */
    setCurrentSubtitle(subtitles) {
        this.sentences = subtitles
        this.currentSubtitleElement = null
        this.currentIndex = 0
        this.setCurrentSubtitleElement()
    }

    /**
     * Changes the current sentence to display, according to `this.currentIndex`.
     *
     * @returns {void}
     */
    setCurrentSubtitleElement() {
        this.currentSubtitleElement = this.sentences[this.currentIndex]
        this.currentSubtitleElement.isStart = false
    }

    /**
     * Goes to the next sentence in the `sentences` array.
     * If there is no more sentence. Empty the current sentence.
     *
     * @returns {void}
     */
    increaseIndex() {
        this.currentIndex++

        if (this.currentIndex < this.sentences.length) this.setCurrentSubtitleElement()
        else {
            this.setText()
            this.currentSubtitleElement = null
        }
    }

    /**
     * Sets the given text into the DOM UI.
     *
     * @param {string} text - The text to set into the UI.
     *
     * @returns {void}
     */
    setText(text = '') {
        DOMManager.ui.subtitles.textContent = text
    }

    /**
     * Updates the current sentence according to the timecode given in parameter.
     * This is called every frame when the associated Sound instance is playing its Audio file.
     *
     * @param {number} soundCurrentTime - The timecode of the associated sound which is currently playing.
     *
     * @returns {void}
     */
    update(soundCurrentTime) {
        if (!this.currentSubtitleElement) return

        if (soundCurrentTime >= this.currentSubtitleElement.start && soundCurrentTime <= this.currentSubtitleElement.end && !this.currentSubtitleElement.isStart) {
            this.currentSubtitleElement.isStart = true

            this.setText(this.currentSubtitleElement.text)
        }

        if (soundCurrentTime > this.currentSubtitleElement.end) this.increaseIndex()
    }
}
