import config from '../../settings/config'
import Step from '../Step'
import Resources from '../../Resources'
import DOMManager from '../../managers/DOMManager'
import HistoryManager from '../../managers/HistoryManager'
import MessagesManager from '../../managers/MessagesManager'
import USER from '../../User'

export default class StartAttack extends Step {
	constructor() {
		super(config.scenario.chapter4.steps.startAttack)
	}

	/**
   * Starts the Step by playing background sound.
   *
   * @returns {Promise} A Promise resolved when Step has started.
   */
	onStart() {
		return new Promise((resolve) => {
			this.voice = Resources.audios.voices.ch4Explain
			this.voice.onEnd = this.onSoundEnded.bind(this)
			this.voice.play()

			USER.rank = 4
			DOMManager.removePoints(850, true)
			DOMManager.setUnreadMessages({ number: 890 })

			MessagesManager.popMessages({
				interval: 650,
				number: 8,
				duration: 35,
				onlyGood: false,
				indexes: [196, 193, 198, 142, 148, 202, 204, 153],
				indexesRoom: Resources.rooms.livingroom
			})

			MessagesManager.popMessages({
				interval: 875,
				number: 2,
				duration: 35,
				onlyGood: false,
				indexes: [68, 69],
				indexesRoom: Resources.rooms.kitchen
			})

			MessagesManager.popMessages({
				interval: 700,
				number: 2,
				duration: 35,
				onlyGood: false,
				indexes: [157, 161],
				indexesRoom: Resources.rooms.entrance
			})

			resolve()
		})
	}

	onSoundEnded(sound) {
		if (sound === this.voice) {
			DOMManager.setNewGoal('Dirigez-vous vers les messages en face du canapé', 'livingroom')

			HistoryManager.nextStep()
		}
	}
}
