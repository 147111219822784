import {
    Vector3,
    Object3D,
    Raycaster,
    Matrix4,
    Ray,
    Vector2
} from 'three'

import HiveObjects from '../objects/HiveObjects'
import config from '../settings/config'
import Inertia from '../utils/Inertia'
import Resources from '../Resources'
import DOMManager from '../managers/DOMManager'
import { radiansToDegrees } from '../utils/rad-deg'
import CameraManager from '../managers/CameraManager'

/**
 * CameraControls is the superclass to inherit when you want to control the game camera.
 * It will provide methods and properties to manage the camera state.
 * @class
 */
export default class CameraControls {

    /**
     * Creates the camera controls, raycasters, and helpers for collisions.
     * Creates inertia for position, yaw / pitch, and roll, separately.
     *
     * @param {Camera} camera - The camera that needs to have controls.
     * @constructor
     */
    constructor(camera) {
        this.bindMethods()

        // Options
        this.active = false
        this.isOnFocus = false
        this.collisionDistance = config.cameras.collisionDistance
        this.currentRoom = null
        this.oldRoom = null
        this.userOrientation = null
        this.posAdd = new Vector3()
        this.userPosition = new Vector3()

        this.PI_2 = Math.PI / 2

        this.moving = {
            forward: false,
            backward: false,
            left: false,
            right: false,
            pos: false,
            head: false
        }

        camera.rotation.reorder('YXZ')

        this.camera = camera

        // Raycast
        this.currentIntersectObject = null
        this.inverseMatrix = new Matrix4()
        this.ray = new Ray()

        // Outline params
        this.interactionObjectPosition = new Vector3()

        // Raycast
        this.flatCamPos = new Vector3(this.camera.position.x, 0, this.camera.position.z)
        this.raycaster = null

        this.initRaycaster()

        this.createInertia()
        this.createDirectionHelpers()
    }

    /**
     * Binds class methods to itself to keep the right scope.
     * Override it to bind your own methods.
     * Called by constructor.
     *
     * @override
     * @returns {void}
     */
    bindMethods() {}

    /**
     * Initializes the raycaster with rays in 8 directions (like a snow flake).
     *
     * @returns {void}
     */
    initRaycaster() {
        this.rays = [
            new Vector3(0, 0, 1),
            new Vector3(1, 0, 1),
            new Vector3(1, 0, 0),
            new Vector3(1, 0, -1),
            new Vector3(0, 0, -1),
            new Vector3(-1, 0, -1),
            new Vector3(-1, 0, 0),
            new Vector3(-1, 0, 1)
        ]

        this.raycaster = new Raycaster()
        this.raycaster.far = config.cameras.interactionDistance
    }

    /**
     * Creates Object3D that is used to determine lateral direction when moving.
     * This object is always at right angle from the Camera because it is added as a child.
     *
     * @returns {void}
     */
    createDirectionHelpers() {
        this.camera.rotation.set(0, 0, 0)

        this.direction = new Vector3()

        this.lateralDirection = new Vector3()
        this.lateralDirectionHelper = new Object3D()
        this.lateralDirectionHelper.rotation.y = -this.PI_2

        this.camera.add(this.lateralDirectionHelper)
    }

    /**
     * Creates inertia instances for the head (yaw / pitch), the position, and the roll.
     *
     * @returns {void}
     */
    createInertia() {
        this.inertia = {
            head: {
                engine: new Inertia({
                    min: new Vector2(-400, -400),
                    max: new Vector2(400, 400),
                    acceleration: config.cameras.standard.head.acceleration,
                    drag: config.cameras.standard.head.drag,
                    threshold: config.cameras.standard.head.threshold
                }),
                value: new Vector2(),
                processedValue: new Vector2()
            },
            roll: {
                engine: new Inertia({
                    min: -400,
                    max: 400,
                    acceleration: config.cameras.standard.roll.acceleration,
                    drag: config.cameras.standard.roll.drag,
                    threshold: config.cameras.standard.roll.threshold
                }),
                value: 0
            },
            position: {
                engine: new Inertia({
                    min: new Vector2(-config.cameras.speed, -config.cameras.speed),
                    max: new Vector2(config.cameras.speed, config.cameras.speed),
                    acceleration: config.cameras.standard.position.acceleration,
                    drag: config.cameras.standard.position.drag
                }),
                value: new Vector2(),
                processedValue: new Vector2()
            }
        }
    }

    /**
     * This method is fired when a `click` event occurs on the canvas / the mobile interaction zone.
     * Override it to add custom behavior.
     *
     * @override
     * @event
     * @returns {void}
     */
    onDomElementClick() {}
    
    /**
     * Activates the controls.
     * This method is usually overriden and called with `super.activate()` to be more flexible.
     *
     * @returns {void}
     */
    activate() {
        this.active = true
        this.resetInertia()
    }
    
    /**
     * Disactivates the controls.
     * This method is usually overriden and called with `super.disactivate()` to be more flexible.
     *
     * @returns {void}
     */
    disactivate() {
        this.resetInertia()
        this.active = false
    }

    /**
	 * Initiates the movement in the given direction by set inertia target value to `config.cameras.speed`.
	 * If `activate` is `false`, the speed value is set to 0.
	 *
	 * @param {string} controlName - 'forward', 'backward', 'right' or 'left'.
	 * @param {boolean} activate - Whether to turn on or off the movement in the given direction.
	 *
	 * @returns {void}
	 */
    setDirection(controlName, activate = false) {
		this.moving[controlName] = activate
		this.moving.pos = activate

		if (controlName === 'forward') this.inertia.position.value.y = activate ? config.cameras.speed : 0
		else if (controlName === 'right') this.inertia.position.value.x = activate ? -config.cameras.speed : 0
		else if (controlName === 'backward') this.inertia.position.value.y = activate ? -config.cameras.speed : 0
        else if (controlName === 'left') this.inertia.position.value.x = activate ? config.cameras.speed : 0
	}
    
    /**
     * Resets inertia to zero. This means no more camera movements at all.
     *
     * @returns {void}
     */
    resetInertia() {
		this.inertia.head.value.set(0, 0)
		this.inertia.head.engine.setValue(this.inertia.head.value)

		this.inertia.position.value.set(0, 0)
		this.inertia.position.engine.setValue(this.inertia.position.value)
		
		this.inertia.roll.value = 0
		this.inertia.roll.engine.setValue(this.inertia.roll.value)

        CameraManager.needsUpdate = true
	}

     /**
      * Updates the camera rotation / position.
      * Saves the camera position with `y = 0`.
      * Checks if objects are near.
      * Checks if interactions are available.
      * Called each frame by CameraManager.
      *
      * @param {Object} timeStamp - Object containing the time and deltaTime properties.
      *
      * @returns {void}
      */
    update(timeStamp) {
        if (!this.isDebugCamera && (!this.isOnFocus || !this.active)) return

        // Save camera position without Y
        this.flatCamPos.x = this.camera.position.x
        this.flatCamPos.z = this.camera.position.z

        this.updateHead()
        this.updatePosition()
        this.checkRoomPosition()
        this.checkInteraction()
        this.checkDistance(CameraManager.needsUpdate)

        // Force distance check the frame after activation
        if (CameraManager.needsUpdate) CameraManager.needsUpdate = false
    }
    
    /**
	 * Updates the user Normalized position.
     * Updates map cursor.
	 *
	 * @returns {void}
	 */
    checkRoomPosition() {
        this.userPosition.copy(this.camera.position)

        const globalPosition = config.model.positions.global
        const bedRoomPosition = config.model.positions.bedroom
        const livingRoomPosition = config.model.positions.livingroom
        const kitchenPosition = config.model.positions.kitchen
        const entrancePosition = config.model.positions.entrance

        this.oldRoom = this.currentRoom

        if (
            this.userPosition.x > bedRoomPosition.xMin &&
            this.userPosition.x < bedRoomPosition.xMax &&
            this.userPosition.z > bedRoomPosition.zMin &&
            this.userPosition.z < bedRoomPosition.zMax
        ) this.currentRoom = Resources.rooms.bedroom
        else if (
            this.userPosition.x > livingRoomPosition.xMin &&
            this.userPosition.x < livingRoomPosition.xMax &&
            this.userPosition.z > livingRoomPosition.zMin &&
            this.userPosition.z < livingRoomPosition.zMax
        ) this.currentRoom = Resources.rooms.livingroom
        else if (
            this.userPosition.x > kitchenPosition.xMin &&
            this.userPosition.x < kitchenPosition.xMax &&
            this.userPosition.z > kitchenPosition.zMin &&
            this.userPosition.z < kitchenPosition.zMax
        ) this.currentRoom = Resources.rooms.kitchen
        else if (this.userPosition.x > entrancePosition.xMin &&
            this.userPosition.x < entrancePosition.xMax &&
            this.userPosition.z > entrancePosition.zMin &&
            this.userPosition.z < entrancePosition.zMax
        ) this.currentRoom = Resources.rooms.entrance

        // Normalize position.
        const xNorm = Number(((this.userPosition.x - globalPosition.xMin) / (globalPosition.xMax - globalPosition.xMin)).toFixed(3))
        const yNorm = Number(((this.userPosition.z - globalPosition.zMin) / (globalPosition.zMax - globalPosition.zMin)).toFixed(3))
        const rotation = Number(radiansToDegrees(this.camera.rotation.y).toFixed(0))

        DOMManager.moveMapCursorTo(xNorm, yNorm, rotation)

        if (this.oldRoom !== this.currentRoom) {
            this.oldRoom !== null && this.oldRoom.onLeave()
            this.currentRoom.onEnter()
        }
    }

    /**
	 * Updates the camera rotation with inertia.
	 * Yaw and pitch have the same inertias.
	 * Roll has a different inertia as it is just a subtle rotation effect on lateral movements.
	 * Called each frame automatically if the game is in focus and the controls are active.
	 *
	 * @returns {void}
	 */
    updateHead() {
		this.inertia.head.processedValue.copy(this.inertia.head.engine.update(this.inertia.head.value))

        // Yaw
		this.camera.rotation.y -= this.inertia.head.processedValue.x * config.cameras.standard.mouseSensitivity

		// Pitch
        const rotX = this.camera.rotation.x - this.inertia.head.processedValue.y * config.cameras.standard.mouseSensitivity

		this.camera.rotation.x = Math.max(-this.PI_2, Math.min(this.PI_2, rotX))

        // Roll
        const roll = this.inertia.roll.engine.update(this.inertia.roll.value)

        this.camera.rotation.z = roll * -config.cameras.standard.rollSensitivity
	}

    /**
	 * Updates the camera position with inertia.
	 * Checks for direction, checks for collisions, then sets the new position to the camera.
	 * Called each frame automatically if the game is in focus and the controls are active.
	 *
	 * @returns {void}
	 */
	updatePosition() {
		this.inertia.position.processedValue.copy(this.inertia.position.engine.update(this.inertia.position.value))

        this.posAdd.set(0, 0, 0)

		if (!(this.moving.forward && this.moving.backward) && this.inertia.position.processedValue.y !== 0) {
            this.camera.getWorldDirection(this.direction)
            
			this.posAdd.add(this.direction.multiplyScalar(this.inertia.position.processedValue.y * 0.01))
		}

		if (!(this.moving.left && this.moving.right) && this.inertia.position.processedValue.x !== 0) {
			this.lateralDirectionHelper.getWorldDirection(this.lateralDirection)

			this.posAdd.add(this.lateralDirection.multiplyScalar(this.inertia.position.processedValue.x * 0.01))
		}

        this.posAdd.y = 0

        this.checkCollision(this.posAdd)

        this.camera.position.add(this.posAdd)
	}

    /**
     * Takes the given vector as direction.
     * Raycasts to every collider to check collision.
     * Returns a new direction modified by eventual collisions.
     *
     * @todo Check only front side when moving forward, etc. Not all sides at the same time.
     * @note Maybe not possible, due to camera rotation.
     *
     * @param {Vector3} direction - A vector determining the direction that the camera wants to take.
     *
     * @returns {Vector3} - The given direction modified by collisions.
     */
    checkCollision(direction) {
        const obstacles = HiveObjects.colliderMeshes

        for (let i = 0; i < this.rays.length; i++) {
            this.raycaster.set(this.flatCamPos, this.rays[i])
            this.raycaster.firstHitOnly = true

            const collisions = this.raycaster.intersectObjects(obstacles)

            if (collisions.length > 0 && collisions[0].distance <= this.collisionDistance) {
                if ((i === 0 || i === 1 || i === 7) && direction.z > 0) direction.setZ(0)
                else if ((i === 3 || i === 4 || i === 5) && direction.z < 0) direction.setZ(0)

                if ((i === 1 || i === 2 || i === 3) && direction.x > 0) direction.setX(0)
                else if ((i === 5 || i === 6 || i === 7) && direction.x < 0) direction.setX(0)
            }
        }

        return direction
    }

    /**
     * Raycasts from camera to check if the center of the screen is currently intersecting any interactive object.
     *
     * @todo Only call this function when player is moving head OR moving position.
     * @note If return when not moving head or position, bug if hovering an interactive.
     *
     * @returns {void}
     */
    checkInteraction() {
        this.raycaster.setFromCamera({
            x: 0,
            y: 0
        }, this.camera)

        const objectsIntersected = this.raycaster.intersectObjects(HiveObjects.activeInteractiveMeshes)

        if (objectsIntersected.length > 0) {
            const objectIntersected = objectsIntersected[0]
            const objectInstance = objectIntersected.object.userData.isInteractiveChild ? objectIntersected.object.parent.userData.instance : objectIntersected.object.userData.instance

            if (!objectInstance.isNear) return

            if (!this.currentIntersectObject) this.currentIntersectObject = objectInstance
            else if (this.currentIntersectObject.name !== objectInstance.name) {
                this.currentIntersectObject.onStopLooking()
                this.currentIntersectObject = objectInstance
            }

            this.currentIntersectObject.canInteract && !this.currentIntersectObject.isLooking && this.currentIntersectObject.onStartLooking()
        } else if (this.currentIntersectObject) {
            this.currentIntersectObject.onStopLooking()
            this.currentIntersectObject = null
        }
    }

    /**
     * Checks the distance between the camera and the Interactives / Interfaces.
     *
     * @param {boolean} force - Whether to force the distance checking even if the user is not moving.
     *
     * @returns {void}
     */
    checkDistance(force) {
        if (!this.moving.pos && !force) return

        this.distanceFromObjects(HiveObjects.activeInteractives)
        this.distanceFromObjects(HiveObjects.activeInterfaces, true)
    }

    /**
     * Checks the distance between the camera and an array of objects.
     * Calls corresponding callbacks if needed.
     *
     * @param {Array} objects - An array containing Interactive instances to check distance with the camera.
     * @param {boolean} isInterface - Whether the current array is containing Interfaces instead of InteractiveObjects.
     *
     * @returns {void}
     */
    distanceFromObjects(objects, isInterface = false) {
        for (let i = 0; i < objects.length; i++) {
            // Get current Object
            const hiveObject = objects[i]

            if (hiveObject.canInteract) {
                this.interactionObjectPosition.copy(hiveObject.mesh.userData.worldPosition)

                this.interactionObjectPosition.y = 0

                // Check distance between object position and rig position
                if (this.interactionObjectPosition.distanceTo(this.flatCamPos) <= config.cameras.interactionDistance) this.approachObject(hiveObject, isInterface)
                else this.getAwayFromObject(hiveObject, isInterface)
            } else this.getAwayFromObject(hiveObject, isInterface)
        }
    }

    /**
     * This is automatically called when a new object is currently near enough to interact with.
     * Automatically calls the `hiveObject's` callback `onApproach()` and add it to static array `nearInteractives` if it is not an Interface.
     *
     * @param {Interactive} hiveObject - The Interactive instance (Interface or InteractiveObject) that is currently near.
     * @param {boolean} isInterface - Whether `hiveObject` is an Interface or not.
     *
     * @returns {void}
     */
    approachObject(hiveObject, isInterface = false) {
        if (hiveObject.isNear) return

        hiveObject.onApproach()
        !isInterface && hiveObject.canInteract && HiveObjects.nearInteractives.push(hiveObject)
    }

    /**
     * This is automatically called when a new object is not near enough to interact with anymore.
     * Automatically calls the `hiveObject's` callback `onGetAway()` and remove it from the static array `nearInteractives` if it is not an Interface.
     *
     * @param {Interactive} hiveObject - The Interactive instance (Interface or InteractiveObject) that is currently near.
     * @param {boolean} isInterface - Whether `hiveObject` is an Interface or not.
     *
     * @returns {void}
     */
    getAwayFromObject(hiveObject, isInterface = false) {
        if (!hiveObject.isNear) return

        if (this.currentIntersectObject && hiveObject.name === this.currentIntersectObject.name && !isInterface) {
            this.currentIntersectObject.onStopLooking()
            this.currentIntersectObject = null
        }

        hiveObject.onGetAway()

        if (isInterface) return

        const objectIndex = HiveObjects.nearInteractives.indexOf(hiveObject)

        HiveObjects.nearInteractives.splice(objectIndex, 1)

        if (!hiveObject.canInteract) HiveObjects.removeActiveInteractive(hiveObject)
    }
    
    /**
     * Starts debug mode.
     * Called by CameraManager if config.debug is true when the game starts.
     * Override it to add custom behavior on debug mode.
     *
	 * @param {Object} parentGUIFolder - The GUI folder to add inner folders to.
     *
     * @override
     * @returns {void}
     */
    startDebugMode(parentGUIFolder) {}
}
