export default [
	{
		start: 0.05,
		end: 1.8,
		text: 'Prêt pour une nouvelle journée, Hiver ?'
	},
	{
		start: 2.5,
		end: 5.5,
		text: 'Je vous ai listé une sélection de ces spécialités italiennes que vous aimez tant.'
	},
	{
		start: 6.2,
		end: 8,
		text: 'Rendez-vous dans la cuisine !'
	}
]
