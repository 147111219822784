import Chapter from '../Chapter'
import DOMManager from '../../managers/DOMManager'
import config from '../../settings/config'
import PresentSituation from './PresentSituation'
import VoiceHack from './VoiceHack'
import GoToDoor from './GoToDoor'
import LiftButton from './LiftButton'
import PushButton from './PushButton'
import EndScene from './EndScene'
import CameraManager from '../../managers/CameraManager'
import AudioManager from '../../managers/AudioManager'
import Resources from '../../Resources'
import USER from '../../User'
import Step from '../Step'
import Door from '../../objects/interactives/Door'
import MessagesManager from '../../managers/MessagesManager';
import HiveObjects from '../../objects/HiveObjects';

/**
 * The Chapter 5 ends the story by introducing a mysterious character.
 * @class
 */
export default class Chapter5 extends Chapter {
	constructor(props = {}) {
		super({
			name: config.scenario.chapter5.name,
			steps: props.steps,
			refreshInterfacesOnStart: props.refreshInterfacesOnStart
		})
	}

	createSteps() {
		return [
			// Changer le placement des objets dans l'appartement
			new PresentSituation(),
			new Step(config.scenario.chapter5.steps.identityTheft),
			new VoiceHack(),
			new GoToDoor(),
			new LiftButton(),
			// Apparition d'un message Texte :
			// "Si vous appuyez sur le bouton, ... ; Si vous refermez le bouton, ..."
			new PushButton(),
			// Video 'Apparition Silouhete & lumière éclatante'
			new EndScene()
		]
	}

	/**
	 * Starts the Chapter by adding contextual text and fading in the black layer (as it has been faded out at the end of the last Chapter).
	 *
	 * @returns {Promise} A Promise resolved when the animations are over.
	 */
	onStart() {
		CameraManager.setStandUpPosition()

		CameraManager.controls.camera.rotation.set(0, 0, 0)

		MessagesManager.popMessages({
			interval: 750,
			number: 6,
			duration: 50,
			onlyGood: false,
			specificMessages: [
				Resources.textures.imgs.messages.bad.content['msg-mdp1'],
				Resources.textures.imgs.messages.bad.content['msg-mdp2'],
				Resources.textures.imgs.messages.bad.content['msg-mdp3'],
				Resources.textures.imgs.messages.bad.content['msg-mdp4'],
				Resources.textures.imgs.messages.bad.content['msg-mdp5'],
				Resources.textures.imgs.messages.bad.content['msg-mdp6']
			],
			indexes: [38, 34, 35, 36, 37, 43],
			indexesRoom: Resources.rooms.bedroom
		})

		HiveObjects.setNightBaking()

		DOMManager.ui.ambience.classList.add('chap5')

		USER.rank = 2
		DOMManager.removePoints(452, true)
		DOMManager.setUnreadMessages({ number: 1019 })

		AudioManager.playMusic({
			intro: Resources.audios.ambiences.chapter5Intro,
			loop: Resources.audios.ambiences.chapter5Loop
		})

		const interactives = Resources.rooms.bedroom.interactives

		for (let i = 0; i < interactives.length; i++) {
			if (interactives[i] instanceof Door && interactives[i].isOpen) interactives[i].toggleDoor()
		}

		return DOMManager.leaveInterlude(4)
	}

	/**
	 * Ends this Chapter by fading out the screen until its black, by using DOMManager after a given delay.
	 *
	 * @returns {Promise} A Promise resolved when the DOMManager finished the animation.
	 */
	onEnd() {
		return DOMManager.interlude(5, 0)
	}
}
