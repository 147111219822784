/**
 * @author Don McCurdy / https://www.donmccurdy.com
 * @author Austin Eng / https://github.com/austinEng
 * @author Shrek Shao / https://github.com/shrekshao
 */

/**
 * Loader for Basis Universal GPU Texture Codec.
 *
 * Basis Universal is a "supercompressed" GPU texture and texture video
 * compression system that outputs a highly compressed intermediate file format
 * (.basis) that can be quickly transcoded to a wide variety of GPU texture
 * compression formats.
 *
 * This loader parallelizes the transcoding process across a configurable number
 * of web workers, before transferring the transcoded compressed texture back
 * to the main thread.
 */

 /* eslint-disable */
// TODO(donmccurdy): Don't use ES6 classes.

import * as THREE from 'three'

export default class BasisTextureLoader {

	constructor (manager) {

		// TODO(donmccurdy): Loading manager is unused.
		this.manager = manager || THREE.DefaultLoadingManager;

		this.transcoderPath = '';
		this.transcoderBinary = null;
		this.transcoderPending = null;

		this.workerLimit = 4;
		this.workerPool = [];
		this.workerNextTaskID = 1;
		this.workerSourceURL = '';
		this.workerConfig = {
			format: null,
			etcSupported: false,
			dxtSupported: false,
			pvrtcSupported: false
		};

	}

	setTranscoderPath (path) {

		this.transcoderPath = path;

	}

	detectSupport (renderer) {

		let context = renderer.context;
		let config = this.workerConfig;

		config.etcSupported = Boolean(context.getExtension('WEBGL_compressed_texture_etc1'));
		config.dxtSupported = Boolean(context.getExtension('WEBGL_compressed_texture_s3tc'));
		config.pvrtcSupported = Boolean(context.getExtension('WEBGL_compressed_texture_pvrtc'))
			|| Boolean(context.getExtension('WEBKIT_WEBGL_compressed_texture_pvrtc'));

		if (config.etcSupported) {

			config.format = BasisTextureLoader.BASIS_FORMAT.cTFETC1;

		} else if (config.dxtSupported) {

			config.format = BasisTextureLoader.BASIS_FORMAT.cTFBC1;

		} else if (config.pvrtcSupported) {

			config.format = BasisTextureLoader.BASIS_FORMAT.cTFPVRTC1_4_OPAQUE_ONLY;

		} else {

			throw new Error('BasisTextureLoader: No suitable compressed texture format found.');

		}

		return this;

	}

	load (url, onLoad, onProgress, onError) {

		// TODO(donmccurdy): Use THREE.FileLoader.
		fetch(url)
			.then((res) => res.arrayBuffer())
			.then((buffer) => this._createTexture(buffer))
			.then(onLoad)
			.catch(onError);

	}

	/**
	 * @param  {ArrayBuffer} buffer
	 * @return {Promise<THREE.CompressedTexture>}
	 */
	_createTexture (buffer) {

		return this.getWorker()
			.then((worker) => new Promise( ( resolve ) => {

					var taskID = this.workerNextTaskID++;

					worker._callbacks[ taskID ] = resolve;
					worker._taskCosts[ taskID ] = buffer.byteLength;
					worker._taskLoad += worker._taskCosts[ taskID ];
					worker._taskCount++;

					worker.postMessage( { type: 'transcode', id: taskID, buffer }, [ buffer ] );

				} ))
			.then((message) => {

				let config = this.workerConfig;

				let { data, width, height } = message;

				let mipmaps = [{ data,
width,
height }];

				let texture;

				if (config.etcSupported) {

					texture = new THREE.CompressedTexture(mipmaps, width, height, THREE.RGB_ETC1_Format);

				} else if (config.dxtSupported) {

					texture = new THREE.CompressedTexture(mipmaps, width, height, BasisTextureLoader.DXT_FORMAT_MAP[config.format], THREE.UnsignedByteType);

				} else if (config.pvrtcSupported) {

					texture = new THREE.CompressedTexture(mipmaps, width, height, THREE.RGB_PVRTC_4BPPV1_Format);

				} else {

					throw new Error('BasisTextureLoader: No supported format available.');

				}

				texture.minFilter = THREE.LinearMipMapLinearFilter;
				texture.magFilter = THREE.LinearFilter;
				texture.generateMipmaps = false;
				texture.needsUpdate = true;

				return texture;

			});

	}

	_initTranscoder () {

		if (! this.transcoderBinary) {

			// TODO(donmccurdy): Use THREE.FileLoader.
			let jsContent = fetch(this.transcoderPath + 'basis_transcoder.js')
				.then((response) => response.text());

			let binaryContent = fetch(this.transcoderPath + 'basis_transcoder.wasm')
				.then((response) => response.arrayBuffer());

			this.transcoderPending = Promise.all([jsContent, binaryContent])
				.then(([jsContent, binaryContent]) => {

					let fn = BasisTextureLoader.BasisWorker.toString();

					let body = [
						'/* basis_transcoder.js */',
						'var Module;',
						'function createBasisModule () {',
						'  ' + jsContent,
						'  return Module;',
						'}',
						'',
						'/* worker */',
						fn.substring(fn.indexOf('{') + 1, fn.lastIndexOf('}'))
					].join('\n');

					this.workerSourceURL = URL.createObjectURL(new Blob([body]));
					this.transcoderBinary = binaryContent;

				});

		}

		return this.transcoderPending;

	}

	getWorker () {

		return this._initTranscoder().then(() => {

			if (this.workerPool.length < this.workerLimit) {

				let worker = new Worker(this.workerSourceURL);

				worker._callbacks = {};
				worker._taskCosts = {};
				worker._taskLoad = 0;
				worker._taskCount = 0;

				worker.postMessage({
					type: 'init',
					config: this.workerConfig,
					transcoderBinary: this.transcoderBinary
				});

				worker.onmessage = function (e) {

					let message = e.data;

					switch (message.type) {

						case 'transcode':
							worker._callbacks[message.id](message);
							worker._taskLoad -= worker._taskCosts[message.id];
							delete worker._callbacks[message.id];
							delete worker._taskCosts[message.id];
							break;

						default:
							throw new Error('BasisTextureLoader: Unexpected message, "' + message.type + '"');

					}

				}

				this.workerPool.push(worker);

			} else {

				this.workerPool.sort(( a, b ) => { return a._taskLoad > b._taskLoad ? -1 : 1; });

			}

			return this.workerPool[this.workerPool.length - 1];

		});

	}

	dispose () {

		for (let i = 0; i < this.workerPool.length; i++) {

			this.workerPool[i].terminate();

		}

		this.workerPool.length = 0;

	}
}

/* CONSTANTS */

BasisTextureLoader.BASIS_FORMAT = {
	cTFETC1: 0,
	cTFBC1: 1,
	cTFBC4: 2,
	cTFPVRTC1_4_OPAQUE_ONLY: 3,
	cTFBC7_M6_OPAQUE_ONLY: 4,
	cTFETC2: 5,
	cTFBC3: 6,
	cTFBC5: 7
};

// DXT formats, from:
// http://www.khronos.org/registry/webgl/extensions/WEBGL_compressed_texture_s3tc/
BasisTextureLoader.DXT_FORMAT = {
	COMPRESSED_RGB_S3TC_DXT1_EXT: 0x83F0,
	COMPRESSED_RGBA_S3TC_DXT1_EXT: 0x83F1,
	COMPRESSED_RGBA_S3TC_DXT3_EXT: 0x83F2,
	COMPRESSED_RGBA_S3TC_DXT5_EXT: 0x83F3
};
BasisTextureLoader.DXT_FORMAT_MAP = {};
BasisTextureLoader.DXT_FORMAT_MAP[BasisTextureLoader.BASIS_FORMAT.cTFBC1] =
	BasisTextureLoader.DXT_FORMAT.COMPRESSED_RGB_S3TC_DXT1_EXT;
BasisTextureLoader.DXT_FORMAT_MAP[BasisTextureLoader.BASIS_FORMAT.cTFBC3] =
	BasisTextureLoader.DXT_FORMAT.COMPRESSED_RGBA_S3TC_DXT5_EXT;

/* WEB WORKER */

BasisTextureLoader.BasisWorker = function () {
	let config;
	let transcoderPending;
	let _BasisFile;

	onmessage = function (e) {

		let message = e.data;

		switch (message.type) {

			case 'init':
				config = message.config;
				init(message.transcoderBinary);
				break;

			case 'transcode':
				transcoderPending.then(() => {

					let { data, width, height } = transcode(message.buffer);

					self.postMessage({ type: 'transcode',
id: message.id,
data,
width,
height }, [data.buffer]);

				});
				break;

		}

	};

	function init (wasmBinary) {

		transcoderPending = new Promise((resolve) => {

			// The 'Module' global is used by the Basis wrapper, which will check for
			// the 'wasmBinary' property before trying to load the file itself.

			// TODO(donmccurdy): This only works with a modified version of the
			// emscripten-generated wrapper. The default seems to have a bug making it
			// impossible to override the WASM binary.
			Module = { wasmBinary,
onRuntimeInitialized: resolve };

		}).then(() => {

			let { BasisFile, initializeBasis } = Module;

			_BasisFile = BasisFile;

			initializeBasis();

		});

		createBasisModule();

	}

	function transcode (buffer) {

		let basisFile = new _BasisFile(new Uint8Array(buffer));

		let width = basisFile.getImageWidth(0, 0);
		let height = basisFile.getImageHeight(0, 0);
		let images = basisFile.getNumImages();
		let levels = basisFile.getNumLevels(0);

		function cleanup () {

			basisFile.close();
			basisFile.delete();

		}

		if (! width || ! height || ! images || ! levels) {

			cleanup();
			throw new Error('BasisTextureLoader:  Invalid .basis file');

		}

		if (! basisFile.startTranscoding()) {

			cleanup();
			throw new Error('BasisTextureLoader: .startTranscoding failed');

		}

		let dst = new Uint8Array(basisFile.getImageTranscodedSizeInBytes(0, 0, config.format));

		let startTime = performance.now();

		let status = basisFile.transcodeImage(
			dst,
			0,
			0,
			config.format,
			config.etcSupported ? 0 :  config.dxtSupported ? 1 : 0 ,
			0
		);

		cleanup();

		if (! status) {

			throw new Error('BasisTextureLoader: .transcodeImage failed.');

		}

		return { data: dst,
width,
height };
	}
}
