import { Group } from 'three'

import VideoInterface from '../VideoInterface'
import Resources from '../../../Resources'
import config from '../../../settings/config'
import Action from '../../actions/Action'
import { degreesToRadians } from '../../../utils/rad-deg'
import Button from '../../Button'
import HistoryManager from '../../../managers/HistoryManager'
import DOMManager from '../../../managers/DOMManager'
import USER from '../../../User'

/**
 * HiverOfTheDay is a VideoInterface used in Chapter 2 to trigger the events of the story.
 * Zima appears and insults the user.
 * It is the only Interface useful to the story in Chapter 2.
 * @class
 */
export default class Montage extends VideoInterface {
  constructor(props) {
    super(props)

    this.firstApproach = false
    this.firstRealApproach = false
    this.distanceCheckLoop = false

    this.playVideo()
  }

  setSteps() {
    return [
      config.scenario.chapter3.steps.goToMontage,
      config.scenario.chapter3.steps.theMontage,
      config.scenario.chapter3.steps.privateMessage,
      config.scenario.chapter3.steps.aLotOfMessages
    ]
  }

  setTextures() {
    this.wait = Resources.textures.videos.waitCh3
    this.montageIn = Resources.textures.videos.montageIn
    this.commentPop = Resources.textures.videos.commentPop
    this.commentPop2 = Resources.textures.videos.commentPop2
    this.commentPop3 = Resources.textures.videos.commentPop3
    this.newMessage = Resources.textures.videos.newMessage
    this.messagesIn = Resources.textures.videos.messagesIn
    this.messagesIn2 = Resources.textures.videos.messagesIn2
    this.answer = Resources.textures.videos.answer
    this.messagesPop = Resources.textures.videos.messagesPop
    this.messagesPop2 = Resources.textures.videos.messagesPop2
    this.readMessages = Resources.textures.videos.readMessages
    this.block = Resources.textures.videos.block
    this.returnVid = Resources.textures.videos.return

    return [
      this.wait,
      this.montageIn,
      this.commentPop,
      this.commentPop2,
      this.commentPop3,
      this.newMessage,
      this.messagesIn,
      this.messagesIn2,
      this.answer,
      this.messagesPop,
      this.messagesPop2,
      this.readMessages,
      this.block,
      this.returnVid
    ]
  }

  addButtons() {
    this.createMenuBtn()
    this.createAnswerBtn()
    this.createBlockBtn()

    this.hideButtons(this.menuBtns, false, false)
    this.hideButtons(this.answerBtns, false, false)
    this.hideButtons(this.blockBtns, false, false)
  }

  createMenuBtn() {
    const btn = new Button({
      actions: [
        new Action({
          name: 'Accéder à vos messages privés',
          steps: [
            {
              name: config.scenario.chapter3.steps.theMontage,
              isLast: true
            }
          ],
          interaction: this.onMenuClick.bind(this)
        })
      ],
      isHidden: true,
      size: {
        x: 43,
        y: 1,
        z: 12
      }
    })

    this.menuBtnsGroup = new Group()
    this.menuBtnsGroup.rotation.z = degreesToRadians(90)
    this.menuBtnsGroup.rotation.y = degreesToRadians(-90)
    this.menuBtnsGroup.position.copy(this.mesh.position)
    this.menuBtnsGroup.position.x += 54
    this.menuBtnsGroup.position.y += 50.5
    this.menuBtnsGroup.add(btn.mesh)
    Resources.scene.add(this.menuBtnsGroup)

    Resources.rooms.livingroom.interactives.push(btn)

    this.menuBtns = [btn]

    btn.updateWorldPosition()

    this.buttonGroups.push(this.menuBtns)
    this.buttons.push(btn)
  }

  createAnswerBtn() {
    const btn = new Button({
      actions: [
        new Action({
          name: '“Ça ne m\'atteint pas, tu cherches juste de l\'attention”',
          steps: [
            {
              name: config.scenario.chapter3.steps.privateMessage,
              isLast: false
            }
          ],
          interaction: this.onAnswerClick.bind(this)
        })
      ],
      name: 'Répondre'
    })

    this.answerBtnsGroup = new Group()
    this.answerBtnsGroup.rotation.z = degreesToRadians(90)
    this.answerBtnsGroup.rotation.y = degreesToRadians(-90)
    this.answerBtnsGroup.position.copy(this.mesh.position)
    this.answerBtnsGroup.position.x -= 65
    this.answerBtnsGroup.position.y -= 10
    this.answerBtnsGroup.add(btn.mesh)
    Resources.scene.add(this.answerBtnsGroup)

    Resources.rooms.livingroom.interactives.push(btn)

    this.answerBtns = [btn]

    btn.updateWorldPosition()

    this.buttonGroups.push(this.answerBtns)
    this.buttons.push(btn)
  }

  createBlockBtn() {
    const answerBtn = new Button({
      actions: [
        new Action({
          name: 'Répondre à ce message',
          steps: [
            {
              name: config.scenario.chapter3.steps.aLotOfMessages,
              isLast: false
            }
          ],
          interaction: this.onBlockClick.bind(this, 'answer')
        })
      ],
      name: 'Répondre'
    })
    const blockBtn = new Button({
      actions: [
        new Action({
          name: 'Ignorer et bloquer ces utilisateurs',
          steps: [
            {
              name: config.scenario.chapter3.steps.aLotOfMessages,
              isLast: false
            }
          ],
          interaction: this.onBlockClick.bind(this, 'block')
        })
      ],
      name: 'Bloquer',
      isSecond: true
    })

    // Separe buttons
    const margin = 25

    answerBtn.mesh.position.z -= margin
    blockBtn.mesh.position.z += margin

    this.blockBtnsGroup = new Group()
    this.blockBtnsGroup.rotation.z = degreesToRadians(90)
    this.blockBtnsGroup.rotation.y = degreesToRadians(-90)
    this.blockBtnsGroup.position.copy(this.mesh.position)
    this.blockBtnsGroup.position.x -= 65
    this.blockBtnsGroup.position.y -= 10
    this.blockBtnsGroup.add(answerBtn.mesh, blockBtn.mesh)
    Resources.scene.add(this.blockBtnsGroup)

    Resources.rooms.livingroom.interactives.push(answerBtn, blockBtn)

    this.blockBtns = [answerBtn, blockBtn]

    answerBtn.updateWorldPosition()
    blockBtn.updateWorldPosition()

    this.buttonGroups.push(this.blockBtns)
    this.buttons.push(answerBtn, blockBtn)
  }

  onMenuClick() {
    return new Promise((resolve) => {
      this.updateCurrentVideo(this.messagesIn)

      DOMManager.goalAchieved()

      this.hideButtons(this.menuBtns, false, true)

      resolve()
    })
  }

  onAnswerClick() {
    return new Promise((resolve) => {
      this.updateCurrentVideo(this.answer)

      DOMManager.goalAchieved()

      this.hideButtons(this.answerBtns, true, true)

      resolve()
    })
  }

  onBlockClick(choice) {
    return new Promise((resolve) => {
      this.hideButtons(this.blockBtns, true, true)

      DOMManager.goalAchieved()

      this.updateCurrentVideo(this.block)

      this.sounds.voices.block.play()

      resolve()
    })
  }

  onApproach(remote) {
    if (remote) {
      // Fired by Oven when the player was not looking.
      if (!this.firstApproach) {
        this.setCurrentVideo({
          video: this.montageIn,
          isFirst: true,
          replaceOld: true
        })

        this.distanceCheckLoop = true

        this.firstApproach = true
      }

      super.onApproach()

      return
    }

    if (!this.firstApproach) {
      super.onApproach()

      return
    }

    if (!this.firstRealApproach) {
      HistoryManager.nextStep()

      this.updateCurrentVideo(this.commentPop2)

      this.sounds.voices.surprise.play()

      this.firstRealApproach = true
    }

    super.onApproach()
  }

  popFirstComment() {
    if (this.firstCommentPopped) return

    this.firstCommentPopped = true
    this.updateCurrentVideo(this.commentPop)
  }

  onVideoEnded(video) {
    if (video === this.commentPop2) {
      setTimeout(() => {
        this.updateCurrentVideo(this.commentPop3)
      }, 600)
    } else if (video === this.commentPop3) {
      setTimeout(() => {
        this.updateCurrentVideo(this.newMessage)
        Resources.audios.effects.goodMessage.play()
      }, 750)
    } else if (video === this.newMessage) {
      this.sounds.voices.zimaMsg.play()

      DOMManager.setNewGoal('Lisez le message privé que vous venez de recevoir via le menu de gauche', 'livingroom')

      this.showButtons(this.menuBtns, false)
    } else if (video === this.messagesIn) {
      this.updateCurrentVideo(this.messagesIn2)

      DOMManager.setNewGoal('Répondez à Zima', 'livingroom')
    } else if (video === this.messagesIn2) this.showButtons(this.answerBtns)
    else if (video === this.answer) {
      this.updateCurrentVideo(this.messagesPop)
      
      setTimeout(() => {
        Resources.audios.effects.goodMessage.play()
      }, 1000)
    } else if (video === this.messagesPop) {
      setTimeout(() => {
        this.updateCurrentVideo(this.messagesPop2)

        this.sounds.voices.newMessages.play()

        HistoryManager.nextStep()

        setTimeout(() => {
          Resources.audios.effects.goodMessage.play()
        }, 500)
      }, 550)
    } else if (video === this.messagesPop2) {
      setTimeout(() => {
        this.updateCurrentVideo(this.readMessages)
      }, 700)
    } else if (video === this.readMessages) {
      this.showButtons(this.blockBtns)

      DOMManager.setNewGoal('Choisissez de répondre à ces messages ou d\'ignorer ces Hivers en les bloquant', 'livingroom')
    } else if (video === this.returnVid) this.sounds.voices.again.play()
  }

  onSoundEnded(sound) {
    if (sound === this.sounds.voices.newMessages) this.showButtons(this.blockBtns)
    else if (sound === this.sounds.voices.block) {
      setTimeout(() => {
        Resources.audios.effects.badMessage.play()
      }, 1000)

      setTimeout(() => {
        this.sounds.voices.newMessage.play()
      }, 2100)
    } else if (sound === this.sounds.voices.newMessage) this.updateCurrentVideo(this.returnVid)
    else if (sound === this.sounds.voices.zimaMsg) USER.unlockedNewBadge()
    else if (sound === this.sounds.voices.again) {
      USER.unlockedNewBadge()
      
      setTimeout(() => {
        this.sounds.voices.stress.play()
      }, 1500)
    } else if (sound === this.sounds.voices.stress) HistoryManager.nextStep()
  }
}
