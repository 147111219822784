import VideoInterface from '../VideoInterface'
import Resources from '../../../Resources'
import config from '../../../settings/config'
import HistoryManager from '../../../managers/HistoryManager'

/**
 * WakeUp is a VideoInterface that is the very first to be seen by the user on Chapter 1.
 * It tells the user that an update has been made on Hive and tells him to discover another Interface in the bedroom.
 * @class
 */
export default class WakeUp extends VideoInterface {
  setSteps() {
    return [
      config.scenario.tutorial.steps.wakeUp,
      config.scenario.tutorial.steps.sleepData
    ]
  }

  play() {
    super.playVideo()
    
    this.sounds.voices.hello.play()
  }

  setTextures() {
    this.firstVideo = Resources.textures.videos.wakeUp
    this.secondVideo = Resources.textures.videos.wakeUp2

    return [
      Resources.textures.videos.wakeUp,
      Resources.textures.videos.wakeUp2
    ]
  }

  onSoundEnded(sound) {
    if (sound === this.sounds.voices.hello) HistoryManager.nextStep()
  }

  onVideoEnded(video) {
    if (video === this.firstVideo) this.updateCurrentVideo(this.secondVideo)
  }
}
