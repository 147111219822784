export default [
	{
		start: 0.05,
		end: 3.7,
		text: 'Vous n\'avez pas répondu à 890 messages depuis six jours...'
	},
	{
		start: 3.9,
		end: 7,
		text: 'Vous ne partagez plus rien sur le réseau et par conséquent...'
	},
	{
		start: 7.1,
		end: 9.75,
		text: '...vous êtes récemment descendu au rang 3.'
	},
	{
		start: 10,
		end: 12.4,
		text: 'Il est temps de remonter la barre, Hiver.'
	},
	{
		start: 12.5,
		end: 15,
		text: 'Supprimez ces messages qui vous font du mal.'
	}
]
