import { Power2, TimelineMax, Power1 } from 'gsap/TweenMax'

import Chapter from '../Chapter'
import DOMManager from '../../managers/DOMManager'
import WakeUpTomorrow from './WakeUpTomorrow'
import GoToKitchen from './GoToKitchen'
import ALotOfMessages from './ALotOfMessages'
import Food from './Food'
import GoToMontage from './GoToMontage'
import CameraManager from '../../managers/CameraManager'
import AudioManager from '../../managers/AudioManager'
import Resources from '../../Resources'
import config from '../../settings/config'
import { degreesToRadians } from '../../utils/rad-deg'
import Weather from '../../objects/interfaces/global/Weather'
import Score from '../../objects/interfaces/chapter1/Score'
import Step from '../Step'
import GameManager from '../../managers/GameManager'

/**
 * The Chapter3 is the backbone of the story as it will continuously grow the tension.
 * @class
 */
export default class Chapter3 extends Chapter {
	constructor(props = {}) {
		super({
			name: config.scenario.chapter3.name,
			steps: props.steps,
			refreshInterfacesOnStart: props.refreshInterfacesOnStart
		})
	}

	createSteps() {
		return [
			new WakeUpTomorrow(),
			new GoToKitchen(),
			new Food(),
			new GoToMontage(),
			new Step(config.scenario.chapter3.steps.theMontage),
			new Step(config.scenario.chapter3.steps.privateMessage),
			new ALotOfMessages()
		]
	}

	/**
	 * Starts the Chapter by adding contextual text and fading in the black layer (as it has been faded out at the end of the last Chapter).
	 *
	 * @returns {Promise} A Promise resolved when the animations are over.
	 */
	onStart() {
		return new Promise((resolve) => {
			const interfaces = Resources.rooms.bedroom.interfaces
			
			for (let i = 0; i < interfaces.length; i++) {
				if (interfaces[i] instanceof Weather) interfaces[i].nextDay()
				else if (interfaces[i] instanceof Score) interfaces[i].nextDay()
			}

			CameraManager.resetDefaultCam(true)
			CameraManager.defaultCamera.position.set(-155, 60, 40)
			CameraManager.defaultCamera.rotation.x = degreesToRadians(50)
			CameraManager.defaultCamera.rotation.y = degreesToRadians(-90)
			GameManager.updateSkyBox(3)

			AudioManager.playMusic({
				intro: Resources.audios.ambiences.chapter3,
				loop: Resources.audios.ambiences.chapter3Loop
			})

			DOMManager.leaveInterlude(2)
				.then(this.wakeUp.bind(this))
				.then(resolve)
		})
	}

	wakeUp() {
		return new Promise((resolve) => {
			if (config.debug) {
				CameraManager.setStandUpPosition(true)

				resolve()

				return
			}

			const tl = new TimelineMax({
				onComplete: () => {
					CameraManager.activate()
					resolve()
				}
			})
			const state1 = {
				position: {
					from: {
						posX: -155,
						posY: 60
					},
					to: {
						posX: -85,
						posY: 120
					},
					i: {
						posX: -156,
						posY: 117
					},
					i2: {
						posX: -122,
						posY: 124
					}
				},
				rotation: {
					from: {
						x: 50,
						y: -90
					},
					to: {
						x: -10,
						y: -90
					}
				}
			}
			const state2 = {
				rotation: {
					from: {
						x: -10,
						y: -90
					},
					to: {
						x: -7.5,
						y: -180
					}
				},
				position: {
					from: {
						posX: -85,
						posY: 120,
						posZ: 40
					},
					to: {
						posX: -85,
						posY: config.user.height,
						posZ: 85
					}
				}
			}

			tl.to(state1.rotation.from, 2, {
					x: state1.rotation.to.x,
					y: state1.rotation.to.y,
					ease: Power1.easeInOut,
					onUpdate: () => {
						CameraManager.rotateOn('y', Number(state1.rotation.from.y))
						CameraManager.rotateOn('x', Number(state1.rotation.from.x))
					}
				})
				.to(state1.position.from, 2, {
					bezier: [{
						posX: state1.position.from.posX,
						posY: state1.position.from.posY
					},
					{
						posX: state1.position.i.posX,
						posY: state1.position.i.posY
					},
					{
						posX: state1.position.i2.posX,
						posY: state1.position.i2.posY
					},
					{
						posX: state1.position.to.posX,
						posY: state1.position.to.posY
					}],
					ease: Power1.easeInOut,
					onUpdate: () => {
						CameraManager.defaultCamera.position.x = Number(state1.position.from.posX)
						CameraManager.defaultCamera.position.y = Number(state1.position.from.posY)
					}
				}, '-=2')
				.to(state2.rotation.from, 1, {
					x: state2.rotation.to.x,
					y: state2.rotation.to.y,
					ease: Power1.easeInOut,
					onUpdate: () => {
						CameraManager.rotateOn('y', Number(state2.rotation.from.y))
						CameraManager.rotateOn('x', Number(state2.rotation.from.x))
					}
				})
				.to(state2.position.from, 1, {
					posX: state2.position.to.posX,
					posY: state2.position.to.posY,
					posZ: state2.position.to.posZ,
					ease: Power2.easeInOut,
					onUpdate: () => {
						CameraManager.defaultCamera.position.x = Number(state2.position.from.posX)
						CameraManager.defaultCamera.position.y = Number(state2.position.from.posY)
						CameraManager.defaultCamera.position.z = Number(state2.position.from.posZ)
					}
				}, '-=1')
		})
	}

	/**
   * Ends this Chapter by fading out the screen until its black, by using DOMManager.
   *
   * @returns {Promise} A Promise resolved when the DOMManager finished the animation.
   */
	onEnd() {
		return DOMManager.interlude(3)
	}
}
