export default [
	{
		start: 0.1,
		end: 3.8,
		text: 'Regardez ! C\'est une belle journée qui commence. Et vous avez bonne mine !'
	},
	{
		start: 4,
		end: 5.9,
		text: 'Vous avez passé une très bonne nuit.'
	},
	{
		start: 6,
		end: 9,
		text: 'Voulez-vous la partager avec votre communauté ?'
	}
]
